import moment, {now} from "moment";
import {Tag} from "antd";
import React from "react";

export function AttendanceStepDateAlertComponent(props: {
  endDate: string
}) {
  const dateTime = moment(props.endDate).set({ hours: 0, minute: 0, second: 0, millisecond: 0});
  const now = moment().set({ hours: 0, minute: 0, second: 0, millisecond: 0});
  const daysDiff = dateTime.diff(now, 'days');
  
  if (daysDiff < 0) {
    return <Tag color={'red'}>Atrasada</Tag>
  }
  if (daysDiff === 0) {
    return  <Tag color={'warning'}> Vence Hoje </Tag>
  }
  return  <Tag color={'processing'}> Vence em {daysDiff} dias </Tag>
}