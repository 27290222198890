import {ModulePages} from "../../core/module-pages";
import {ListCategoryPage} from "./page/list.category";
import {CreateCategoryPage} from "./page/create.category";
import {EditCategoryPage} from "./page/edit.category";

export function FinanceCategoryModule() {
  return (
    <ModulePages routes={[
      { path: 'create', render: (props) => <CreateCategoryPage {...props} />},
      { path: 'edit/:id', render: (props) => <EditCategoryPage {...props} />},
      { path: 'list', render: (props) => <ListCategoryPage {...props} />},
      { path: '', render: (props) => <ListCategoryPage {...props} />},
      ]}
    />
  )
}
