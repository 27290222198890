import {ModulePages} from "../../core/module-pages";
import {ListWorkflowPage} from "./pages/list.workflow-page";
import {CreateWorkflowPage} from "./pages/create.workflow.page";
import {EditWorkflowPage} from "./pages/edit.workflow.page";

export function WorkflowModule(props: any) {
  return (
    <ModulePages routes={[
      { path: 'create', render: props => <CreateWorkflowPage {...props} /> },
      { path: 'edit/:id', render: props => <EditWorkflowPage {...props} /> },
      { path: '', render: props => <ListWorkflowPage {...props} /> }
    ]} />
  )
}

