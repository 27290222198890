import logo from "../../media/logo_juristtabusiness_branca.png";
import {UserOutlined} from "@ant-design/icons";

export function PublicHeaderComponent(props: {
  clientName: string,
}) {
  return (
    <div style={{
      width: '100%',
      backgroundColor: '#303030',
      height: '150px',
      textAlign: 'center',
      display: 'flex',
      
    }}>
      <div style={{
        flex: 1,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '20px',
        justifyContent: 'flex-start'
      }}>
        <img src={logo} height={'50%'}/>
      </div>
      <div style={{
        flex: 4,
        height: '100%',
        fontSize: 44,
        color: 'white',
        fontWeight: 'bold',
        verticalAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {props.clientName}
      </div>
    </div>
  )
}