import {Badge, Button, List, Popover} from "antd";
import {CalendarOutlined, NotificationOutlined, PushpinOutlined} from "@ant-design/icons";
import React, {useContext, useMemo} from "react";
import {
  AdminApplicationContext,
} from "../../../../core/context/admin-application.context";
import {NotificationEntity} from "../../../../domain/entity/notification.entity";
import {useHistory, useRouteMatch} from "react-router-dom";

export function NotificationButton() {
  const { notifications, readNotifications } = useContext<any>(AdminApplicationContext);
  
  const notificationCount = useMemo(() => {
    return  notifications?.length ?? 0;
  }, [notifications])
  
  
  function renderNotificationList() {
    return (
      <NotificationList
        notifications={notifications}
      />
    )
  }
  
  return (
    <Popover
      placement={'leftTop'}
      trigger={'click'}
      overlayInnerStyle={{
        maxHeight: '90vh',
        overflowY: 'auto'
      }}
      overlayStyle={{
        width: 400,
      }}
      onOpenChange={visible => !visible && readNotifications(notifications.map((w: any) => w.id))}
      content={() => renderNotificationList()}
    >
      <Badge count={notificationCount}>
        <Button
          icon={<NotificationOutlined />}
          type={'primary'}
          size={'large'}
          shape={'circle'}
          style={{
            boxShadow: '5px 5px 5px #888888',
          }}
        />
      </Badge>
    </Popover>
  )
}

function NotificationList(props: { notifications: NotificationEntity[] }) {
  const history = useHistory();
  const { applicationPrefix } = useContext<any>(AdminApplicationContext);
  
  function actionLink(notificationName: string) {
    switch (notificationName) {
      case 'new_external_attendance':
        return `/${applicationPrefix}/external-attendance`;
      default:
        return '';
    }
  }
  
  return (
    <List
      dataSource={props.notifications}
      renderItem={item => (
        <List.Item
          className={'list-item'}
          key={item.id}
          onClick={() => history.push(actionLink(item.name))}
          style={{
            cursor: 'pointer',
          }}
        >
          <List.Item.Meta
            avatar={<NotificationIcon notification={item} />}
            title={item.title}
            description={item.message}
          ></List.Item.Meta>
        </List.Item>
      )}
    />
  )
}



function NotificationIcon(props: { notification: NotificationEntity }) {
  switch (props.notification.name) {
    case 'new_external_attendance':
      return  <CalendarOutlined />
    default:
      return <NotificationOutlined />
  }
}