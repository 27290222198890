import {Badge, Button, Menu, Popover} from "antd";
import {LogoutOutlined, NotificationOutlined, SettingOutlined, UserOutlined} from "@ant-design/icons";
import {useJbHttpAuthService} from "../../../../core/service/jb-http-auth.service";
import {useContext} from "react";
import {AdminApplicationContext} from "../../../../core/context/admin-application.context";

export function AccountButtonComponent() {
  const ctx = useContext(AdminApplicationContext)
  const authService = useJbHttpAuthService()

  function AccountOptions() {
    return (
      <div>
        <Menu
          items={[
            {
              key: 0,
              label: 'Trocar de Contexto',
              icon: <SettingOutlined />,
              onClick: () => ctx?.showModalContext(),
              
            },
            {
              key: 1,
              label: 'Trocar de Senha',
              icon: <UserOutlined />,
              onClick: () => ctx?.setShowChangePasswdModal(true),
              
            },
            {
              key: 99,
              label: 'Sair',
              icon: <LogoutOutlined />,
              onClick: () => authService.logout()
            }
        ]} />
      </div>
    )
  }
  return (
    <Popover
      title={ctx?.user?.person.name}
      content={AccountOptions}
    >
      <Button
        icon={<UserOutlined />}
        type={'primary'}
        size={'large'}
        shape={'circle'}
        style={{
          width: '50px',
          height: '50px',
          boxShadow: '5px 5px 5px #888888'
        }}
      />
    </Popover>
  )
}
