import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {Avatar, List, Timeline} from "antd";
import {useMemo} from "react";
import {AttendanceAuditEntity} from "../../domain/entity/attendance-audit.entity";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";

export function AttendanceAuditEvents(props: {
  attendance: AttendanceEntity
}) {
  const {formDateInPtBr} = useDatetimeUtil();
  const items = useMemo(() => {
    return props.attendance?.auditEvents?.sort(
      (a,b) => {
        if (new Date(a.createdAt) > new Date(b.createdAt)) return -1;
        if (new Date(a.createdAt) < new Date(b.createdAt)) return 1;
        return 0
      }
    )
  }, [props.attendance])
  
  function formatTitle(auditItem: AttendanceAuditEntity) {
    let op = '';
    switch (auditItem.eventType) {
      case "CREATE":
        op = 'criou o atendimento'
        break;
      case "EDIT":
        op = 'alterou'
        break;
      case "DELETE":
        op = 'removeu'
        break;
      default: break;
    }
    const dt = formDateInPtBr(auditItem.createdAt)
    return `${auditItem.createdBy} ${op} em ${dt}`;
  }
  
  
  return (
    <List
      dataSource={items}
      renderItem={(item: AttendanceAuditEntity) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar children={item.createdBy} gap={1} style={{backgroundColor: '#8024f4'}}  /> }
            title={formatTitle(item)}
            description={<pre>{item.message}</pre>}
          />
        </List.Item>
      )}
    />
  )
}