import {Divider, Form, Input, Modal, Tag} from "antd";
import {JbFormActionButtonComponent} from "../../admin/core/component/jb-form-action-button.component";
import ReCAPTCHA from "react-google-recaptcha";
import {useEnvHook} from "../../../core/common/env-hook";
import {useState} from "react";
import {FileOutlined} from "@ant-design/icons";
import {MaskedInput} from "antd-mask-input";

export function PublicAttendanceInteractionFormModal(props: {
  onSubmit: (values: any) => any,
  open?: boolean,
  onCancel: () => any,
  currentContent: string,
  file?: File,
}) {
  const [form] = Form.useForm();
  const env = useEnvHook();
  const [captchaValue ,setCaptchaValue] = useState<string>();

  function onFinish(values: any) {
    values['contentType'] = props.file ? 'file' : 'text';
    values['meta'] = {
      email: values['email'],
      tell: values['tell']
    }
    return props.onSubmit({
      ...values,
      captchaToken: captchaValue,
    })
  }
  
  return (
    <Modal
      width={800}
      footer={null}
      onCancel={props.onCancel}
      open={props.open}
    >
      <Form
        form={form}
        layout={'vertical'}
        initialValues={{ content: props.currentContent, }}
        onFinish={onFinish}
      >
        {
          props.file ? (
            <>
              <Tag icon={<FileOutlined />}>{props.file.name}</Tag>
              <Divider />
            </>
            
          ) : (
            <Form.Item name={'content'} label={'Comentário'}>
              <Input />
            </Form.Item>
          )
        }
        
        <Form.Item name={'createdBy'} label={'Identifique-se (nome): '} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        
        <Form.Item name={'email'} label={'Email: '}>
          <Input type={'email'} />
        </Form.Item>
        
        <Form.Item name={'tell'} label={'Telefone para contato: '} rules={[ { required: true }]}>
          <MaskedInput mask={'(00)00000-0000'} />
        </Form.Item>
  
        {
          env.getRecaptchaKey() && (
            <ReCAPTCHA
              onChange={(value) => value && setCaptchaValue(value)}
              sitekey={env.getRecaptchaKey()} />
          )
        }
        
        <JbFormActionButtonComponent
          onCancel={props.onCancel}
          saveDisabled={!captchaValue}
        />
        
      </Form>
    </Modal>
  )
}