import {TenantEntity} from "../../../../../domain/entity/tenant.entity";
import {Modal} from "antd";
import {TenantFormComponent} from "./tenant.form.component";
import {useTenantRepository} from "../../../../../domain/repository/tenant.repository";

export function TenantFormModalComponent(props: {
  editTenant?: TenantEntity,
  onSave: () => any,
  onCancel: () => any,
  open?: boolean,
}) {
  const tenantRepo = useTenantRepository();
  
  function onSubmit(values: any) {
    if (props.editTenant) {
      tenantRepo.edit(props.editTenant.id, values).then(onFinish)
    } else {
      tenantRepo.create(values).then(onFinish)
      
    }
  }
  
  function onFinish(res: any) {
    props.onSave()
  }
  
  return (
    <Modal
      title={'Novo Cliente'}
      width={800}
      open={props.open}
      onCancel={props.onCancel}
      footer={null}
    >
      <TenantFormComponent
        onSubmit={onSubmit}
        values={props.editTenant}
      />
    </Modal>
  )
}