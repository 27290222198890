import {Button, Table} from "antd";
import {FinanceEntryTypeComponent} from "./finance-entry.type.component";
import {FinanceEntryAmountComponent} from "./finance-entry.amount.component";
import {FinanceEntryStatus} from "./finance-entry.status.componenet";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {useDatetimeUtil} from "../../../../../core/util/datetime.util";
import {FinanceEntryEntity} from "../../../../../domain/entity/finance-entry.entity";
import {useEffect, useMemo, useState} from "react";
import {useFinanceEntryRepository} from "../../../../../domain/repository/finance-entry.repository";
import {ArrowDownOutlined, RollbackOutlined} from "@ant-design/icons";
import {JbButtonWithPopover} from "../../../core/button-with-popover";
import {ModalRegisterPaymentForm} from "./register-payment.form.component";

export function ListFinanceEntry(props: {
  onShow: (entry: FinanceEntryEntity) => any,
  onEdit: (id: string) => any,
  filter?: any,
}) {
  const [showRegisterPayment, setShowRegisterPayment] = useState<FinanceEntryEntity>();
  const [entries, setEntries] = useState<FinanceEntryEntity[]>([])
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0)
  const entryRepo = useFinanceEntryRepository();
  
  function loadData() {
    const offset = (page - 1) * limit;
    entryRepo.search(
      limit,
      offset,
      props.filter
    ).then(res => setEntries(res.data?.data));
  }
  
  function onRemove(id: string) {
    entryRepo.remove(id).then(() =>  loadData())
  }
  
  useEffect(() => {
    loadData()
  }, [page, limit, props.filter]);
  
  const {formDateInPtBr} = useDatetimeUtil();
  
  function getOnRemove(item: FinanceEntryEntity) {
    if (item.status === 'opened') {
      return () => onRemove(item.id)
    }
    return undefined;
  }
  
  function cancelEntry(id: string) {
    return entryRepo.cancelEntry(id).then(() => loadData())
  }
  
  function canEdit(item: FinanceEntryEntity) {
    return item.status === 'opened';
  }
  
  function canCancel(item: FinanceEntryEntity) {
    return item.status !== 'canceled';
  }
  
  return (
    <>
      <Table
        dataSource={entries}
        pagination={{
          pageSize: limit,
          current: page,
          total,
          onChange: (page, pageSize) => {
            setPage(page)
            setLimit(pageSize)
          }
        }}
        columns={[
          {
            title: 'Cliente/Fornecedor',
            key: 'clientSupplier',
            dataIndex: 'type',
            render: (type, row) => type === 'R' ? row?.client?.name : row?.supplier?.name,
          },
          {
            title: 'Type',
            dataIndex: 'type',
            render: type =>  <FinanceEntryTypeComponent type={type} />
          },
          {
            title: 'Valor',
            dataIndex: 'amount',
            render: (amount: number) => <FinanceEntryAmountComponent amount={amount} />
          },
          {
            title: 'Situação',
            dataIndex: 'status',
            render: status => <FinanceEntryStatus status={status} />
          },
          {
            title: 'Criado Em',
            dataIndex: 'createdAt',
            render: formDateInPtBr
          },
          {
            title: 'Data de Vencimento',
            dataIndex: 'dueDate',
            render: formDateInPtBr
          },
          {
            title: 'Baixado Em',
            dataIndex: 'lastPaymentDate',
            render: formDateInPtBr
          },
          {
            title: '',
            dataIndex: 'id',
            align: 'right',
            render: (id, item) => (
              <JbTableRowActionsComponent
                onShow={() => props.onShow(entries.find(w => w.id === id) as FinanceEntryEntity)}
                onRemove={canEdit(item) ? getOnRemove(item) : undefined}
                onEdit={canEdit(item) ? () => props.onEdit(id) : undefined}
                extra={[
                  canEdit(item) ?
                    <JbButtonWithPopover
                      title={'Baixar Lançamento'}
                      type={'primary'}
                      icon={<ArrowDownOutlined />}
                      size={'small'}
                      onClick={() => setShowRegisterPayment(item)}
                    /> : undefined,
                  canCancel(item) ?
                    <JbButtonWithPopover
                      title={'Cancelar Lançamento'}
                      color={'danger'}
                      icon={<RollbackOutlined />}
                      size={'small'}
                      onClick={() => cancelEntry(id)}
                    /> : undefined,
                ]}
              />
            )
          }
        ]}
      />
      
      {
        showRegisterPayment && (
          <ModalRegisterPaymentForm
            onClose={() => setShowRegisterPayment(undefined)}
            defaultValue={showRegisterPayment.amount}
            onSubmit={values => {
              entryRepo.registerPayment(
                showRegisterPayment.id,
                values.amount,
                values.method,
                values.paymentDate,
              ).then(() => {
                setShowRegisterPayment(undefined)
                loadData()
              })
            }}
          />
        )
      }
    </>
  )
}