import {ModulePageProps} from "../../../core/module-pages";
import {Col, Form, Modal, Row, Select, Tabs} from "antd";
import {ListAttendanceStepsComponent} from "../component/list.attendance-step.component";
import {useEffect, useMemo, useState} from "react";
import {AttendanceStepEntity} from "../../attendance/domain/entity/attendance-step.entity";
import {useAttendanceStepRepository} from "../../attendance/domain/repository/attendance-step.repository";
import {AttendanceStepCardComponent} from "../../attendance/component/full-attendance/attendance-step-card.component";
import {AttendanceEntity} from "../../attendance/domain/entity/attendance.entity";
import {useAttendanceRepository} from "../../attendance/domain/repository/attendance.repository";
import {FullAttendanceComponent} from "../../attendance/component/full-attendance";
import {AttendanceCardComponent} from "../component/attendance-card.component";
import {StatusEnum} from "../../../../../domain/vo/status.enum";
import {JbSearchableSelectComponent} from "../../../core/component/jb-searchable-select.component";
import {ListAttendanceComponent} from "../component/list.attendance.component";

export function Main(props: ModulePageProps) {
  
  return (
    <>
      <Tabs
        tabPosition={'top'}
        items={[
          { key: '1' , label: 'Pendente de Conclusão', children: <PendingTab /> },
          { key: '2' , label: 'Meus Atendimentos', children: <Attendances /> },
        ]}
      />
    </>
  )
}

function PendingTab(props: any) {
  const [pendingSteps, setPendingSteps] = useState<AttendanceStepEntity[]>([]);
  const attendanceStepRepo = useAttendanceStepRepository();
  const [selectedStep, setSelectedStep] = useState<AttendanceStepEntity | undefined>();

  function loadData() {
    setSelectedStep(undefined)
    attendanceStepRepo.listPendingSteps()
      .then(res => setPendingSteps(res.data))
  }
  
  useEffect(() => {
    loadData()
  }, [])
  
  return (
    <>
      <ListAttendanceStepsComponent
        onSelect={setSelectedStep}
        attendanceSteps={pendingSteps}
      />
      {
        selectedStep && (
          <Modal
            width={800}
            footer={null}
            onCancel={() => setSelectedStep(undefined)}
            open
            bodyStyle={{padding: 0}}
            closable={false}
          >
            <AttendanceStepCardComponent
              step={selectedStep}
              reloadAttendance={loadData}
            />
          </Modal>
        )
      }
    </>
  )
}

function Attendances(props: any) {
  const [myAttendances, setMyAttendances] = useState<AttendanceEntity[]>([]);
  const [showAttendance, setShowAttendance] = useState<AttendanceEntity>();
  const [filter, setFilter] = useState<any>();
  const attendanceRepo = useAttendanceRepository();
  
  function loadMyAttendances() {
    attendanceRepo.listMyAttendances().then(res => setMyAttendances(res.data))
  }
  
  useEffect(() => {
    loadMyAttendances();
  }, [])
  
  const statusOpts = useMemo(() => {
    const statuses = new Set<string>();
    myAttendances.forEach(w => { statuses.add(w.status) })
    return Array.from(statuses).map(w => ({ label: StatusEnum.parse(w), value: w }))
  }, [myAttendances])
  
  const clients = useMemo(() => {
    const clients = new Map<string, string>();
    myAttendances.forEach(w => { clients.set(w.client?.id, w.client.name) })
    return Array.from(clients).map(([id, name]) => ({ label: name, value: id }))
  }, [myAttendances])
  
  const filteredData = useMemo(() => {
    return myAttendances?.filter(w => {
      if (filter?.clientId && w.client?.id !== filter.clientId) {
        return false;
      }
      if (filter?.status && filter.status !== w.status) {
        return false;
      }
      return true;
    })
  }, [myAttendances,filter])
  
  return (
    <>
      <Form onValuesChange={(changed, values) => setFilter(values)}>
        <Row gutter={12}>
          <Col span={4}>
            <Form.Item name={'status'}>
              <Select allowClear placeholder={'Status'} options={statusOpts} />
            </Form.Item>
          </Col>
          <Col span={6}>
              <JbSearchableSelectComponent
                name={'clientId'}
                options={clients}
                label={'Cliente'}
              />
          </Col>
        </Row>
      </Form>
      <ListAttendanceComponent
        attendances={filteredData}
        onSelected={att => setShowAttendance(att)}
      />
      {/*<div style={{width: '100%', display: "flex", gap: 12, flexWrap: 'wrap', }}>*/}
      {/*  {*/}
      {/*    filteredData.map((attendance) => (*/}
      {/*      <div style={{flexBasis: 450, cursor: 'pointer'}} onClick={() => setShowAttendance(attendance)}>*/}
      {/*        <AttendanceCardComponent*/}
      {/*          attendance={attendance}*/}
      {/*          key={attendance.id}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    ))*/}
      {/*  }*/}
      {/*</div>*/}
      {
        showAttendance && (
          <Modal
            footer={null}
            onCancel={() => setShowAttendance(undefined)}
            width={'85vw'}
            open
          >
            <FullAttendanceComponent attendanceId={showAttendance.id} />
          </Modal>
        )
      }
    </>
  )
}
