import {Button, Form, Input, message} from "antd";
import logo from "./../../../media/logo_juristtabusiness_branca.png";
import {useJbHttpAuthService} from "../../../core/service/jb-http-auth.service";
import {useHistory} from "react-router-dom";
import {KeyOutlined, UserOutlined} from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import {useEnvHook} from "../../../core/common/env-hook";
import {useMemo, useState} from "react";
import './override.css'
import {PASSWORD_PATTERN} from "../../admin/module/super-admin/component/change-user.form";

export function ResetPassword(props: any) {
  const [form] = Form.useForm();
  const [captcha, setCaptcha] = useState<string>();
  const authService = useJbHttpAuthService()
  const history = useHistory()
  const keyCaptcha = useEnvHook().getRecaptchaKey();
  
  const token = useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    return query.get('token') ?? ''
  }, [window.location.search] )
  
  function onSubmit(values: any) {
    if (values.newPassword !== values.confirmPassword) {
      form.setFields([
        { name: 'confirmPassword', errors: [ 'As senhas informadas não são iguais' ]}
      ])
      return;
    }
    if (!values.newPassword.match(PASSWORD_PATTERN)) {
      form.setFields([
        { name: 'newPassword', errors: [ 'As nova senha precisa ter 6 digitos com letras e númeors' ]}
      ])
      return;
    }
    authService.resetPassword(token , values.newPassword).then(res => {
      history.push('/admin')
    })
  }

  return (
    <div style={{
      width: '100%',
      height: '100vh',
      backgroundImage: 'url(/bg-login.png)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }}>
      <div style={{
        width: '450px',
        padding: '30px 20px 40px 20px',
        borderRadius: '5px',
        marginTop: '50px'
      }}>
        <img src={logo} style={{
          width: '100%',
          marginBottom: '10px'
        }} />
        <Form size={'large'} onFinish={onSubmit} form={form} style={{padding: '25px'}}>
          <Form.Item name={'newPassword'}>
            <Input.Password
              placeholder={'Informe uma nova senha'}
              size={'large'}
              prefix={<KeyOutlined />}
            />
          </Form.Item>
          <Form.Item name={'confirmPassword'}>
            <Input.Password
              placeholder={'Confirmar Informe uma nova senha'}
              size={'large'}
              prefix={<KeyOutlined />}
            />
          </Form.Item>
          {
            keyCaptcha && (
              <ReCAPTCHA
                onChange={token => token && setCaptcha(token)}
                sitekey={keyCaptcha}
                theme={'dark'}
                size={'normal'}
              />
            )
          }
          
          <Button
            disabled={!captcha}
            htmlType={'submit'}
            type={'primary'}
            size={'large'}
            style={{ marginTop: 20 }}
            block
          >
            Salvar
          </Button>
      </Form>
    </div>
    
    </div>
)
}
