import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React from "react";
import {useProcessTypeRepository} from "../domain/repository/process-type.repository";
import {FormProcessTypeComponent} from "../component/form.process-type.component";

export function CreateProcessTypePage(props: ModulePageProps) {
  const processTypeRepo = useProcessTypeRepository()

  function onCreate(values: any) {
    if (!values.integrationEnabled) {
      values.integrationEnabled = false
    }
    return processTypeRepo.create(values).then(res => {
      props.navigator(`edit/${res.data.id}?tab=1`)
    })
  }

  return (
    <>
      <JbPageHeaderComponent
        title={'Parametrizações / Tipo de Process'}
        description={'Cadastrar Tipo de Processo'}
      />
      <FormProcessTypeComponent
        onSubmit={onCreate}
      />
    </>
  )
}
