import {AttendancePerStatusGraphComponent} from "../component/attendance-per-status.graph.component";
import {useEffect, useMemo, useState} from "react";
import {AttendanceReportEntity} from "../../../../../domain/entity/attendance-report.entity";
import {useAttendanceReportRepository} from "../../../../../domain/repository/attendance-report.repository";
import {AttendancePerOriginGraphComponent} from "../component/attendance-per-origin.graph.component";
import {AttendanceNewAgainstCurrentGraphComponent} from "../component/attendance-new-against-current.graph.component";
import {Col, Divider, Row, Statistic} from "antd";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {AttendanceStepReportEntity} from "../../../../../domain/entity/attendance-step-report.entity";
import {DashboardTimeFilterComponent} from "../component/dashboard-time-filter.component";
import moment from "moment";
import {ProcessReportEntity} from "../../../../../domain/entity/process-report.entity";
import {ProcessPerTypeGraphComponent} from "../component/process-per-type.graph.component";
import {ProcessPerObjectGraphComponent} from "../component/process-per-object.graph.component";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export function DashboardIndex(props: any) {
  const [data, setData] = useState<AttendanceReportEntity[]>([])
  const [dateProcess, setDataProcess] = useState<ProcessReportEntity[]>([]);
  const [dataSteps, setDataSteps] = useState<AttendanceStepReportEntity[]>([])
  const [filter, setFilter] = useState<any>({
    start: moment().add(-6, 'month').toISOString(true),
    end: moment().toISOString(true)
  })
  const attendanceReportRepo = useAttendanceReportRepository();
  
  function loadDate() {
    attendanceReportRepo
      .getReport(filter.start, filter.end)
      .then(res => setData(res.data))
    
    attendanceReportRepo
      .getStepsReport(filter.start, filter.end)
      .then(res => setDataSteps(res.data))
    
    attendanceReportRepo
      .getProcessReport(filter.start, filter.end)
      .then(res => setDataProcess(res.data))
  }
  
  useEffect(() => {
    loadDate()
  }, [filter])
  
  const attendanceTotal = useMemo(() => {
    return data.length;
  }, [data])
  
  const attendanceClosed = useMemo(() => {
    return data.filter(w => w.status === 'Concluído').length;
  }, [data])
  
  const stepsClosed = useMemo(() => {
    return dataSteps.filter(w => w.finished).length
  }, [dataSteps])
  
  const stepsDelayed = useMemo(() => {
    return dataSteps.filter(w => !w.finished && new Date(w.endDate) < new Date()).length
  }, [dataSteps])
  
  return (
    <>
      <JbPageHeaderComponent
        title={'Dashboard Business'}
        description={'Cheque os insights do seus negócio'}
      />
      <DashboardTimeFilterComponent
        initialValues={filter}
        onFilter={setFilter}
      />
      <Divider />
      <Row>
        <Col span={6}>
          <Statistic
            value={attendanceTotal}
            title={'Atendimentos em Aberto'}
          />
        </Col>
        <Col span={6}>
          <Statistic
            value={attendanceClosed}
            title={'Atendimentos Concluídos'}
          />
        </Col>
        <Col span={6}>
          <Statistic
            value={stepsClosed}
            valueStyle={{ color: 'green' }}
            title={'Movimentações Finalizadas'}
          />
        </Col>
        <Col span={6}>
          <Statistic
            value={stepsDelayed}
            valueStyle={{ color: 'red' }}
            title={'Movimentações Em Atraso'}
          />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={12}>
          <AttendancePerStatusGraphComponent
            reports={data}
            colors={COLORS}
          />
        </Col>
        <Col span={12}>
          <AttendancePerOriginGraphComponent
            reports={data}
            colors={COLORS}
          />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={12}>
          <ProcessPerTypeGraphComponent reports={dateProcess} colors={COLORS} />
        </Col>
        <Col span={12}>
          <ProcessPerObjectGraphComponent reports={dateProcess} colors={COLORS} />
        </Col>
      </Row>
      <Divider />
      <AttendanceNewAgainstCurrentGraphComponent
        reports={data}
        colors={COLORS}
      />
    </>
  )
}