import {Button, List, Space, Tag} from "antd";
import {FinanceEntryTypeComponent} from "../../finance-entry/component/finance-entry.type.component";
import {SettingOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {FinanceEntryEntity} from "../../../../../domain/entity/finance-entry.entity";
import {useFinanceEntryRepository} from "../../../../../domain/repository/finance-entry.repository";
import moment from "moment";
import {ShowFinanceEntryModal} from "../../finance-entry/component/show-finance-entry/modal";

export function FinanceEntriesComponent() {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency', currency: 'BRL'
  })
  const [entries, setEntries]  = useState<FinanceEntryEntity[]>([])
  const [showEntry, setShowEntry] = useState<FinanceEntryEntity>();
  const entryRepo = useFinanceEntryRepository();
  const loadEntries = () => entryRepo.loadPendingEntries().then(res => setEntries(res.data));
  
  useEffect(() => {
    loadEntries()
  }, []);
  
  
  function renderTime(item: FinanceEntryEntity) {
    if (!item.dueDate) return null;
    const now = new Date().toISOString().substring(0, 10)
    const diff = moment(item.dueDate.substring(0, 10)).diff(now,'day');
    if (diff < 0) {
      return <Tag color={'red'}>Venceu a {Math.abs(diff)} dias</Tag>
    }
    if (diff === 0) {
      return <Tag color={'red'}>Vence hoje</Tag>
    }
    if (diff > 0 && diff <= 4) {
      return <Tag color={'warning'}>Vence em {diff} dia(s)</Tag>
    }
    return <Tag color={'cyan'}>Vence em {diff} dias</Tag>
  }
  
  function renderTitle(item: FinanceEntryEntity) {
    return (
      <Space>
        {formatter.format(item.amount)}
        <FinanceEntryTypeComponent type={item.type} />
        {renderTime(item)}
      </Space>
    )
  }
  
  const Title = () => <h3>Lançamentos Pendentes</h3>
  return (
    <>
      <List
        dataSource={entries}
        loading={entryRepo.loading}
        header={<Title />}
        pagination={{pageSize: 5}}
        renderItem={(item) => {
          return (
            <List.Item
              key={item.id}
              actions={[
                <Button onClick={() => setShowEntry(item)} icon={<SettingOutlined/>} size={'small'}/>
              ]}
            >
              <List.Item.Meta
                title={renderTitle(item)}
                description={''}
              />
            </List.Item>
          )
        }}
      />
      {
        showEntry && (
          <ShowFinanceEntryModal
            financeEntryCode={showEntry?.id ?? ""}
            onClose={() => setShowEntry(undefined)}
            entry={showEntry}
            open
            enableAction
            onUpdated={() => {
              setShowEntry(undefined)
              loadEntries()
            }}
          />
        )
      }
    </>
  )
}