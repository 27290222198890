import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {WorkflowEntity} from "../entity/workflow.entity";
import {WorkflowTaskEntity} from "../entity/workflow-task.entity";
import {BaseResponse} from "../../core/common/base-response.common";

export function useWorkflowRepository() {
  const crud = useJbHttpCrudService<WorkflowEntity>('workflow');
  
  function addTask(workflowId: string, task: Partial<WorkflowTaskEntity>): Promise<BaseResponse<WorkflowTaskEntity>> {
    return crud.post(
      `${crud.resourceName}/${workflowId}/task`,
      JSON.stringify(task)
    )
  }
  
  function editTask(workflowId: string, taskId: string, task: Partial<WorkflowTaskEntity>): Promise<BaseResponse<WorkflowTaskEntity>> {
    return crud.put(
      `${crud.resourceName}/${workflowId}/task/${taskId}`,
      JSON.stringify(task)
    )
  }
  
  function listTasks(workflowId: string): Promise<BaseResponse<WorkflowTaskEntity[]>> {
    return crud.get(
      `${crud.resourceName}/${workflowId}/tasks`,
    )
  }
  
  function removeTasks(workflowId: string, taskId: string): Promise<BaseResponse<WorkflowTaskEntity[]>> {
    return crud.delete(
      `${crud.resourceName}/${workflowId}/task/${taskId}`,
    )
  }
  
  function changeOrder(workflowId: string, taskId: string, typeChange: 'up' | 'down') {
    return crud.put(
      `${crud.resourceName}/${workflowId}/task/${taskId}/change-order`,
      JSON.stringify({
        workflowId,
        taskId,
        typeChange
      })
    )
  }
  
  return {
    ...crud,
    addTask,
    editTask,
    listTasks,
    removeTasks,
    changeOrder
  }
}