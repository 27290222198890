import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {Button, Result} from "antd";
import React from "react";

export function FinishAttendanceStep(props: {
  attendance: AttendanceEntity,
  onGoToList: () => any,
  onViewAttendance: () => any,
}) {
  return (
    <>
      <Result
        status={'success'}
        title={`Dados Salvos com sucesso !`}
        subTitle={`Código do Atendimento ${props.attendance.code}`}
        extra={[
          <Button onClick={() => props.onViewAttendance()}>Visualizar Atendimento</Button>,
          <Button onClick={() => props.onGoToList()}>Lista de Atendimentos</Button>,
        ]}
      />
    </>
  )
}
