import {ModulePages} from "../../core/module-pages";
import {ListPartTypePage} from "./page/list.part-type.page";
import {CreatePartTypePage} from "./page/create.part-type.page";
import {EditPartTypePage} from "./page/edit.part-type.page";

export function PartTypeModule(props: any) {
  return (
    <ModulePages routes={[
        { path: 'create', render: (props) => <CreatePartTypePage {...props} />},
        { path: 'edit/:id', render: (props) => <EditPartTypePage {...props} />},
        { path: 'list', render: (props) => <ListPartTypePage {...props} />},
        { path: '', render: (props) => <ListPartTypePage {...props} />},
      ]}
   />
  )
}
