import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {FinanceEntryForm} from "../component/finance-entry-form";
import {useFinanceEntryRepository} from "../../../../../domain/repository/finance-entry.repository";
import {ModulePageProps} from "../../../core/module-pages";
import {useEffect, useState} from "react";
import {FinanceEntryEntity} from "../../../../../domain/entity/finance-entry.entity";
import {useParams} from "react-router-dom";

export function EditFinanceEntryPage(props: ModulePageProps) {
  const [currentEntry, setCurrentEntry] = useState<FinanceEntryEntity>();
  const { id: entryId } = useParams<any>();
  const entryRepo = useFinanceEntryRepository();
  
  useEffect(() => {
    if (entryId) {
      entryRepo
        .find(entryId)
        .then(res => setCurrentEntry(res.data))
    }
  }, [entryId]);
  
  function onEdit(values: any) {
    entryRepo.edit(entryId, values).then(() => {
      props.navigator('/')
    })
  }
  
  return (
    <>
      <JbPageHeaderComponent title={'Novo Lançamento'} />
      <FinanceEntryForm
        onSubmit={onEdit}
        disabledFields={['type']}
        initialValues={currentEntry}
      />
    </>
  )
}