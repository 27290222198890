import React, {ReactNode, useEffect, useState} from "react";
import {ProcessEntity} from "../../domain/entity/process.entity";
import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {useProcessRepository} from "../../domain/repository/process.repository";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {JbPageHeaderComponent} from "../../../../core/component/jb-page-header.component";
import {Divider, Steps} from "antd";
import {AttendanceStep} from "./attendance.step";
import {ProcessStep} from "./process.step";
import {AttendanceStepStep} from "./attendance-step.step";
import {FinishAttendanceStep} from "./finish-attendance.step";
import {AttendancePartStep} from "./attendance-part.step";
import {AttendanceAttachmentStep} from "./attendance-attachment.step";
import {useCustomNavigatorHook} from "../../../../core/hook/custom-navigator.hook";

export function AttendanceWizard(props: {
  attendanceId?: string,
  step?: number,
  navigator: (path: string) => any,

}) {
  const [step, setStep] = useState(0)
  const [process, setProcess] = useState<ProcessEntity>();
  const [attendance, setAttendance] = useState<AttendanceEntity>()
  const [currentStep, setCurrentStep] = useState<ReactNode>()
  const navigator = useCustomNavigatorHook();
  const processRepo = useProcessRepository()
  const attendanceRepo = useAttendanceRepository()
  
  function loadAttendance(attendanceId: string) {
    attendanceRepo.find(attendanceId).then(res => {
      setAttendance(res.data)
      if (res.data.processId) {
        return processRepo.find(res.data.processId).then(res => {
          setProcess(res.data)
        })
      }
    })
  }
  useEffect(() => {
    if (props.attendanceId) {
      loadAttendance(props.attendanceId)
    }
    if (props.step) {
      setStep(props.step)
    }
  },[props])

  function onCreateAttendance(attendance: AttendanceEntity) {
    setAttendance(attendance);
  }

  function reloadAttendance() {
    if (attendance) {
      loadAttendance(attendance.id)
    }
  }

  useEffect(() => {
    switch (step) {
      case 0:
        setCurrentStep(() => (
          <AttendanceStep
            onCreate={(item) => onCreateAttendance(item)}
            nextStep={() => setStep(prev => prev + 1)}
            attendance={attendance}
          />
        ))
        break;
      case 1:
        setCurrentStep(() => (
          <AttendanceAttachmentStep
            attendanceId={attendance?.id ?? ''}
            onFinish={() => setStep(step + 1)}
            onCancel={() => setStep(step - 1)}
          />
        ))
        break;
      case 2:
        setCurrentStep(() => (
          <ProcessStep
            setProcess={data => setProcess(data)}
            attendance={attendance as AttendanceEntity}
            process={process}
            nextStep={() => setStep(step + 1)}
          />
        ))
        break;
      case 3:
        setCurrentStep(() => (
          <AttendancePartStep
            reloadAttendance={() => reloadAttendance()}
            attendance={attendance as AttendanceEntity}
            onCancel={() => setStep(prev => prev)}
            onFinish={() => setStep(prev => prev + 1)}
          />
        ))
        break;
      case 4:
        setCurrentStep(() => (
          <AttendanceStepStep
            attendance={attendance as AttendanceEntity}
            onFinish={() => setStep(prev => prev + 1)}
          />
        ))
        break;
      case 5:
        setCurrentStep(() => (
          <FinishAttendanceStep
            attendance={attendance as AttendanceEntity}
            onViewAttendance={
              () => {
                setStep(prev => prev + 1 )
                navigator.navigate('attendance',`details/${attendance?.id}`)
              }
            }
            onGoToList={
              () => {
                setStep(prev => prev + 1 )
                navigator.navigate('attendance', 'list')
              }
            }
          />
          
        ))
        break;
      default: break;
    }
  }, [step, process, attendance])

  return (
    <>
      <JbPageHeaderComponent
        title={`Atendimentos / ${props.attendanceId ? 'Edição de Atendimento' : 'Novo Atendimento'}`}
        description={'Gestão dos Atendimento'}
      />
      <Steps onChange={step => setStep((prev: number) => step < prev ? prev : step)} current={step}>
        <Steps.Step
          title={'Abertura do Atendimento'}
          description={'Dados iniciais do atendimento'}
        />
        <Steps.Step
          title={'Documentos do Processo'}
          description={'Dados iniciais do atendimento'}
          key={1}
        />
        <Steps.Step
          title={'Dados do Processo'}
          description={'Informações do Processo Jurídico'}
          key={2}
        />
        <Steps.Step
          title={'Partes Envolvidas'}
          description={'Registro das Partes Envolvidas no Processo'}
          key={3}
        />
        <Steps.Step
          title={'Registrar Movimentações'}
          description={'Registrar Primeira Movimentação'}
          key={4}
        />
      </Steps>
      <Divider />
      <div style={{marginTop: 30}}>
        {currentStep}
      </div>
    </>
  )
}
