import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {FinanceEntryForm} from "../component/finance-entry-form";
import {useFinanceEntryRepository} from "../../../../../domain/repository/finance-entry.repository";
import {ModulePageProps} from "../../../core/module-pages";

export function CreateFinanceEntryPage(props: ModulePageProps) {
  const entryRepo = useFinanceEntryRepository();
  
  function onCreate(values: any) {
    entryRepo.create(values).then(() => {
      props.navigator('/')
    })
  }
  
  return (
    <>
      <JbPageHeaderComponent title={'Novo Lançamento'} />
      <FinanceEntryForm onSubmit={onCreate} />
    </>
  )
}