import {ModulePageProps} from "../../../core/module-pages";
import {PartTypeFormComponent} from "../component/part-type.form.component";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useEffect, useState} from "react";
import {usePartTypeRepository} from "../domain/repository/part-type.repository";
import {message} from "antd";
import {PartTypeEntity} from "../domain/entity/part-type.entity";
import {useParams} from "react-router-dom";

export function EditPartTypePage(props: ModulePageProps) {
  const [partType, setPartType] = useState<PartTypeEntity>()
  const partTypeRepo = usePartTypeRepository();
  const { id: partTypeId } = useParams<any>()
  
  useEffect(() => {
    partTypeId && partTypeRepo.find(partTypeId).then(res => setPartType(res.data))
  }, [partTypeId])

  function onEdit(values: any) {
    return partTypeRepo.edit(partTypeId, values).then(res => {
      props.navigator('list')
    })
  }

  return (
    <>
      <JbPageHeaderComponent
        title={`Parametrizações / Tipo Parte / ${partType?.name}`}
        description={'Novo Tipo de Parte'}
      />
      <PartTypeFormComponent
        onSubmit={onEdit}
        values={partType}
      />
    </>
  )
}
