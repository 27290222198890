import {Drawer, Modal} from "antd";
import {ListClientAttendancesComponent} from "./list-client-attendances.component";
import {useState} from "react";
import {FullAttendanceModal} from "../../attendance/component/full-attendance/modal";

export function DrawerClientAttendancesComponent(props: {
  clientId: string,
  open?: boolean,
  onClose: () => any,
  onEdit: (id: string) => any
}) {
  const [showAttendance, setShowAttendance] = useState<string>();
  
  return (
    <Drawer
      title={'Atendimentos do Cliente'}
      placement={'bottom'}
      height={500}
      open={props.open}
      onClose={props.onClose}
    >
      <ListClientAttendancesComponent
        clientId={props.clientId}
        onShow={setShowAttendance}
        onEdit={props.onEdit}
      />
      {
        showAttendance && (
          <FullAttendanceModal
            attendanceId={showAttendance}
            onClose={() => setShowAttendance(undefined)}
          />
        )
      }
    </Drawer>
  )
}