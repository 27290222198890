import {StepTypeEntity} from "../../../../../domain/entity/step-type.entity";
import {Button, List, message} from "antd";
import {DeleteOutlined, FileOutlined, LinkOutlined, VideoCameraOutlined} from "@ant-design/icons";
import {useStepTypeRepository} from "../../../../../domain/repository/step-type.repository";

export function ListStepTypeLinkComponent(props: {
  stepType: StepTypeEntity,
  loading: boolean,
  onLinkRemoved?: () => any,
}) {
  const stepTypeRepository = useStepTypeRepository();
  
  function getIcon(type: string) {
    switch (type) {
      case 'video':
        return <VideoCameraOutlined />;
      case 'site':
        return <LinkOutlined />;
      default:
        return <FileOutlined />;
    }
  }
  
  function removeLink(link: string) {
    const links = props.stepType?.links?.filter(w => w.link !== link) ?? [];
    return stepTypeRepository.saveLinks(
      props.stepType.id,
      links
    ).then((res) => {
      return props.onLinkRemoved ? props.onLinkRemoved() : null;
    })
  }
  return (
    <List
      key={1}
      dataSource={props.stepType?.links ?? []}
      loading={stepTypeRepository.loading}
      renderItem={(item, idx) => (
        <List.Item
          key={idx}
          extra={[
            <a href={item.link} target="_blank">
              <Button
                icon={<LinkOutlined />}
                type='text'
              >
                Acessar
              </Button>
            </a>,
            props.onLinkRemoved && (
              <Button
                onClick={() => removeLink(item.link)}
                icon={<DeleteOutlined />}
                type='text'
              >
                Remover
              </Button>
            ),
          ]}
        >
          <List.Item.Meta
            avatar={getIcon(item.type)}
            title={item.title}
            description={item.link}
          />
        </List.Item>
      )}
    />
  )
}