import {Modal} from "antd";
import {AttendanceStepCommentComponent} from "./index";

export function AttendanceStepCommentComponentModal(props: {
  stepId : string,
  attendanceId: string,
  onClose: () => any,
  visible: boolean
}) {
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.onClose()}
      width={800}
      footer={null}
    >
      { props.visible && <AttendanceStepCommentComponent attendanceId={props.attendanceId} stepId={props.stepId} /> }
    </Modal>
  )
}