import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {useState} from "react";
import {ShowFinanceEntryModal} from "../component/show-finance-entry/modal";
import {ListFinanceEntry} from "../component/list-finance.entry";
import {FinanceEntryEntity} from "../../../../../domain/entity/finance-entry.entity";
import {FinanceListFilterComponent} from "../component/finance-list-filter.component";


export function ListFinanceEntryPage(props: ModulePageProps) {
  const [showEntry, setShowEntry] = useState<FinanceEntryEntity>()
  const [filter, setFilter] = useState<any>();
  
  
  return (
    <>
      <JbPageHeaderComponent
        title={'Lançamentos Financeiros'}
        onCreateLink={`${props.prefix}/create`}
      />
      <FinanceListFilterComponent
        onFilter={console.log}
        onTypeFilter={type => setFilter((prev: any) => ({ ...prev, type }))}
        onStatusFilter={status => setFilter((prev: any) => ({ ...prev, status }))}
        
      />
      <ListFinanceEntry
        onShow={setShowEntry}
        onEdit={id => props.navigator(`edit/${id}`)}
        filter={filter}
      />
      {
        showEntry && (
          <ShowFinanceEntryModal
            financeEntryCode={showEntry?.id  ?? ''}
            onClose={() => setShowEntry(undefined)}
            entry={showEntry}
            open={!!showEntry}
          />
        )
      }
    </>
  )
}
