import {SearchOutlined} from "@ant-design/icons";
import {Button} from "antd";
import React, {useState} from "react";
import {ModalSearch} from "./modal-search";

export function GlobalSearchButton(props: any) {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Button
        icon={<SearchOutlined />}
        size={'large'}
        type={'link'}
        shape={'round'}
        children={'Pesquisar Atendimento'}
        onClick={() => setShowModal(prev => !prev)}
      />
      <ModalSearch
        open={showModal} onClose={() => setShowModal(false)} />
    </>
  )
}
