import {Button, Col, Descriptions, Divider, Form, message, Row, Segmented, Space} from "antd";
import {ExternalAttendanceEntity} from "../../../../../../domain/entity/external-attendance.entity";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";
import {useContext, useEffect, useMemo, useState} from "react";
import {ClientForm} from "../../../client/component/client.form";
import {JbSearchableSelectComponent} from "../../../../core/component/jb-searchable-select.component";
import {useClientRepository} from "../../../client/domain/repository/client.repository";
import {ClientEntity} from "../../../client/domain/entity/client.entity";
import {useAttendanceRepository} from "../../../attendance/domain/repository/attendance.repository";
import {useExternalAttendanceRepository} from "../../../../../../domain/repository/external-attendance.repository";
import {useHistory} from "react-router-dom";
import {AdminApplicationContext} from "../../../../../../core/context/admin-application.context";

export function ConvertAttendanceComponent(props: {
  externalAttendance: ExternalAttendanceEntity
}) {
  const [parsedClient, setParsedClient] = useState<ClientEntity>();
  const attendanceRepo = useAttendanceRepository();
  const externalAttendanceRepo = useExternalAttendanceRepository();
  const app = useContext(AdminApplicationContext);
  const history = useHistory()
  
  function goToAttendanceWizard(attendanceId: string) {
    history.replace(
      `/${app?.applicationPrefix}/attendance/wizard/${attendanceId}`
    )
  }
  
  function createAttendance() {
    return externalAttendanceRepo.convert(
      props.externalAttendance.id,
      parsedClient?.id as string
    ).then((res) => {
      goToAttendanceWizard(res.data.id)
    })
  }
  
  const { formDateTimeInPtBr } = useDatetimeUtil()
  return (
    <>
      <Descriptions bordered layout={'vertical'}>
        <Descriptions.Item label={'Relato'} span={2} >
          {props.externalAttendance.description}
        </Descriptions.Item>
        <Descriptions.Item label={'Data de Criação'} >
          {formDateTimeInPtBr(props.externalAttendance.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label={'Cliente'} >
          {props.externalAttendance.personName}
        </Descriptions.Item>
        <Descriptions.Item label={'Telefone'} >
          {props.externalAttendance.phoneNumber}
        </Descriptions.Item>
        <Descriptions.Item label={'Email'} >
          {props.externalAttendance.personEmail}
        </Descriptions.Item>
      </Descriptions>
      {
        parsedClient ? (
          <>
            <Divider />
            <Space>
              <Button type={'primary'} onClick={() => createAttendance()} loading={attendanceRepo.loading}>
                Criar Atendimento para o cliente: {
                `${parsedClient.name} - ${parsedClient.phoneNumber}`
              }
              </Button>
              <Button type={'link'} onClick={() => setParsedClient(undefined)}>
                Cancelar
              </Button>
            </Space>
          </>
        ) : (
          <ConvertClientComponent
            externalAttendance={props.externalAttendance}
            onClientParsed={setParsedClient}
          />
        )
      }
    </>
  )
}

function ConvertClientComponent(props: {
  externalAttendance: ExternalAttendanceEntity,
  onClientParsed: (client: ClientEntity) => any,
}) {
  const [clientStrategy, setClientStrategy] = useState('new');
  const clientRepo = useClientRepository();
  
  function onFinish(values: any) {
    clientRepo.create(values).then(res => props.onClientParsed(res.data));
  }
  return (
    <>
      
      <Row>
        <Col>
          <Segmented
            value={clientStrategy}
            onChange={e => setClientStrategy(e.toString())}
            options={[
              { label: 'Cadastrar Novo Cliiente', value: 'new'},
              { label: 'Selecionar Cliiente Exiistente', value: 'current'},
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{padding: 10}}>
            {
              clientStrategy === 'new' ? (
                <ClientForm onSubmit={onFinish} values={{
                  name: props.externalAttendance.personName,
                  phoneNumber: props.externalAttendance.phoneNumber,
                  email: props.externalAttendance.personEmail
                }} />
              ) : (
                <SelectClient onSelected={props.onClientParsed} />
              )
            }
        </Col>
      </Row>
    </>
  )
}

function SelectClient(props: { onSelected: (id: ClientEntity) => any}) {
  const [allClients, setAllClients] = useState<ClientEntity[]>([])
  const clientRepo = useClientRepository();
  
  useEffect(() => {
    clientRepo.list().then(res => setAllClients(res.data))
  }, []);
  
  const clientOptions = useMemo(() => allClients.map(w => ({
    label: `${w.name} - ${w.phoneNumber}`,
    value: w.id,
  })), [allClients])
  
  return (
    <Form layout={'vertical'}>
      <JbSearchableSelectComponent
        name={'clieintId'}
        label={'Selecione um Cliente'}
        options={clientOptions}
        onSelect={s => props.onSelected(allClients.find(w => w.id === s) as ClientEntity)}
      />
    </Form>
  )
}