import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {FormWorkflowComponent} from "../component/form.workflow.component";
import {useWorkflowRepository} from "../../../../../domain/repository/workflow.repository";
import {FormTaskWorkflowComponent} from "../component/form-task.workflow.component";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {WorkflowEntity} from "../../../../../domain/entity/workflow.entity";
import {WorkflowTaskEntity} from "../../../../../domain/entity/workflow-task.entity";
import {ListWorkflowTaskComponent} from "../component/list-workflow-task.component";
import {Modal} from "antd";

export function EditWorkflowPage(props: ModulePageProps) {
  const workflowRepo = useWorkflowRepository();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [workflow, setWorkflow] = useState<WorkflowEntity>();
  const [workflowTasks, setWorkflowTasks] = useState<WorkflowTaskEntity[]>([])
  const { id: workFlowId } = useParams<any>();
  
  function onEditData(values: any) {
    workflowRepo.edit(workFlowId, values).then((res) => {
      setWorkflow(res.data);
    })
  }
  
  function listTasks(workflowId: string) {
    workflowRepo
      .listTasks(workflowId)
      .then(res => setWorkflowTasks(res.data))
  }
  
  function findWorkFlow(id: string) {
    workflowRepo
      .find(id)
      .then(res => setWorkflow(res.data))
  }
  
  function addTask(values: any) {
    workflowRepo.addTask(workFlowId, values).then(
      () => {
        listTasks(workFlowId)
        setShowCreateModal(false)
      }
    )
  }
  
  function onChangeOrder(taskId: string, type: 'up' | 'down') {
    workflowRepo.changeOrder(
      workFlowId,
      taskId,
      type
    ).then(() => listTasks(workFlowId))
  }
  
  function removeTask(task: WorkflowTaskEntity) {
    workflowRepo.removeTasks(
      workFlowId,
      task.id
    ).then(() => listTasks(workFlowId))
  }
  
  useEffect(() => {
    if (workFlowId) {
      findWorkFlow(workFlowId)
      listTasks(workFlowId)
    }
  }, [workFlowId]);
  
  return (
    <>
      <JbPageHeaderComponent
        title={'Novo Workflow'}
      />
      <FormWorkflowComponent
        onSubmit={onEditData}
        initialValues={workflow}
        disableFields={['type']}
      />
      <ListWorkflowTaskComponent
        tasks={workflowTasks}
        onRemoveTask={removeTask}
        onCreate={() => setShowCreateModal(true)}
        onMoveUp={taskId => onChangeOrder(taskId,'up')}
        onMoveDown={taskId => onChangeOrder(taskId,'down')}
      />
      <Modal
        open={showCreateModal}
        width={800}
        onCancel={() => setShowCreateModal(false)}
        footer={null}
      >
        <FormTaskWorkflowComponent
          tasks={workflowTasks}
          onSubmit={addTask}
        />
      </Modal>
    </>
    
  )
}