import {ModulePages} from "../../core/module-pages";
import {ListFinanceEntryPage} from "./page/list-finance-entry.page";
import {CreateFinanceEntryPage} from "./page/create-finance-entry.page";
import {EditFinanceEntryPage} from "./page/edit-finance-entry.page";

export function FinanceEntryModule() {
  return (
    <>
      <ModulePages routes={[
        { path: 'create', render: props => <CreateFinanceEntryPage {...props} /> },
        { path: 'edit/:id', render: props => <EditFinanceEntryPage {...props} /> },
        { path: '', render: props => <ListFinanceEntryPage {...props} /> }
      ]}/>
    </>
  )
}