import {AttendanceStepEntity} from "../domain/entity/attendance-step.entity";
import {Badge, Modal, Space, Table, Tag} from "antd";
import {useDatetimeUtil} from "../../../../../core/util/datetime.util";
import {JbButtonWithPopover} from "../../../core/button-with-popover";
import {InfoOutlined, LinkOutlined, UsergroupAddOutlined, WechatOutlined} from "@ant-design/icons";
import {FinishAttendanceStepButtonComponent} from "./full-attendance/finish-attendance-step-button.component";
import React, {useState} from "react";
import {FormAttendanceStep} from "./form.attendance-step";
import {AttendanceStepMediaModal} from "./full-attendance/attendance-step-media.modal";
import {AttendanceStepCommentComponentModal} from "./attendance-step-comment.component/modal";
import {AttendanceStepAttachmentsModal} from "./attendance-step-attachments/modal";
import {AttendanceStepOwnerModal} from "./attendance-step-owner/modal";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {AttendanceEntity} from "../domain/entity/attendance.entity";
import {useAttendanceRepository} from "../domain/repository/attendance.repository";
import moment from "moment";
import {AttendanceStepDateAlertComponent} from "./attendance-step-date-alert.component";

export function ListAttendanceStepComponent(props: {
  steps: AttendanceStepEntity[],
  attendance: AttendanceEntity,
  reload: () => any
}) {
  const [editStep, setEditStep] = useState<AttendanceStepEntity>();
  const [showAttachments, setShowAttachments] = useState<string>()
  const [displayComments, setDisplayComments] = useState<string>();
  const [showStepOwners, setShowStepOwners] = useState<string>();
  const [showStepTypeMedias, setShowStepTypeMedias] = useState<string>();
  const attendanceRepo = useAttendanceRepository();
  
  function onEditStep(id: string, values: any) {
    return attendanceRepo.editStep(
      props.attendance?.id ?? '',
      editStep?.id ?? '',
      values
    ).then(res => {
      setEditStep(undefined)
      props.reload()
    })
  }
  
  return (
    <>
      <Table
        dataSource={props.steps}
        columns={[
          {
            dataIndex: 'stepType',
            title: 'Tipo',
            render: data => data?.description
          },
          {
            dataIndex: 'beginDate',
            title: 'Período',
            render: (_,step) => <AttendanceStepPeriod step={step} />
          },
          {
            dataIndex: 'finished',
            title: 'Situação',
            render: (_, step) => <AttendanceStepDateAlertComponent endDate={step.endDate} />
          },
          {
            dataIndex: 'finished',
            title: 'Status',
            render: (_, step) => <AttendanceStepStatus step={step} />
          },
          {
            title: '',
            dataIndex: 'id',
            
            align: 'right',
            render: (id, step) => (
              <>
                <JbTableRowActionsComponent
                  onEdit={() => setEditStep(step)}
                  // onRemove={() => removeStep(id)}
                  extra={[
                    <JbButtonWithPopover
                      title={'Responsáveis pela Tarefa'}
                      type={'text'}
                      onClick={() => setShowStepOwners(id)}
                      icon={<UsergroupAddOutlined />}
                    />,
                    <FinishAttendanceStepButtonComponent
                      step={step}
                      onFinish={() => props.reload()}
                    />,
                    <Badge count={step?.attachments?.length ?? 0} size={'small'}>
                      <JbButtonWithPopover
                        onClick={() => setShowAttachments(step.id)}
                        type={'text'}
                        icon={<LinkOutlined />}
                        title={'Anexos da Movimentação'}
                      />
                    </Badge>,
                    <JbButtonWithPopover
                      type={'text'}
                      title={'Comentários'}
                      onClick={() => setDisplayComments(step.id)}
                      icon={<WechatOutlined />}
                    />,
                    <JbButtonWithPopover
                      onClick={() => setShowStepTypeMedias(step.stepType.id)}
                      type={'text'}
                      title={'Orientações'}
                      icon={<InfoOutlined />}
                    />
                  ]}
                />
              </>
            )
          }
        ]}
      />
      
      {
        editStep && (
          <Modal
            title={editStep.stepType.description}
            visible={true}
            width={1024}
            onCancel={() => setEditStep(undefined)}
            footer={null}
          >
            <FormAttendanceStep
              onSubmit={values => onEditStep(editStep.id, values)}
              values={editStep}
              startDate={props.attendance?.createdAt}
              onCancel={() => setEditStep(undefined)}
            />
          </Modal>
        )
      }
      {
        showStepTypeMedias && (
          <AttendanceStepMediaModal
            stepTypeId={showStepTypeMedias}
            closeModal={() => setShowStepTypeMedias(undefined)}
          />
        )
      }
      {
        <AttendanceStepCommentComponentModal
          stepId={displayComments ?? ''}
          attendanceId={props.attendance.id}
          visible={!!displayComments}
          onClose={() => setDisplayComments(undefined)}
        />
      }
      {
        showAttachments && (
          <AttendanceStepAttachmentsModal
            stepId={showAttachments}
            visible
            attendanceId={props.attendance.id}
            onClose={() => {
              setShowAttachments(undefined)
              props.reload()
            }}
          />
        )
      }
      {
        <AttendanceStepOwnerModal
          stepId={showStepOwners ?? ''}
          attendanceId={props.attendance.id}
          onClose={() => setShowStepOwners(undefined)}
          open={!!showStepOwners}
        />
      }
    </>
  )
}

export function AttendanceStepPeriod(props: { step: AttendanceStepEntity }) {
  const {
    step
  } = props;
  const initialDate = new Date(step.beginDate);
  const endDate = new Date(step.endDate);
  const initialMonth = initialDate.toLocaleString('pt-BR', { month: 'long' })
  const endMonth = endDate.toLocaleString('pt-BR', { month: 'long' })
  const diff = moment(endDate).diff(initialDate, 'day');
  return (
    <>
      de <strong>{initialDate.getDate()}/{initialMonth}</strong> até <strong>{endDate.getDate()}/{endMonth}</strong> -
      <strong>{diff}</strong> dias
    </>
  )
}

export function AttendanceStepStatus(props: { step: AttendanceStepEntity }) {
  if (props.step.finished) {
    return (
      <Tag color={'cyan'}>Fechada</Tag>
    )
  }
  let color = 'warning'
  const daysDiff = moment(props.step.endDate)
    .set({ hours: 0, minute: 0, second: 0, millisecond: 0 })
    .diff(moment().set({ hours: 0, minute: 0, second: 0, millisecond: 0 }), 'day');
  let message = `Vence em ${daysDiff} dias`;
  if (daysDiff < 0) {
    if (daysDiff === -1) {
      message = `Venceu ontem`
    } else {
      message = `Venceu a ${Math.abs(daysDiff)} dias`
    }
    color = 'red'
  } else if (daysDiff === 0) {
    message = 'Movimentação Vencendo !'
  }
  
  return (
    <Tag color={color}>
      Em Aberto ({message})
    </Tag>
  );
}