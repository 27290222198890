import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useEffect, useState} from "react";
import {AttendanceEntity} from "../domain/entity/attendance.entity";
import {useAttendanceRepository} from "../domain/repository/attendance.repository";
import {FilterListAttendanceComponent} from "../component/filter-list-attendance.component";
import {ListAttendanceComponent} from "../component/list-attendance.component";

export function ListAttendance(props: ModulePageProps) {
  const [attendances, setAttendances] = useState<AttendanceEntity[]>([])
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [attendanceCode, setAttendanceCode] = useState<string>( '')
  const [clientId, setClientId] = useState<string>( '')
  const attendanceRepo = useAttendanceRepository()

  useEffect(() => {
    attendanceRepo.list({
      page,
      limit,
      filter: {
        status: statusFilter,
        attendanceCode,
        clientId
      }
    }).then(res => {
      setAttendances(res.data.result)
      setTotalRegisters(res.data.total)
    })
  }, [page, statusFilter, attendanceCode, clientId])
  
  return (
    <>
      <JbPageHeaderComponent
        title={`Atendimentos / Todos Atendimentos `}
        description={'Gestão dos Atendimento'}
        onCreateLink={`${props.prefix}/wizard`}
        createActionLabel={'Registrar Atendimento'}
      />
      <FilterListAttendanceComponent
        onFilter={({status, clientId, attendanceCode}) => {
          setStatusFilter(status)
          setAttendanceCode(attendanceCode)
          setClientId(clientId)
        }}
      />
      <ListAttendanceComponent
        onEdit={(id) => props.navigator( `wizard/${id}`)}
        onShow={(id) => props.navigator(`details/${id}`)}
        onPrint={(id) => props.navigator(`print/${id}`)}
        dataSource={attendances}
        loading={attendanceRepo.loading}
          pagination={{
            pageSize: limit,
            current: page,
            total: totalRegisters,
            onChange: (page, pageSize) => {
              setPage(page)
              setLimit(pageSize)
            }
          }}
      />
    </>
  )
}
