import {JbDropzoneComponent} from "../../../../core/dropzone";
import {AttendanceAttachment} from "../../domain/entity/attendance.entity";
import {useEffect, useState} from "react";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {ListAttendanceAttachmentComponent} from "../list-attendance-attachment.component";
import {JbFormActionButtonComponent} from "../../../../core/component/jb-form-action-button.component";

export function AttendanceAttachmentStep(props: {
  attendanceId: string,
  onFinish: () => any,
  onCancel: () => any,
}) {
  const [attachments, setAttachments] = useState<AttendanceAttachment[]>([]);
  const attendanceRepo = useAttendanceRepository();
  
  function loadAttachments(id: string) {
    attendanceRepo.listAttachments(id).then(res => {
      setAttachments(res.data)
    })
  }
  
  function uploadAttachment(file: any) {
    attendanceRepo.uploadAttachment(props.attendanceId, file).then(() => {
      loadAttachments(props.attendanceId)
    })
  }
  
  useEffect(() => {
    loadAttachments(props.attendanceId);
  }, [props.attendanceId])
  
  return (
    <>
      <JbDropzoneComponent
        onDrop={file => uploadAttachment(file)}
        description={"Faça Upload dos Documentos Necessários do Atendimento"}
      />
      <ListAttendanceAttachmentComponent
        attendanceId={props.attendanceId}
        files={attachments}
        onRemoved={() => loadAttachments(props.attendanceId)}
        onToggle={() => loadAttachments(props.attendanceId)}
      />
      <JbFormActionButtonComponent
        labelSave={'Continuar'}
        // onCancel={props.onCancel}
        onSaveClick={props.onFinish}
        loading={attendanceRepo.loading}
      />
    </>
  )
}