import {Button, Col, Divider, Form, Input, Row, Select, Space} from "antd";
import {LeftSquareOutlined, SaveOutlined} from "@ant-design/icons";
import {UserEntity} from "../../../../../domain/entity/user.entity";
import {useEffect, useState} from "react";
import {MaskedInput} from "antd-mask-input";
import {useUserRepository} from "../../../../../domain/repository/user.repository";

export type UserFormComponent = {
  onSubmit: (values: any) => any,
  onCancel: () => any,
  values?: Partial<UserEntity>,
  editMode?: boolean
}

export function UserFormComponent(props: UserFormComponent) {
  const [form] = Form.useForm()
  const [documentMask, setDocumentMask] = useState('000.000.000-00')
  const [disabledEdit, setDisabledEdit] = useState(false)
  const userRepo = useUserRepository();

  useEffect(() => {
    if (props.values) {
      form.setFieldsValue({
        ...props.values,
        ...props.values?.person
      })
      // setCurrentMask()
      form.setFields([
        { name: 'document', value: props.values.person?.document }
      ])
    }
  }, [props?.values])

  function setCurrentMask() {
    form.setFieldsValue({ document: null })
    if (form.getFieldValue('type') === 'J') {
      setDocumentMask('00.000.000/0000-00')
    } else {
      setDocumentMask('000.000.000-00')
    }
  }
  
  function clearDocument(document: string) {
    return document.split('.')
      .join('')
      .split('/')
      .join('')
      .split('-')
      .join('')
  }
  
  function disablePersonFields(values: any) {
    setDisabledEdit(true);
    form.setFieldsValue(values)
  }
  
  function checkEmail(email: string) {
    userRepo.checkUser('email', email).then(res => {
      if (!res.data) return;
      disablePersonFields({
        ...res.data,
        ...res.data?.person,
        password: '******'
      })
    })
  }
  
  function checkDocument(document: string) {
    userRepo.checkUser('document', clearDocument(document)).then(res => {
      if (!res.data) return;
      disablePersonFields({
        ...res.data,
        ...res.data?.person,
        password: '******'
      })
    })
  }

  function onFinish(values: any) {
    values.document = values.document ? clearDocument(values.document) : undefined;
    props.onSubmit(values);
  }
  
  return (
    <Form
      layout={'vertical'}
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={'name'} label={'Nome'}  rules={[{ required: true }]}>
            <Input disabled={disabledEdit} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={'type'} label={'Tipo Usuário'}  rules={[{ required: true }]}>
            <Select
              disabled={disabledEdit}
              onChange={setCurrentMask}
              options={
                [{ label: 'Pessoa Física', value: 'F'},
                { label: 'Pessoa Jurídica', value: 'J'}]}
              />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'document'}
            label={'Documento'}
            rules={[{ required: true }]}
          >
            <MaskedInput
              mask={documentMask}
              maskOptions={{
                lazy: true,
              }}
              onBlur={evt => checkDocument(evt.target.value)}
              disabled={disabledEdit}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={10}>
          <Form.Item name={'email'} label={'E-mail'}  rules={[{ required: true }]}>
            <Input
              disabled={disabledEdit}
              type={'email'}
              onBlur={evt => checkEmail(evt.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={'username'} label={'Usuário'} rules={[{ required: true }]}>
            <Input  />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'password'} label={'Senha'}  rules={[{ required: !props.editMode }]}>
            <Input.Password  disabled={props.editMode || disabledEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item style={{textAlign: 'right'}}>
        <Divider />
        <Space>
          <Button type={'primary'} htmlType={'submit'} icon={<SaveOutlined />}>
            Salvar
          </Button>
          <Button onClick={props.onCancel} icon={<LeftSquareOutlined />}>
            Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}
