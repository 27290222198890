import {AttendancePartEntity} from "../domain/entity/attendance-part.entity";
import {useMemo} from "react";
import {ColumnsType} from "antd/es/table/Table";
import {Table} from "antd";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";

export function AttendancePartListComponent(props: {
  parts: AttendancePartEntity[],
  onEdit: (id: AttendancePartEntity) => any,
  onRemove: (id: string) => any,
}) {
  const columns = useMemo<ColumnsType<any>>(() => ([
    {
      title: 'Tipo Parte',
      dataIndex: 'partType',
      key: 'partType',
      render: val => val?.name
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Documento',
      dataIndex: 'document',
      key: 'document'
    },
    {
      title: 'Contato',
      dataIndex: 'email',
      key: 'email',
      render: (value, row: any) => `${value ?? ''} ${(row?.phoneNumber ?? '') ? ` | ${row?.phoneNumber ?? ''}` : ''}`
    },
    {
      title: 'Obs',
      dataIndex: 'observation',
      key: 'observation',
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (id, row) => (
        <JbTableRowActionsComponent
          onRemove={() => props.onRemove(row)}
          onEdit={() => props.onEdit(row)}
        />
      )
    }
  ]), [])
  
  return (
    <Table
      columns={columns}
      dataSource={props.parts}
    />
  )
}
