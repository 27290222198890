import {CategoryFormComponent} from "../component/category.form.component";
import {useCategoryRepository} from "../../../../../domain/repository/category.repository";
import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";

export function CreateCategoryPage(props: ModulePageProps) {
  
  const categoryRepo = useCategoryRepository();
  
  function onCreate(values: any) {
    categoryRepo.create(values).then(() => {
      props.navigator('list')
    })
  }
  
  return (
    <>
      <JbPageHeaderComponent title={'Nova Categoria'} />
      <CategoryFormComponent
        onSubmit={onCreate}
        onCancel={() => props.navigator('list')}
      />
    </>
  )
}