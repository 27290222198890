import {Button, Divider, PageHeader} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import React, {ReactNode, useMemo} from "react";
import {useHistory} from "react-router-dom";

export type JbPageHeaderComponentProps = {
  title: string,
  description?: any,
  onCreateLink?: string,
  extraActions?: ReactNode[],
  hideDivider?: boolean,
  children?: any,
  createActionLabel?: string
}
export function JbPageHeaderComponent(props: JbPageHeaderComponentProps) {
  const history = useHistory()
  const {
    title,
    description
  } = props

  function getCreateButton(link: string) {
    return (
      <Button
        key="1"
        type="primary"
        shape={'round'}
        onClick={() => history.push(link)}
        icon={<PlusOutlined />}
      >
        {props.createActionLabel ?? 'Novo Regitro'}
      </Button>
    )
  }
  const actions = useMemo(() => {
    const actions = []
    if (props.onCreateLink) {
      actions.push(getCreateButton(props.onCreateLink))
    }
    if (props.extraActions && props.extraActions.length) {
      actions.push(props.extraActions)
    }
    return actions
  }, [props])

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => history.goBack()}
        title={title}
        subTitle={description}
        extra={actions}
        children={props.children}
      />
      { !props.hideDivider && <Divider /> }
    </>
  )
}
