import moment from "moment";
import {Button, Col, Form, Row, Select, Switch as Toggle} from "antd";
import React, {useEffect} from "react";

export function CustomHeader(props: {
  value: moment.Moment,
  onChange: (value: moment.Moment) => any,
  onOwnerFilter: (filterAllAttendances: boolean) => any
}) {
  const [form] = Form.useForm()
  function getYearOpts() {
    const year = new Date().getFullYear();
    const opts: any[] = [];
    for (let yearIdx = year - 5; yearIdx < year + 2; yearIdx++) {
      opts.push({ label: yearIdx, value: yearIdx })
    }
    return opts;
  }
  
  function getMonths() {
    return [
      { label: 'Janeiro', value: 0 },
      { label: 'Fevereiro', value: 1 },
      { label: 'Março', value: 2 },
      { label: 'Abril', value: 3 },
      { label: 'Maio', value: 4 },
      { label: 'Junho', value: 5 },
      { label: 'Julho', value: 6 },
      { label: 'Agosto', value: 7 },
      { label: 'Setembro', value: 8 },
      { label: 'Outubro', value: 9 },
      { label: 'Novembro', value: 10 },
      { label: 'Dezembro', value: 11 },
    ]
  }
  
  useEffect(() => {
    form.setFieldsValue({
      year: props.value.year(),
      month: props.value.month()
    })
  }, [props.value])
  
  function onValueChange(value: any, values: any) {
    if (typeof value.allAttendances !== 'undefined') {
      return props.onOwnerFilter(!value.allAttendances);
    }
    if (value.year || value.month) {
      props.onChange(
        props.value.clone().set({ year: values.year, month: values.month })
      )
    }
  }
  
  function setCurrentDate() {
    const today = new Date()
    props.onChange(
      props.value.clone().set({
        year: today.getFullYear(),
        month: today.getMonth(),
        date: today.getDate()
      })
    )
  }
  
  return (
    <Form form={form} initialValues={{allAttendances: true}} onValuesChange={onValueChange} >
      <Row gutter={12} justify={'start'}>
        <Col>
          <Form.Item valuePropName={'checked'} name={'allAttendances'}>
            <Toggle
              checkedChildren={'Atribuído a mim'}
              unCheckedChildren={'Todos Atendimentos'}
            />
          </Form.Item>
        </Col>
        <Col>
          <Button type={'primary'} onClick={setCurrentDate} title={new Date().toLocaleDateString()}>
            Mês Atual
          </Button>
        </Col>
        <Col span={4}>
          <Form.Item name={'year'}>
            <Select options={getYearOpts()} />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name={'month'}>
            <Select options={getMonths()} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}