import {ProcessObjectAttachedEntity} from "../../../../../../domain/entity/process-object-attached.entity";
import {Button, List} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import React from "react";

export function ListProcessAttachedObjectsComponent(props: {
  data: ProcessObjectAttachedEntity[],
  loading?: boolean,
  onAdd: () => any,
  onRemove: (item: ProcessObjectAttachedEntity) => any,
  onEdit: (item: ProcessObjectAttachedEntity) => any,
}) {
  
  const { format } = new Intl.NumberFormat('pt')
  const getProbability = (item: ProcessObjectAttachedEntity) => item.probability ? `${item.probability}%` : null
  const getAmount = (item: ProcessObjectAttachedEntity) => item.amount ? `R$ ${format(item.amount)}` : null
  return (
    <List
      loading={props.loading}
      dataSource={props.data}
      header={<Button onClick={() => props.onAdd()} shape={'round'}>Adicionar Objeto ao Processo</Button>}
      renderItem={(item) => (
        <List.Item extra={[
          <Button.Group>
            <Button onClick={() => props.onEdit(item)}>Editar</Button>
            <Button onClick={() => props.onRemove(item)}>Remover</Button>
          </Button.Group>,
        ]}>
          <List.Item.Meta
            avatar={
              item.type === 'revenue' ? <PlusOutlined /> : <MinusOutlined />
            }
            title={item.object.description}
            description={`${getProbability(item)} / ${getAmount(item)}`}
          />
        </List.Item>
      )}
    />
  )
}