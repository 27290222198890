import {Document, Page, PDFViewer, StyleSheet, Text} from "@react-pdf/renderer";
import {useMemo} from "react";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";

export type AttendancePdfReportArgs = {
  title: string,
  subtitle: string,
  sections: Array<{
    key: number,
    title: string,
    rows: string[],
  }>
}


const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    fontFamily: 'Helvetica'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 6,
  },
  subtitle: {
    fontSize: 13,
    marginLeft: 4,
    marginTop: 10,
    fontFamily: 'Helvetica',
    borderBottom: 1,
  },
  subtitle2: {
    fontSize: 12,
    marginLeft: 8,
    marginTop: 5,
    fontFamily: 'Helvetica',
  },
  text: {
    marginLeft: 4,
    marginTop: 2,
    fontSize: 11,
    textAlign: 'justify',
    fontFamily: 'Helvetica',
  },
  text2: {
    marginLeft: 8,
    marginTop: 1,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Helvetica',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 9,
    marginBottom: 2,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

export function AttendancePdfReport(props: AttendancePdfReportArgs) {
  const { formDateTimeInPtBr } = useDatetimeUtil();
  const now = useMemo(() => formDateTimeInPtBr(new Date()), [])
    
  return (
    <PDFViewer width={'100%'} height={800}>
      <Document title={props.title}>
        <Page size={'A4'} style={styles.body}>
          <Text style={styles.header} fixed>
            Gerado por Juristta Business em {now}
          </Text>
          <Text style={styles.title}>
            {props.title}
          </Text>
          <Text style={styles.author}>
            {props.subtitle}
          </Text>
          {
            props.sections.map(w => (
              <>
                <Text style={styles.subtitle}>
                  {w.key} - {w.title}
                </Text>
                {
                  w.rows.map(r => (
                    <Text style={styles.text}>
                      {r}
                    </Text>
                  ))
                }
              </>
            ))
          }
          
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => (
              `${pageNumber} / ${totalPages}`
            )}
            fixed
          />
        </Page>
      </Document>
    </PDFViewer>
  )
  
}