import {Button, Form, Popover, Timeline} from "antd";
import {CheckCircleOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {useEffect, useMemo, useState} from "react";
import {AttendanceStepCommentEntity} from "../../domain/entity/attendance.step.comment.entity";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";

export function AttendanceStepCommentComponent(props: { attendanceId: string, stepId: string }) {
  const [comments, setComments] = useState<AttendanceStepCommentEntity[]>([])
  const attendanceRepo = useAttendanceRepository();
  
  function loadComments() {
    attendanceRepo.listStepComments(
      props.attendanceId,
      props.stepId
    ).then(res => setComments(res.data))
  }
  
  useEffect(() => {
    loadComments()
  }, [props])
  
  function onCommentSave(values: any) {
     attendanceRepo.addStepComments(
       props.attendanceId,
       props.stepId,
       values
     ).then(() => loadComments())
  }
  
  const sortedComments = useMemo(() => {
    return comments.sort(
      (a,b) => {
        if (a.createdAt > b.createdAt) return 1;
        if (a.createdAt < b.createdAt) return -1;
        return 0;
      }
    )
  }, [comments])
  
  return (
    <Timeline
      mode={'left'}
    >
      {
        sortedComments.map((w,idx) => (
          <CommentItem
            key={idx}
            createdBy={w.user?.personName ?? ''}
            comment={w.comment}
            createdAt={w.createdAt}
          />
        ))
      }
      <CommentForm
        onSubmit={onCommentSave}
        loading={attendanceRepo.loading}
      />
    </Timeline>
  )
}


function CommentItem(props: {
  comment: string,
  createdAt: string,
  createdBy: string,
  position?: string
}) {
  const { formDateTimeInPtBr } = useDatetimeUtil()
  return (
    <Timeline.Item
      position={props.position ?? 'left'}
      dot={
        <Popover content={formDateTimeInPtBr(props.createdAt)}>
          <CheckCircleOutlined />
        </Popover>
      }
    >
      <small><em>{formDateTimeInPtBr(props.createdAt)} - {props.createdBy} </em></small> : {props.comment}
    </Timeline.Item>
  )
}

function CommentForm(props: {
  onSubmit: (values: any) => any,
  loading?: boolean
}) {
  const [form] = Form.useForm()
  
  function onFinish(values: any) {
    props.onSubmit(values);
    form.resetFields();
  }
  
  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item name={'comment'} rules={[{ required: true }]}>
        <TextArea placeholder={'Descreva seu Comentário....'} rows={5}/>
      </Form.Item>
      <Button
        htmlType={'submit'}
        type={'ghost'}
        shape={'round'}
      >
        Salvar Comentário
      </Button>
    </Form>
  )
}