import {ModulePages} from "../../core/module-pages";
import {ListStepTypePage} from "./page/list.step-type";
import {CreateStepTypePage} from "./page/create.step-type";
import {EditStepTypePage} from "./page/edit.step-type";

export function StepTypeModule(props: any) {
  return (
    <ModulePages routes={[
        { path: 'create', render: (props) => <CreateStepTypePage {...props} /> },
        { path: 'edit/:id', render: (props) => <EditStepTypePage {...props} /> },
        { path: 'list', render: (props) => <ListStepTypePage {...props} /> },
        { path: '', render: (props) => <ListStepTypePage {...props} /> },
      ]}
   />
  )
}
