import {ClientEntity} from "../domain/entity/client.entity";
import {ClientPhysicalPersonForm} from "./client-physical-person.form";
import {ClientCompanyPersonForm} from "./client-company-person.form";
import {useEffect, useState} from "react";
import {ClientIndividualEntity} from "../domain/entity/client-individual.entity";
import {useClientRepository} from "../domain/repository/client.repository";
import {ClientLegalEntity} from "../domain/entity/client-legal.entity";

export function ClientAdditionalDataForm(props: {
  client: ClientEntity,
  currentData?: any
  onSubmit: (value: any) => any,
}) {
  const [individualData, setIndividualData] = useState<ClientIndividualEntity>();
  const [legalData, setLegalData] = useState<ClientLegalEntity>();
  const { id: clientId } = props.client;
  
  useEffect(() => {
    if (props.client) {
      if (props.client.type === 'J') {
        clientRepo.findLegalData(clientId).then(res => {
          setLegalData(res.data);
        })
      } else {
        clientRepo.findIndividualData(clientId).then(res => {
          setIndividualData(res.data)
        })
      }
    }
  }, [props.client])
  
  const clientRepo = useClientRepository();
  
  
  function onSaveIndividual(data: any) {
    clientRepo
      .saveIndividualData(clientId,data)
      .then(res => {
        setIndividualData(res.data)
        props.onSubmit(res.data)
      })
  }
  
  
  function onSaveLegal(data: any) {
    clientRepo
      .saveLegalData(clientId,data)
      .then(res => {
        setIndividualData(res.data)
        props.onSubmit(data)
      })
  }
  
  return (
    props.client.type === 'J' ? (
      <ClientCompanyPersonForm
        currentData={legalData}
        onSubmit={onSaveLegal}
        client={props.client}
      />
    ) : (
      <ClientPhysicalPersonForm
        onSubmit={onSaveIndividual}
        currentData={individualData}
        client={props.client}
      />
    )
  )
}