import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {UserFormComponent} from "../component/user.form.component";
import React, {useEffect, useState} from "react";
import {useUserRepository} from "../../../../../domain/repository/user.repository";
import {UserEntity} from "../../../../../domain/entity/user.entity";
import {useParams} from "react-router-dom";
import {message} from "antd";

export function EditUserPage(props: ModulePageProps) {
  const [user, setUser] = useState<UserEntity>()
  const { id: userId } = useParams<any>()

  const userRepository = useUserRepository();

  useEffect(() => {
    userRepository.find(userId)
      .then(res => setUser(res.data))
  }, [userId])

  function onSubmit(values: any) {
    return userRepository.edit(user?.id ?? '', values).then(() => {
      props.navigator('list')
    })
  }
  return (
    <>
      <JbPageHeaderComponent
        title={`Parametrizações / Usuário / ${user?.person.name}`}
      />
      <UserFormComponent
        onSubmit={onSubmit}
        values={user}
        onCancel={() => props.navigator('list')}
        editMode
      />
    </>
  )
}
