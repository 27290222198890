import {Modal} from "antd";
import {FinanceEntryForm} from "./index";

export function FinanceEntryFormModal(props: {
  open: boolean,
  onClose: () => any,
  initialFields?: any,
  disabledFields?: string[],
  onSubmit: (values: any) => any
}) {
  console.log(props.initialFields)
  return (
    <Modal
      width={1024}
      open={props.open}
      onCancel={props.onClose}
      footer={null}
    >
      <FinanceEntryForm
        initialValues={props.initialFields}
        disabledFields={props.disabledFields}
        onSubmit={props.onSubmit}
      />
    </Modal>
  )
}