import {useForm} from "antd/es/form/Form";
import {useEffect, useState} from "react";
import {Col, Form, Input, Row, Select} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {MaskedInput} from "antd-mask-input";
import {SupplierEntity} from "../../../../../domain/entity/supplier.entity";

export function SupplierFormComponent(props: {
  currentValues?: Partial<SupplierEntity>,
  onSubmit: (values: any) => any,
  onCancel: () => any,
}) {
  const [form] = useForm()
  const [documentType, setDocumentType] = useState(props.currentValues?.documentType ?? 'cnpj');
  const [documentTypeMask, setDocumentTypeMask] = useState('000.000.000/000-00');
  useEffect(() => {
    if (documentType === 'cnpj') {
      setDocumentTypeMask('000.000.000/000-00')
    } else {
      setDocumentTypeMask('000.000.000-00')
    }
  }, [documentType]);
  
  useEffect(() => {
    if (props.currentValues) {
      form.setFieldsValue({
        ...props.currentValues,
      });
      if (props.currentValues?.documentType) {
        setDocumentType(props.currentValues?.documentType)
      }
    }
  }, [form, props.currentValues]);
  
  function onSubmit(values: any) {
    return props.onSubmit({
      ...values,
      documentType,
    });
  }
  
  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={onSubmit}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={'name'} label={'Nome'} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12} >
          <Form.Item name={'document'} label={'Documento'} rules={[{ required: true }]}>
            <MaskedInput
              mask={documentTypeMask}
              addonBefore={(
                <Select
                  value={documentType}
                  style={{width: 'auto'}}
                  onSelect={(value: string) => setDocumentType(value)}
                  options={[{ value: 'cnpj', label: 'CNPJ' }, { value: 'cpf', label: 'CPF' }]}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item label={'Telefone de Contato'} name={'phoneNumber'}>
            <MaskedInput maskOptions={{ lazy: true }} mask={'(00)00000-0000'} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={'E-mail'} name={'email'}>
            <Input type={'email'} />
          </Form.Item>
        </Col>
      </Row>
      <JbFormActionButtonComponent
        onCancel={props.onCancel}
      />
    </Form>
  )
}