import {ModulePageProps} from "../../../core/module-pages";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {FullAttendanceComponent} from "../component/full-attendance";
import {Button, Descriptions, Modal, Tag, Typography} from "antd";
import {EditOutlined, LinkOutlined} from "@ant-design/icons";
import {AttendanceEntity} from "../domain/entity/attendance.entity";
import {useAttendanceRepository} from "../domain/repository/attendance.repository";
import {AttendancePriorityTagComponent} from "../component/attendance-priority-tag.component";

export function ShowAttendance(props: ModulePageProps) {
  const [attendance, setAttendance] = useState<AttendanceEntity>()
  const attendanceRepo = useAttendanceRepository()
  const [showPublicLink, setShowPublicLink] = useState(false);
  const { attendanceId } = useParams<any>()
  
  useEffect(() => {
    attendanceRepo.find(attendanceId).then(res => setAttendance(res.data))
  }, [attendanceId])
  
  return (
    <>
      <JbPageHeaderComponent
        title={`Atendimentos / Dashboard Atendimento / ${attendance?.code}`}
        description={attendance ? <AttendancePriorityTagComponent attendance={attendance} /> : ''}
        onCreateLink={`${props.prefix}/wizard`}
        extraActions={[
          <Button
            type={'default'}
            shape={'round'}
            icon={<EditOutlined />}
            onClick={() => props.navigator(`wizard/${attendanceId}`)}
          >
            Editar Atendimento
          </Button>,
          <Button
            shape={'round'}
            icon={<LinkOutlined />}
            onClick={() => setShowPublicLink(true)}
          >
            Link Público
          </Button>
        ]}
      >
      </JbPageHeaderComponent>
      <FullAttendanceComponent attendanceId={attendanceId} />
      <Modal title={'Acesso Público'} open={showPublicLink} onCancel={() => setShowPublicLink(false)}>
        <Typography.Text copyable>
          {`${window.location.origin}/public-search/${attendance?.code}`}
        </Typography.Text>
      </Modal>
    </>
  )
}

