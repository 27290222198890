import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {ExternalAttendanceEntity} from "../entity/external-attendance.entity";
import {BaseResponse} from "../../core/common/base-response.common";
import {AttendanceEntity} from "../../context/public-search/domain/attendance.entity";

export function useExternalAttendanceRepository() {
  const crudHttpService = useJbHttpCrudService<ExternalAttendanceEntity>('external-attendance')

  function convert(externalAttendanceId: string, clientId: string): Promise<BaseResponse<AttendanceEntity>> {
    return crudHttpService.post(
      `${crudHttpService.resourceName}/convert`,
      {
        externalAttendanceId,
        clientId
      }
    )
  }
  
  
  return {
    ...crudHttpService,
    convert
  }
}
