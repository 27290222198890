import {ModulePageProps} from "../../../core/module-pages";
import {StepTypeFormComponent} from "../component/step-type.form";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useEffect, useState} from "react";
import {useStepTypeRepository} from "../../../../../domain/repository/step-type.repository";
import {message, Tabs} from "antd";
import {StepTypeEntity} from "../../../../../domain/entity/step-type.entity";
import {useParams} from "react-router-dom";
import { StepTypeMediaFormComponent } from "../component/step-type-media.form";
import { StepTypeLinksFormComponent } from "../component/step-type-links.form";

export function EditStepTypePage(props: ModulePageProps) {
  const [tab, setTab] = useState(1);
  const [stepType, setStepType] = useState<StepTypeEntity>()
  const steTypeRepo = useStepTypeRepository()
  const { id: stepTypeId } = useParams<any>()

  useEffect(() => {
    steTypeRepo.find(stepTypeId).then(res => setStepType(res.data))
  }, [])

  function onCreate(values: any) {
    return steTypeRepo.edit(stepTypeId, values).then(res => {
      props.navigator('list')
    })
  }

  function Content(props: { tab: number }) {
    switch(props.tab) {
      case 1:
        return (
          <StepTypeFormComponent onSubmit={onCreate} values={stepType} />
        )
        break;
      case 2:
        return (
          <StepTypeMediaFormComponent 
            stepTypeId={stepTypeId} 
            currentFileList={stepType?.medias?.map(media => ({
                uid: media.path,
                name: media.name,
                status: 'done',
                path: media.path    
              }) 
            )}
          />
        )
      break;
      case 3: 
        return <StepTypeLinksFormComponent stepTypeId={stepTypeId} />
      default:
        return null;
        break;
    }
  }

  return (
    <>
      <JbPageHeaderComponent
        title={`Parametrizações / Tipo Movimentação / ${stepType?.description}`}
        description={'Nova Movimentação'}
      />
      <Tabs tabIndex={tab} onChange={key => setTab(parseInt(key))}>
        <Tabs.TabPane key={1} tab='Identificação' />
        <Tabs.TabPane key={2} tab='Anexos' />      
        <Tabs.TabPane key={3} tab='Links' />      
      </Tabs>
      <Content tab={tab} />
    </>
  )
}
