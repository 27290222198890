import moment from "moment";

export function useDatetimeUtil() {
  function splitDateTime(dt: string | Date) {
    dt = moment(dt).toISOString(true)
    const [year, month, dayAndTime] = dt.split('-')
    const [day, time] = dayAndTime.split('T');
    const [hour, minute, secondAndMilliSecond] = time.split(':')
    const [second, millisecond] = secondAndMilliSecond.split('.')
    return {
      year,
      month,
      day,
      hour,
      minute,
      second,
      millisecond
    }
  }
  function formDateTimeInPtBr(dt: string | Date) {
    const {
      day,
      month,
      year,
      hour,
      minute,
      second
    } = splitDateTime(dt)
    return `${day}/${month}/${year} ${hour}:${minute}:${second}`
  }

  function formDateInPtBr(dt: string | Date) {
    if (!dt) return null;
    const {
      day,
      month,
      year,
    } = splitDateTime(dt)
    return `${day}/${month}/${year}`
  }
  
  function getDiffInDays(firstDate: Date | string, secondDate: Date | string): number {
    const diff = new Date(secondDate).getTime() - new Date(firstDate).getTime();
    return Math.ceil(diff / (1000 * 3600 * 24))
  }

  return {
    formDateTimeInPtBr,
    formDateInPtBr,
    splitDateTime,
    getDiffInDays
  }
}
