import {Col, Form, Input, Row, Select} from "antd";
import {ReactNode, useEffect, useState} from "react";
import {ProcessTypeEntity} from "../../process-type/domain/entity/process-type.entity";
import {useProcessTypeRepository} from "../../process-type/domain/repository/process-type.repository";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";

export type ProcessFormComponent = {
  onSubmit: (values: any) => any,
  initialValue?: any,
  extraActions?: ReactNode[]
}
export function ProcessFormComponent(props: ProcessFormComponent) {
  const [processTypes, setProcessTypes] = useState<ProcessTypeEntity[]>([])
  const [currentType, setCurrentType] = useState<ProcessTypeEntity>()
  const [form] = Form.useForm()
  
  const processTypeRepo = useProcessTypeRepository();
  

  useEffect(() => {
    processTypeRepo.list().then(res => {
      setProcessTypes(res.data)
      if (props.initialValue.processType) {
        setCurrentType(res.data.find(w => w.id === props.initialValue.processType.id))
      }
    })
  }, [])

  useEffect(() => {
    if (props.initialValue) {
      form.setFieldsValue({
        ...props.initialValue,
        processTypeId: props.initialValue.processType?.id,
        processSubtypeId: props.initialValue.processSubtype?.id,
        objects: props.initialValue?.objects?.map((w:any) => w.id)
      })
    }
  }, [props.initialValue])

  function onSubmit(values: any) {
    return props.onSubmit(values)
  }

  function getProcessTypesOpts() {
    return processTypes.map(w => ({
      label: w.name,
      value: w.id
    })).sort((a,b) => a.label > b.label ? 1 : -1)
  }
  
  function getProcessSubtypeOpts() {
    if (!currentType) return [];
    return currentType?.subtypes?.map(w => ({
      label: w.description,
      value: w.id
    }))
  }
  
  function onFormChange(arg: any) {
    if (arg.processTypeId) {
      setCurrentType(processTypes.find(w => w.id === arg.processTypeId))
      form.resetFields(['processSubtypeId'])
    }
  }

  return (
    <Form form={form} layout={'vertical'} onFinish={onSubmit} onValuesChange={onFormChange}>
      <Row gutter={12}>
        <Col span={9}>
          <Form.Item name={'processTypeId'} label={'Tipo'} rules={[{ required: true }]}>
            <Select options={getProcessTypesOpts()} />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name={'processSubtypeId'} label={'Sub Tipo'} rules={[{ required: true }]}>
            <Select options={getProcessSubtypeOpts()} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={'code'} label={'Código do Processo'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <JbFormActionButtonComponent
        extraActions={props.extraActions}
      />
    </Form>
  )
}
