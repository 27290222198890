import {Button, Col, Form, FormInstance, Radio, Row} from "antd";
import {JbDatePickerComponent} from "../../../core/form/jb-date-picker.component";
import {useCallback, useEffect, useState} from "react";
import moment, {Moment} from "moment";
import {SearchOutlined} from "@ant-design/icons";

export function CashFlowFilterComponent(props: {
  currentValues: any,
  onFilter: (values: any) => any,
}) {
  const [form] = Form.useForm();
  const [picker, setPicker] = useState<"date" | "time" | "month" | "week" | "quarter" | "year" | undefined>('date')
  
  useEffect(() => {
    if (props.currentValues) {
      form.setFieldsValue({
        ...props.currentValues,
        start: props.currentValues.start ? moment(props.currentValues.start) : undefined,
        end: props.currentValues.end ? moment(props.currentValues.end) : undefined,
      })
    }
  }, [form, props.currentValues]);
  
  const onChangeInitialDate = useCallback((dt: Moment) => {
    const end = form.getFieldValue('end');
    // @ts-ignore
    if (Math.abs(moment(end).diff(dt, picker)) > 7) {
      form.setFieldsValue({ end: undefined })
    }
  }, [form, picker])

  const onChangeFinalDate = useCallback((dt: Moment) => {
    const start = form.getFieldValue('start');
    // @ts-ignore
    if (Math.abs(moment(start).diff(dt, picker)) > 7) {
      form.setFieldsValue({ start: undefined })
    }
  }, [form, picker])

  const onChangeStrategy = useCallback((value: string) => {
    switch (value) {
      case 'daily':
        setPicker('date')
        break;
      case 'monthly':
        setPicker('month');
        break;
      case 'yearly':
        setPicker('year');
        break;
    }
    updateDateValues(value)
  }, [])
  
  const updateDateValues = useCallback((strategy: string) => {
    switch (strategy) {
      case 'daily':
        form.setFieldsValue({
          start: moment().add(-7, 'd'),
          end: moment()
        })
        break;
      case 'monthly':
        form.setFieldsValue({
          start: moment().add(-7, 'month'),
          end: moment()
        })
        break;
      case 'yearly':
        form.setFieldsValue({
          start: moment().add(-7, 'y'),
          end: moment()
        })
        break;
    }
  }, [form])
  
  return (
    <Form form={form} layout={'vertical'} onFinish={props.onFilter}>
      <Row gutter={12}>
        <Col>
          <Form.Item name={'groupStrategy'} label={'Tipo de Período'} rules={[{ required: true }]}>
            <Radio.Group
              optionType={'button'}
              onChange={e => onChangeStrategy(e.target.value)}
              options={[
                { value: 'daily', label: 'Diário' },
                { value: 'monthly',  label: 'Mensal'},
                { value: 'yearly', label: 'Anual'}
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={5} >
          <Form.Item name={'start'} label={'Data Inicial'}>
            <JbDatePickerComponent
              picker={picker}
              onChange={(dt) => dt && onChangeInitialDate(dt)}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name={'end'} label={'Data Final'}>
            <JbDatePickerComponent
              onChange={e => e && onChangeFinalDate(e)}
              picker={picker}
            />
          </Form.Item>
        </Col>
        <Col style={{paddingTop: 30}}>
          <Button htmlType={'submit'} icon={<SearchOutlined />}  >
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  )
}