import {AttendanceStepEntity} from "../../domain/entity/attendance-step.entity";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {Button, Divider, Popover, Tag} from "antd";
import {CheckCircleOutlined, CheckOutlined} from "@ant-design/icons";
import React, {useMemo} from "react";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";
import {useAttendanceStepRepository} from "../../domain/repository/attendance-step.repository";
import {JbButtonWithPopover} from "../../../../core/button-with-popover";

export function FinishAttendanceStepButtonComponent(props: {
  step: AttendanceStepEntity,
  onFinish: () => any
}) {
  const attendanceRepo = useAttendanceRepository();
  const attendanceStepRepo = useAttendanceStepRepository();
  const { formDateInPtBr } = useDatetimeUtil()
  function onFinish() {
    attendanceRepo.finishStep(
      props.step.attendanceId,
      props.step.id
    ).then(() => {
      props.onFinish()
    })
  }
  
  function onReopen() {
    attendanceStepRepo.reopenStep(props.step.id)
      .then(() => {
        props.onFinish()
      })
  }
  
  const finishDateLabel = useMemo(() => {
    if (!props.step.dateTimeFinished) return null;
    return (
      <>
        <Tag color={'success'}>
          Concluído em {formDateInPtBr(props.step.dateTimeFinished)} <br/>
        </Tag>
        <Divider />
        <Button
          loading={attendanceStepRepo.loading}
          block
          onClick={() => onReopen()}
        >
          Reabrir Movimentação
        </Button>
      </>
    )
  }, [props.step])
  
  
  return (
    <>
      {
        props.step.finished
          ?
          <Popover content={finishDateLabel}>
            <Button
              type={'text'}
              style={{color: 'green'}}
              icon={<CheckCircleOutlined />}
            />
          </Popover>
          :
          <JbButtonWithPopover
            type={'text'}
            title={'Finalizar Movimentação'}
            block
            onClick={onFinish}
            icon={<CheckOutlined />}
            style={{color: 'darkgray'}}
          />
      }
    </>
  )
}