import {Col, Form, Input, Row, Select} from "antd";
import {JbDatePickerComponent} from "../../../core/form/jb-date-picker.component";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {ClientEntity} from "../domain/entity/client.entity";
import {useEffect} from "react";
import moment from "moment";

export function ClientPhysicalPersonForm(props: {
  client: ClientEntity,
  currentData?: any
  onSubmit: (value: any) => any,
}) {
  const [form] = Form.useForm();
  
  useEffect(() => {
    if (props.currentData) {
      form.setFieldsValue({
        ...props.currentData,
        birthDate: props.currentData.birthDate ? moment(props.currentData.birthDate) : undefined
      });
    }
  }, [props.currentData])
  
  const schoolLevelOptions = [
    { label: 'Superior Incompleto', value: 'superior_incompleto' },
    { label: 'Superior Completo', value: 'superior_completo' },
    { label: 'Pós Graduação Lato Sensu Incompleto', value: 'pos_latu_incompleto' },
    { label: 'Pós Graduação Lato Sensu Completo', value: 'pos_latu_completo' },
    { label: 'Pós Graduação Stricto Sensu Incompleto (Mestre)', value: 'pos_stricto_mestre_incompleto' },
    { label: 'Pós Graduação Stricto Sensu Completo (Mestre)', value: 'pos_stricto_mestre_completo' },
    { label: 'Pós Graduação Stricto Sensu Incompleto (Doutor)', value: 'pos_stricto_doutor_incompleto' },
    { label: 'Pós Graduação Stricto Sensu Completo (Doutor)', value: 'pos_stricto_doutor_completo' },
  ]
  
  const genreOpts = [
    { label: 'Masculino', value: 'masculino'},
    { label: 'Feminino', value: 'feminino'},
    { label: 'Transgênero', value: 'transgenero'},
    { label: 'Gênero Neutro', value: 'genero_neutro'},
    { label: 'Não binário', value: 'nao_binario'},
    { label: 'Agênero', value: 'pangênero'},
    { label: 'Agênero', value: 'genderqueer'},
    { label: 'Agênero', value: 'two_spirit'},
    { label: 'Pangênero', value: 'pangenero'},
  ]
  
  function onFinish(values: any) {
    props.onSubmit(values)
  }
  
  return (
    <Form layout={'vertical'} form={form} onFinish={onFinish}>
      <Row gutter={12}>
        <Col span={10}>
          <Form.Item label={'Gênero'} name={'gender'}>
            <Select options={genreOpts} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={'Data de Nascimento'} name={'birthDate'}>
            <JbDatePickerComponent />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={'Escolaridade'} name={'schoolLevel'}>
            <Select options={schoolLevelOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item name={'originCountry'} label={'País de Origem'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'originState'} label={'Estado de Origem'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'originCity'} label={'Cidade de Origem'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item label={'Nome da Mãe'} name={'motherName'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={'Nome da Pai'} name={'fatherName'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={24}>
          <Form.Item label={'Deficiência (Caso Exista)'} name={'motherName'}>
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>
      <JbFormActionButtonComponent />
    </Form>
  )
}