import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useEffect, useMemo, useState} from "react";
import {ColumnsType} from "antd/es/table";
import {Table} from "antd";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {FinanceItemEntity} from "../../../../../domain/entity/finance-item.entity";
import {useFinanceItemRepository} from "../../../../../domain/repository/finance-item.repository";

export function ListItemPage(props: ModulePageProps) {
  const [items, setItems] = useState<FinanceItemEntity[]>([]);
  const itemRepo = useFinanceItemRepository();
  
  function loadCategory() {
    itemRepo.list().then((res) => setItems(res.data));
  }
  
  useEffect(() => {
    loadCategory()
  }, []);
  
  function onRemove(id: string) {
    itemRepo.remove(id).then(() => loadCategory());
  }

  const columns = useMemo<ColumnsType<any>>(() => {
    return [
      {
        key: 1,
        title: 'Nome',
        dataIndex: 'name'
      },
      {
        key: 1,
        title: 'Descrição',
        dataIndex: 'description'
      },
      {
        key: 1,
        title: 'Categoria',
        dataIndex: 'category',
        render: category => category?.name,
      },
      {
        title: '',
        key: 99,
        align: 'right',
        dataIndex: 'id',
        render: (id) => (
          <JbTableRowActionsComponent
            onEdit={() => props.navigator(`edit/${id}`)}
            onRemove={() => onRemove(id)}
          />
        )
      }
    ]
  }, [])
  
  return(
    <>
      <JbPageHeaderComponent
        title={'Financeiro / Itens'}
        description={'Gestão das itens financeiras'}
        onCreateLink={`${props.prefix}/create`}
      />
      <Table
        loading={itemRepo.loading}
        dataSource={items}
        columns={columns}
      />
    </>
  )
}
