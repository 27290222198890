import {TenantEntity} from "../../../../../domain/entity/tenant.entity";
import {Table} from "antd";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";

export function ListTenantComponent(props: {
  data: TenantEntity[],
  onClickRow: (id: string) => any,
  onEdit: (id: TenantEntity) => any,
}) {
  return (
    <Table
      columns={[
        {
          key: 'name',
          dataIndex: 'name',
          title: 'Nome'
        },
        {
          key: 'type',
          dataIndex: 'type',
          title: 'Tipo Cliente'
        },
        {
          key: 'document',
          dataIndex: 'document',
          title: 'Documento'
        },
        {
          key: 'actions',
          dataIndex: 'id',
          render: (id, row) => (
            <JbTableRowActionsComponent
              onShow={() => props.onClickRow(id)}
              onEdit={() => props.onEdit(row)}
            />
          )
        }
      ]}
      dataSource={props.data}
    />
  )
}