import {AttendanceEntity} from "../domain/attendance.entity";
import {Button, Descriptions, List, Modal, Popover, Table} from "antd";
import {useDatetimeUtil} from "../../../core/util/datetime.util";
import {FileOutlined} from "@ant-design/icons";
import {useRef, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {useJbHttpPublicService} from "../../../core/service/jb-http-public.service";
import {useEnvHook} from "../../../core/common/env-hook";
import {
  AttendancePublicInteractionHistoryComponent
} from "../../admin/module/attendance/component/attendance-public-interacton/attendance-public-interaction-history.component";
import {
  AttendancePublicInteractionFormComponent
} from "../../admin/module/attendance/component/attendance-public-interacton/attendance-public-interaction-form.component";
import {PublicAttendanceInteractionFormModal} from "./public-attendance-interaction-form.modal";
import {AttendancePublicInteractionEntity} from "../../../domain/entity/attendance-public-interaction.entity";

export function PublicAttendanceComponent(props: {
  attendance: AttendanceEntity
}) {
  const [showAttachments, setShowAttachments] = useState<boolean>(false);
  const [showModalInteraction, setShowModalInteraction] = useState<any>()
  const [interactions, setInteractions] = useState<AttendancePublicInteractionEntity[]>(props.attendance.publicInteractions ?? [])
  const { attendance } = props;
  const { formDateInPtBr } = useDatetimeUtil();
  const { createInteraction, uploadFileInteraction } = useJbHttpPublicService();
  
  function onInteractionSubmit(values: any) {
    setShowModalInteraction(values)
  }
  
  function onSubmit(values: any) {
    values['attendanceId'] = props.attendance.id;
    values['tenantId'] = props.attendance.tenantId;
    values['createdAt'] = new Date().toISOString();
    if (showModalInteraction.file) {
      values['file'] = showModalInteraction.file;
      if (typeof values['meta'] === 'object') {
        values['meta'] = JSON.stringify(values['meta']);
      }
      return uploadFileInteraction(values).then(res => {
        setInteractions(prev => [...prev, res.data])
        setShowModalInteraction(undefined)
      })
    }
    createInteraction(values).then(res => {
      setInteractions(prev => [...prev, res.data])
      setShowModalInteraction(undefined)
    })
  }
  
  return (
    <>
      <Descriptions
        layout={'vertical'}
        bordered
        column={4}
      >
        <Descriptions.Item label={'Código Juristta'}>
          {attendance?.code}
        </Descriptions.Item>
        <Descriptions.Item label={'Código Processo PJE'}>
          {attendance.processCode}
        </Descriptions.Item>
        <Descriptions.Item label={'Date de Criação'}>
          {formDateInPtBr(attendance.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label={'Anexos'}>
          <Button
            type={'ghost'}
            shape={'round'}
            icon={<FileOutlined />}
            onClick={() => setShowAttachments(!!props.attendance?.publicAttachments?.length)}
          > {props.attendance?.publicAttachments?.length ?? 0}
          </Button>
        </Descriptions.Item>
      </Descriptions>
      <br/>
      <Table
        columns={[
          {
            title: 'Movimentação',
            dataIndex: 'stepTypeDescription',
            key: 'stepTypeDescription',
          },
          {
            title: 'Data de Início',
            dataIndex: 'beginStep',
            key: 'beginDate',
            render: formDateInPtBr
          },
          {
            title: 'Data de Término',
            dataIndex: 'endStep',
            key: 'endDate',
            render: formDateInPtBr
          },
          {
            title: 'Finalizada',
            dataIndex: 'finished',
            key: 'finished',
            render: info => info === true ? 'Sim' : 'Não'
          }
        ]}
        dataSource={attendance?.steps ?? []}
        pagination={{
          defaultPageSize: 10
        }}
      />
      <AttendancePublicInteractionHistoryComponent
        interactions={interactions}
        disableRemovePopover
        onRemove={() => null}
      />
      
      <AttendancePublicInteractionFormComponent
        onSubmit={onInteractionSubmit}
        onUpload={(data) => {
          setShowModalInteraction({
            content: data?.file?.name,
            file: data?.file
          })
        }}
      />
  
      {
        showModalInteraction?.content && (
          <PublicAttendanceInteractionFormModal
            onCancel={() => setShowModalInteraction(false)}
            onSubmit={onSubmit}
            currentContent={showModalInteraction?.content}
            file={showModalInteraction?.file}
            open
          />
        )
      }
      
      <Modal
        title={'Anexos'}
        footer={undefined}
        width={600}
        open={showAttachments}
        onCancel={() => setShowAttachments(false)}
      >
        <AttachmentList
          attendance={props.attendance}
          attachments={props.attendance.publicAttachments ?? []} />
      </Modal>
    </>
  )
}

export function AttachmentList(props: {
  attachments: Array<{ id: string, originalName: string, path: string, mimeType: string}>,
  attendance: AttendanceEntity,
}) {
  return (
    <List
      dataSource={props.attachments}
      renderItem={(attachment) => (
        <List.Item
          actions={[
            <DownloadAttachment attachment={attachment} tenantId={props.attendance.tenantId} />
          ]}
        >
          <List.Item.Meta
            title={attachment?.originalName}
            description={attachment?.mimeType}
          />
        </List.Item>
      )}
    />
  )
}

export function DownloadAttachment(props: {
  attachment: { id: string, originalName: string, path: string, mimeType: string},
  tenantId: string,
}) {
  const captchaRef = useRef<any>();
  const { getAttachment } = useJbHttpPublicService();
  const envHook = useEnvHook();
  
  function download(captchaToken: string) {
    return getAttachment(
      props.attachment.id,
      props.tenantId,
      captchaToken,
      props.attachment.mimeType
    )
      .finally(() => captchaRef?.current?.reset());
  }
  
  return (
    <Popover
      title={'Download de arquivo'}
      trigger={'click'}
      content={
        <ReCAPTCHA
          ref={captchaRef}
          onChange={(e) => !!e ? download(e) : null}
          sitekey={envHook.getRecaptchaKey()}
        />
      }
    >
      <Button type={'link'}>Download</Button>
    </Popover>
  )
}