import {Col, Form, Input, Row} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {StepTypeEntity} from "../../../../../domain/entity/step-type.entity";
import {useEffect} from "react";

export function AttendanceTypeFormComponent(props: {
  onSubmit: (values: any) => any,
  values?: Partial<StepTypeEntity>
}) {
  const [form] = Form.useForm()

  useEffect(() => {
    if (props.values) {
      form.setFieldsValue(props.values)
    }
  }, [props])

  return (
    <Form form={form} layout={'vertical'} onFinish={props.onSubmit}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label={'Nome'} name={'name'} required rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item label={'Descrição'} name={'description'} required rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <JbFormActionButtonComponent />
    </Form>
  )
}
