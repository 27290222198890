import {useContext} from "react";
import {AdminApplicationContext} from "../../../../core/context/admin-application.context";
import {ChangePasswordFormModal} from "./change-password-form.modal";

export function FirstLoginChangePasswordComponent(props: {
  refreshUser: () => any,
}) {
  const { user: currentUser } = useContext<any>(AdminApplicationContext);
  
  return (
    <ChangePasswordFormModal
      onClose={props.refreshUser}
      refreshUser={props.refreshUser}
      open={currentUser?.changePassword}
    />
  )
}