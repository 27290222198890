import {ModulePageProps} from "../../../core/module-pages";
import {AttendanceTypeFormComponent} from "../component/step-type.form";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React from "react";
import {useAttendanceTypeRepository} from "../../../../../domain/repository/attendance-type.repository";

export function CreateAttendanceTypePage(props: ModulePageProps) {
  const attendanceTypeRepo = useAttendanceTypeRepository()

  function onCreate(values: any) {
    return attendanceTypeRepo.create(values).then(res => {
      props.navigator(`list`)
    })
  }

  return (
    <>
      <JbPageHeaderComponent
        title={'Parametrizações / Tipo Atendimento'}
        description={'Novo Registro'}
      />
      <AttendanceTypeFormComponent onSubmit={onCreate} />
    </>
  )
}
