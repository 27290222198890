import {Button, Descriptions, Empty, Tag} from "antd";
import {FinanceEntryStatus} from "../finance-entry.status.componenet";
import {FinanceEntryTypeComponent} from "../finance-entry.type.component";
import {FinanceEntryAmountComponent} from "../finance-entry.amount.component";
import {FinanceEntryEntity} from "../../../../../../domain/entity/finance-entry.entity";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";
import {ListFinanceItemsComponent} from "../list-finance-item.component";
import {useEffect, useState} from "react";
import {useFinanceEntryRepository} from "../../../../../../domain/repository/finance-entry.repository";

export function ShowFinanceEntry(props: {
  entry: FinanceEntryEntity,
}) {
  const [refreshedEntry, setRefreshedEntry] = useState<FinanceEntryEntity>();
  const { entry } = props;
  const entryRepo = useFinanceEntryRepository();
  
  useEffect(() => {
    entryRepo.find(entry.id).then(res => setRefreshedEntry(res.data));
  }, [props.entry]);
  const { formDateInPtBr } = useDatetimeUtil();
  
  
  return (
    <>
      <Descriptions bordered layout={'vertical'}>
        
        <Descriptions.Item label={'Tipo'}>
          <FinanceEntryTypeComponent type={entry.type} />
        </Descriptions.Item>
        
        <Descriptions.Item label={'Status'}>
          <FinanceEntryStatus status={entry.status} />
        </Descriptions.Item>
        
        <Descriptions.Item label={'Valor Original'}>
          <FinanceEntryAmountComponent amount={entry.amount} />
        </Descriptions.Item>
        
        <Descriptions.Item label={'Valor Baixado'}>
          <FinanceEntryAmountComponent amount={0} />
        </Descriptions.Item>
        
        {
          entry?.type === 'D' && (
            
            <Descriptions.Item label={'Fornecedor'}>
              {entry.supplier?.name}
            </Descriptions.Item>
          )
        }
        {
          entry?.type === 'R' && (
            
            <Descriptions.Item label={'Cliente'}>
              {entry.client?.name}
            </Descriptions.Item>
          )
        }
        
        <Descriptions.Item label={'Data Criação'}>
          {formDateInPtBr(entry.createdAt)}
        </Descriptions.Item>
        
        <Descriptions.Item label={'Data de Baixa'}>
          &nbsp;
        </Descriptions.Item>
        
        
        <Descriptions.Item  span={3} label={'Observação'}>
          { props.entry.observation }
        </Descriptions.Item>
      
      </Descriptions>
      
      <ListFinanceItemsComponent items={refreshedEntry?.items ?? []} />
    </>
  )
}