import {useJbHttpCrudService} from "../../../../../../core/service/jb-http-crud.service";
import {ProcessEntity} from "../entity/process.entity";

export function useProcessRepository() {
  const { ...crud  } = useJbHttpCrudService<ProcessEntity>('process');
  
  function attachObject(processId: string, dto: {
    objectId: string,
    type: string,
    probability: number,
    amount: number
  }) {
    return crud.post(
      `${crud.resourceName}/${processId}/add-process-object`,
      JSON.stringify(dto),
    )
  }
  
  function editAttachedObject(
    processId: string,
    objectAttachedId: string,
    dto: {
      type: string,
      probability: number,
      amount: number,
      result: number,
  }) {
    return crud.put(
      `${crud.resourceName}/${processId}/edit-process-object/${objectAttachedId}`,
      JSON.stringify(dto),
    )
  }
  
  function removeAttachedObject(processId: string, objectId: string) {
    return crud.post(
      `${crud.resourceName}/${processId}/remove-process-object/${objectId}`,
      {},
    )
  }
  
  
  function listAttachedObject(processId: string) {
    return crud.get(
      `${crud.resourceName}/${processId}/list-process-objects`,
      {},
    )
  }
  
  return {
    ...crud,
    attachObject,
    removeAttachedObject,
    listAttachedObject,
    editAttachedObject,
  }
}
