import {Cell, Pie, PieChart, ResponsiveContainer} from "recharts";
import {Typography} from "antd";
import {AttendanceReportEntity} from "../../../../../domain/entity/attendance-report.entity";
import {useMemo} from "react";

export function AttendancePerOriginGraphComponent(props: {
  reports: AttendanceReportEntity[],
  colors: string[]
}) {

  const source = useMemo(() => {
    const map = new Map<string, number>();
    props.reports.forEach(item => {
      const value = map.get(item.origin);
      map.set(item.origin, (value ?? 0) + 1 );
    })
    return Array.from(map).map(([key, value]) => ({ key, value }))
  }, [props.reports])
  function renderLabel(props: any) {
    const key = props.payload.payload.key
    return `${key} (${(props.percent * 100).toFixed(2)}%)`;
  }
  
  return (
    <>
      <Typography.Title level={4}>
        Atendimentos / Origem
      </Typography.Title>
      <ResponsiveContainer width={'100%'} height={400}>
        <PieChart width={400} height={400}>
          <Pie
            data={source}
            fill="#8884d8"
            dataKey="value"
            allowReorder={'yes'}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderLabel}
          >
            {source.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={props.colors[index % props.colors.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  )

}