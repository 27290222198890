import {Button, Divider, Form, Space} from "antd";
import {LeftSquareOutlined, SaveOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {ReactNode} from "react";

export  type JbFormActionButtonComponentProps = {
  onCancel?: () => any,
  extraActions?: ReactNode[],
  labelSave?: string,
  labelCancel?: string,
  hideCancelButton?: boolean,
  onSaveClick?: () => any,
  saveDisabled?: boolean,
  loading?: boolean,
}
export function JbFormActionButtonComponent(props: JbFormActionButtonComponentProps) {
  const history = useHistory()

  function onCancel() {
    if (props.onCancel) {
      props.onCancel()
    } else {
      history.goBack()
    }
  }

  const labelSave = props.labelSave ?? 'Salvar';
  const labelCancel = props.labelCancel ?? 'Cancelar'

  return (
    <Form.Item style={{textAlign: 'right'}}>
      <Divider />
      <Space>
        <Button
          onClick={props.onSaveClick}
          type={'primary'}
          htmlType={'submit'}
          icon={<SaveOutlined />}
          disabled={props.saveDisabled || props.loading}
          loading={props.loading}
        >
          {labelSave}
        </Button>
        {
          !props.hideCancelButton && (
            <Button
              onClick={() => onCancel()}
              icon={<LeftSquareOutlined />}
            >
              {labelCancel}
            </Button>
          )
        }
        { props.extraActions }
      </Space>
    </Form.Item>
  )
}
