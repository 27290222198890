import {ModulePageProps} from "../../../core/module-pages";
import {PartTypeFormComponent} from "../component/part-type.form.component";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React from "react";
import {usePartTypeRepository} from "../domain/repository/part-type.repository";
import {message} from "antd";

export function CreatePartTypePage(props: ModulePageProps) {
  const partTypeRepo = usePartTypeRepository();

  function onCreate(values: any) {
    return partTypeRepo.create(values).then(res => {
      props.navigator('list')
    })
  }

  return (
    <>
      <JbPageHeaderComponent
        title={'Parametrizações / Tipo Parte'}
        description={'Novo Tipo de Parte'}
      />
      <PartTypeFormComponent onSubmit={onCreate} />
    </>
  )
}
