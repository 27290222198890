export class StatusEnum {
  static OPENED = 'OPENED';
  static CONCLUDED = 'CONCLUDED';
  static STOPPED = 'STOPPED';
  
  static list(): string[] {
    return [
      this.CONCLUDED,
      this.OPENED,
      this.STOPPED
    ]
  }
  
  static parse(statusCode: string) {
    switch (statusCode) {
      case this.STOPPED: return 'Suspenso';
      case this.CONCLUDED: return 'Concluído';
      case this.OPENED: return 'Aberto';
      default: return statusCode;
    }
  }
}