import {ItemFormComponent} from "../component/item..form.component";
import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {useFinanceItemRepository} from "../../../../../domain/repository/finance-item.repository";

export function CreateItemPage(props: ModulePageProps) {
  const itemRepo = useFinanceItemRepository();
  
  function onCreate(values: any) {
    itemRepo.create(values).then(() => {
      props.navigator('list')
    })
  }
  
  return (
    <>
      <JbPageHeaderComponent title={'Novo Item'} />
      <ItemFormComponent
        onSubmit={onCreate}
        onCancel={() => props.navigator('list')}
      />
    </>
  )
}