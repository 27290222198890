import {Modal} from "antd";
import {AttendanceWorkflow} from "./index";
import {WorkflowEntity} from "../../../../../../domain/entity/workflow.entity";
import {AttendanceEntity} from "../../domain/entity/attendance.entity";

export function AttendanceWorkflowModal(props: {
  onClose: () => any
  workflow: WorkflowEntity,
  attendance: AttendanceEntity,
  open: boolean,
}) {
  return (
    <Modal
      open={props.open}
      title={props.workflow.name}
      onCancel={props.onClose}
      footer={null}
      width={800}
    >
      <AttendanceWorkflow
        workflow={props.workflow}
        attendance={props.attendance}
        onWorkflowDisabled={props.onClose}
      />
    </Modal>
  )
}