import {SupplierFormComponent} from "../component/supplier.form.component";
import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {FinanceItemEntity} from "../../../../../domain/entity/finance-item.entity";
import {useSupplierRepository} from "../../../../../domain/repository/supplier.repository";

export function EditSupplierPage(props: ModulePageProps) {
  const [supplier, setSupplier] = useState<FinanceItemEntity>();
  const { id } = useParams<any>()
  
  const supplierRepo = useSupplierRepository();
  
  useEffect(() => {
    supplierRepo.find(id).then(res => setSupplier(res.data));
  }, [id]);
  
  function onSave(values: any) {
    supplierRepo.edit(id, values).then(() => {
      props.navigator('list')
    })
  }
  
  return (
    <>
      <JbPageHeaderComponent title={'Editar Fornecedor ' + supplier?.name} />
      <SupplierFormComponent
        onSubmit={onSave}
        currentValues={supplier}
        onCancel={() => props.navigator('list')}
      />
    </>
  )
}