import {JbDropzoneComponent} from "../../../core/dropzone";
import {useAttendanceRepository} from "../domain/repository/attendance.repository";
import {useState} from "react";
import {Form, Modal, Switch} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {LoadingOutlined} from "@ant-design/icons";

export function AttendanceAttachmentForm(props: {
  attendanceId: string,
  onSaved: (attach: any) => any,
}) {
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [form] = Form.useForm()
  
  const attendanceRepo = useAttendanceRepository();
  
  function uploadAttachment(file: any, isPublic?: boolean) {
    attendanceRepo.uploadAttachment(props.attendanceId, file, isPublic).then(() => {
      props.onSaved(props.attendanceId)
      setFileToUpload(undefined);
    })
  }
  
  return (
    <div style={{width: '100%', height: 150}}>
      <JbDropzoneComponent
        onDrop={file => setFileToUpload(file)}
        description={"Faça Upload dos Anexos do Atendimento"}
      />
      {
        attendanceRepo.loading && (
          <LoadingOutlined />
        )
      }
      {
        fileToUpload && (
          <Modal
            title={'Deseja disponibilizar esse arquivo na consulta pública ?'}
            open
            footer={null}
            onCancel={() => setFileToUpload(undefined)}
          >
            <Form form={form} onFinish={({isPublic}) => uploadAttachment(fileToUpload, isPublic)}>
              <Form.Item valuePropName={'checked'} label={''} name={'isPublic'}>
                <Switch checkedChildren={'Sim'} unCheckedChildren={'Não'} />
              </Form.Item>
              <JbFormActionButtonComponent
                labelSave={'Confirmar'}
                onCancel={() => setFileToUpload(undefined)}
                loading={attendanceRepo.loading}
              />
            </Form>
          </Modal>
        )
      }
    </div>
  )
}