import {Form, Input} from "antd";
import {JbFormActionButtonComponent} from "../component/jb-form-action-button.component";
import {PASSWORD_PATTERN} from "../../module/super-admin/component/change-user.form";

export function ChangePasswordFormComponent(props: {
  onSubmit: (values: any) => any,
}) {
  const [form] = Form.useForm()
  
  function onFinish(values: any) {
    if (values.newPassword !== values.confirmNewPassword) {
      form.setFields([
        { name: 'confirmNewPassword', errors: ['As senhas não são iguais']}
      ])
      return
    }
    
    props.onSubmit(values);
    form.resetFields();
  }
  
  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={onFinish}
    >
      <Form.Item name={'currentPassword'} label={'Senha Atual'} rules={[{ required: true }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item name={'newPassword'} label={'Nova Senha'} rules={[{ required: true }]}>
        <Input.Password pattern={PASSWORD_PATTERN} />
      </Form.Item>
      <Form.Item name={'confirmNewPassword'} label={'Confirmar Nova Senha'} rules={[{ required: true }]}>
        <Input.Password pattern={PASSWORD_PATTERN} />
      </Form.Item>
      <JbFormActionButtonComponent />
    </Form>
  )
}