import {Alert, Button, Card, Form, Input} from "antd";
import ReCAPTCHA from "react-google-recaptcha"
import {SearchOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import {useJbHttpPublicService} from "../../core/service/jb-http-public.service";
import {AttendanceEntity} from "./domain/attendance.entity";
import {PublicAttendanceComponent} from "./component/public-attendance.component";
import {useParams} from "react-router-dom";
import {useEnvHook} from "../../core/common/env-hook";
import {PublicHeaderComponent} from "../../core/component/public-header.component";

export function PublicSearch() {
  const [form] = Form.useForm();
  const [clientName, setClientName] = useState('');
  const captchaRef = useRef<any>(null);
  const [errorMessage, setErrorMessage] = useState();
  const [attendance, setAttendance] = useState<AttendanceEntity>()
  const { publicAttendanceSearch, getClientName } = useJbHttpPublicService();
  const { attendanceCode } = useParams<any>()
  const envHook = useEnvHook();
  
  
  useEffect(() => {
    if (attendance)
      getClientName(attendance.tenantId).then(res => setClientName(res))
  }, [attendance]);
  
  function onFinish(values: any) {
    const captchaGoogle = captchaRef?.current?.getValue();
    captchaRef?.current?.reset();
    publicAttendanceSearch(values.attendanceCode, captchaGoogle).then(
      (res) => {
        setAttendance(res.data)
        setErrorMessage(undefined)
      }
    ).catch(err => {
      setErrorMessage(err?.response?.data?.message ?? 'Erro ao realizar consulta')
    })
  }
  
  return (
    <div style={{display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center'}}>
      <PublicHeaderComponent clientName={clientName} />
      <div style={{ width: '75%', paddingTop: '15px' }}>
        <Card type={'inner'} title={<h2>Consulta Pública de Atendimento</h2>}>
          <Form initialValues={{attendanceCode}} onFinish={onFinish} layout={'vertical'} form={form}>
            <Form.Item name={'attendanceCode'} label={'Código do Atendimento'} required>
              <Input />
            </Form.Item>
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={envHook.getRecaptchaKey()}
            />
            <br/>
            <Form.Item>
              <Button
                htmlType={'submit'}
                icon={<SearchOutlined />}
                shape={'round'}
                type={'primary'}
              >
                Pesquisar
              </Button>
            </Form.Item>
            {
              errorMessage && (
                <Alert message={errorMessage} type={'error'} />
              )
            }
          </Form>
        </Card>
        <br/>
        {
          attendance && (
            <PublicAttendanceComponent attendance={attendance} />
          )
        }
      </div>
    </div>
  )
}
