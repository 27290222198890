import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {Modal, Table, Tag} from "antd";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {useCallback, useEffect, useState} from "react";
import {WorkflowEntity} from "../../../../../domain/entity/workflow.entity";
import {useWorkflowRepository} from "../../../../../domain/repository/workflow.repository";
import {ShowWorkflowComponent} from "../component/show-workflow.component";
import {WorkflowTypeComponent} from "../component/workflow-type.component";

export function ListWorkflowPage(props: ModulePageProps) {
  const [workflows, setWorkflows] = useState<WorkflowEntity[]>([]);
  const [showWorkflow, setShowWorkflow] = useState<WorkflowEntity>();
  const workflowRepo = useWorkflowRepository();
  
  function loadData() {
    workflowRepo.list().then(res => setWorkflows(res.data))
  }
  
  function onEdit(id: string) {
    return props.navigator(`edit/${id}`)
  }
  
  function onRemove(id: string) {
    workflowRepo.remove(id).then(() => loadData())
  }
  
  useEffect(() => {
    loadData()
  }, []);
  
  return (
    <>
      <JbPageHeaderComponent
        title={'Workflows'}
        description={'Cria Fluxos de Atendimento'}
        onCreateLink={'workflow/create'}
      />
      <Table
        columns={[
          {
            dataIndex: 'name',
            title: 'Nome'
          },
          {
            dataIndex: 'type',
            title: 'Tipo Workflow',
            render: type => <WorkflowTypeComponent type={type} />
          },
          {
            dataIndex: 'id',
            render: (id, row) => (
              <JbTableRowActionsComponent
                onEdit={() => onEdit(id)}
                onRemove={() => onRemove(id)}
                onShow={() => setShowWorkflow(row)}
              />
            )
          }
        ]}
        dataSource={workflows}
      />
      
      {
        !!showWorkflow && (
          <Modal
            title={showWorkflow.name}
            open
            width={800}
            footer={null}
            onCancel={() => setShowWorkflow(undefined)}
          >
            <ShowWorkflowComponent workflowId={showWorkflow.id} />
          </Modal>
        )
      }
    </>
  )
}