import {useJbHttpCrudService} from "../../../../../../core/service/jb-http-crud.service";
import {ProcessTypeEntity} from "../entity/process-type.entity";
import {BaseResponse} from "../../../../../../core/common/base-response.common";
import {ProcessSubtypeEntity} from "../entity/process-subtype.entity";

export function useProcessTypeRepository() {
  const crud = useJbHttpCrudService<ProcessTypeEntity>('process-type')
  function toggleSubtype(
    processTypeId: string,
    description: string
  ): Promise<BaseResponse<ProcessSubtypeEntity>> {
    return crud.put(
      `${crud.resourceName}/${processTypeId}/toggle-subtype`,
      { description }
    )
  }
  return {
    ...crud,
    toggleSubtype
  }
}
