import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useEffect, useMemo, useState} from "react";
import {PartTypeEntity} from "../domain/entity/part-type.entity";
import {usePartTypeRepository} from "../domain/repository/part-type.repository";
import {ColumnsType} from "antd/es/table";
import {ListActionsComponent} from "../../../core/component/list-actions.component";
import {Table} from "antd";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";

export function ListPartTypePage(props: ModulePageProps) {
  const [partTypes, setPartTypes] = useState<PartTypeEntity[]>([])
  const partTypeRepo = usePartTypeRepository()

  function load() {
    partTypeRepo.list().then((res) => setPartTypes(res.data))
  }
  
  useEffect(() => {
    load()
  }, [])
  
  function remove(id:string) {
    partTypeRepo.remove(id).then(() => load())
  }

  const columns = useMemo<ColumnsType<any>>(() => ([
    {
      key: 1,
      title: 'Nome',
      dataIndex: 'name'
    },
    {
      key: 99,
      title: '',
      align: 'right',
      dataIndex: 'id',
      render: id => (
        <JbTableRowActionsComponent
          onEdit={() => props.navigator(`edit/${id}`)}
          onRemove={() => remove(id)}
        />
      )
    }
  ]), [])

  return (
    <>
      <JbPageHeaderComponent
        title={'Parametrizações / Tipo Parte'}
        description={'Gestão dos Tipos de Parte'}
        onCreateLink={`${props.prefix}/create`}
      />
      <Table
        loading={partTypeRepo.loading}
        dataSource={partTypes}
        columns={columns}
      />
    </>
  )
}
