import {AttendanceAttachment} from "../domain/entity/attendance.entity";
import {Button, List, message, Switch} from "antd";
import {useAttendanceRepository} from "../domain/repository/attendance.repository";
import {DeleteOutlined, DownloadOutlined} from "@ant-design/icons";
import {useState} from "react";

export function ListAttendanceAttachmentComponent(props: {
  files: AttendanceAttachment[],
  attendanceId: string,
  onRemoved: () => any,
  onToggle: () => any,
}) {
  
  
  return (
    <List
      dataSource={props.files}
      renderItem={file => (
        <AttachmentListItem
          file={file}
          attendanceId={props.attendanceId}
          onRemoved={props.onRemoved}
          onToggle={props.onToggle}
          key={file.id}
        />
      )}
    />
  )
}

function AttachmentListItem(props: {
  file: AttendanceAttachment,
  attendanceId: string ,
  onRemoved: () => any,
  onToggle: () => any,
}) {
  
  const [loading, setLoading] = useState(false);
  const attendanceRepo = useAttendanceRepository();
  const { file , attendanceId} = props;
  function removeAttachment(attachId: string) {
    return attendanceRepo.removeAttachment(
      props.attendanceId,
      attachId
    ).then(res => {
      props.onRemoved();
    })
  }
  
  function toggleAttendance(attachId: string) {
    return attendanceRepo.toggleAttachment(
      props.attendanceId,
      attachId
    ).then(res => {
      props.onToggle();
    })
    
  }
  
  function downloadItem(file: AttendanceAttachment) {
    setLoading(true);
    return attendanceRepo.downloadAttachment(
      file.path,
      file.originalName,
      true,
      file.mimeType
    ).finally(() => setLoading(false))
  }
  return (
    <List.Item
      key={file.id}
      extra={[
        <Switch
          unCheckedChildren={'Documento Privado'}
          checkedChildren={'Documento Público'}
          checked={file.isPublic}
          
          onChange={() => toggleAttendance(file.id)}
        />,
        <Button
          type={'text'}
          icon={<DownloadOutlined />}
          loading={loading}
          onClick={() => downloadItem(file)}
        >
          Download
        </Button>,
        <Button
          type={'text'}
          icon={<DeleteOutlined />}
          onClick={() => removeAttachment(file.id)}
        >
          Remover
        </Button>,
      ]}
    >
      <List.Item.Meta
        title={file.originalName}
        description={file.mimeType}
      />
    </List.Item>
  )
}