import {DatePicker} from "antd";
import {useCallback} from "react";


const locale = {
  lang: {
    locale: 'pt_BR',
    "dateFormat": "DD/MM/YYYY",
    "dateTimeFormat": "M/D/YYYY HH:mm:ss",
    "shortMonths": [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ]
  }
}
export function JbDatePickerComponent(props: React.ComponentProps<typeof DatePicker>) {
  const getFormat = useCallback((picker?: string) => {
    switch (picker) {
      case 'month':
        return 'MM/YYYY';
      case 'year':
        return 'YYYY';
      default:
        return 'DD/MM/YYYY';
        
    }
  }, [])
  
  return (
    <DatePicker
      format={getFormat(props?.picker)}
      // @ts-ignore
      locale={locale}
      style={{width: '100%'}}
      picker={'date'}
      {...props}
    />
  )
}