import {useEffect, useMemo, useState} from "react";
import {ClientEntity} from "../../../client/domain/entity/client.entity";
import {Col, Form, Radio, Row, Select} from "antd";
import {useClientRepository} from "../../../client/domain/repository/client.repository";
import moment from "moment/moment";
import {JbDatePickerComponent} from "../../../../core/form/jb-date-picker.component";
import TextArea from "antd/es/input/TextArea";
import {JbFormActionButtonComponent} from "../../../../core/component/jb-form-action-button.component";
import {useSupplierRepository} from "../../../../../../domain/repository/supplier.repository";
import {SupplierEntity} from "../../../../../../domain/entity/supplier.entity";

export function FormEntryStep(props: {
  initialValues?: any,
  onSubmit: (values: any) => any,
  disabledFields?: string[],
}) {
  const [suppliers, setSuppliers] = useState<SupplierEntity[]>([]);
  const [clients, setClients] = useState<ClientEntity[]>([]);
  const [formType, setFormType] = useState<"D" | 'R'>('R')
  const [form] = Form.useForm();
  const clientRepo = useClientRepository();
  const supplierRepo = useSupplierRepository();
  
  const now = useMemo(() => moment(), [])
  
  useEffect(() => {
    form.setFieldsValue({
      type: formType,
    })
    if (props.initialValues) {
      form.setFieldsValue({
        ...props.initialValues,
        categoryId: props.initialValues.category?.id,
        clientId: props.initialValues.client?.id,
        dueDate: props.initialValues.dueDate ? moment(props.initialValues.dueDate) : null,
      })
    }
  }, [props.initialValues]);
  
  useEffect(() => {
    supplierRepo.list().then(res => setSuppliers(res.data));
    clientRepo.list().then(res => setClients(res.data));
  }, []);
  
  const supplierOpts = useMemo(() => {
    return suppliers.map(w => ({
      value: w.id,
      label: w.name,
    }))
  }, [suppliers])
  
  const clientOpts = useMemo(() => {
    if (clients?.length) {
      return clients.map(item => ({
        label: item.name,
        value: item.id
      }))
    }
    return [];
  }, [clients])
  
  function onSubmit(values: any) {
    return props.onSubmit({
      ...values,
      tags: values.tags ?? [],
    })
  }
  
  
  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={onSubmit}
    >
      <Row gutter={12}>
        <Col span={6}>
          <Form.Item name={'type'} label={'Tipo Lançamento'} rules={[{ required: true }]}>
            <Radio.Group
              disabled={props.disabledFields?.includes('type')}
              onChange={evt => setFormType(evt.target.value)}
              defaultValue={'R'}
              options={[
                { label: 'Receita', value: 'R' },
                { label: 'Despesa', value: 'D' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={9}>
          {
            formType === 'D' ? (
              <Form.Item name={'supplierId'} label={'Fornecedor'} rules={[{ required: true }]}>
                <Select
                  disabled={props.disabledFields?.includes('supplierId')}
                  options={supplierOpts}
                />
              </Form.Item>
            ) : (
              <Form.Item name={'clientId'} label={'Cliente'} rules={[{ required: true }]}>
                <Select
                  disabled={props.disabledFields?.includes('clientId')}
                  options={clientOpts}
                />
              </Form.Item>
            )
          }
        </Col>
        
        <Col span={9}>
          <Form.Item name={'dueDate'} label={'Data de Vencimento'} rules={[{ required: true }]}>
            <JbDatePickerComponent disabledDate={dt => dt.isBefore(now)}  />
          </Form.Item>
        </Col>
      </Row>
      
      <Form.Item name={'observation'} label={'Obervações'}>
        <TextArea rows={4} style={{width: '100%'}} />
      </Form.Item>
      
      <JbFormActionButtonComponent
        labelSave={'Continuar'}
      />
    </Form>
  )
}

