import React from 'react';
import './App.less'
import './App.css'
import {AdminContext} from "./context/admin";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {AuthContext} from "./context/auth";
import {useJbHttpAuthService} from "./core/service/jb-http-auth.service";
import {ConfigProvider} from "antd";
import ptBr from "antd/lib/locale/pt_BR"
import {PublicSearch} from "./context/public-search";
import {PublicPreAttendance} from "./context/public-pre-attendance";

const App = () => {
  const authService = useJbHttpAuthService()

  function renderAdminContext(prefix: string) {
    if (authService.isAuth()) {
      return <AdminContext prefix={prefix} />
    }
    return <Redirect to={'/auth'} />
  }



  return (
    <ConfigProvider locale={ptBr} >
      <BrowserRouter>
        <Switch>
          <Route path={'/auth'} >
            <AuthContext />
          </Route>
          <Route path={'/admin'} render={() => renderAdminContext('admin')} />
          <Route path={'/public-search/:attendanceCode'}>
            <PublicSearch />
          </Route>
          <Route path={'/public-search'}>
            <PublicSearch />
          </Route>
          <Route path={'/public-pre-attendance/:tenantId'}>
            <PublicPreAttendance />
          </Route>
          <Route path={''}>
            <Redirect to={'/admin'} />
          </Route>
        </Switch>
      </BrowserRouter>
    </ConfigProvider>
  )
};

export default App;
