import {Col, Form, Modal, Radio, Row, Select, Space} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {useEffect, useMemo, useState} from "react";
import {ClientEntity} from "../../client/domain/entity/client.entity";
import {useClientRepository} from "../../client/domain/repository/client.repository";
import {JbSearchableSelectComponent} from "../../../core/component/jb-searchable-select.component";
import {ClientForm} from "../../client/component/client.form";
import {AttendanceTypeEntity} from "../../../../../domain/entity/attendance-type.entity";
import {useAttendanceTypeRepository} from "../../../../../domain/repository/attendance-type.repository";
import {JbRichTextComponentField} from "../../../core/form/jb-rich-text.component";
import {AttendanceOriginEnum} from "../../../../../domain/vo/attendance-origin.enum";
import {useWorkflowRepository} from "../../../../../domain/repository/workflow.repository";
import {WorkflowEntity} from "../../../../../domain/entity/workflow.entity";

export type AttendanceFormComponentProps = {
  onSubmit: (values: any) => any,
  values?: any,
  disabledFields?: string[]
}

export function AttendanceFormComponent(props: AttendanceFormComponentProps) {
  const [showModalClient, setShowModalClient] = useState(false)
  const [clients, setClients] = useState<ClientEntity[]>([])
  const [types, setTypes] = useState<AttendanceTypeEntity[]>([]);
  const [workflows, setWorkflows] = useState<WorkflowEntity[]>([]);
  const [form] = Form.useForm()
  const clientRepo = useClientRepository()
  const typeRepo = useAttendanceTypeRepository();
  const workflowRepo = useWorkflowRepository();

  function loadClients() {
    return clientRepo.list().then(res => setClients(res.data))
  }

  useEffect(() => {
    if (props.values) {
      let values = { ...props.values }
      if (props.values.client) {
        values = {
          ...values,
          clientId: props.values.client.id,
          attendanceTypeId: props.values?.attendanceType?.id,
          workflowId: props.values?.workflow?.id,
        }
      }
      form.setFieldsValue(values)
    } else {
      form.setFieldsValue({ priority: 4 })
    }
    typeRepo.list().then(res => setTypes(res.data))
    loadClients()
  },[props])
  
  useEffect(() => {
    workflowRepo.list().then(res => setWorkflows(res.data));
  }, []);


  function getClientOpts() {
    const clientOpts = clients.map(w => ({
      label: w.name,
      value: w.id
    }))
    clientOpts.push({
      label: '(Cadastrar novo cliente)',
      value: 'new'
    })
    return clientOpts
  }

  function onClientChange(opt: any) {
    if (opt === 'new') {
      setShowModalClient(true)
    }
  }

  function onCancelInsertClient() {
    setShowModalClient(false)
    form.setFieldsValue({ clientId: null })
  }

  function onInsertClient(values: any) {
    clientRepo.create(values).then(res => {
      setShowModalClient(false)
      form.setFieldsValue({ clientId: null })
      loadClients().then(() => {
        form.setFieldsValue({ clientId: res.data.id })
      })
    })
  }

  function disabled(key: string): boolean {
    if (!props.disabledFields) {
      return false
    }
    return props.disabledFields.includes(key)
  }
  
  const workflowOpts = useMemo(() => {
    return workflows.map(w => ({
      label: w.name,
      value: w.id,
    }))
  }, [workflows])
  
  const typeOpts = useMemo(() => {
    return types.map(w => ({
      label: w.name,
      value: w.id,
    }))
  }, [types])

  return (
    <Form form={form} layout={'vertical'} onFinish={props.onSubmit}>
      <Row gutter={12}>
        <Col span={12}>
          <JbSearchableSelectComponent
            name={'clientId'}
            label={'Cliente'}
            options={getClientOpts()}
            onSelect={(opt) => onClientChange(opt)}
            required
            disabled={disabled('clientId')}
          />
        </Col>
        <Col span={6}>
          <Form.Item name={'attendanceTypeId'} label={'Tipo Atendimento'}>
            <Select options={typeOpts} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={'origin'} label={'Origem Atendimento'} initialValue={'physical'}>
            <Select options={AttendanceOriginEnum.getOptions()} />
          </Form.Item>
        </Col>
      </Row>
      <JbRichTextComponentField
        name={'report'}
        label={'Relato do Cliente'}
        rules={[{ required: true }]}
      />
      <Row gutter={22}>
        <Col span={8}>
          <Form.Item name={'workflowId'} label={'Associar Workflow'}>
            <Select disabled={disabled('workflowId')} options={workflowOpts} />
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item name={'priority'} label={'Prioridade: '}>
            <Radio.Group defaultValue={4}>
              <Space direction="horizontal">
                <Radio value={1}>Prioridade Máxima</Radio>
                <Radio value={2}>Prioridade Média</Radio>
                <Radio value={3}>Prioridade Mínima</Radio>
                <Radio value={4}>Sem Prioridade</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <JbFormActionButtonComponent />
      {
        showModalClient && (
         <Modal
           title={'Novo Cliente'}
           footer={null}
           open={true}
           width={800}
           onCancel={() => onCancelInsertClient()}
         >
           <ClientForm onSubmit={onInsertClient} />
         </Modal>
        )
      }
    </Form>
  )
}
