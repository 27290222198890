import {useCallback, useEffect, useState} from "react";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {AttendanceAttachment} from "../../domain/entity/attendance.entity";
import {ListAttendanceAttachmentComponent} from "../list-attendance-attachment.component";
import {AttendanceAttachmentForm} from "../attendance-attachment.form";
import {AttendanceStepEntity} from "../../domain/entity/attendance-step.entity";
import {AttendanceStepAttachments} from "../attendance-step-attachments";
import {Button, Collapse, Typography} from "antd";
import {LinkOutlined} from "@ant-design/icons";

export function AttendanceAttachmentsTabComponent(props: {
  attendanceId: string
}) {
  const [attachments, setAttachments] = useState<AttendanceAttachment[]>([]);  
  const [steps, setSteps] = useState<AttendanceStepEntity[]>([]);
  const attendanceRepo = useAttendanceRepository();
  
  const listAttachs = useCallback(() =>  {
    attendanceRepo
      .listAttachments(props.attendanceId)
      .then(res => setAttachments(res.data))
  }, [])
  
  const listSteps = useCallback(() => {
    attendanceRepo.listSteps(props.attendanceId).then(res => setSteps(res.data));
  }, [])
  
  
  useEffect(() => {
    listAttachs()
    listSteps()
  }, [listAttachs, listSteps, props.attendanceId])
  
  return (
    <>
      <AttendanceAttachmentForm
         onSaved={listAttachs}
         attendanceId={props.attendanceId}
      />
      <ListAttendanceAttachmentComponent
        attendanceId={props.attendanceId}
        files={attachments ?? []}
        onRemoved={listAttachs}
        onToggle={listAttachs}
      />
      <Typography.Title level={5}>
        Anexos em Movimentações
      </Typography.Title>
      <Collapse >
        {
          steps.map(w => (
            <Collapse.Panel
              key={w.id}
              header={`${w.stepType.description} (${w.attachments?.length})`}
            >
              <AttendanceStepAttachments attendanceStep={w} onAttachmentRemoved={listSteps} />
            </Collapse.Panel>
          
          ))
        }
      </Collapse>
    </>
  )
}