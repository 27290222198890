import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useEffect, useMemo, useState} from "react";
import {ColumnsType} from "antd/es/table";
import {Table} from "antd";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {AttendanceTypeEntity} from "../../../../../domain/entity/attendance-type.entity";
import {useAttendanceTypeRepository} from "../../../../../domain/repository/attendance-type.repository";

export function ListAttendanceTypePage(props: ModulePageProps) {
  const [stepTypes, setStepTypes] = useState<AttendanceTypeEntity[]>([])
  const attendanceTypeRepo = useAttendanceTypeRepository();

  function loadData() {
    attendanceTypeRepo.list().then(res => setStepTypes(res.data))
  }
  
  function remove(id: string) {
    return attendanceTypeRepo.remove(id).then(() => loadData())
  }
  
  useEffect(() => {
    loadData()
  }, [])
  

  const columns = useMemo<ColumnsType<any>>(() => [
    {
      key: 'name',
      title: 'Nome',
      dataIndex: 'name'
    },
    {
      key: 'description',
      title: 'Descrição',
      dataIndex: 'description'
    },
    {
      key: 4,
      title: '',
      dataIndex: 'id',
      align: 'right',
      render: id => (
        <JbTableRowActionsComponent
          onEdit={() => props.navigator(`edit/${id}`) }
          onRemove={() => remove(id)}
        />
      )
    }
  ], [])

  return (
    <>
      <JbPageHeaderComponent
        title={'Parametrizações / Tipo Atendimento'}
        description={'Parametrização dos tipos de atendimento'}
        onCreateLink={`${props.prefix}/create`}
      />
      <Table
        loading={attendanceTypeRepo.loading}
        columns={columns}
        dataSource={stepTypes}
      />
    </>
  )
}
