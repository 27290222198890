import moment, {Moment} from "moment";
import React, {useMemo} from "react";
import {CheckOutlined, FolderOpenOutlined} from "@ant-design/icons";
import {Menu, Popover, Tag} from "antd";
import {AttendanceStepEntity} from "../../../attendance/domain/entity/attendance-step.entity";

export function BusinessCell(props: {
  value: Moment,
  attendanceSteps: AttendanceStepEntity[],
  onClick: () => any,
}) {
  
  const beginning = useMemo(() => {
    return props.attendanceSteps.filter(
      w => moment(w.beginDate).diff(props.value,'day') === 0
      && !w.finished
    ).length
  }, [props])
  
  const expiring = useMemo(() => {
    return props.attendanceSteps.filter(
      w => moment(w.endDate).diff(props.value,'day') === 0
      && !w.finished
    ).length
  }, [props])
  
  const finished = useMemo(() => props.attendanceSteps.filter(w => w.finished).length, [props])
  
  if (!beginning && !expiring  && !finished) {
    return null
  }
  return (
    <div onClick={props.onClick} style={{height: '100%', width: '100%'}}>
      {
        expiring ? (
          <Tag icon={<FolderOpenOutlined />} color={'warning'} children={`${expiring} - Vencendo`} />
        ) : null
      }
      {
        beginning ? (
          <Tag icon={<FolderOpenOutlined />} color={'processing'} children={`${beginning} - Começando`} />
        ) : null
      }
      {
        finished ? (
          <Tag icon={<CheckOutlined />} color={'success'} children={`${finished} - Finalizadas`} />
        ) : null
      }
    </div>
  )
}


export function CustomCell(props: {
  value: Moment,
  attendanceSteps: AttendanceStepEntity[],
  onShowSteps: () => any,
  onAddStep: () => any,
  onClick: () => any,
}) {
  return (
    <BusinessCell {...props} />
  )
}