import React from "react";
import {CrudProcessObjectAttachedComponent} from "../process-object-attached/crud-process-object-attached.component";
import {Empty} from "antd";

export function ProcessObjectAttachedTabComponent(props: {
  processId?: string
}) {
  
  if (!props.processId) {
    return (
      <Empty description={'Para cadastrar objetos de processo primeiro cadastre o processo no atendimento'} />
    )
  }
  return (
    <CrudProcessObjectAttachedComponent processId={props.processId} />
  )
}