import {useEffect, useState} from "react";
import env from '../../env.json';

export function useEnvHook() {
  const [envObject, setEnvObject] = useState<any>({});
  
  useEffect(() => {
    const _env = process.env.NODE_ENV === 'development' ? 'dev' : 'prod';
    if (!env) throw new Error('*** No env file found ***');
    if (!env[_env]) throw new Error('*** No env content found to the environment ' + _env + '***');
    setEnvObject(env[_env]);
  }, [])
  
  function getJbBaseUr() {
    return envObject['jb-base-url'];
  }
  
  function getRecaptchaKey() {
    return envObject['recaptcha-key'];
  }
  
  return {
    getJbBaseUr,
    getRecaptchaKey,
    jbBaseUrl: getJbBaseUr(),
  }
}