import {Calendar} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import {CustomHeader} from "./custom-header";
import moment, {Moment} from "moment";
import {CustomCell} from "./custom-cell";
import {AttendanceStepEntity} from "../../../attendance/domain/entity/attendance-step.entity";

export function BusinessCalendarComponent(props: {
  attendanceSteps: AttendanceStepEntity[],
  onDateChange: (value: Moment) => any,
  onOwnerFilter: (allAttendances: boolean) => any,
  onSelect: (value: Moment) => any,
}) {
  const [dateSelected, setDateSelected] = useState<Moment>(moment.utc());
  
  const mapSteps = useMemo(() => {
    const map = new Map<string, AttendanceStepEntity[]>();
    for (const item of props.attendanceSteps) {
      const keyBegin = mountKey(moment(item.beginDate))
      const keyEnd = mountKey(moment(item.endDate))
      map.set(keyBegin, [...(map.get(keyBegin) ?? []), item])
      map.set(keyEnd, [...(map.get(keyEnd) ?? []), item])
    }
    return map;
  }, [props.attendanceSteps])
  
  function mountKey(value: Moment) {
    return value.toISOString().substring(0, 10);
  }
  
  useEffect(() => {
    props.onDateChange(dateSelected)
  }, [dateSelected]);
  
  return (
    <Calendar
      onChange={setDateSelected}
      defaultValue={dateSelected}
      dateCellRender={value => (
        <CustomCell
          onShowSteps={() => null}
          onAddStep={() => null}
          attendanceSteps={mapSteps.get(mountKey(value)) ?? []}
          value={value}
          onClick={() => props.onSelect(value)}
        />
      )}
      headerRender={(_props) => <CustomHeader onOwnerFilter={props.onOwnerFilter} {..._props} />}
    />
  )
}

