import {Tag} from "antd";
import {useMemo} from "react";

export function WorkflowTypeComponent(props: { type: string }) {
  const label = useMemo(() => {
    switch (props.type) {
      case 'template': return 'Template';
      case 'automation': return 'Automaçãp';
      default: return props.type;
    }
  }, [props.type])
  
  const color = useMemo(() => {
    switch (props.type) {
      case 'template': return 'cyan';
      case 'automation': return 'purple';
      default: return props.type;
    }
  }, [props.type])
  
  return (
    <Tag color={color}>{label}</Tag>
  )
}