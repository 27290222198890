import {ModulePageProps} from "../../../core/module-pages";
import {ClientForm} from "../component/client.form";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React from "react";
import {useClientRepository} from "../domain/repository/client.repository";
import {message} from "antd";

export function CreateClientPage(props: ModulePageProps) {
  const clientRepo = useClientRepository();

  function createClient(values: any) {
    return clientRepo.create(values).then(res => {
      props.navigator('list')
    })
  }
  
  return (
    <>
      <JbPageHeaderComponent
        title={`Atendimentos / Cliente / Novo Cliente +`}
        description={'Gestão dos clientes'}
      />
      <ClientForm
        onSubmit={createClient}
      />
    </>

  )
}
