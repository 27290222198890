import {Button, Col, Form, Input, Radio, Row, Select} from "antd";
import {AttendanceStatusEnum} from "../domain/entity/attendance.entity";
import {useEffect, useMemo, useState} from "react";
import {ClientEntity} from "../../client/domain/entity/client.entity";
import {useClientRepository} from "../../client/domain/repository/client.repository";
import {JbSearchableSelectComponent} from "../../../core/component/jb-searchable-select.component";

export function FilterListAttendanceComponent(props: {
  onFilter: (values: any) => any
}) {
  const [clients, setClients] = useState<ClientEntity[]>([]);
  const [form] = Form.useForm();
  
  const clientRepo = useClientRepository();
  
  useEffect(() => {
    clientRepo.list().then(res => setClients(res.data));
  }, [])
  
  const clientOpts = useMemo(() => {
    return clients.map(w => ({
      label: w.name, value: w.id,
    }))
  }, [clients])
  
  return (
    <Form form={form} onFinish={values => props.onFilter(values)}>
      <Row gutter={12} justify={'end'}>
        <Col>
          <Form.Item name={'status'} label={'Filtrar Status'}>
            <Radio.Group
              options={[
                { label: 'Todos', value: '' },
                { label: 'Aberto', value: AttendanceStatusEnum.OPENED },
                { label: 'Suspenso', value: AttendanceStatusEnum.STOPPED },
                { label: 'Concluído', value: AttendanceStatusEnum.CONCLUDED },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={'attendanceCode'} label={'Código Atendimento'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <JbSearchableSelectComponent
            options={clientOpts}
            label={'Cliente'}
            name={'clientId'}
          />
        </Col>
        <Col>
          <Button htmlType={'submit'}>Filtrar</Button>
        </Col>
      </Row>
    </Form>
  )
}