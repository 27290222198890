import {AttendanceEntity} from "../../attendance/domain/entity/attendance.entity";
import {useMemo} from "react";
import {ColumnsType} from "antd/es/table";
import {AttendanceStatusComponent} from "../../attendance/component/attendance-status.component";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {Table} from "antd";
import {AttendancePriorityTagComponent} from "../../attendance/component/attendance-priority-tag.component";

export function ListAttendanceComponent(props: {
  attendances: AttendanceEntity[],
  onSelected: (attendance: AttendanceEntity) => any
}) {
  
  const columns = useMemo<ColumnsType<any>>(() => {
    return [
      {
        key: '1',
        title: 'Cod. Atendimento',
        dataIndex: 'code'
      },
      {
        key: '2',
        title: 'Cliente',
        dataIndex: 'client',
        render: client => client.name,
      },
      {
        key: 'priority',
        title: 'Prioridade',
        dataIndex: 'priority',
        render: (_,row) => <AttendancePriorityTagComponent attendance={row} />
      },
      {
        key: 3,
        title: 'Status',
        dataIndex: 'status',
        render: status => <AttendanceStatusComponent status={status} />
      },
      {
        key: 4,
        title: 'actions',
        dataIndex: 'id',
        render: (id, row) => (
          <JbTableRowActionsComponent
            onShow={() => props.onSelected(row)}
          />
        )
      }
    ]
  }, [])
  
  return (
    <>
      <Table
        columns={columns}
        dataSource={props.attendances}
      />
    </>
  )
}