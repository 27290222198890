import {useMemo, useState} from "react";
import {ColumnsType} from "antd/es/table";
import {Button, Modal, Table, Tag} from "antd";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {AttendanceStepEntity} from "../../attendance/domain/entity/attendance-step.entity";
import {useDatetimeUtil} from "../../../../../core/util/datetime.util";
import moment, {now} from "moment";
import {FullAttendanceComponent} from "../../attendance/component/full-attendance";
import {LinkOutlined} from "@ant-design/icons";

export function ListAttendanceStepsComponent(props: {
  attendanceSteps: AttendanceStepEntity[],
  onSelect: (step: AttendanceStepEntity) => any
}) {
  const {formDateInPtBr} = useDatetimeUtil()
  const [showAttendance, setShowAttendance] = useState<string>();
  const columns = useMemo<ColumnsType<AttendanceStepEntity>>(() => {
    return [
      {
        title: 'Atendimento',
        dataIndex: 'attendanceId',
        key: 'attendanceId',
        render: (_, row) => renderAttendance(row.attendanceId, row.attendanceCode ?? '')
      },
      {
        key: 'stepType',
        dataIndex: 'stepType',
        title: 'Movimentação',
        render: item => item.description,
      },
      {
        key: 'startDate',
        dataIndex: 'beginDate',
        title: 'Data de Início',
        render: formDateInPtBr
      },
      {
        key: 'endDate',
        dataIndex: 'endDate',
        title: 'Data de Encerramento',
        render: renderDateColumn
      },
      {
        key: 'actions',
        dataIndex: 'id',
        render: (_,row) => (
          <JbTableRowActionsComponent
            onShow={() => props.onSelect(row)}
          />
        )
      }
    ]
  }, [])
  
  function renderAttendance(attendanceId: string, code: string) {
    return (
      <Button
        onClick={() => setShowAttendance(attendanceId)}
        type={'default'}
        shape={'round'}
        size={'middle'}
        icon={<LinkOutlined />}
      >
        {code}
      </Button>
    )
  }
  
  function renderDateColumn(dt: string) {
    const dateTime = moment(dt);
    const daysDiff = dateTime.diff(now(), 'd');
    if (daysDiff < 0) {
      return <Tag color={'red'}>Venceu em {formDateInPtBr(dt)} - {Math.abs(daysDiff)} dias</Tag>
    }
    if (daysDiff < 5) {
      return  <Tag color={'warning'}> Vence em {formDateInPtBr(dt)} - {daysDiff} dias </Tag>
    }
    return  <Tag color={'processing'}> Vence em {formDateInPtBr(dt)} - {daysDiff} dias </Tag>
  }
  
  return (
    <>
      <Table
        columns={columns}
        dataSource={props.attendanceSteps}
      />
  
      {
        showAttendance && (
          <Modal
            footer={null}
            onCancel={() => setShowAttendance(undefined)}
            width={'85vw'}
            open
          >
            <FullAttendanceComponent attendanceId={showAttendance} />
          </Modal>
        )
      }
    </>
  )
}