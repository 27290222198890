import {List} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {AttendanceStepAttachmentType, AttendanceStepEntity} from "../../domain/entity/attendance-step.entity";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {useAttendanceStepRepository} from "../../domain/repository/attendance-step.repository";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";
import {useMemo} from "react";

export function AttendanceStepAttachments(props: {
  attendanceStep: AttendanceStepEntity,
  onAttachmentRemoved: () => any
}) {
  const attendanceStepRepo = useAttendanceStepRepository();
  
  function removeAttachment(attachment: AttendanceStepAttachmentType) {
    attendanceStepRepo.removeAttachment(
      props.attendanceStep.id,
      attachment.id
    ).then(() => {
      props.onAttachmentRemoved()
    })
  }
  
  return (
    <List
      dataSource={props.attendanceStep?.attachments ?? []}
      renderItem={item => (
        <AttachmentItem
          attachment={item}
          stepId={props.attendanceStep.id}
          onRemoved={() => removeAttachment(item)}
        />
      )}
    />
  )
}

function AttachmentItem(props: {
  attachment: AttendanceStepAttachmentType,
  stepId: string,
  onRemoved: () => any,
}) {
  const dateTimeUtil = useDatetimeUtil();
  const attendanceRepo = useAttendanceRepository();
  function downloadMedia() {
    return attendanceRepo.downloadAttachment(
      props.attachment.path,
      props.attachment.originalName,
      true,
      props.attachment.mimeType,
    )
  }
  
  
  const formattedDate = useMemo(() => {
    return dateTimeUtil.formDateTimeInPtBr(props.attachment.createdAt)
  }, [props])
  
  return (
    <List.Item
      extra={[
        <DeleteOutlined onClick={e => {
          e.stopPropagation()
          return props.onRemoved()
        }} />
      ]}
      onClick={downloadMedia}
      style={{cursor: 'pointer'}}
    >
      <List.Item.Meta
        title={props.attachment.originalName}
        description={`Salvo em ${formattedDate} por ${props.attachment.createdBy}`}
      />
    </List.Item>
  )
}