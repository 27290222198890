import {ModulePageProps} from "../../../core/module-pages";
import {StepTypeFormComponent} from "../component/step-type.form";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React from "react";
import {useStepTypeRepository} from "../../../../../domain/repository/step-type.repository";

export function CreateStepTypePage(props: ModulePageProps) {
  const steTypeRepo = useStepTypeRepository()

  function onCreate(values: any) {
    return steTypeRepo.create(values).then(res => {
      props.navigator(`edit/${res.data.id}`)
    })
  }

  return (
    <>
      <JbPageHeaderComponent
        title={'Parametrizações / Tipo Movimentação'}
        description={'Nova Movimentação'}
      />
      <StepTypeFormComponent onSubmit={onCreate} />
    </>
  )
}
