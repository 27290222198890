import {Modal} from "antd";
import {ChangePasswordFormComponent} from "./change-password.form.component";
import {useUserRepository} from "../../../../domain/repository/user.repository";

export function ChangePasswordFormModal(props: {
  open?: boolean,
  refreshUser: () => any,
  onClose: () => any,
})  {
  const userRepo = useUserRepository();
  
  function onChange(values: any) {
    const { currentPassword, newPassword } = values;
    userRepo.changeMyPassword(
      currentPassword,
      newPassword
    ).then(() => props.onClose())
  }
  
  return (
    <Modal
      title={'Alterar Senha de Acesso'}
      open={props.open}
      width={600}
      onCancel={() => props.onClose()}
      footer={null}
    >
      {
        props.open && (
          <ChangePasswordFormComponent
            onSubmit={onChange}
          />
        )
      }
    </Modal>
  )
}
