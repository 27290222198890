import {ModulePageProps} from "../../../core/module-pages";
import {AttendanceTypeFormComponent} from "../component/step-type.form";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAttendanceTypeRepository} from "../../../../../domain/repository/attendance-type.repository";
import {AttendanceTypeEntity} from "../../../../../domain/entity/attendance-type.entity";

export function EditAttendanceTypePage(props: ModulePageProps) {
  const [attendanceType, setAttendanceType] = useState<AttendanceTypeEntity>()
  const attendanceTypeRepo = useAttendanceTypeRepository()
  const { id: attendanceTypeId } = useParams<any>()

  useEffect(() => {
    attendanceTypeRepo.find(attendanceTypeId).then(res => setAttendanceType(res.data))
  }, [])

  function onEdit(values: any) {
    return attendanceTypeRepo.edit(attendanceTypeId, values).then(res => {
      props.navigator('list')
    })
  }

  return (
    <>
      <JbPageHeaderComponent
        title={`Parametrizações / Tipo Movimentação / ${attendanceType?.description}`}
        description={'Nova Movimentação'}
      />
      <AttendanceTypeFormComponent
        values={attendanceType}
        onSubmit={onEdit}
      />
    </>
  )
}
