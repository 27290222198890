import {ModulePages} from "../../core/module-pages";
import {CreateSupplierPage} from "./page/create.supplier.page";
import {EditSupplierPage} from "./page/edit.supplier.page";
import {ListSupplierPage} from "./page/list.supplier.page";

export function SupplierModule() {
  return (
    <ModulePages routes={[
      { path: 'create', render: (props) => <CreateSupplierPage {...props} />},
      { path: 'edit/:id', render: (props) => <EditSupplierPage {...props} />},
      { path: 'list', render: (props) => <ListSupplierPage {...props} />},
      { path: '', render: (props) => <ListSupplierPage {...props} />},
      ]}
    />
  )
}
