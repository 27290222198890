import {useForm} from "antd/es/form/Form";
import {useEffect} from "react";
import {Form, Input} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";

export function CategoryFormComponent(props: {
  currentValues?: any,
  onSubmit: (values: any) => any,
  onCancel: () => any,
}) {
  const [form] = useForm()
  
  useEffect(() => {
    if (props.currentValues) {
      form.setFieldsValue(props.currentValues);
    }
  }, [props.currentValues]);
  
  function onSubmit(values: any) {
    return props.onSubmit(values);
  }
  
  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={onSubmit}
    >
      <Form.Item name={'name'} label={'Categoria'} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={'description'} label={'Descrição'}>
        <Input />
      </Form.Item>
      <JbFormActionButtonComponent
        onCancel={props.onCancel}
      />
    </Form>
  )
}