import {Modal} from "antd";
import {useRouteMatch} from "react-router-dom";
import {FullAttendanceComponent} from "../../attendance/component/full-attendance";

export function ModalAttendanceComponent(props: { onClose: () => void }) {
  const routeMath = useRouteMatch<any>();
  const { id } = routeMath.params;
  return (
    <Modal
      open
      width={'90vw'}
      footer={null}
      onCancel={props.onClose}
    >
      <FullAttendanceComponent attendanceId={id} />
    </Modal>
  )
}