export function ActionButtonGrid(props: any) {
  return (
    <div style={{
      width: '100%',
      marginBottom: '20px',
      height: '50px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      zIndex: 10,
      gap: 15
    }}>
      {props.children}
    </div>
  )
}