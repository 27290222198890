import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import React, {useEffect, useState} from "react";
import {AttendanceStepEntity} from "../../domain/entity/attendance-step.entity";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";
import {Button, Col, List, Row} from "antd";
import {FormAttendanceStep} from "../form.attendance-step";
import {ArrowRightOutlined} from "@ant-design/icons";

export function AttendanceStepStep(props: {
  attendance: AttendanceEntity,
  onFinish: () => any
}) {
  const [steps, setSteps] = useState<AttendanceStepEntity[]>([])
  const attendanceRepo = useAttendanceRepository()
  const { formDateInPtBr } = useDatetimeUtil()

  function loadSteps(attendanceId: string) {
    attendanceRepo.listSteps(attendanceId).then(res => {
      setSteps(res.data)
    })
  }

  useEffect(() => {
    if (props.attendance) {
      loadSteps(props.attendance.id)
    }
  }, [props, props.attendance])

  function onFinish(values: any) {
    values.attendanceId = props.attendance.id
    return attendanceRepo.addStep(values).then(res => {
      props.onFinish()
    })
  }


  return (
    <>
      {
        !props.attendance?.workflow || props.attendance.workflowDisabled ? (
          <Row gutter={18}>
            <Col span={24}>
              <FormAttendanceStep
                onCancel={() => null}
                onSubmit={onFinish}
                startDate={props.attendance.createdAt}
                extraActions={[
                  <Button icon={<ArrowRightOutlined />} onClick={() => props.onFinish()}>
                    Continuar
                  </Button>
                ]}
              />
            </Col>
          </Row>
        ) : (
          <>
            <h2>{props.attendance?.workflow?.name} Vinculado</h2>
            <Row justify={'end'}>
              <Col span={2}>
                <Button icon={<ArrowRightOutlined />} onClick={() => props.onFinish()}>
                  Continuar
                </Button>
              </Col>
            </Row>
          </>
        )
      }
      <Row>
        <Col span={24}>
          <List
            header={'Movimentações Existentes'}
            dataSource={steps}
            pagination={{
              defaultPageSize: 5
            }}
            renderItem={(item: AttendanceStepEntity) => (
              <List.Item>
                <List.Item.Meta
                  title={item.stepType.description}
                  description={<div dangerouslySetInnerHTML={{ __html: item.description }} />}
                  avatar={formDateInPtBr(item.beginDate)}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  )
}
