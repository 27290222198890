import {Form, Input} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {useEffect} from "react";

export function FormProcessObjectComponent(props: {
  initialValues?: any,
  onSubmit: (values: any) => any,
}) {
  const [form] = Form.useForm();
  
  useEffect(() => {
    if (props.initialValues) {
      form.setFieldsValue({ description: props.initialValues?.description })
    }
  }, [props.initialValues])
  
  function onSubmit(values: any) {
    return props.onSubmit(values);
  }
  
  return (
    <Form form={form} layout={'vertical'} onFinish={onSubmit}>
      <Form.Item
        name={'description'}
        label={'Descrição'}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <JbFormActionButtonComponent />
    </Form>
  )
}