import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useEffect, useState} from "react";
import {ProcessObjectEntity} from "../../../../../domain/entity/process-object.entity";
import {useProcessObjectRepository} from "../../../../../domain/repository/process-object.repository";
import {ListProcessObjectComponent} from "../component/list.process-object.component";
import {BrowserRouter, Route, useHistory, useRouteMatch} from "react-router-dom";
import {Modal} from "antd";
import {FormProcessObjectComponent} from "../component/form.process-object.component";

export function ListProcessObject(props: ModulePageProps) {
  const [objects, setObjects] = useState<ProcessObjectEntity[]>([]);
  const repo = useProcessObjectRepository();
  const history = useHistory();
  
  useEffect(() => {
    listObjects()
  }, [])
  
  function listObjects() {
    return repo.list().then(res => setObjects(res.data))
  }
  function onEditObject(obj: ProcessObjectEntity) {
    return repo.edit(obj.id, obj).then(
      () => listObjects()
    )
  }
  function onRemoveObject(obj: ProcessObjectEntity) {
    return repo.remove(obj.id).then(
      () => listObjects()
    )
  }
  
  function onCreate(values: any) {
    return repo.create(values).then(() => {
      props.navigator(props.prefix)
    })
  }
  
  return (
    <>
      <JbPageHeaderComponent
        title={'Parametrizações / Objetos de Processo'}
        description={'Parametrização dos possíveis objetos de process'}
        onCreateLink={`${props.prefix}/create`}
      />
      
      <ListProcessObjectComponent
        dataSource={objects}
        onRemove={onRemoveObject}
        onEdit={onEditObject}
      />
      
      <BrowserRouter>
        <Route path={`${props.prefix}/create`} exact={false}>
          <Modal
            title={'Novo Objeto'}
            open
            footer={null}
            onCancel={() => history.goBack()}
          >
            <FormProcessObjectComponent onSubmit={onCreate} />
          </Modal>
        </Route>
      </BrowserRouter>
    </>
  )
}