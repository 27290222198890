import {AttendancePublicInteractionEntity} from "../../../../../../domain/entity/attendance-public-interaction.entity";
import {Button, Popover, Tag, Timeline} from "antd";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";
import {useMemo, useRef, useState} from "react";
import {FileOutlined, LoadingOutlined, WechatOutlined} from "@ant-design/icons";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";

export function AttendancePublicInteractionHistoryComponent(props: {
  interactions: AttendancePublicInteractionEntity[],
  onRemove: (interaction: AttendancePublicInteractionEntity) => any
  disableRemovePopover?: boolean,
}) {
  
  const sorted = useMemo(() => {
    return props.interactions.sort((a,b) => {
      if (new Date(a.createdAt) > new Date(b.createdAt)) return -1;
      if (new Date(a.createdAt) < new Date(b.createdAt)) return 1;
      return 0
    })
  }, [props.interactions])
  
  return (
    <Timeline>
      {
        sorted.map(w => (
          <RenderItem
            disableRemovePopover={props.disableRemovePopover}
            onRemove={props.onRemove}
            interaction={w}
            key={w.id}
          />
        ))
      }
    </Timeline>
  )
}


function RenderItem(props: {
  onRemove: (interaction: AttendancePublicInteractionEntity) => any,
  interaction: AttendancePublicInteractionEntity,
  disableRemovePopover?: boolean,
}) {
  const { formDateTimeInPtBr } = useDatetimeUtil();
  const ref = useRef<any>()
  const color = useMemo(() => {
    return props.interaction?.meta?.isPrivate ? undefined : 'gray'
  }, [props.interaction])
  
  const additionalInfo = useMemo(() => {
    if (props.disableRemovePopover) return '';
    if (props.interaction.meta?.isPrivate) return '';
    const info = [];
    if (props.interaction.meta?.tell) {
      info.push(props.interaction.meta.tell)
    }
    if (props.interaction.meta?.email) {
      info.push(props.interaction.meta.email)
    }
    if (info.length) return info.join(' | ');
    return '';
  }, [props.interaction])
  
  const content = useMemo(() => {
    switch (props.interaction.contentType) {
      case 'file': return (
        <FileItem
          fileName={props.interaction.content}
          path={props.interaction.meta?.fileDefinitions?.path}
          mimeType={props.interaction.meta?.fileDefinitions?.mimeType}
        />
      )
      default: return props.interaction.content;
    }
  }, [props.interaction])
  
  
  return (
    <Timeline.Item
      color={color}
      dot={
        props.disableRemovePopover ? (
            <WechatOutlined />
        ) : (
          <Popover
            ref={ref}
            trigger={'click'}
            title={'Remover Interação'}
            content={
              <Button
                type={'ghost'}
                block
                onClick={() => {
                  props.onRemove(props.interaction)
                  ref.current?.close()
                }}
              >
                Confirmar
              </Button>
            }>
            <WechatOutlined />
          </Popover>
        )
      }
    >
      <i>{formDateTimeInPtBr(props.interaction.createdAt)} {props.interaction.createdBy} {additionalInfo}: </i>
      <br />
      <Popover>
        {content}
      </Popover>
      
    </Timeline.Item>
  )
}


function FileItem(props: {
  fileName: string,
  path: string,
  mimeType: string,
}) {
  const [loading, setLoading] = useState(false)
  const attendanceRepo = useAttendanceRepository();
  
  
  function download() {
    setLoading(true)
    attendanceRepo.downloadAttachment(
      props.path,
      props.fileName,
      true,
      props.mimeType
    ).finally(() => setLoading(false))
  }
  return (
    <Tag
      icon={
        loading ?  <LoadingOutlined /> : <FileOutlined />
      }
      style={{cursor: 'pointer'}}
      onClick={() => download()}
    >
      {props.fileName}
    </Tag>
  )
}

