import {JbPageHeaderComponent} from "../../core/component/jb-page-header.component";
import {useContext, useEffect, useState} from "react";
import {AdminApplicationContext} from "../../../../core/context/admin-application.context";
import {TenantEntity} from "../../../../domain/entity/tenant.entity";
import {useTenantRepository} from "../../../../domain/repository/tenant.repository";
import {ListTenantComponent} from "./component/list.tenant.component";
import {Button, Drawer, Modal} from "antd";
import {ViewTenantComponent} from "./component/view-tenant.component";
import {UserFormComponent} from "../user/component/user.form.component";
import {UserEntity} from "../../../../domain/entity/user.entity";
import {TenantFormModalComponent} from "./component/tenant.form.modal.component";
import {JbButtonComponent} from "../../core/component/jb-button.component";
import {ChangeUserForm} from "./component/change-user.form";

export function SuperAdminModule() {
  const [tenants, setTenants] = useState<TenantEntity[]>([]);
  const [showCreateUserModal, setShowCreateUserModal] = useState<string>();
  const [showCreateTenantModal, setShowCreateTenantModal] = useState(false);
  const [showEditTenantModal, setShowEditTenantModal] = useState<TenantEntity>();
  const [changePassword, setChangePassword] = useState<UserEntity>();
  const [editUser, setEditUser] = useState<UserEntity>();
  const [viewTenant, setViewTenant] = useState<TenantEntity>();
  const tenantRepo = useTenantRepository();
  const args = useContext(AdminApplicationContext);
  
  function loadTenants() {
    tenantRepo.list().then(res => setTenants(res.data))
  }
  
  useEffect(() => {
    loadTenants();
  }, [])
  
  
  
  function createUsers(tenantId: string, values: any) {
    return tenantRepo.createUser(
      tenantId,
      values
    ).then(res => {
      loadTenants()
      setShowCreateUserModal(undefined)
      setViewTenant(undefined);
    })
  }
  
  function onEditUser(tenantId: string, userId: string, values: any) {
    return tenantRepo.editUser(
      tenantId,
      userId,
      values
    ).then(res => {
      loadTenants()
      setEditUser(undefined)
      setViewTenant(undefined);
    })
  }
  
  return (
    <>
      <JbPageHeaderComponent
        title={'Administração do Sistema - Clientes'}
        extraActions={[
          <JbButtonComponent
            type={'primary'}
            onClick={() => setShowCreateTenantModal(true)}
          >
            Adicionar Cliente
          </JbButtonComponent>
        ]}
      />
      <ListTenantComponent
        data={tenants}
        onClickRow={id => setViewTenant(tenants.find(w => w.id === id))}
        onEdit={tenant => setShowEditTenantModal(tenant)}
      />
      {
        viewTenant && (
          <Drawer
            title={`Detalhes de ${viewTenant.name}`}
            footer={null}
            width={1024}
            height={500}
            placement={'bottom'}
            onClose={() => setViewTenant(undefined)}
            open
            extra={[
              <Button
                onClick={() => setShowCreateUserModal(viewTenant?.id)}
                type={'primary'} shape={'round'}
              >
                Adicionar Usuário
              </Button>
            ]}
          >
            <ViewTenantComponent
              onEditUser={setEditUser}
              onToggleUser={setEditUser}
              onEditPassword={setChangePassword}
              tenant={viewTenant}
            />
  
  
            {
              editUser && (
                <Modal
                  title={'Novo Usuário'}
                  width={950}
                  open
                  onCancel={() => setShowCreateUserModal(undefined)}
                  footer={null}
                >
                  <UserFormComponent
                    editMode
                    onSubmit={values => onEditUser(viewTenant?.id, editUser?.id, values)}
                    onCancel={() => setEditUser(undefined)}
                    values={editUser}
                  />
                </Modal>
              )
            }
            
            {
              changePassword && (
                <Modal
                  title={'Alterar Senha'}
                  width={600}
                  open
                  onCancel={() => setChangePassword(undefined)}
                  footer={null}
                >
                  <ChangeUserForm
                    userId={changePassword.id}
                    onPasswordChanged={() => setChangePassword(undefined)}
                  />
                </Modal>
              )
            }
          </Drawer>
        )
      }
  
      {
        showCreateUserModal && (
          <Modal
            title={'Novo Usuário'}
            width={950}
            open
            onCancel={() => setShowCreateUserModal(undefined)}
            footer={null}
          >
            <UserFormComponent
              onSubmit={values => createUsers(showCreateUserModal, values)}
              onCancel={() => setShowCreateUserModal(undefined)}
            />
          </Modal>
        )
      }
      
      <TenantFormModalComponent
        onSave={() => {
          loadTenants()
          setShowCreateTenantModal(false);
        }}
        onCancel={() => setShowCreateTenantModal(false)}
        open={showCreateTenantModal}
      />
  
      {
        showEditTenantModal && (
          <TenantFormModalComponent
            onSave={() => {
              loadTenants()
              setShowEditTenantModal(undefined);
            }}
            editTenant={showEditTenantModal}
            onCancel={() => setShowEditTenantModal(undefined)}
            open
          />
        )
      }
    </>
  )
}