import {ModulePages} from "../../core/module-pages";
import {CreateAttendancePage} from "./page/create.attendance";
import { ListAttendance } from "./page/list.attendance";
import { ShowAttendance } from "./page/show.attendance";
import {EditAttendance} from "./page/edit.attendance";
import {PrintAttendance} from "./page/print.attendance";

export function AttendanceModule(props: any) {
  return (
    <ModulePages
      routes={[
        { path: 'wizard/:attendanceId/:step', render: (props) => <CreateAttendancePage {...props} />},
        { path: 'wizard/:attendanceId', render: (props) => <EditAttendance {...props} />},
        { path: 'wizard', render: (props) => <CreateAttendancePage {...props} />},
        { path: 'details/:attendanceId', render: (props) => <ShowAttendance {...props} />},
        { path: 'print/:attendanceId', render: (props) => <PrintAttendance {...props} />},
        { path: '', render: (props) => <ListAttendance {...props} />}
      ]}
    />
  )
}
