import {FinanceEntryEntity} from "../../../../../../domain/entity/finance-entry.entity";
import {FinanceEntryItemEntity} from "../../../../../../domain/entity/finance-entry-item.entity";
import {Descriptions, List} from "antd";
import {JbFormActionButtonComponent} from "../../../../core/component/jb-form-action-button.component";
import {FinanceEntryTypeComponent} from "../finance-entry.type.component";
import {useEffect, useMemo, useState} from "react";
import {ClientEntity} from "../../../client/domain/entity/client.entity";
import {FinanceItemEntity} from "../../../../../../domain/entity/finance-item.entity";
import {useClientRepository} from "../../../client/domain/repository/client.repository";
import {useFinanceItemRepository} from "../../../../../../domain/repository/finance-item.repository";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";
import {useSupplierRepository} from "../../../../../../domain/repository/supplier.repository";
import {SupplierEntity} from "../../../../../../domain/entity/supplier.entity";

export function ConfirmEntryStep(props: {
  entry: Partial<FinanceEntryEntity>,
  items: Partial<FinanceEntryItemEntity>[],
  onConfirm: () => any,
  onCancel: () => any,
}) {
  const [client, setClient] = useState<ClientEntity>()
  const [supplier, setSupplier] = useState<SupplierEntity>();
  const [items, setItems] = useState<FinanceItemEntity[]>([]);
  const {formDateInPtBr} = useDatetimeUtil();
  const { format } = new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency'});
  const clientRepo = useClientRepository();
  const itemRepo = useFinanceItemRepository();
  const supplierRepo = useSupplierRepository();
  
  useEffect(() => {
    clientRepo.find(props.entry.clientId as string).then(res => setClient(res.data));
    props.entry.supplierId && supplierRepo.find(props.entry.supplierId).then(res => setSupplier(res.data));
    itemRepo.list().then(res => setItems(res.data));
  }, [props.entry]);
  
  const totalAmount = useMemo(() => {
    return props.items
      .map(w => (w.amount ?? 0) * (w.unitPrice ?? 0))
      .reduce((prev, next) => prev + next , 0)
  }, [props.items])
  
  return (
    <div>
      <h1>Revisar Informações</h1>
      <Descriptions layout={'vertical'}>
        <Descriptions.Item label={'Tipo Lançamento'}>
          <FinanceEntryTypeComponent type={props.entry.type ?? ''} />
        </Descriptions.Item>
        {
          props.entry?.type === 'R' && (
            <Descriptions.Item label={'Cliente'}>
              {client?.name}
            </Descriptions.Item>
          )
        }
        {
          props.entry?.type === 'D' && (
            <Descriptions.Item label={'Fornecedor'}>
              {supplier?.name}
            </Descriptions.Item>
          )
        }
        <Descriptions.Item label={'Vencimento'}>
          {formDateInPtBr(props.entry.dueDate as string)}
        </Descriptions.Item>
        <Descriptions.Item label={'Valor Total'}>
          {format(totalAmount)}
        </Descriptions.Item>
        <Descriptions.Item label={'Observações'}>
          {props.entry.observation}
        </Descriptions.Item>
      </Descriptions>
      <List
        dataSource={props.items}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={items.find(w => w.id === item.itemId)?.name ?? ''}
              description={`${item.amount} x ${format(item.unitPrice ?? 0)}`}
            />
          </List.Item>
        )}
      />
      <JbFormActionButtonComponent
        onSaveClick={props.onConfirm}
        onCancel={props.onCancel}
        labelCancel={'Voltar'}
        labelSave={'Salvar Lançamento'}
      />
    </div>
  )
}