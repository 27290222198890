import {Tabs} from "antd";
import {ReactElement, ReactNode, useEffect, useState} from "react";
import {TabsPosition} from "antd/es/tabs";

export function JbTabsComponent(props: {
  children: ReactElement<{ key: string | number, title: string }>[],
  position?: TabsPosition
}) {
  const [key, setKey] = useState('0');
  const params = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (params.has('tab')) {
      setKey(params.get('tab') ?? '0')
    }
  }, [params.values])
  return (
    <>
      <Tabs tabPosition={props.position} activeKey={key} onChange={setKey}>
        {
          props.children.map((item, idx) => {
            return <Tabs.TabPane key={idx} tab={item.props.title}/>
          })
        }
      </Tabs>
      <div>
        {
          props.children[parseInt(key)]
        }
      </div>
    </>
    
  )
}
export function JbTabComponent(props: {
  title: string,
  key: string,
  children: ReactNode,
  show?: boolean
}) {
  return <> {props.children} </>
}
