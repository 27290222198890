import {Button, Modal} from "antd";
import {FormAttendanceStep} from "../../attendance/component/form.attendance-step";
import {useRouteMatch} from "react-router-dom";
import {useAttendanceRepository} from "../../attendance/domain/repository/attendance.repository";
import {useEffect, useState} from "react";
import {AttendanceStepEntity} from "../../attendance/domain/entity/attendance-step.entity";
import {LinkOutlined} from "@ant-design/icons";

export function ModalAttendanceStepComponent(props: {
  onClose: () => void
  oinAttendanceLink: (id: string) => any
}) {
  const [currentStep, setCurrentStep] = useState<AttendanceStepEntity>()
  const attendanceRepo = useAttendanceRepository();
  const routeMath = useRouteMatch<any>();
  const { id, stepId } = routeMath.params;
  
  useEffect(() => {
    if (!stepId) {
      return;
    }
    attendanceRepo.listSteps(id).then(res => {
      setCurrentStep(res.data.find(w => w.id === stepId))
    })
  }, [id, stepId])
  
  function save(values: any) {
    attendanceRepo.editStep(id, stepId, values).then(res => {
      props.onClose()
    })
  }
  return (
    <Modal
      open
      width={'90vw'}
      footer={null}
      onCancel={props.onClose}
    >
      <FormAttendanceStep
        onSubmit={save}
        values={currentStep}
        onCancel={props.onClose}
        extraActions={[
          <Button
            onClick={() => props.oinAttendanceLink(id)}
            icon={<LinkOutlined />}
          >
            Atendimento
          </Button>
        ]}
      />
    </Modal>
  )
}