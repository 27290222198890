import {Bar, BarChart, CartesianGrid, Label, Legend, Rectangle, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {Tooltip, Typography} from "antd";
import {AttendanceReportEntity} from "../../../../../domain/entity/attendance-report.entity";
import {useMemo} from "react";

const monthsMap = new Map([
  [1, 'Janeiro'],
  [2, 'Fevereiro'],
  [3, 'Março'],
  [4, 'Abril'],
  [5, 'Maio'],
  [6, 'Junho'],
  [7, 'Julho'],
  [8, 'Agosto'],
  [9, 'Setembro'],
  [10, 'Outubro'],
  [11, 'Novembro'],
  [12, 'Dezembro'],
])

export function AttendanceNewAgainstCurrentGraphComponent(props: {
  reports: AttendanceReportEntity[],
  colors: string[]
}) {

  function extractMonth(dateStr: string) {
    const month = dateStr.split('-')[1];
    const year = dateStr.split('-')[0];
    return parseInt(`${year}${month}`);
  }
  
  const source = useMemo(() => {
    const months = new Set<number>();
    const mapNew = new Map<string, number>();
    const mapCurrent = new Map<string, number>();
    for (const item of props.reports) {
        months.add(extractMonth(item.createdAt))
    }
    for (const month of Array.from(months)) {
      for (const item of props.reports) {
        const currentMonth = extractMonth(item.createdAt);
        if (currentMonth === month) {
          const value = mapNew.get(month.toString());
          mapNew.set(month.toString(), (value ?? 0) + 1 );
        } else {
          const value = mapCurrent.get(month.toString());
          mapCurrent.set(month.toString(), (value ?? 0) + 1 );
        }
      }
    }
    
    return Array.from(months).map(month => ({
      key: month,
      month: monthsMap.get(parseInt(month.toString().slice(4))),
      isNew: mapNew.get(month.toString()) ?? 0,
      isCurrent: mapCurrent.get(month.toString()) ?? 0,
    })).sort((a,b) => {
      if (a.key > b.key) return 1;
      if (a.key < b.key) return -1;
      return 0;
    })
  }, [props.reports])
  
  
  
  return (
    <>
      <Typography.Title level={4}>
        Atendimentos Novos / Atendimentos em Aberto
      </Typography.Title>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          height={300}
          width={500}
          data={source}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip  />
          <Legend  />
          <Label />
          <Bar
            dataKey="isNew"
            legendType={'square'}
            name={'Novos Atendimentos'}
            fill="#8884d8"
            label={{fill: 'white', fontSize: 15}}
            activeBar={<Rectangle fill="#8884d8" stroke="blue" />}
          />
          <Bar
            dataKey="isCurrent"
            name={'Atendimento Existentes'}
            fill="#82ca9d"
            label={{fill: 'white', fontSize: 15}}
            activeBar={<Rectangle fill="#82ca9d" stroke="purple" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  )

}