import ReCAPTCHA from "react-google-recaptcha";
import {useEnvHook} from "../common/env-hook";
import {useRef} from "react";

export function JbCaptchaComponent(props: {
  onChange: (token: string) => any,
}) {
  const captchaRef = useRef<any>();
  const envHook = useEnvHook();
  
  return (
    <ReCAPTCHA
      ref={captchaRef}
      onChange={(e) => !!e ? props.onChange(e) : null}
      sitekey={envHook.getRecaptchaKey()}
    />
  )
}