import {Tag} from "antd";

export function FinanceEntryStatus(props: { status: string }) {
  switch (props.status) {
    case "opened":
      return <Tag color={'blue'}>Aberto</Tag>;
    case 'finished':
      return <Tag color={'green'}>Baixado</Tag>;
    case 'canceled':
      return <Tag color={'lightgray'}>Cancelado</Tag>;
    default:
      return <Tag>{props.status}</Tag>
  }
}