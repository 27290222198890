import {Button, List, Space, Tag} from "antd";
import {EyeOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {useAttendanceStepRepository} from "../../attendance/domain/repository/attendance-step.repository";
import {AttendanceStepEntity} from "../../attendance/domain/entity/attendance-step.entity";
import {AttendancePriorityTagComponent} from "../../attendance/component/attendance-priority-tag.component";
import moment, {now} from "moment/moment";
import {AttendanceStepDateAlertComponent} from "../../attendance/component/attendance-step-date-alert.component";

export function PendingStepsComponent(props: {
  currentUserSelected?: boolean,
  onStepClick: (step: AttendanceStepEntity) => any,
}) {
  const [pendingSteps, setPendingSteps] = useState<AttendanceStepEntity[]>([])
  const attendanceStepRepo = useAttendanceStepRepository();
  
  useEffect(() => {
    attendanceStepRepo.listPendingSteps(
      !props.currentUserSelected
    ).then(res => setPendingSteps(res.data))
  }, [props.currentUserSelected]);
  
  function renderTitle(step: AttendanceStepEntity) {
    return (
      <Space>
        {step.stepType?.description}
        <AttendanceStepDateAlertComponent endDate={step.endDate} />
        <AttendancePriorityTagComponent priority={step.attendancePriority} />
      </Space>
    )
  }
  
  
  return (
    <List
      header={<h1>Próximas Movimentações</h1>}
      dataSource={pendingSteps}
      loading={attendanceStepRepo.loading}
      pagination={{
        pageSize: 5
      }}
      renderItem={(step) => (
        <List.Item
          onClick={() => props.onStepClick(step)}
          actions={[
            <Button
              type={'primary'}
              size={'small'}
              icon={<EyeOutlined />}
              onClick={() => props.onStepClick(step)}
            />
          ]}
        >
          <List.Item.Meta
            title={renderTitle(step)}
          />
        </List.Item>
      )}
    />
  )
}