import {ModulePages} from "../../core/module-pages";
import {CreateItemPage} from "./page/create.item.page";
import {EditItemPage} from "./page/edit.item.page";
import {ListItemPage} from "./page/list.item.page";

export function FinanceItemModule() {
  return (
    <ModulePages routes={[
      { path: 'create', render: (props) => <CreateItemPage {...props} />},
      { path: 'edit/:id', render: (props) => <EditItemPage {...props} />},
      { path: 'list', render: (props) => <ListItemPage {...props} />},
      { path: '', render: (props) => <ListItemPage {...props} />},
      ]}
    />
  )
}
