import {useCallback} from "react";

export function useDocumentUtil() {
  const formatCPF = useCallback((cpf: string) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4")
  },[])
  
  const formatCNPJ = useCallback((cnpj: string) => {
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5")
  },[])
  
  const formatDocument = useCallback((doc: string) => {
    return doc.length === 11 ? formatCPF(doc) : formatCNPJ(doc)
  }, [formatCNPJ, formatCPF])
  
  return {
    formatCPF,
    formatCNPJ,
    formatDocument
  }
}