import {FinanceEntryItemEntity} from "../../../../../../domain/entity/finance-entry-item.entity";
import {Button, Form, Input, Select} from "antd";
import {useEffect, useMemo, useState} from "react";
import {FinanceItemEntity} from "../../../../../../domain/entity/finance-item.entity";
import {useFinanceItemRepository} from "../../../../../../domain/repository/finance-item.repository";
import {JbInputNumberComponent} from "../../../../core/form/jb-input-number.component";
import {PlusOutlined} from "@ant-design/icons";

export function FormItemStep(props: {
  onAddItem: (item: any) => any,
  currentItems: FinanceEntryItemEntity[],
  typeEntry?: string
}) {
  const [form] = Form.useForm();
  const [items, setItems] = useState<FinanceItemEntity[]>([]);
  const financeItemRepo = useFinanceItemRepository();
  
  useEffect(() => {
    financeItemRepo.list().then(res => setItems(res.data))
  }, []);
  
  const opts = useMemo(() => {
    const currentItems = props.currentItems.map(w => w.itemId);
    return items
      .filter(w => !currentItems.includes(w.id))
      .filter(w => {
        if (!props.typeEntry || !w.itemType) return true;
        return props.typeEntry === w.itemType;
      })
      .map(w => ({
        label: w.name,
        value: w.id
      }))
  }, [items, props.currentItems, props.typeEntry])
  
  const onFinish = (values: any) => {
    values = {
      ...values,
      item: items.find(w => w.id === values.itemId),
      id: values.id ? values.id : `${Date.now().toString()}${Math.random()}`
    }
    props.onAddItem(values);
    form.resetFields()
  };
  
  return (
    <Form
      form={form}
      layout={'inline'}
      onFinish={onFinish}
      initialValues={{ amount: 1 }}
    >
      <Form.Item name={'itemId'} rules={[{ required: true }]} >
        <Select options={opts} placeholder={'Selecione um Item'}  style={{width: '400px'}} />
      </Form.Item>
      <Form.Item name={'amount'}  rules={[{ required: true }]}  >
        <Input type={'number'} defaultValue={1} />
      </Form.Item>
      <Form.Item name={'unitPrice'}  rules={[{ required: true }]} >
        <JbInputNumberComponent placeholder={'0,00'} />
      </Form.Item>
      <Button onClick={() => form.submit()} icon={<PlusOutlined />}>
        Adicionar Item
      </Button>
    </Form>
  )
}
