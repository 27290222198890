import {Button, Col, Form, Row} from "antd";
import {JbDatePickerComponent} from "../../../core/form/jb-date-picker.component";
import {useEffect} from "react";
import moment from "moment";

export function DashboardTimeFilterComponent(props: {
  onFilter: (values: any) => any,
  initialValues?: any
}) {
  const [form] = Form.useForm()
  
  useEffect(() => {
    if (props.initialValues) {
      form.setFieldsValue({
        start: props.initialValues.start ? moment(props.initialValues.start): undefined,
        end: props.initialValues.end ? moment(props.initialValues.end) : undefined,
      })
    }
  }, [props.initialValues])
  
  function onFinish(values: any) {
    props.onFilter({
      ...values,
      start: values.start.toISOString(true),
      end: values.end.toISOString(true),
    })
  }
  
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'inline'}
    >
      <Form.Item label={'Selecione um período'} name={'start'} rules={[{ required: true }]}>
        <JbDatePickerComponent placeholder={'Selecione a Data Inicial'} />
      </Form.Item>
      <Form.Item label={''} name={'end'} rules={[{ required: true }]}>
        <JbDatePickerComponent placeholder={'Selecione a Data Final'} />
      </Form.Item>
      <Button htmlType={'submit'}>Filtrar</Button>
    </Form>
  )
}