import {Col, Form, Input, message, Row, Select} from "antd";
import {useEffect, useState} from "react";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {StepTypeEntity} from "../../../../../domain/entity/step-type.entity";
import {useStepTypeRepository} from "../../../../../domain/repository/step-type.repository";
import {ListStepTypeLinkComponent} from "./list-step-type-link.component";

export function StepTypeLinksFormComponent(props: { stepTypeId: string }) { 
    const [stepType, setStepType] = useState<StepTypeEntity>()
    const stepTypeRepository = useStepTypeRepository()
    
    function loadStepType() {
      stepTypeRepository.find(props.stepTypeId)
        .then(res => setStepType(res.data));
    }
  
  useEffect(() => {
    loadStepType()
  }, [props.stepTypeId]);
    
    return (
      <>      
        <LinkForm
          key={1}
          stepTypeId={props.stepTypeId}
          onSubmited={() => loadStepType()}
          links={stepType?.links ?? []}
        />
        {
          stepType && (
            <ListStepTypeLinkComponent
              stepType={stepType}
              loading={stepTypeRepository.loading}
              onLinkRemoved={() => loadStepType()}
            />
          )
        }
        
      </>
    )
}

function LinkForm(props: { stepTypeId: string, links: any[], onSubmited: () => any }) {
  const [form] = Form.useForm()
  const stepTypeRepository = useStepTypeRepository();

  const linkTypes = [
    { label: 'Vídeo', value: 'video' },
    { label: 'Artigo', value: 'article' },
    { label: 'Site', value: 'site' },
  ]

  function onSubmit(values: any) {
    stepTypeRepository.saveLinks(
      props.stepTypeId,
      [...props.links, values]  
    ).then((res) => {
      props.onSubmited()
      form.resetFields()
    })
  }

  return (
    <Form form={form} layout='vertical' onFinish={onSubmit}>
      <Row gutter={12}>
        <Col span={4}>
          <Form.Item label='Tipo' name='type' required>             
            <Select options={linkTypes} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item name={'title'} label='Título' required>
            <Input />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item name={'link'} label='Url' required>
            <Input type="url" />
          </Form.Item>
        </Col>
      </Row>
      <JbFormActionButtonComponent />
    </Form>
  )
}