import {ModulePages} from "../../core/module-pages";
import {ListProcessObject} from "./page/list.process-object";

export function ProcessObjectModule() {
  return (
    <ModulePages routes={[
      { path: 'create', render: props => <ListProcessObject {...props} /> },
      { path: '', render: props => <ListProcessObject {...props} /> },
    ]} />
  )
}