import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {Button, Modal, Segmented, Table, Typography} from "antd";
import {ColumnsType} from "antd/es/table";
import {useExternalAttendanceRepository} from "../../../../../domain/repository/external-attendance.repository";
import {ExternalAttendanceEntity} from "../../../../../domain/entity/external-attendance.entity";
import {useDatetimeUtil} from "../../../../../core/util/datetime.util";
import {AdminApplicationContext} from "../../../../../core/context/admin-application.context";
import {LineOutlined} from "@ant-design/icons";
import {ConvertAttendanceComponent} from "../component/convert-attendance";

export function ListExternalAttendance(props: ModulePageProps) {
  const [externalAttendance, setExternalAttendance] = useState<ExternalAttendanceEntity[]>([]);
  const [showDescription, setShowDescription] = useState<string>();
  const [showPublicLink, setShowPublicLink] = useState(false)
  const [convertAttendance, setConvertAttendance] = useState<ExternalAttendanceEntity>();
  const externalAttendanceRepo = useExternalAttendanceRepository()
  const [statusFilter, setStatusFilter] = useState('OPENED');
  const { formDateTimeInPtBr } = useDatetimeUtil();
  const { currentTenant } = useContext<any>(AdminApplicationContext)
  

  const loadData = () => externalAttendanceRepo.list().then(res => setExternalAttendance(res.data));
  
  useEffect(() => {
    loadData()
  }, [])
  
  function archive(id: string) {
    return externalAttendanceRepo.edit(id, {
      status: 'ARCHIVED',
    }).then(() => loadData())
  }


  const columns = useMemo<ColumnsType<any>>(() => {
    return [
      {
        key: 0,
        title: 'Nome',
        dataIndex: 'personName'
      },
      {
        key: 1,
        title: 'Telefone',
        dataIndex: 'phoneNumber'
      },
      {
        key: 2,
        title: 'Email',
        dataIndex: 'personEmail'
      },
      {
        key: 4,
        title: 'Criado em.',
        dataIndex: 'createdAt',
        render: formDateTimeInPtBr
      },
      {
        key: 5,
        title: 'Relato',
        dataIndex: 'description',
        render: (val) => <Button onClick={() => setShowDescription(val)} type={'link'}> Ver Relato </Button>
      },
      {
        key: 99,
        title: '',
        dataIndex: 'id',
        align: 'right',
        render: (id,record) => (
          <>
            {
              statusFilter === 'OPENED' && (
                <Button.Group size={'middle'}>
                  <Button onClick={() => setConvertAttendance(record)}>Criar Atendimento</Button>
                  <Button onClick={() => archive(id)}>Arquivar</Button>
                </Button.Group>
              )
            }
          </>
        )
      }
    ]
  }, [statusFilter])
  

  const filteredData = useMemo(() => {
    if (statusFilter === 'ALL') return externalAttendance;
    return externalAttendance.filter(w => w.status === statusFilter)
  }, [externalAttendance, statusFilter])
  
  
  return (
    <>
      <JbPageHeaderComponent
        title={`Atendimentos Externos`}
        description={'Pedidos de atendiimentos feito pelo fomulário público'}
        extraActions={[
          <Button icon={<LineOutlined />} shape={'round'} onClick={() => setShowPublicLink(true)}>
            Link Formulário
          </Button>
        ]}
      />
      <Segmented
        options={[
          { label: 'Em Aberto', value: 'OPENED' },
          { label: 'Convertidos', value: 'CONVERTED' },
          { label: 'Arquivados', value: 'ARCHIVED' },
          { label: 'Todos', value: 'ALL' },
        ]}
        value={statusFilter}
        onChange={e => setStatusFilter(e.toString())}
      />
      <Table
        loading={externalAttendanceRepo.loading}
        columns={columns}
        dataSource={filteredData}
      />
      {
        !!showDescription && (
          <Modal
            title={'Relato do Atendimento'}
            footer={null}
            open
            onCancel={() => setShowDescription(undefined)}
            width={450}
          >
            {showDescription}
          </Modal>
        )
      }
      
      {
        showPublicLink && (
          <Modal
            title={'Link para formulárioi Público'}
            footer={null}
            open
            onCancel={() => setShowPublicLink(false)}
          >
            <Typography.Text copyable>
              {`${window.location.origin}/public-pre-attendance/${currentTenant?.id}`}
            </Typography.Text>
          </Modal>
        )
      }
      
      {
        convertAttendance && (
          <Modal
            title={'Criar Atendimento'}
            footer={null}
            open
            width={1024}
            onCancel={() => setConvertAttendance(undefined)}
          >
            <ConvertAttendanceComponent externalAttendance={convertAttendance} />
          </Modal>
        )
      }
    </>
  )
}
