import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {AttendancePartStep} from "../attendance-wizard/attendance-part.step";

export function AttendancePartsTabComponent(props: {
  attendance?: AttendanceEntity,
  reloadAttendance: () => any
}) {
  if (!props.attendance) {
    return null;
  }
  return (
    <AttendancePartStep
      attendance={props.attendance}
      reloadAttendance={props.reloadAttendance}
      onCancel={console.log}
    />
  )
}
