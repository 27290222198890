import {useJbHttpCrudService} from "../../../../../../core/service/jb-http-crud.service";
import {AttendanceStepEntity} from "../entity/attendance-step.entity";
import {BaseResponse} from "../../../../../../core/common/base-response.common";

export function useAttendanceStepRepository() {
  const crud = useJbHttpCrudService<AttendanceStepEntity>('attendance-step')
  
  function removeAttachment(stepId: string, attachmentId:string) {
    attachmentId = encodeURIComponent(attachmentId)
    return crud.delete(
     `${crud.resourceName}/${stepId}/attachment/${attachmentId}`,
    )
  }
  
  function uploadStepAttachment(stepId: string, file: File) {
    const formData = new FormData()
    formData.set('file', file)
    return crud.post(
      `${crud.resourceName}/${stepId}/attachment`,
      formData
    )
  }
  
  /**
   * @return {Promise<BaseResponse<AttendanceStepEntity[]>>}
   */
  function listPendingSteps(allUsers = false) {
    return crud.get(
      `${crud.resourceName}/${allUsers ? 'pending' : 'my-pending'}`
    )
  }
  
  function addOwner(userId: string, stepId: string) {
    return crud.post(
      `${crud.resourceName}/${stepId}/change-owner`,
      {
        userId,
        action: 'include'
      }
    )
  }
  
  function removeOwner(userId: string, stepId: string) {
    return crud.post(
      `${crud.resourceName}/${stepId}/change-owner`,
      {
        userId,
        action: 'remove'
      }
    )
  }
  
  function reopenStep(stepId: string) {
    return crud.post(
      `${crud.resourceName}/${stepId}/reopen`,
      { }
    )
  }
  
  
  function listAllStepsByPeriod(
    beginDate: string,
    endDate: string,
    justMySteps: boolean = false
  ): Promise<BaseResponse<AttendanceStepEntity[]>> {
    let query = `beginDate=${beginDate}&endDate=${endDate}`
    if (justMySteps) {
      query += `&currentUserOwner=true`
    }
    return crud.get(
      `${crud.resourceName}?${query}`,
    )
  }
  
  return {
    ...crud,
    removeAttachment,
    uploadStepAttachment,
    listPendingSteps,
    addOwner,
    removeOwner,
    reopenStep,
    listAllStepsByPeriod
  }
}