import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {useCallback, useEffect, useState} from "react";
import moment, {Moment} from "moment";
import {useFinanceEntryRepository} from "../../../../../domain/repository/finance-entry.repository";
import {CashFlowItemEntity} from "../../../../../domain/entity/cash-flow-item.entity";
import {CashFlowFilterComponent} from "../component/cash-flow-filter.component";
import {CashFlowListComponent} from "../component/cash-flow-list.component";


export function CashFlowIndex(props: ModulePageProps) {
  const [items, setItems] = useState<CashFlowItemEntity[]>([])
  const [startDate, setStartDate] = useState<Moment>(
    moment().add(-5,'d')
  );
  const [groupStrategy, setGroupStrategy] =
    useState<'daily' | 'monthly' | 'yearly'>('daily');
  const [endDate, setEndDate] = useState<Moment>(moment())
  const financeRepo = useFinanceEntryRepository();
  
  useEffect(() => {
    financeRepo.listCashFlowItems(
      startDate.toISOString(),
      endDate.toISOString()
    ).then(res => setItems(res.data));
  }, [startDate, endDate]);
  
  const onFilter = useCallback((values: any) => {
    if (values.start) setStartDate(values.start);
    if (values.end) setEndDate(values.end);
    if (values.groupStrategy) setGroupStrategy(values.groupStrategy);
  }, [])
  
  return (
    <>
      <JbPageHeaderComponent title={'Fluxo de Caixa'} />
      <CashFlowFilterComponent
        onFilter={onFilter}
        currentValues={{
          groupStrategy,
          end: endDate,
          start: startDate,
        }}
      />
      <CashFlowListComponent
        endDate={endDate}
        startDate={startDate}
        items={items}
        groupStrategy={groupStrategy}
      />
    </>
  )
}