import {Modal} from "antd";
import {AttendanceWizard} from "../../attendance/component/attendance-wizard";

export function ModalAttendanceWizardComponent(props: {
  visible: boolean,
  onCancel: () => any,
  navigator: () => any,
}) {
  return (
    <Modal
      title={'Novo Atendimento'}
      open={props.visible}
      footer={null}
      onCancel={props.onCancel}
      width={'80vw'}
    >
      <AttendanceWizard navigator={props.navigator} />
    </Modal>
  )
}