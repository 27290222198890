import {Form, Select} from "antd";

export type JbSearchableSelectComponentProps = {
  name: string,
  label: string,
  options: Array<{ label: string, value: string }>,
  required?: boolean,
  onSelect?: (value: any) => any
  disabled?: boolean
}

export function JbSearchableSelectComponent(props: JbSearchableSelectComponentProps) {
  function filter(input: string, option: any) {
    return (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
      || option?.value === 'new'
  }
  return (
    <Form.Item {...props}>
      <Select
        options={props.options}
        showSearch
        disabled={props.disabled}
        optionFilterProp="label"
        onSelect={props.onSelect}
        filterOption={filter}
        filterSort={(optionA, optionB) =>
          (optionA!.label as unknown as string)
            .toLowerCase()
            .localeCompare((optionB!.label as unknown as string).toLowerCase())
        }
      />
    </Form.Item>
  )
}
