import {FullAttendanceComponent} from "./index";
import {Modal} from "antd";

export function FullAttendanceModal(props: {
  attendanceId: string,
  onClose: () => any,
  
}) {
  return (
    <Modal
      title={null}
      footer={null}
      onCancel={props.onClose}
      width={'85vw'}
      open
    >
      <FullAttendanceComponent
        attendanceId={props.attendanceId}
      />
    </Modal>
    
  )
}