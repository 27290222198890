import {Button, List, Modal} from "antd";
import React, { useEffect, useState} from "react";
import {TenantEntity} from "../../../../domain/entity/tenant.entity";
import {useJbHttpAuthService} from "../../../../core/service/jb-http-auth.service";

export function ContextModal(props: {
  onClose: () => any,
  open: boolean,
  currentTenant: TenantEntity,
  onChanged: () => any,
}) {
  const [availableTenants, setAvailableTenants] = useState<TenantEntity[]>();
  const authRepo = useJbHttpAuthService();
  
  useEffect(() => {
    authRepo.listTenants().then(res => setAvailableTenants(res.data))
  }, [])
  
  function changeContext(tenantId: string) {
    return authRepo.changeContext(tenantId).then(() => props.onChanged()) ;
  }
  
  return (
    <Modal
      title={'Selecionar outro contexto'}
      footer={null}
      width={600}
      onCancel={props.onClose}
      open={props.open}
    >
      <List
        dataSource={availableTenants}
        renderItem={item => (
          <List.Item actions={[
            props.currentTenant?.id !== item.id ?
              <Button onClick={() => changeContext(item.id)}>Trocar</Button>
              :
              <Button type={'primary'}>Selecionado</Button>
          ]}>
            <List.Item.Meta
              title={item.name}
              description={item.document}
            />
          </List.Item>
        )}
      />
    </Modal>
  )
}