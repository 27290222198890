import {Modal} from "antd";
import {AttendanceStepOwner} from "./index";

export function AttendanceStepOwnerModal(props: {
  stepId: string,
  attendanceId: string,
  onClose: () => any,
  open: boolean
}) {
  return (
    <Modal
      width={500}
      footer={null}
      onCancel={props.onClose}
      open={props.open}
    >
      {
        props.open && (
          <AttendanceStepOwner {...props} />
        )
      }
    </Modal>
  )
}