import {LoginPage} from "./page/login.page";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {ForgetPassword} from "./page/forget-password.page";
import {ResetPassword} from "./page/reset-password.page";

export function AuthContext() {
  const {path} = useRouteMatch()
  
  return (
    <Switch>
      <Route path={`${path}/forget-password`} render={() => <ForgetPassword />} />
      <Route path={`${path}/reset-password`} render={() => <ResetPassword />} />
      <Route path={path} render={() => <LoginPage />} />
    </Switch>
  )
}
