import {JbPageHeaderComponent} from "../../core/component/jb-page-header.component";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {Col, Divider, Row, Statistic, Table, Typography} from "antd";
import {useEffect, useMemo, useState} from "react";
import {FinanceInsightEntity} from "../../../../domain/entity/finance-insight.entity";
import {useFinanceEntryRepository} from "../../../../domain/repository/finance-entry.repository";
import {FinanceHistoryEntity} from "../../../../domain/entity/finance-history.entity";

export function  FinanceDashboardModule() {
  const [insightData, setInsightData] = useState<FinanceInsightEntity[]>([]);
  const [historyData, setHistoryData] = useState<FinanceHistoryEntity>();
  const financeRepo = useFinanceEntryRepository();
  
  useEffect(() => {
    financeRepo.reportFinanceInsights().then(res => setInsightData(res.data))
    financeRepo.reportFinanceHistory().then(res => setHistoryData(res.data))
  }, []);
  
  const amountRevenueOpened = useMemo(() => {
    return insightData
      .filter(w => w.status === 'opened' && w.type === 'R')
      .map(w => w.amount)
      .reduce(
        (prev, next) => prev + next, 0
      )
  }, [insightData])
  
  const amountRevenueExpired = useMemo(() => {
    return insightData
      .filter(w => w.status === 'opened' && w.type === 'R')
      .map(w => w.expiredAmount ?? 0)
      .reduce(
        (prev, next) => prev + next, 0
      )
  }, [insightData])
  
  const amountDeductionOpened = useMemo(() => {
    return insightData
      .filter(w => w.status === 'opened' && w.type === 'D')
      .map(w => w.amount)
      .reduce(
        (prev, next) => prev + next, 0
      )
  }, [insightData])
  
  const amountDeductionExpired = useMemo(() => {
    return insightData
      .filter(w => w.status === 'opened' && w.type === 'D')
      .map(w => w.expiredAmount ?? 0)
      .reduce(
        (prev, next) => prev + next ?? 0, 0
      )
  }, [insightData])
  
  // TODO  Grafico de receitas / despesas por tipo de atendimento
  return (
    <>
      <JbPageHeaderComponent title={'Dashboard Financeira'} />
      <div>
        <Row gutter={12}>
          <Col span={12}>
            <Statistic value={amountDeductionOpened}  title={'Despesas em Aberto'} />
          </Col>
          <Col span={12}>
            <Statistic value={amountDeductionExpired} title={'Despesas Vencidas'} />
          </Col>
          <Col span={12}>
            <Statistic value={amountRevenueOpened} title={'Receitas em Aberto'} />
          </Col>
          <Col span={12}>
            <Statistic value={amountRevenueExpired} title={'Receitas em Vencidas'} />
          </Col>
        </Row>
        <Divider />
        
        <Typography.Title level={4}>
          Receitas x Despesas
        </Typography.Title>
        <ResponsiveContainer width={'100%'} height={400}>
          <LineChart
            width={800}
            title={'Receitas x Despesas'}
            height={300}
            data={historyData?.history ?? []}>
            <XAxis
              type={'category'}
              dataKey="due_month" />
            <YAxis/>
            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
            <Line label={'adasdsdasd'} type="monotone" dataKey="total_revenue" stroke="green" />
            <Line type="monotone" dataKey="total_deduction" stroke="red" />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <Divider />
    </>
  )
}