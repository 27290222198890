import {Empty} from "antd";
import {AttendancePublicInteractionEntity} from "../../../../../../domain/entity/attendance-public-interaction.entity";
import {useContext, useEffect, useMemo, useState} from "react";
import {
  useAttendanceTypeRepository
} from "../../../../../../domain/repository/attendance-public-interaction.repository";
import {AdminApplicationContext} from "../../../../../../core/context/admin-application.context";
import {
  AttendancePublicInteractionHistoryComponent
} from "../attendance-public-interacton/attendance-public-interaction-history.component";
import {
  AttendancePublicInteractionFormComponent
} from "../attendance-public-interacton/attendance-public-interaction-form.component";

export function AttendancePublicInteractionTabComponent(props: {
  attendanceId: string,
}) {
  const [
    interactions,
    setInteractions
  ] = useState<AttendancePublicInteractionEntity[]>([]);
  
  const attendanceInteractionRepo = useAttendanceTypeRepository();
  const ctx = useContext(AdminApplicationContext)
  
  function loadInteractions() {
    attendanceInteractionRepo
      .listInteractions(props.attendanceId)
      .then(res => setInteractions(res.data))
  }
  
  useEffect(() => {
    if (props.attendanceId) {
      loadInteractions();
    }
  }, [props.attendanceId])
  
  function onCreate(items: any) {
    items.createdBy = ctx?.user?.person?.name
    items.createdAt = new Date().toISOString()
    return attendanceInteractionRepo.createInteraction(props.attendanceId, items).then(res => {
      return loadInteractions()
    })
  }
  
  function onRemove(item: AttendancePublicInteractionEntity) {
    return attendanceInteractionRepo.removeInteraction(item.id)
      .then(
        () => attendanceInteractionRepo
          .listInteractions(props.attendanceId)
          .then(res => setInteractions(res.data)))
  }
  
  const sortedItems = useMemo(() => {
    return interactions.sort( (a,b) => {
      if (new Date(a.createdAt) > new Date(b.createdAt)) return 1;
      if (new Date(a.createdAt) < new Date(b.createdAt)) return -1;
      return 0
    })
  }, [interactions])
  
  function onUpload(args: any) {
    const { file } = args;
    return attendanceInteractionRepo.uploadAttachment(
      props.attendanceId,
      file,
      ctx?.user?.person?.name ?? ''
    ).then(() => loadInteractions())
  }
  
  return (
    <div>
      {
        !sortedItems.length && (
          <Empty description={'Ainda não existe registros de interações públicos'} />
        )
      }
      <AttendancePublicInteractionHistoryComponent
        interactions={sortedItems}
        onRemove={onRemove}
      />
      <AttendancePublicInteractionFormComponent
        onUpload={onUpload}
        onSubmit={onCreate}
        triggerOnSubmitWhenUploads
        disableUpload
      />
    </div>
  )
}