import {AttendanceStatusVo} from "../../domain/vo/attendance-status.vo";
import React, {useMemo} from "react";
import {AttendanceStatusEnum} from "../../domain/entity/attendance.entity";
import {Button, DatePicker, Form, Popover, Space} from "antd";
import {CheckOutlined, FolderOpenOutlined, FolderOutlined} from "@ant-design/icons";
import {JbDatePickerComponent} from "../../../../core/form/jb-date-picker.component";

export function AttendanceStatusActions(props: {
  status: AttendanceStatusVo,
  onConclude: () => any,
  onStop: (dateLime: Date) => any,
  onOpen: () => any,
}) {
  const availableOptions = useMemo(() => {
    const options: AttendanceStatusVo[] = [];
    switch (props.status) {
      case AttendanceStatusEnum.OPENED:
        options.push(
          AttendanceStatusEnum.STOPPED,
          AttendanceStatusEnum.CONCLUDED,
        )
        break;
      case AttendanceStatusEnum.CONCLUDED:
      case AttendanceStatusEnum.STOPPED:
        options.push(
          AttendanceStatusEnum.OPENED
        );
        break
    }
    return options
  }, [props.status])
  
  return (
    <Space>
      {
        availableOptions.map(status => {
          switch (status) {
            case AttendanceStatusEnum.CONCLUDED:
              return (
                <Button
                  onClick={props.onConclude}
                  size={'small'}
                  type={'ghost'}
                  color={'success'}
                  icon={<CheckOutlined />}
                >
                  Concluir
                </Button>
              )
            case AttendanceStatusEnum.OPENED:
              return (
                <Button
                  onClick={props.onOpen}
                  size={'small'}
                  type={'ghost'}
                  color={'info'}
                  icon={<FolderOpenOutlined />}
                >
                  Reabrir
                </Button>
              )
            case AttendanceStatusEnum.STOPPED:
              return (
                <Popover trigger={'click'} overlayInnerStyle={{
                  width: 300
                }} content={() => (
                  <Form onFinish={(values) => props.onStop(values.dueDate)} layout={'vertical'}>
                    <Form.Item name={'dueDate'} label={'Data Limite Para Suspensão'}>
                      <JbDatePickerComponent style={{width: '100%'}} />
                    </Form.Item>
                      <Button htmlType={'submit'} block type={'primary'}>Suspender</Button>
                  </Form>
                  
                )}>
                  <Button
                    size={'small'}
                    type={'ghost'}
                    color={'warning'}
                    icon={<FolderOutlined />}
                  >
                    Suspender
                  </Button>
                </Popover>
              )
            default:
              return null;
          }
        })
      }
    </Space>
  )
}