import {Button, Col, PageHeader, Row} from "antd";
import React, {useEffect, useState} from "react";
import {PlusOutlined} from "@ant-design/icons";
import moment, {Moment} from "moment";
import {AttendanceStepEntity} from "../../attendance/domain/entity/attendance-step.entity";
import {ModalAttendanceWizardComponent} from "../component/modal.attendance-wizard.component";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import {ModulePageProps} from '../../../core/module-pages';
import {ModalAttendanceStepComponent} from "../component/modal.attendance-step.component";
import {ModalAttendanceComponent} from "../component/modal.attendance.component";
import {BusinessCalendarComponent} from "../component/business-calendar";
import {DrawerStepActionComponent} from "../component/drawer-step-action";
import {InsightsDashboardComponent} from "../component/insights.dashboard.component";
import {useAttendanceStepRepository} from "../../attendance/domain/repository/attendance-step.repository";
import {PendingStepsComponent} from "../component/pending-steps.component";
import {FinanceEntriesComponent} from "../component/finance-entries.component";

export function DashboardIndex(props: ModulePageProps) {
  const [attendanceSteps, setAttendanceSteps] = useState<AttendanceStepEntity[]>([])
  const [dateParams, setDateParams] = useState<string[]>([])
  const [listAllAttendances, setListAllAttendances] = useState(false);
  const [daySelected, setDaySelected] = useState<Moment>(moment())
  const [createAttendance, setCreateAttendance] = useState(false);
  const [cellSelected, setCellSelected] = useState<Moment>();
  const attendanceStepRepo = useAttendanceStepRepository();
  const routeMatch = useRouteMatch();
  const history = useHistory();
  
  useEffect(() => onCalendarDateChange(daySelected), [])
  
  function loadData() {
    if (!dateParams.length) return;
    const [beginDate, endDate] = dateParams;
    attendanceStepRepo
      .listAllStepsByPeriod(beginDate, endDate, !listAllAttendances)
      .then(res => setAttendanceSteps(res.data))
  }
  
  useEffect(() => {
    loadData();
  }, [dateParams, listAllAttendances])
  
  
  function convertDateToParams(dt: Moment) {
    const [refYear, refMonth] = dt.toISOString(true).split(/[-T]/)
    const [nextYear, nextMonth] = dt.add(1, 'month').toISOString(true).split(/[-T]/)
    return [
      `${refYear}-${refMonth}-01`,
      `${nextYear}-${nextMonth}-01`,
    ]
  }
  
  function onCalendarDateChange(dt: Moment) {
    setDaySelected(dt.clone());
    setDateParams(convertDateToParams(dt.clone()))
  }
  
  function goToAttendance(id: string) {
    props.navigator(
      `attendance/${id}`
    )
  }
  
  function onSelect(date: Moment) {
    setCellSelected(date);
    onCalendarDateChange(date);
  }
  
  function onStepView(step: AttendanceStepEntity) {
    props.navigator(`attendance/${step.attendanceId}/step/${step.id}`)
  }
  
  return (
    <>
      <PageHeader
        title={'Visão Geral'}
        subTitle={'Gestão das Movimentações Registradas no Sistema'}
        extra={[
          <Button
            onClick={() => setCreateAttendance(true)}
            size={'large'}
            shape={'round'}
            type={'primary'}
            icon={<PlusOutlined />}
          >
            Atendimento
          </Button>
        ]}
      />
      <Row gutter={22}>
        <Col span={22}>
          <BusinessCalendarComponent
            onSelect={onSelect}
            onOwnerFilter={setListAllAttendances}
            attendanceSteps={attendanceSteps}
            onDateChange={onCalendarDateChange}
          />
        </Col>
        <Col span={2}>
          <InsightsDashboardComponent steps={attendanceSteps} />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <PendingStepsComponent
            onStepClick={onStepView}
            currentUserSelected={false}
          />
        </Col>
        <Col span={12}>
          <FinanceEntriesComponent />
        </Col>
      </Row>
      {
        createAttendance && (
          <ModalAttendanceWizardComponent
            visible={true}
            onCancel={() => {
              loadData();
              setCreateAttendance(false)
            }}
            navigator={props.navigator as any}
          />
        )
      }
      {
        cellSelected && (
          <DrawerStepActionComponent
            currentDate={cellSelected}
            steps={attendanceSteps}
            onViewStep={onStepView}
            onCancel={() => setCellSelected(undefined)}
          />
        )
      }
      {
        <Switch>
          <Route path={`${routeMatch.path}attendance/:id/step/:stepId`} strict={false}>
            <ModalAttendanceStepComponent
              oinAttendanceLink={
                id => goToAttendance(id)
              }
              onClose={() => {
                loadData()
                history.push(props.prefix)
              }}
            />
          </Route>
          <Route path={`${routeMatch.path}attendance/:id`} strict={false}>
            <ModalAttendanceComponent
              onClose={() => {
                loadData()
                history.push(props.prefix)
              }}
            />
          </Route>
        </Switch>
      }
    </>
  )
}