import {TenantEntity} from "../../../../../domain/entity/tenant.entity";
import {Descriptions} from "antd";
import {ListTenantUsersComponent} from "./list.tenant-users.component";
import {useEffect, useState} from "react";
import {useTenantRepository} from "../../../../../domain/repository/tenant.repository";
import {UserEntity} from "../../../../../domain/entity/user.entity";

export function ViewTenantComponent(props: {
  tenant: TenantEntity
  onEditUser: (user: UserEntity) => any,
  onToggleUser: (user: UserEntity) => any,
  onEditPassword: (user: UserEntity) => any,
}) {
  const [users, setUsers] = useState<UserEntity[]>([]);
  
  const tenantRepo = useTenantRepository();
  
  function loadUsers() {
    return tenantRepo.listUsers(props.tenant.id)
      .then(res => setUsers(res.data))
  }
  
  useEffect(() => {
    loadUsers();
  }, [props.tenant])
  
  return (
    <>
      <Descriptions>
        <Descriptions.Item label={'Nome'}>
          {props.tenant.name}
        </Descriptions.Item>
        <Descriptions.Item label={'Tipo'}>
          {props.tenant.type}
        </Descriptions.Item>
        <Descriptions.Item label={'Documento'}>
          {props.tenant.document}
        </Descriptions.Item>
      </Descriptions>
      <ListTenantUsersComponent
        users={users}
        onEditRow={props.onEditUser}
        onToggleRow={props.onToggleUser}
        onChangePassword={props.onEditPassword}
      />
    </>
  )
}