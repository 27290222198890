import {ListUserPage} from "./pages/list.user";
import {CreateUserPage} from "./pages/create.user";
import {ModulePages} from "../../core/module-pages";
import {EditUserPage} from "./pages/edit.user";

export function UserModule() {
  return (
    <ModulePages
      routes={[
        { path: 'create', render: (props) => <CreateUserPage {...props} /> },
        { path: 'edit/:id', render: (props) => <EditUserPage {...props} /> },
        { path: '', render: (props) => <ListUserPage {...props} /> }
      ]}
   />
  )
}
