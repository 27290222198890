import { useJbTokenStorageService } from "./jb-token-storage.service";
import { useJbHttpService } from "./jb-http.service";
import {useState} from "react";
import {useMessageService} from "../messages/message.service";
import {message, notification} from "antd";
import {BaseResponse} from "../common/base-response.common";
import {UserEntity} from "../../context/auth/entity/user.entity";
import {TenantEntity} from "../../domain/entity/tenant.entity";

export function useJbHttpAuthService() {
  const [loading, setLoading] = useState(false)
  const {
    setToken,
    setRefreshToken,
    removeToken,
    getToken,
    getRefreshToken,
  } = useJbTokenStorageService()
  const { getMessage } = useMessageService()
  const {axiosInstance: axios, baseUrl} = useJbHttpService()

  function authUser(args: { username: string, password: string }) {
    return axios.post(
      `/auth`,
      args,
    ).then(res => {
      setToken(res.data?.data?.bearerToken)
      setRefreshToken(res.data?.data?.refreshToken)
      return res.data
    })
  }
  
  function forgetPassword(login: string) {
    return post(
      `/auth/recover-password`, {
        login
      }
    )
  }
  function resetPassword(token: string, newPassword: string) {
    return put(
      `/auth/recover-password`, {
        token, newPassword
      }
    )
  }

  function refreshUser() {
    return axios.post(`/auth/refresh`, {
      refreshToken: getRefreshToken()
    }).then(res => {
      setToken(res.data?.data?.bearerToken)
      setRefreshToken(res.data?.data?.refreshToken)
      return res.data
    })
  }

  function isAuth() {
    return !!getToken();
  }

  function logout() {
    removeToken()
    window.location.reload()
  }

  function request(args: {
    method: string,
    uri: string,
    queryParams?: any,
    headers?: any,
    data?: any,
    opts?: { displayMessage?: boolean }
  }): Promise<any> {
    setLoading(true);
    return axios.request({
      ...args,
      params: args.queryParams,
      url: args.uri,
    })
      .then(res => {
        if (!res.data.message) {
          return res.data;
        }
        const parsedMessage = getMessage(res.data.message);
        if (args.opts && args.opts.displayMessage) {
          notification.success({
            message: parsedMessage,
          })
          // message.success(parsedMessage)
        }
        return {
          ...res.data,
          message: parsedMessage,
        }
      })
      .catch(async err => {
        if (err.response && err.response.status === 405) {
          return refreshUser()
            .then(() => request(args).then(res => res.data))
            .catch(() => logout())
        }
        const error = {
          message: 'Erro ao tentar realizar operação !',
          ...err?.response?.data
        }
        error.message = getMessage(error.message)
        
        notification.error({
          message: error.message,
        })
        // message.error(error.message)
        return Promise.reject(error)
      })
      .finally(() => setLoading(false))
  }

  function get(uri: string, headers?: any, opts?: { displayMessage?: boolean, queryParams?: any }): Promise<any> {
    return request({ uri, headers, method: 'GET', opts, queryParams: opts?.queryParams })
  }

  function post(uri: string, data: any, headers?: any, opts?: { displayMessage?: boolean }): Promise<any> {
    opts = {
      displayMessage: true,
      ...opts
    }
    return request({ uri, data, headers, method: 'POST', opts })
  }

  function put(uri: string, data: any, headers?: any, opts?: { displayMessage?: boolean }): Promise<any> {
    opts = {
      displayMessage: true,
      ...opts
    }
    return request({ uri, data, headers, method: 'PUT', opts })
  }

  function _delete(uri: string, data?: any ,headers?: any, opts?: { displayMessage?: boolean }): Promise<any> {
    opts = {
      displayMessage: true,
      ...opts
    }
    return request({ uri, data, headers, method: 'DELETE', opts })
  }

  function describeMe(): Promise<BaseResponse<{ user: UserEntity, tenant: TenantEntity }>> {
    return get(`auth/describe/me`)
  }
  
 
  function listTenants(): Promise<BaseResponse<TenantEntity[]>> {
    return get('auth/tenants')
  }
  
  function changeContext(newTenantId: string) {
    return post(`auth/change-tenant`, { newTenantId }).then(res => {
      setToken(res.data.bearerToken)
      setRefreshToken(res.data.refreshToken)
    })
  }
  
  return {
    authUser,
    setLoading,
    refreshUser,
    isAuth,
    logout,
    request,
    post,
    get,
    put,
    delete: _delete,
    loading,
    describeMe,
    baseUrl,
    changeContext,
    listTenants,
    forgetPassword,
    resetPassword
  }
}
