import {createContext} from "react";
import {UserEntity} from "../../context/auth/entity/user.entity";
import {TenantEntity} from "../../domain/entity/tenant.entity";
import {NotificationEntity} from "../../domain/entity/notification.entity";

export const AdminApplicationContext = createContext<AdminApplicationContextProps | null>(null)


export type AdminApplicationContextProps = {
  user?: UserEntity;
  currentTenant?: TenantEntity,
  applicationPrefix: string;
  showModalContext: () => any,
  readNotifications: (notifications: string[]) => any,
  notifications: NotificationEntity[],
  showChangePasswdModal: boolean,
  setShowChangePasswdModal: (show: boolean) => any,
}