import {Button, Modal, Space, Tag} from "antd";
import {ShowFinanceEntry} from "./index";
import {FinanceEntryEntity} from "../../../../../../domain/entity/finance-entry.entity";
import {useCallback, useMemo, useState} from "react";
import {ArrowDownOutlined, DeleteOutlined, RollbackOutlined} from "@ant-design/icons";
import {useFinanceEntryRepository} from "../../../../../../domain/repository/finance-entry.repository";
import {ModalRegisterPaymentForm} from "../register-payment.form.component";

export function ShowFinanceEntryModal(props: {
  financeEntryCode: string,
  onClose: () => any,
  entry: FinanceEntryEntity,
  onUpdated?: () => any,
  enableAction?: boolean
  open: boolean,
}) {
  const [showPaymentFrom, setShowPaymentForm] = useState(false);
  const {
    onClose,
    open
  } = props;
  const entryRepo = useFinanceEntryRepository();
  
  const payEntry = useCallback((
    method: string,
    amount: number,
    paymentDate: any
  ) => entryRepo.registerPayment(
    props.entry.id,
    amount,
    method,
    paymentDate
  ).then(() => {
    setShowPaymentForm(false)
    props.onUpdated && props.onUpdated()
  }), [])
  
  const formattedCode = useMemo(() => {
    return props.financeEntryCode.split('-').join('').toUpperCase()
  }, [props.financeEntryCode])
  
  const CancelAction = (
    <Button
      icon={<RollbackOutlined />}
      color={'danger'}
      onClick={() => {
        entryRepo.cancelEntry(props.entry.id).then(() => {
          props.onUpdated && props.onUpdated()
        })
      }}
    >
      Cancelar Lançamento
    </Button>
    )
  ;
  const PayAction =(
    <Button
      icon={<ArrowDownOutlined />}
      type={'primary'}
      onClick={() => setShowPaymentForm(true)}
    >
      Baixar Lançamento
    </Button>
  );
  const DeleteAction = (
    <Button
      icon={<DeleteOutlined />}
      color={'danger'}
      onClick={() => {
        entryRepo.remove(props.entry.id).then(() => {
          props.onUpdated && props.onUpdated()
        })
      }}
    >
      Excluir Lançamento
    </Button>
  )
  
  const actions = useMemo(() => {
    const actions = [];
    if (props.entry.status === 'opened') {
      actions.push(PayAction);
      actions.push(DeleteAction);
    }
    if (props.entry.status === 'finished') {
      actions.push(CancelAction)
    }
    return actions;
  }, [props.entry])
  
  const Title = () => <>Lançamento Financeiro  <Tag>{formattedCode}</Tag></>;
  return (
    <>
      <Modal
        title={<Title />}
        onCancel={onClose}
        footer={null}
        open={open}
        width={1200}
        bodyStyle={{padding: 0}}
      >
        <ShowFinanceEntry
          entry={props.entry}
        />
        {
          props.enableAction && actions.length && (
            <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10}}>
              <Space>
                {actions}
              </Space>
            </div>
          )
        }
      </Modal>
      {
        showPaymentFrom && (
          <ModalRegisterPaymentForm
            onClose={() => setShowPaymentForm(false)}
            defaultValue={props.entry?.amount}
            onSubmit={values => payEntry(
              values.method,
              values.amount,
              values.paymentDate
            )}
          />
        )
      }
    </>
  )
}