import {ModulePages} from "../../core/module-pages";
import {ListProcessTypePage} from "./page/list.process-type.page";
import {CreateProcessTypePage} from "./page/create.process-type.page";
import {EditProcessTypePage} from "./page/edit.process-type.page";

export function ProcessTypeModule() {
  return (
    <ModulePages routes={[
      { path: 'create', render: (props) => <CreateProcessTypePage {...props} /> },
      { path: 'edit/:id', render: (props) => <EditProcessTypePage {...props} /> },
      { path: 'list', render: (props) => <ListProcessTypePage {...props} />},
      { path: '', render: (props) => <ListProcessTypePage {...props} />},
      ]}
    />
  )
}
