import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {TenantEntity} from "../entity/tenant.entity";

export function useTenantRepository() {
  const crud = useJbHttpCrudService<TenantEntity>('tenant');
  
  function listUsers(tenantId: string) {
    return crud.get(
      `${crud.resourceName}/${tenantId}/user`
    )
  }
  
  function createUser(tenantId: string, values: any) {
    return crud.post(
      `${crud.resourceName}/${tenantId}/user`,
      values
    )
  }
  
  function editUser(tenantId: string, userId: string, values: any) {
    return crud.put(
      `${crud.resourceName}/${tenantId}/user/${userId}`,
      values
    )
  }
  
  return {
    ...crud,
    listUsers,
    createUser,
    editUser
  }
}