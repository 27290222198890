import {ModulePages} from "../../core/module-pages";
import {ListExternalAttendance} from "./pages/list.external-attendance";

export function ExternalAttendanceModule() {
  return (
    <ModulePages
      routes={[
        { path: 'list' , render: (props) => <ListExternalAttendance {...props} /> },
        { path: '' , render: (props) => <ListExternalAttendance {...props} /> }
      ]}
      />
  )
}
