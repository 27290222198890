import React, {useEffect, useState} from "react";
import {ProcessObjectAttachedEntity} from "../../../../../../domain/entity/process-object-attached.entity";
import {useProcessRepository} from "../../domain/repository/process.repository";
import {ListProcessAttachedObjectsComponent} from "./list-process-attached-objects.component";
import {Modal} from "antd";
import {FormProcessObjectAttachedComponent} from "./form-process-object-attached.component";

export function CrudProcessObjectAttachedComponent(props: {  processId: string  }) {
  const [showObjectForm, setShowObjectForm] = useState(false);
  const [editObject, setEditObject] = useState<ProcessObjectAttachedEntity>();
  const [attachedObjects, setAttachedObjects] = useState<ProcessObjectAttachedEntity[]>([]);
  const processRepo = useProcessRepository();
  function listObjects() {
    processRepo.listAttachedObject(props.processId)
      .then(res => setAttachedObjects(res.data));
  }
  
  function attachObject(values: any) {
    return processRepo.attachObject(props.processId, values).then(
      () => {
        setShowObjectForm(false);
        listObjects()
      }
    )
  }
  function editObjectAttached(values: any) {
    return processRepo.editAttachedObject(props.processId, editObject?.id as string, values).then(
      () => {
        setEditObject(undefined);
        listObjects()
      }
    )
  }
  
  function removeAttachedObject(id: string) {
    return processRepo.removeAttachedObject(props.processId, id).then(
      () => listObjects()
    )
  }
  
  useEffect(() => {
    listObjects()
  }, [])
  
  return (
    <>
      
      <ListProcessAttachedObjectsComponent
        data={attachedObjects}
        onAdd={() => setShowObjectForm(true)}
        onRemove={item => removeAttachedObject(item.id)}
        onEdit={item => setEditObject(item)}
      />
      
      {
        showObjectForm && props.processId && (
          <Modal
            open
            title={'Objetos de Processo'}
            width={850}
            footer={null}
            onCancel={() => setShowObjectForm(false)}
          >
            <FormProcessObjectAttachedComponent
              values={{}}
              processId={props?.processId}
              onCancel={() => setShowObjectForm(false)}
              onSubmit={attachObject}
            />
          </Modal>
        )
      }
      
      {
        editObject && props.processId && (
          <Modal
            open
            title={'Editar Objeto de Processo'}
            width={850}
            footer={null}
            onCancel={() => setEditObject(undefined)}
          >
            <FormProcessObjectAttachedComponent
              values={editObject}
              processId={props?.processId}
              disableFields={['objectId']}
              onCancel={() => {
                setEditObject(undefined)
              }}
              onSubmit={editObjectAttached}
            />
          </Modal>
        )
      }
    </>
  )
}