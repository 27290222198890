import {ModulePages} from "../../core/module-pages";
import {Main} from "./pages/main";

export function MyWorkModule() {
  return(
    <ModulePages
      routes={[
        { path: '', render: props => <Main {...props} />}
      ]}
    />
  )
}