import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {NotificationEntity} from "../entity/notification.entity";
import {BaseResponse} from "../../core/common/base-response.common";

export function useNotificationRepository() {
  const crud = useJbHttpCrudService<NotificationEntity>('auth/notification');
  
  function readNotifications(notifications: string[]): Promise<BaseResponse<any>> {
    return crud.post(
      `${crud.resourceName}/read`,
      { notifications }
    )
  }
  
  return {
    listNotifications: crud.list,
    readNotifications
  }
}