import React, {useEffect, useState} from "react";
import {Button, message, Modal} from "antd";
import {AttendancePartFormComponent} from "../attendance-part.form.component";
import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {AttendancePartEntity} from "../../domain/entity/attendance-part.entity";
import {AttendancePartListComponent} from "../attendance-part.list.component";
import {JbFormActionButtonComponent} from "../../../../core/component/jb-form-action-button.component";
import {ArrowRightOutlined, PlusOutlined} from "@ant-design/icons";

export function AttendancePartStep(props: {
  attendance: AttendanceEntity,
  reloadAttendance: () => any,
  onCancel: () => any,
  onFinish?: () => any
}) {
  const [currentParts, setCurrentParts] = useState<AttendancePartEntity[]>([])
  const [showCreateClientPart, setShowCreateClientPart] = useState(false);
  const attendanceRepo = useAttendanceRepository();
  const [showCreatePartForm, setShowCreatePartForm] = useState(false)
  const [showEditPartForm, setShowEditPartForm] = useState<AttendancePartEntity>()

  useEffect(() => {
    setCurrentParts(props.attendance.attendanceParts ?? [])
  }, [props, props.attendance])

  function onFinish(values: any) {
    if (showEditPartForm) {
      return editPart(values, showEditPartForm.id)
    } else {
      return addPart(values)
    }
  }

  function editPart(values: any, partId: string) {
    attendanceRepo.editPart(props.attendance.id, partId, values).then(res => {
      setShowEditPartForm(undefined)
      return props.reloadAttendance();
    })
  }

  function addPart(values: any) {
    attendanceRepo.addPart(props.attendance.id, values).then(res => {
      setShowCreatePartForm(false)
      setShowCreateClientPart(false);
      return props.reloadAttendance();
    })
  }

  function removePart(part: any) {
    return attendanceRepo.removePart(
      props.attendance.id,
      part.id
    ).then(res => {
      return props.reloadAttendance()
    })
  }

  function transformClientData() {
    const { client } = props.attendance;
    return {
      ...client,
      observation: 'Cliente Responsável pelo atendimento.'
    }  
  }

  return (
    <>
      <AttendancePartListComponent
        key={3}
        parts={currentParts}
        onRemove={item => removePart(item)}
        onEdit={ed => setShowEditPartForm(ed)}
      />
      <JbFormActionButtonComponent
        labelSave={'Adicionar Parte'}
        hideCancelButton={true}
        onSaveClick={() => setShowCreatePartForm(true)}
        extraActions={[
          <Button icon={<PlusOutlined />} onClick={() => setShowCreateClientPart(true)}>
            Adicionar Cliente Como Parte
          </Button>,
          props.onFinish && (
           <Button icon={<ArrowRightOutlined />} onClick={() => props.onFinish ? props.onFinish() : null}>
             Continuar
          </Button>
          )
        ]}
      />
      {
        showCreatePartForm && (
          <Modal
            title={'Adicionar Parte'}
            footer={null}
            visible={true}
            width={900}
            onCancel={() => setShowCreatePartForm(false)}
          >
            <AttendancePartFormComponent
              onSubmit={onFinish}
              onCancel={() => setShowCreatePartForm(false)}
            />
          </Modal>
        )
      }
      {
        showEditPartForm && (
          <Modal
            title={`Editar ${showEditPartForm.name}`}
            footer={null}
            visible={true}
            width={900}
            onCancel={() => setShowEditPartForm(undefined)}
          >
            <AttendancePartFormComponent
              onSubmit={onFinish}
              currentValues={showEditPartForm}
              onCancel={() => setShowEditPartForm(undefined)}
            />
          </Modal>
        )
      }
      {
        showCreateClientPart && (
          <Modal
            title={`Adicionando Cliente Como Parte`}
            footer={null}
            visible={true}
            width={900}
            onCancel={() => setShowCreateClientPart(false)}
          >
            <AttendancePartFormComponent
              onSubmit={onFinish}
              currentValues={transformClientData()}
              onCancel={() => setShowCreateClientPart(false)}
            />
          </Modal>
        )
      }
    </>
  )
}
