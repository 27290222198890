import {useForm} from "antd/es/form/Form";
import {useEffect, useMemo, useState} from "react";
import {Col, Form, Input, Radio, Row, Select} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {FinanceItemEntity} from "../../../../../domain/entity/finance-item.entity";
import {useCategoryRepository} from "../../../../../domain/repository/category.repository";
import {CategoryEntity} from "../../../../../domain/entity/category.entity";

export function ItemFormComponent(props: {
  currentValues?: Partial<FinanceItemEntity>,
  onSubmit: (values: any) => any,
  onCancel: () => any,
}) {
  const [form] = useForm()
  const [categories, setCategories] = useState<CategoryEntity[]>([]);
  const categoryRepo = useCategoryRepository();
  
  useEffect(() => {
    categoryRepo.list().then(res => setCategories(res.data))
    if (props.currentValues) {
      form.setFieldsValue({
        ...props.currentValues,
        categoryId: props.currentValues?.category?.id,
      });
    }
  }, [props.currentValues]);
  
  function onSubmit(values: any) {
    return props.onSubmit(values);
  }
  
  const categoriesOpts = useMemo(() => {
    return categories.map(w => ({
      label: w.name,
      value: w.id
    }))
  }, [categories])
  
  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={onSubmit}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={'name'} label={'Nome'} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'description'} label={'Descrição'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item name={'categoryId'} label={'Categoria'}>
            <Select options={categoriesOpts} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name={'itemType'} label={'Tipo do Item'}>
            <Radio.Group options={[
                { label: 'Todos', value: '' },
                { label: 'Receita', value: 'R' },
                { label: 'Despesa', value: 'D' },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <JbFormActionButtonComponent
        onCancel={props.onCancel}
      />
    </Form>
  )
}