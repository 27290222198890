import {Form, Input} from "antd";
import {PartTypeEntity} from "../domain/entity/part-type.entity";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {useEffect} from "react";

export type PartTypeFormComponentProps = {
  onSubmit: (values: any) => any,
  values?: Partial<PartTypeEntity>
}

export function PartTypeFormComponent(props: PartTypeFormComponentProps) {
  const [form] = Form.useForm()
  
  useEffect(() => {
    if (props.values) {
      form.setFieldsValue(props.values)
    }
  }, [props.values])
  
  return (
    <Form form={form} onFinish={props.onSubmit}>
      <Form.Item name={'name'} label={'Nome'} required rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <JbFormActionButtonComponent />
    </Form>
  )
}
