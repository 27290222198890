import { Button, Col, Form, Input, Row, Select } from "antd";
import {ReactNode, useEffect, useMemo, useState} from "react";
import { PartTypeEntity } from "../../part-type/domain/entity/part-type.entity";
import { usePartTypeRepository } from "../../part-type/domain/repository/part-type.repository";
import { JbFormActionButtonComponent } from "../../../core/component/jb-form-action-button.component";
import TextArea from "antd/es/input/TextArea";

export type ProcessPersonPartFormComponentProps = {
  onSubmit: (values: any) => any,
  currentValues?: any,
  extraAction?: ReactNode[],
  onCancel?: () => any
}
export function AttendancePartFormComponent(props: ProcessPersonPartFormComponentProps) {
  const [form] = Form.useForm()
  const [partTypes, setPartTypes] = useState<PartTypeEntity[]>([])
  const partTypeRepo = usePartTypeRepository()

  useEffect(() => {
    partTypeRepo.list().then(res => setPartTypes(res.data))
  }, [])

  useEffect(() => {
    if (props.currentValues) {
      form.setFieldsValue({
        ...props.currentValues,
        partTypeId: props.currentValues?.partType?.id
      })
    }
  }, [props.currentValues])

  const partTypeOpts = useMemo<{ label: string, value: string}[]>(() => {
    return partTypes.map(w => ({
      value: w.id,
      label: w.name
    }))
  }, [partTypes])

  function onSubmit(values: any) {
    props.onSubmit(values)
    form.resetFields();
  }

  return (
    <>
      <Form layout={'vertical'} onFinish={onSubmit} form={form}>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item label={'Tipo Parte'} name={'partTypeId'} rules={[{ required: true }]}>
              <Select options={partTypeOpts} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name={'name'} label={'Nome'}  rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'document'} label={'Documento'} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name={'email'} label={'Email'}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'phoneNumber'} label={'Telefone'}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name={'observation'} label={'Observação'}>
          <TextArea style={{width: '100%'}} rows={2} />
        </Form.Item>
        <JbFormActionButtonComponent extraActions={props.extraAction} onCancel={props.onCancel} />
      </Form>
    </>
  )
}
