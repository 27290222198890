import {ModulePages} from "../../core/module-pages";
import {ListClient} from "./pages/list.client";
import {CreateClientPage} from "./pages/create.client";
import {EditClientPage} from "./pages/edit.client";

export function ClientModule() {
  return (
    <ModulePages
      routes={[
        { path: 'edit/:id' , render: (props) => <EditClientPage {...props} /> },
        { path: 'create' , render: (props) => <CreateClientPage {...props} /> },
        { path: 'list' , render: (props) => <ListClient {...props} /> },
        { path: '' , render: (props) => <ListClient {...props} /> }
      ]}
      />
  )
}
