import {JbDropzoneComponent} from "../../../../core/dropzone";
import {LoadingOutlined} from "@ant-design/icons";
import {useAttendanceStepRepository} from "../../domain/repository/attendance-step.repository";

export function UploadForm(props: {
  attendanceId: string,
  stepId: string,
  onAttached: () => any
}) {
  const attendanceStepRepo = useAttendanceStepRepository();
  
  function onDrop(file: File) {
    return attendanceStepRepo.uploadStepAttachment(
      props.stepId,
      file
    ).then(() => props.onAttached())
  }
  
  return (
    <>
      {
        attendanceStepRepo.loading && (
          <LoadingOutlined />
        )
      }
      <JbDropzoneComponent
        onDrop={onDrop}
        description={'Anexar seus arquivos'}
      />
    </>
  )
}