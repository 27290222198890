import { Button, Form, Input, message } from "antd";
import logo from "./../../../media/logo_juristtabusiness_branca.png";
import { useJbHttpAuthService } from "../../../core/service/jb-http-auth.service";
import { useHistory } from "react-router-dom";
import { KeyOutlined, UserOutlined } from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import {useEnvHook} from "../../../core/common/env-hook";
import {useState} from "react";
import './override.css'

export function LoginPage(props: any) {
  const [captcha, setCaptcha] = useState<string>();
  const authService = useJbHttpAuthService()
  const history = useHistory()
  const keyCaptcha = useEnvHook().getRecaptchaKey();

  function onSubmit(values: any) {
    authService.authUser(values).then(res => {
      history.push('/admin')
    }).catch((err) => {
      if (err.response) {
        if (err.response.data.message === 'invalid_credentials_exception') {
          message.error('Usuário ou senha inválidos')
        } else {
          message.error('Ocorreu um erro ao tentar realizar o login')
        }
      }
    })
  }

  return (
    <div style={{
      width: '100%',
      height: '100vh',
      backgroundImage: 'url(bg-login.png)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }}>
      <div style={{
        width: '450px',
        padding: '30px 20px 40px 20px',
        borderRadius: '5px',
        marginTop: '50px'
      }}>
        <img src={logo} style={{
          width: '100%',
          marginBottom: '10px'
        }} />
        <Form size={'large'} onFinish={onSubmit} style={{padding: '25px'}}>
          <Form.Item name={'username'}>
            <Input placeholder={'USUÁRIO'} size={'large'} prefix={<UserOutlined/>}/>
          </Form.Item>
          <Form.Item name={'password'}>
            <Input.Password placeholder={'SENHA'} size={'large'} prefix={<KeyOutlined/>}/>
          </Form.Item>
          {
            keyCaptcha && (
              <ReCAPTCHA
                onChange={token => token && setCaptcha(token)}
                sitekey={keyCaptcha}
                theme={'dark'}
                size={'normal'}
              />
            )
          }
          
          <Button
            disabled={!captcha}
            htmlType={'submit'}
            type={'primary'}
            size={'large'}
            style={{ marginTop: 20 }}
            block
          >
            ENTRAR
          </Button>
          <Button
            htmlType={'submit'}
            type={'link'}
            size={'large'}
            style={{ marginTop: 20 }}
            block
            onClick={() => history.push('/auth/forget-password')}
          >
            Esqueci minha senha
          </Button>
      </Form>
    </div>
    
    </div>
)
}
