import {Popover, Tag} from "antd";
import {AttendanceStatusVo} from "../domain/vo/attendance-status.vo";
import {useDatetimeUtil} from "../../../../../core/util/datetime.util";

export function AttendanceStatusComponent(props: {
  status: AttendanceStatusVo | string,
  suspensionDeadline?: string | Date
}) {
  const {formDateInPtBr} = useDatetimeUtil();
  switch (props.status) {
    case 'OPENED':
      return <Tag color={'blue'}>Aberto</Tag>;
    case 'CONCLUDED':
      return <Tag color={'green'}>Concluído</Tag>;
    case 'STOPPED':
      if (props.suspensionDeadline) {
        return <Tag>Suspenso até {formDateInPtBr(props.suspensionDeadline)} </Tag>
      }
      return <Tag>Suspenso</Tag>;
    default:
      return <Tag>{props.status}</Tag>
  }
}
