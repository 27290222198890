import {useHistory} from "react-router-dom";
import {useContext} from "react";
import {AdminApplicationContext} from "../../../../core/context/admin-application.context";

export function useCustomNavigatorHook() {
  const history = useHistory()
  const app = useContext<any>(AdminApplicationContext)
  
  
  function navigate(model: string, page: string) {
    history.push(
      `/${app.applicationPrefix}/${model}/${page}`
    )
  }
  
  return {
    navigate
  }
}