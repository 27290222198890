import {ShowWorkflowComponent} from "../../../workflow/component/show-workflow.component";
import {WorkflowEntity} from "../../../../../../domain/entity/workflow.entity";
import {Button, Divider, Popconfirm} from "antd";
import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";

export function AttendanceWorkflow(props: {
  workflow: WorkflowEntity,
  attendance: AttendanceEntity,
  onWorkflowDisabled: () => any,
}) {
  const attendanceRepo = useAttendanceRepository();
  
  function disableWorkflow() {
    attendanceRepo.edit(props.attendance.id, {
      workflowDisabled: true
    }).then(
      () => props.onWorkflowDisabled()
    )
  }
  
  return (
    <>
      <ShowWorkflowComponent workflowId={props.workflow.id} />
      <Divider />
      <div style={{textAlign: 'right'}}>
        <Popconfirm
          title={'Deseja Desabilitar o workflow nesse Atednimento ?'}
          onConfirm={disableWorkflow}
        >
          
          <Button  type={'primary'}>
            Desvincular Workflow
          </Button>
        </Popconfirm>
      </div>
    </>
  )
}