import logo from "../../../../media/logo_juristtabusiness_branca.png";
import React from "react";

export function LogoCoreComponent() {
  return (
    <div className="logo" >
      <img alt={'logo'} src={logo} style={{width: '100%'}}/>
    </div>
  )
}
