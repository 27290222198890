import {JbPageHeaderComponent} from "../../core/component/jb-page-header.component";
import {Button, Col, Descriptions, Divider, Empty, Form, Input, Row, Select} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {useJbHttpTjService} from "../../../../core/service/jb-http-tj.service";
import {useEffect, useState} from "react";
import {DescribeSearchComponent} from "./component/describe-search.component";
import {useProcessCodeUtil} from "../../../../core/util/process-code.util";
import {MaskedInput} from "antd-mask-input";

export function SearchProcessModule() {
  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [processCode, setProcessCode] = useState<string>();
  const [processNumber, setProcessNumber] = useState<string>();
  const [segment, setSegment] = useState<string>();
  const [processYear, setProcessYear] = useState<string>();
  const {getTRByProcessCode, decode} = useProcessCodeUtil();
  const {search, loading} = useJbHttpTjService();
  
  useEffect(() => {
  }, []);
  
  // 'tjmg', '50055501120178130145'
  function searchData(sg: string, processNumber: string) {
    search(sg, processNumber).then(res => {
      if (res.length) setData(res[0])
      else setData(undefined);
    })
  }
  
  useEffect(() => {
    if (processCode) {
      const courtCode = getTRByProcessCode(processCode);
      if (courtCode) form.setFieldValue('sg', courtCode.toLowerCase());
      const { processNumber, processYear, segment, segmentCode } = decode(processCode);
      setProcessNumber(processNumber)
      setSegment(`${segmentCode} ${segment}`)
      setProcessYear(processYear)
    } else {
      setProcessNumber(undefined)
      setSegment(undefined)
      setProcessYear(undefined)
    }
  }, [processCode]);
  
  function onSubmit({ sg, processNumber }: any) {
    searchData(sg, processNumber);
  }
  
  return (
    <>
      <JbPageHeaderComponent title={'Consula de Processos no CNJ'} />
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onSubmit}
      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              label={'Número do Processo'}
              name={'processNumber'}
              rules={[{ required: true }]}
            >
              <MaskedInput
                onBlur={(evt) => {
                  setProcessCode(evt.target.value);
                }}
                mask={'0000000-00.0000.0.00.0000'}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name={'sg'} label={'Selecione a origem do Processo'}  rules={[{ required: true }]}>
              <Select options={STATIC_OPTIONS} />
            </Form.Item>
          </Col>
        </Row>
        {
          processCode && (
            <Row>
              <Col span={24}>
                <Descriptions layout={'vertical'} bordered>
                  <Descriptions.Item label={'Número do Processo'}>
                    {processNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label={'Ano do Processo'}>
                    {processYear}
                  </Descriptions.Item>
                  <Descriptions.Item label={'Segmento'}>
                    {segment}
                  </Descriptions.Item>
                </Descriptions>
                <br/>
              </Col>
            </Row>
          )
        }
        <Row justify={'end'}>
          <Col>
            <Button
              htmlType={'submit'}
              type={'ghost'}
              icon={<SearchOutlined />}
              shape={'round'}
              loading={loading}
            >
              Pesquisar
            </Button>
          </Col>
        </Row>
      </Form>
      {
        !!data ? (
          <>
            <Divider/>
            <DescribeSearchComponent data={data} />
          </>
        ) : (
          typeof data === 'undefined' ? (
            <Empty description={'Nenhum dado econtrado'} />
          ) : null
        )
      }
    </>
  )
}

const STATIC_OPTIONS = [
  {
    label: 'Tribunais Superiores',
    options: [
      {
        label: 'Tribunal Superior do Trabalho',
        value: 'https://api-publica.datajud.cnj.jus.br/api_publica_tst/_search'
      },
      {
        label: 'Tribunal Superior Eleitoral',
        value: 'https://api-publica.datajud.cnj.jus.br/api_publica_tse/_search'
      },
      {
        label: 'Tribunal Superior de Justiça',
        value: 'https://api-publica.datajud.cnj.jus.br/api_publica_stj/_search'
      },
      {
        label: 'Tribunal Superior Militar',
        value: 'https://api-publica.datajud.cnj.jus.br/api_publica_stm/_search'
      },
    ]
  },
  {
    label: 'Justiça Federal',
    options: [
      {
        label: 'Tribunal Regional Federal da 1ª Região',
        value: 'trf1',
      },
      {
        label: 'Tribunal Regional Federal da 2ª Região',
        value: 'trf2',
      },
      {
        label: 'Tribunal Regional Federal da 3ª Região',
        value: 'trf3',
      },
      {
        label: 'Tribunal Regional Federal da 4ª Região',
        value: 'trf4',
      },
      {
        label: 'Tribunal Regional Federal da 5ª Região',
        value: 'trf5',
      },
      {
        label: 'Tribunal Regional Federal da 6ª Região',
        value: 'trf6',
      }
    ]
  },
  {
    label: 'Justiça Estdual',
    options: [
      {
        label: 'Tribunal de Justiça do Acre',
        value: 'tjac',
      },
      {
        label: 'Tribunal de Justiça do Alagoas',
        value: 'tjal',
      },
      {
        label: 'Tribunal de Justiça do Amazonas',
        value: 'tjam',
      },
      {
        label: 'Tribunal de Justiça do Amapá',
        value: 'tjap',
      },
      {
        label: 'Tribunal de Justiça da Bahia',
        value: 'tjba',
      },
      {
        label: 'Tribunal de Justiça da Ceará',
        value: 'tjce',
      },
      {
        label: 'Tribunal de Justiça da Distrito Federal e Territórios',
        value: 'tjdft',
      },
      {
        label: 'Tribunal de Justiça da Espirito Santo',
        value: 'tjes',
      },
      {
        label: 'Tribunal de Justiça de Goiás',
        value: 'tjgo',
      },
      {
        label: 'Tribunal de Justiça do Maranhão',
        value: 'tjma',
      },
      {
        label: 'Tribunal de Justiça de Minas Gerais',
        value: 'tjmg',
      },
      {
        label: 'Tribunal de Justiça do Mato Grosso',
        value: 'tjms',
      },
      {
        label: 'Tribunal de Justiça do Pará',
        value: 'tjpa',
      },
      {
        label: 'Tribunal de Justiça da Paraíba',
        value: 'tjpb',
      },
      {
        label: 'Tribunal de Justiça de Pernambuco',
        value: 'tjpe'
      },
      {
        label: 'Tribunal de Justiça do Piauí',
        value: 'tjpi',
      },
      {
        label: 'Tribunal de Justiça do Paraná',
        value: 'tjpr'
      },
      {
        label: 'Tribunal de Justiça do Rio de Janeiro',
        value: 'tjrj'
      },
      {
        label: 'Tribunal de Justiça do Rio Grande do Norte',
        value: 'tjrn'
      },
      {
        label: 'Tribunal de Justiça de Rondônia',
        value: 'tjro'
      },
      {
        label: 'Tribunal de Justiça de Roraima',
        value: 'tjrr',
      },
      {
        label: 'Tribunal de Justiça do Rio Grande do Sul',
        value: 'tjrs'
      },
      {
        label: 'Tribunal de Justiça de Santa Catarina',
        value: 'tjsc',
      },
      {
        label: 'Tribunal de Justiça de Sergipe',
        value: 'tjse'
      },
      {
        label: 'Tribunal de Justiça de São Paulo',
        value: 'tjsp'
      },
      {
        label: 'Tribunal de Justiça do Tocantins',
        value: 'tjto'
      }
    ]
  },
  {
    label: 'Justiça do Trabalho',
    options: [
      {
        label: 'Tribunal Regional do Trabalho da 1ª Região',
        value: 'trt1'
      },
      {
        label: 'Tribunal Regional do Trabalho da 2ª Região',
        value: 'trt2'
      },
      {
        label: 'Tribunal Regional do Trabalho da 3ª Região',
        value: 'trt3'
      },
      {
        label: 'Tribunal Regional do Trabalho da 4ª Região',
        value: 'trt4'
      },
      {
        label: 'Tribunal Regional do Trabalho da 5ª Região',
        value: 'trt5'
      },
      {
        label: 'Tribunal Regional do Trabalho da 6ª Região',
        value: 'trt6'
      },
      {
        label: 'Tribunal Regional do Trabalho da 7ª Região',
        value: 'trt7'
      },
      {
        label: 'Tribunal Regional do Trabalho da 8ª Região',
        value: 'trt8'
      },
      {
        label: 'Tribunal Regional do Trabalho da 9ª Região',
        value: 'trt9'
      },
      {
        label: 'Tribunal Regional do Trabalho da 10ª Região',
        value: 'trt10'
      },
      {
        label: 'Tribunal Regional do Trabalho da 11ª Região',
        value: 'trt11'
      },
      {
        label: 'Tribunal Regional do Trabalho da 12ª Região',
        value: 'trt12'
      },
      {
        label: 'Tribunal Regional do Trabalho da 13ª Região',
        value: 'trt13'
      },
      {
        label: 'Tribunal Regional do Trabalho da 14ª Região',
        value: 'trt14'
      },
      {
        label: 'Tribunal Regional do Trabalho da 15ª Região',
        value: 'trt15'
      },
      {
        label: 'Tribunal Regional do Trabalho da 16ª Região',
        value: 'trt16'
      },
      {
        label: 'Tribunal Regional do Trabalho da 17ª Região',
        value: 'trt17'
      },
      {
        label: 'Tribunal Regional do Trabalho da 18ª Região',
        value: 'trt18'
      },
      {
        label: 'Tribunal Regional do Trabalho da 19ª Região',
        value: 'trt19'
      },
      {
        label: 'Tribunal Regional do Trabalho da 20ª Região',
        value: 'trt20'
      },
      {
        label: 'Tribunal Regional do Trabalho da 21ª Região',
        value: 'trt21'
      },
      {
        label: 'Tribunal Regional do Trabalho da 23ª Região',
        value: 'trt22'
      },
      {
        label: 'Tribunal Regional do Trabalho da 23ª Região',
        value: 'trt23'
      },
      {
        label: 'Tribunal Regional do Trabalho da 24ª Região',
        value: 'trt24'
      },
    ]
  },
  {
    label: 'Justiça Eleitoral',
    options: [
      {
        label: 'Tribunal Regional Eleitoral do Acre',
        value: 'tre-ac',
      },
      {
        label: 'Tribunal Regional Eleitoral do Alagoas',
        value: 'tre-al',
      },
      {
        label: 'Tribunal Regional Eleitoral do Amazonas',
        value: 'tre-am',
      },
      {
        label: 'Tribunal Regional Eleitoral do Amapá',
        value: 'tre-ap',
      },
      {
        label: 'Tribunal Regional Eleitoral da Bahia',
        value: 'tre-ba',
      },
      {
        label: 'Tribunal Regional Eleitoral da Ceará',
        value: 'tre-ce',
      },
      {
        label: 'Tribunal Regional Eleitoral da Distrito Federal e Territórios',
        value: 'tre-dft',
      },
      {
        label: 'Tribunal Regional Eleitoral da Espirito Santo',
        value: 'tre-es',
      },
      {
        label: 'Tribunal Regional Eleitoral de Goiás',
        value: 'tre-go',
      },
      {
        label: 'Tribunal Regional Eleitoral do Maranhão',
        value: 'tre-ma',
      },
      {
        label: 'Tribunal Regional Eleitoral de Minas Gerais',
        value: 'tre-mg',
      },
      {
        label: 'Tribunal Regional Eleitoral do Mato Grosso',
        value: 'tre-ms',
      },
      {
        label: 'Tribunal Regional Eleitoral do Pará',
        value: 'tre-pa',
      },
      {
        label: 'Tribunal Regional Eleitoral da Paraíba',
        value: 'tre-pb',
      },
      {
        label: 'Tribunal Regional Eleitoral de Pernambuco',
        value: 'tre-pe'
      },
      {
        label: 'Tribunal Regional Eleitoral do Piauí',
        value: 'tre-pi',
      },
      {
        label: 'Tribunal Regional Eleitoral do Paraná',
        value: 'tre-pr'
      },
      {
        label: 'Tribunal Regional Eleitoral do Rio de Janeiro',
        value: 'tre-rj'
      },
      {
        label: 'Tribunal Regional Eleitoral do Rio Grande do Norte',
        value: 'tre-rn'
      },
      {
        label: 'Tribunal Regional Eleitoral de Rondônia',
        value: 'tre-ro'
      },
      {
        label: 'Tribunal Regional Eleitoral de Roraima',
        value: 'tre-rr',
      },
      {
        label: 'Tribunal Regional Eleitoral do Rio Grande do Sul',
        value: 'tre-rs'
      },
      {
        label: 'Tribunal Regional Eleitoral de Santa Catarina',
        value: 'tre-sc',
      },
      {
        label: 'Tribunal Regional Eleitoral de Sergipe',
        value: 'tre-se'
      },
      {
        label: 'Tribunal Regional Eleitoral de São Paulo',
        value: 'tre-sp'
      },
      {
        label: 'Tribunal Regional Eleitoral do Tocantins',
        value: 'tre-to'
      }
    ]
  },
  {
    label: 'Justiça Militar',
    options: [
      {
        label: 'Tribunal Justiça Militar de Minas Gerais',
        value: 'tjmmg'
      },
      {
        label: 'Tribunal Justiça Militar do Rio Grande do Su',
        value: 'tjmmrs'
      },
      {
        label: 'Tribunal Justiça Militar de São Paulo',
        value: 'tjmsp'
      },
    ]
  }
]