import {StepTypeEntity} from "../../../../../domain/entity/step-type.entity";
import {Button, List} from "antd";
import {DeleteOutlined, DownloadOutlined, FileOutlined} from "@ant-design/icons";
import {useStepTypeRepository} from "../../../../../domain/repository/step-type.repository";

export function ListStepTypeMediaComponent(props: {
  stepType: StepTypeEntity,
  loading: boolean,
  onMediaRemoved?: () => any,
}) {
  const stepTypeRepository = useStepTypeRepository();
  
  function removeMedia(id: string, path: string) {
    return stepTypeRepository.removeMedia(
      id,
      path
    ).then(() => props.onMediaRemoved ? props.onMediaRemoved() : null)
  }
  
  
  return (
    <List
      dataSource={props.stepType?.medias ?? []}
      loading={props.loading || stepTypeRepository.loading}
      renderItem={item => (
        <List.Item
          key={item.path}
          extra={[
            <Button
              onClick={() => stepTypeRepository.downloadMedia(
                item.path,
                item.name,
                true,
                item.mimetype
              )}
              icon={<DownloadOutlined />}
              type='text'
            >
              Download
            </Button>,
            props.onMediaRemoved ? (
              <Button
                icon={<DeleteOutlined />}
                type='text'
                onClick={() => removeMedia(props.stepType?.id ?? '', item.path)}
              >
                Remover
              </Button>
            ) : null,
          ]}
        >
          <List.Item.Meta
            avatar={<FileOutlined />}
            title={item.name}
          />
        </List.Item>
      )}
    />
  )
}
