import {useJbHttpCrudService} from "../../../../../../core/service/jb-http-crud.service";
import {AttendanceAttachment, AttendanceEntity} from "../entity/attendance.entity";
import { AttendanceStepEntity } from "../entity/attendance-step.entity";
import { BaseResponse } from "../../../../../../core/common/base-response.common";
import {UserEntity} from "../entity/user.entity";
import {AttendancePartEntity} from "../entity/attendance-part.entity";
import axios from "axios";
import {useJbTokenStorageService} from "../../../../../../core/service/jb-token-storage.service";
import {AttendanceStatusVo} from "../vo/attendance-status.vo";
import {AttendanceStepCommentEntity} from "../entity/attendance.step.comment.entity";
import {SearchableAttendanceDto} from "../../../../../../domain/entity/searchable-attendance.dto";

export function useAttendanceRepository() {
  const tokenData = useJbTokenStorageService();
  const crud = useJbHttpCrudService<AttendanceEntity>('attendance')

  function addStep(
    values: Partial<AttendanceStepEntity>
  ): Promise<BaseResponse<AttendanceStepEntity>> {
    return crud.post(
      `${crud.resourceName}/step`,
      values
    )
  }

  function editStep(
    attendanceId: string,
    attendanceStepId: string,
    values: Partial<AttendanceStepEntity>
  ): Promise<BaseResponse<AttendanceStepEntity>> {
    return crud.put(
      `${crud.resourceName}/${attendanceId}/step/${attendanceStepId}`,
      values
    )
  }

  function finishStep(
    attendanceId: string,
    attendanceStepId: string,
  ): Promise<BaseResponse<AttendanceStepEntity>> {
    return crud.post(
      `${crud.resourceName}/${attendanceId}/step/${attendanceStepId}/finish`,
      {}
    )
  }

  function listSteps(attendanceId: string): Promise<BaseResponse<AttendanceStepEntity[]>> {
    return crud.get(`${crud.resourceName}/${attendanceId}/steps`)
  }

  function listOwners(attendanceId: string): Promise<BaseResponse<UserEntity[]>> {
    return crud.get(
      `${crud.resourceName}/${attendanceId}/owners`
    )
  }

  function listAttachments(attendanceId: string): Promise<BaseResponse<AttendanceAttachment[]>> {
    return crud.get(
      `${crud.resourceName}/${attendanceId}/attachments`
    )
  }

  function toggleOwner(args: { attendanceId: string, userId: string }): Promise<BaseResponse<any>> {
    return crud.post(
      `${crud.resourceName}/toggle-owner`,
      args
    )
  }

  function addPart(attendanceId: string, part: AttendancePartEntity): Promise<BaseResponse<AttendancePartEntity>> {
    return crud.post(
      `${crud.resourceName}/${attendanceId}/part`,
      part
    )
  }

  function editPart(attendanceId: string, partId: string, part: AttendancePartEntity): Promise<BaseResponse<AttendancePartEntity>> {
    return crud.put(
      `${crud.resourceName}/${attendanceId}/part/${partId}`,
      part
    )
  }


  function removePart(attendanceId: string, partId: string): Promise<BaseResponse<AttendancePartEntity>> {
    return crud.delete(
      `${crud.resourceName}/${attendanceId}/part/${partId}`,
    )
  }

  function removeAttachment(attendanceId: string, attachmentId: string): Promise<BaseResponse<AttendanceEntity>> {
    return crud.delete(
      `${crud.resourceName}/${attendanceId}/remove-attachment`,
      {
        attendanceId, attachmentId
      }
    )
  }
  
  function toggleAttachment(attendanceId: string, attachmentId: string): Promise<BaseResponse<AttendanceEntity>> {
    return crud.post(
      `${crud.resourceName}/${attendanceId}/attachment/${attachmentId}/toggle-public`,
      { }
    )
  }
  
  function uploadAttachment(attendanceId: string, file: File, isPublic?: boolean) {
    const formData = new FormData()
    formData.set('file', file)
    return crud.put(
      `${crud.resourceName}/${attendanceId}/save-attachment?isPublic=${isPublic === true ? 'true' : 'false'}`,
      formData
    )
  }
  
  function downloadAttachment(path: string, fileName: string, openNewTab?: boolean, mimeType?: string) {
    return axios.get(`${crud.baseUrl}/uploads/${path}`, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${tokenData.getToken()}`
      }
    })
      .then(response => {
        if (response.statusText === 'OK') {
          return response.data;
        }
        return Promise.reject(response.statusText);
      })
      .then(response => {
        const url = window.URL.createObjectURL(
          new Blob([response], { type: mimeType})
        );
        if (openNewTab) {
          return window.open(url, '_blank')
        }
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
  }
  
  function changeStatus(
    attendanceId: string,
    statusTo: AttendanceStatusVo,
    suspensionDeadline?: Date,
  ): Promise<BaseResponse<AttendanceEntity>> {
    return crud.post(
      `${crud.resourceName}/change-status`,
      { attendanceId, statusTo, suspensionDeadline }
    )
  }
  
  function listStepComments(
    attendanceId: string,
    stepId: string
  ): Promise<BaseResponse<AttendanceStepCommentEntity[]>> {
    return crud.get(
      `${crud.resourceName}/${attendanceId}/step/${stepId}/comments`
    )
  }
  
  function listMyAttendances(): Promise<BaseResponse<AttendanceEntity[]>> {
    return crud.get(
      `${crud.resourceName}/my-attendances`
    )
  }
  
  function addStepComments(
    attendanceId: string,
    stepId: string,
    data: Partial<AttendanceStepCommentEntity>
  ): Promise<AttendanceStepCommentEntity> {
    return crud.post(
      `${crud.resourceName}/${attendanceId}/step/${stepId}/comment`,
      data
    )
  }
  
  function list(args: {
    limit?: number,
    page?: number,
    filter?: {
      status?: string,
      attendanceCode?: string,
      clientId?: string,
    }
  }) : Promise<BaseResponse<{ result: AttendanceEntity[], total: number}>> {
    const queryParameters = [];
    const { limit, page, filter } = args;
    if (limit) queryParameters.push(`limit=${limit}`)
    if (page) queryParameters.push(`page=${page}`)
    if (filter?.status) {
      queryParameters.push(`status=${filter.status}`);
    }
    if (filter?.clientId) {
      queryParameters.push(`clientId=${filter.clientId}`);
    }
    if (filter?.attendanceCode) {
      queryParameters.push(`attendanceCode=${filter.attendanceCode}`);
    }
    return crud.get(
      `${crud.resourceName}?${queryParameters.join('&')}`
    )
  }

  function searchAttendances(term: string): Promise<BaseResponse<SearchableAttendanceDto[]>> {
    return crud.get(
      `${crud.resourceName}/search?term=${term}`
    )
  }
  
  return {
    ...crud,
    addStep,
    editStep,
    listSteps,
    listOwners,
    toggleOwner,
    addPart,
    editPart,
    removePart,
    listAttachments,
    uploadAttachment,
    downloadAttachment,
    removeAttachment,
    changeStatus,
    finishStep,
    listStepComments,
    addStepComments,
    listMyAttendances,
    list,
    toggleAttachment,
    searchAttendances
  }
}
