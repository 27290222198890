import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useEffect, useState} from "react";
import {useProcessTypeRepository} from "../domain/repository/process-type.repository";
import {FormProcessTypeComponent} from "../component/form.process-type.component";
import {ProcessTypeEntity} from "../domain/entity/process-type.entity";
import {useParams} from "react-router-dom";
import {FormProcessSubtypeComponent} from "../component/form.process-subtype.component";
import {JbTabComponent, JbTabsComponent} from "../../../core/component/jb-tabs.component";

export function EditProcessTypePage(props: ModulePageProps) {
  const [processType, setProcessType] = useState<ProcessTypeEntity>()
  const processTypeRepo = useProcessTypeRepository()
  const { id: processTypeId } = useParams<any>();

  useEffect(() => {
    processTypeId && processTypeRepo.find(processTypeId).then(res => setProcessType(res.data))
  }, [processTypeId])

  function onEdit(values: any) {
    if (!values.integrationEnabled) {
      values.integrationEnabled = false
    }
    return processTypeRepo.edit(processTypeId, values).then(res => {
      props.navigator('list')
    })
  }

  return (
    <>
      <JbPageHeaderComponent
        title={`Parametrizações / Tipo de Process / ${processType?.name}`}
        description={'Cadastrar Tipo de Processo'}
      />
      <JbTabsComponent>
        <JbTabComponent title={'Dados Básicos'} key={'1'}>
          <FormProcessTypeComponent
            onSubmit={onEdit}
            values={processType}
          />
        </JbTabComponent>
        <JbTabComponent title={'Sub Tipos'} key={'2'} >
          <FormProcessSubtypeComponent
            processTypeId={processTypeId}
          />
        </JbTabComponent>
      </JbTabsComponent>
    </>
  )
}
