import {Button, Form, Input, message, Upload} from "antd";
import TextArea from "antd/es/input/TextArea";
import {JbFormActionButtonComponent} from "../../../../core/component/jb-form-action-button.component";

const TOTAL_MB = 8;
export function AttendancePublicInteractionFormComponent(props: {
  onSubmit: (values: any) => any,
  onUpload: (values: any) => any,
  triggerOnSubmitWhenUploads?: boolean,
  disableUpload?: boolean,
}) {
  
  const [form] = Form.useForm()
  
  function validateSize(size: number) {
    return size < (TOTAL_MB * 1000000);
  }
  
  function onUpload(file: any) {
    if (!validateSize(file.file.size)) {
      message.error(`O Arquivo excede o limite de tamanho de ${TOTAL_MB}MB`);
    } else {
      props.onUpload(file);
      if (props.triggerOnSubmitWhenUploads) {
        if (form.getFieldValue('content')) {
          form.submit()
        }
      }
    }
  }
  
  function onFinish(values: any) {
    props.onSubmit(values);
    form.resetFields();
  }
  
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      initialValues={{ contentType: 'text' }}
    >
      <Form.Item name={'contentType'}>
        <Input type={'hidden'}/>
      </Form.Item>
      <Form.Item name={'content'} label={'Adicionar Comentário'} rules={[{ required: true }]}>
        <TextArea rows={3} />
      </Form.Item>
      <JbFormActionButtonComponent
        hideCancelButton
        extraActions={[
          props.disableUpload ? null : (
            <Upload
              customRequest={onUpload}
              showUploadList={false}
              accept=".doc,.docx,.pdf,.png,.jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.documen"
            >
              <Button >
                Anexar Documento ({TOTAL_MB}MB)
              </Button>
            </Upload>
          )
        ]}
      />
    </Form>
  )
}