import {Col, Form, Input, Radio, Row} from "antd";
import {ClientEntity} from "../domain/entity/client.entity";
import {useEffect, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {MaskedInput} from "antd-mask-input";

export type ClientFormProps = {
  onSubmit: (values: any) => any,
  values?: Partial<ClientEntity>
}

const MASKS = {
  cnpj: '00.000.000/0000-00',
  cpf: '000.000.000-00'
}

export function ClientForm(props: ClientFormProps) {
  const [form] = Form.useForm()
  const [selectedType, setSelectedType] = useState<string>('F')

  useEffect(() => {
    form.setFieldsValue({ type: selectedType })
    form.setFieldsValue(props.values)
  }, [props?.values])

  function onFinish(values: any) {
    const document = values.document?.replace(/[\.\/\-\_]/g,'');
    props.onSubmit({
      ...values,
      email: values.email ? values.email : undefined,
      phoneNumber: values.phoneNumber?.replace(/[\(\)\-]/g,''),
      document: document !== '' ? document : undefined,
    })
  }
  
  function onChangeType(type: string) {
    form.resetFields(['document'])
    setSelectedType(type)
  }

  return (
    <Form form={form} layout={'vertical'} onFinish={onFinish}>
      <Row gutter={12}>
        <Form.Item label={'Tipo'} name={'type'} required rules={[{ required: true, }]}>
          <Radio.Group
            onChange={e => onChangeType(e.target.value)}
            options={[ { label: 'Pessoa Física', value: 'F'  }, { label: 'Pessoa Jurídica', value: 'J' } ]}
          />
        </Form.Item>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item label={'Nome'} name={'name'} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={'Documento'} name={'document'}>
            <MaskedInput
              mask={selectedType === 'J' ? MASKS.cnpj : MASKS.cpf}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item label={'Email'} name={'email'} rules={[{ type: 'email' }]}>
            <Input type={'email'} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={'Telefone'} name={'phoneNumber'} rules={[{ required: true }]}>
            <MaskedInput mask={'(00)00000-0000'} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={'Observações'} name={'observation'}>
        <TextArea />
      </Form.Item>
      <JbFormActionButtonComponent />
    </Form>
  )
}
