import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {Button, Divider, Form, Typography} from "antd";
import React, {useState} from "react";
import {JbRichTextComponent, JbRichTextComponentField} from "../../../../core/form/jb-rich-text.component";
import {JbFormActionButtonComponent} from "../../../../core/component/jb-form-action-button.component";
import {EditOutlined} from "@ant-design/icons";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";

export function AttendanceDescriptionTab(props: {
  attendance: AttendanceEntity,
  reloadAttendance: () => any,
}) {
  const [mode, setMode] = useState<"show" | 'edit'>('show')
  const attendanceRepo = useAttendanceRepository();
  
  function onEdit(newReport: string) {
    attendanceRepo.edit(props.attendance.id, {
      report: newReport
    }).then(() => props.reloadAttendance().then(() => setMode('show')))
  }
  
  if (mode === 'show') {
    return  (
      <ShowDescription
        html={props.attendance.report ?? ''}
        onEdit={() => setMode('edit')}
      />
    )
  }
  return (
    <EditDescription
      attendance={props.attendance}
      onShow={() => setMode('show')}
      onSubmit={onEdit}
    />
  )
}

function ShowDescription(props: { html: string, onEdit: () => any }) {
  return (
    <>
      <Typography.Paragraph style={{textAlign: 'justify',  whiteSpace: 'pre-wrap' }} >
        <div dangerouslySetInnerHTML={{ __html: props.html}} />
      </Typography.Paragraph>
      <Divider />
      <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          type={'link'}
          shape={'round'}
          icon={<EditOutlined />}
          onClick={props.onEdit}
        >
          Editar
        </Button>
      </div>
    </>
  )
}

function EditDescription(props: {
  attendance: AttendanceEntity,
  onShow: () => any,
  onSubmit: (report: string) => any
}) {
  return (
    <Form initialValues={{ report: props.attendance.report }} onFinish={values => props.onSubmit(values.report)}>
      <JbRichTextComponentField name={'report'} />
      <JbFormActionButtonComponent
        onCancel={() => props.onShow()}
      />
    </Form>
  )
}