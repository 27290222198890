import {WorkflowEntity} from "../../../../../domain/entity/workflow.entity";
import {Descriptions, Divider, List} from "antd";
import {useEffect, useState} from "react";
import {useWorkflowRepository} from "../../../../../domain/repository/workflow.repository";
import {WorkflowTaskEntity} from "../../../../../domain/entity/workflow-task.entity";
import {WorkflowTypeComponent} from "./workflow-type.component";

export function ShowWorkflowComponent(props: {
  workflowId: string,
}) {
  const [workflow, setWorkflow] = useState<WorkflowEntity>();
  const [tasks, setTasks] = useState<WorkflowTaskEntity[]>([])
  const workflowRepo = useWorkflowRepository();
  
  useEffect(() => {
    workflowRepo.find(props.workflowId).then(res => {
      setWorkflow(res.data);
    })
    workflowRepo.listTasks(props.workflowId).then(res => setTasks(res.data))
  }, [props.workflowId]);
  return (
    <>
      <Descriptions layout={'vertical'} bordered>
        <Descriptions.Item label={'Nome'} children={workflow?.name} />
        <Descriptions.Item label={'Tipo'} children={<WorkflowTypeComponent type={workflow?.type ?? ''} /> } />
      </Descriptions>
      <Divider />
      <List
        dataSource={tasks}
        renderItem={(task, idx) => (
          <List.Item>
            <List.Item.Meta
              title={`${idx + 1} - ${task.stepType?.description}`}
              description={`${task.daysNeeded} Dias. ${task.observation}`}
            />
          </List.Item>
        )}
      />
    </>
  )
}