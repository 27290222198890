import {ModulePageProps} from "../../../core/module-pages";
import {ClientForm} from "../component/client.form";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useEffect, useState} from "react";
import {useClientRepository} from "../domain/repository/client.repository";
import {ClientEntity} from "../domain/entity/client.entity";
import {useParams} from "react-router-dom";
import {JbTabComponent, JbTabsComponent} from "../../../core/component/jb-tabs.component";
import {ClientAdditionalDataForm} from "../component/client-additional-data.form";

export function EditClientPage(props: ModulePageProps) {
  const [client, setClient] = useState<ClientEntity>()
  const clientRepo = useClientRepository();
  const { id: clientId } = useParams<any>()

  useEffect(() => {
    if (clientId) {
      clientRepo.find(clientId).then(res => {
        setClient(res.data)
      })
    }
  }, [clientId])

  function createClient(values: any) {
    return clientRepo.edit(clientId, values).then(res => {
      props.navigator('list')
    })
  }
  
  function onSubmit(data: any) {
    // ...
  }
  
  
  return (
    <>
      <JbPageHeaderComponent
        title={`Atendimentos / Cliente / Novo Cliente +`}
        description={'Gestão dos clientes'}
        hideDivider
      />
      <JbTabsComponent >
        <JbTabComponent title={'Dados Básicos'} key={'1'}>
          <ClientForm
            onSubmit={createClient}
            values={client}
          />
        </JbTabComponent>
        <JbTabComponent title={'Dados Adicionais'} key={'2'}>
          {
            client && (
              <ClientAdditionalDataForm
                client={client}
                onSubmit={onSubmit}
              />
            )
          }
        </JbTabComponent>
      </JbTabsComponent>
    </>

  )
}
