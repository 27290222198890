import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {UserEntity} from "../entity/user.entity";
import {BaseResponse} from "../../core/common/base-response.common";

export function useUserRepository() {
  const crud = useJbHttpCrudService<UserEntity>('user')
  function checkUser(field: string, value: string): Promise<BaseResponse<UserEntity>> {
    return crud.get(
      `${crud.resourceName}/check/${field}/${value}`
    )
  }
  
  function changePassword(userId: string, newPassword: string) {
    return crud.put(
      `${crud.resourceName}/${userId}/change-password`,
      { newPassword }
    )
  }
  
  function changeMyPassword(currentPassword: string, newPassword: string) {
    return crud.put(
      `${crud.resourceName}/change-my-password`,
      {
        confirmCurrentPassword: currentPassword,
        newPassword
      }
    )
  }
  function resetUserPassword(userId: string) {
    return crud.post(
      `${crud.resourceName}/${userId}/reset-user-password`,
      {}
    )
  }
  
  return {
    ...crud,
    checkUser,
    changeMyPassword,
    changePassword,
    resetUserPassword
  }
}
