import {RcFile} from "antd/es/upload";
import {Button, message, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {formatBytes} from "../../../../core/common/util";


const TOTAL_MB = 50;

export function JbDropzoneComponent(props: {
  onDrop: (file: RcFile) => any,
  description: string,
  bytesLimit?: number,
}) {
  const [size, setSize] = useState(props.bytesLimit ?? 0);
  useEffect(() => {
    if (props.bytesLimit) {
      setSize(props.bytesLimit)
    } else {
      setSize(10000000);
    }
  }, [props.bytesLimit])
  
  function validateSize(size: number) {
    return size < (TOTAL_MB * 1000000);
  }
  
  return (
    <Upload.Dragger
      accept=".doc,.docx,.pdf,.png,.jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.documen"
      beforeUpload={(file) => {
        if (validateSize(file.size)) {
          props.onDrop(file)
        } else {
          message.error('Tamnho do arquivo excede o limite !')
        }
        
        return false;
      }}
      onChange={() => {
        return false;
      }}
      onDownload={() => {
        return false;
      }}
      onPreview={() => {
        return false;
      }}
      itemRender={() => {
        return null;
      }}
    >
      <p>{props.description}</p>
      <Button
        type="link" icon={<UploadOutlined />}
      >
        Upload (Tamanho Máximo {TOTAL_MB}MB)
      </Button>
    </Upload.Dragger>
  )
  
}