import {Descriptions, Divider, Table} from "antd";
import {useDatetimeUtil} from "../../../../../core/util/datetime.util";
import {useMemo} from "react";

export function DescribeSearchComponent(props: {  data: any }) {
  const { data } = props;
  const {formDateTimeInPtBr} = useDatetimeUtil()
  
  const tasks = useMemo(() => {
    return data._source?.movimentos?.sort((a:any,b:any) => {
      if (new Date(a.dataHora) < new Date(b.dataHora)) return 1;
      if (new Date(a.dataHora) > new Date(b.dataHora)) return -1;
      return 0;
    }) ?? []
  }, [data]);
  
  return (
    <>
      <Descriptions title={'Informações Básicaas'} bordered layout={'vertical'}>
        <Descriptions.Item label={'Classe'}>
          {data?._source?.classe?.codigo} - {data?._source?.classe?.nome}
        </Descriptions.Item>
        <Descriptions.Item label={'Data Ajuizamento'}>
          {formDateTimeInPtBr(data?._source?.dataAjuizamento)}
        </Descriptions.Item>
        <Descriptions.Item label={'Ultima Atualização'}>
          {formDateTimeInPtBr(data?._source?.dataHoraUltimaAtualizacao)}
        </Descriptions.Item>
        <Descriptions.Item label={'Orgao Julgador'}>
          {data?._source?.orgaoJulgador?.nome}
        </Descriptions.Item>
        <Descriptions.Item label={'Tribunal'}>
          {data?._source?.tribunal}
        </Descriptions.Item>
        <Descriptions.Item label={'Assuntos'}>
          {data?._source?.assuntos?.map((w: any) => w.nome)?.join(', ')}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Table
        columns={[
          {
            title: 'Movimento',
            dataIndex: 'nome',
          },
          {
            title: 'Data Hora',
            dataIndex: 'dataHora',
            render: formDateTimeInPtBr
          }
        ]}
        dataSource={tasks}
      />
    </>
  )
}