import {ItemFormComponent} from "../component/item..form.component";
import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {FinanceItemEntity} from "../../../../../domain/entity/finance-item.entity";
import {useFinanceItemRepository} from "../../../../../domain/repository/finance-item.repository";

export function EditItemPage(props: ModulePageProps) {
  const [item, setItem] = useState<FinanceItemEntity>();
  const { id } = useParams<any>()
  
  const itemRepo = useFinanceItemRepository();
  
  useEffect(() => {
    itemRepo.find(id).then(res => setItem(res.data));
  }, [id]);
  
  function onSave(values: any) {
    itemRepo.edit(id, values).then(() => {
      props.navigator('list')
    })
  }
  
  return (
    <>
      <JbPageHeaderComponent title={'Editar Item ' + item?.name} />
      <ItemFormComponent
        onSubmit={onSave}
        currentValues={item}
        onCancel={() => props.navigator('list')}
      />
    </>
  )
}