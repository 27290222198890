import axios from "axios";
import { BaseResponse } from "../../core/common/base-response.common";
import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import { useJbTokenStorageService } from "../../core/service/jb-token-storage.service";
import {StepTypeEntity} from "../entity/step-type.entity";

export function useStepTypeRepository() {
  const crudHttpService = useJbHttpCrudService<StepTypeEntity>('step-type')
  const tokenData = useJbTokenStorageService()
  
  function getBseUrlUploadMedia(stepTypeId: string): string {
    return `${crudHttpService.baseUrl}/step-type/${stepTypeId}/upload-media`
  }

  function uploadMedia(stepTypeId: string, medias: File) { 
    const formData = new FormData()
    formData.set('medias', medias)
    return crudHttpService.put(
      `${crudHttpService.resourceName}/${stepTypeId}/upload-media`,
      formData
    )
  }

  function getBaseUrlDownloadMedia(path: string): string {
    return `${crudHttpService.baseUrl}/${path}`
  }

  function downloadMedia(path: string, fileName: string, openNewTab?: boolean, mimeType?: string) {
    crudHttpService.setLoading(true);
    return axios.get(`${crudHttpService.baseUrl}/uploads/${path}`, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${tokenData.getToken()}`
      }
    })
      .then(response => {
        if (response.statusText === 'OK') {
          return response.data;
        }
        Promise.reject(response.statusText);
      })
      .then(response => {
        const url = window.URL.createObjectURL(
          new Blob([response], { type: mimeType})
        );
        if (openNewTab) {
          return window.open(url, '_blank')
        }
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }).finally(() => {
        crudHttpService.setLoading(false)
      })
  }

  function removeMedia(stepTypeId: string, path: string): Promise<BaseResponse<StepTypeEntity>> {
    return crudHttpService.delete(
      `${crudHttpService.resourceName}/${stepTypeId}/remove-media`, 
      { path }
    )
  }

  function saveLinks(
    stepTypeId: string, 
    links:Array<{ title: string, link: string, type: string}>
  ): Promise<BaseResponse<StepTypeEntity>> {
    return crudHttpService.put(
      `${crudHttpService.resourceName}/${stepTypeId}/links`,
      { links }
    )
  }

  return {
    ...crudHttpService,
    getBseUrlUploadMedia,
    getBaseUrlDownloadMedia,
    uploadMedia,
    downloadMedia,
    removeMedia,
    saveLinks,
  }
}
