import {useJbHttpCrudService} from "../../../../../../core/service/jb-http-crud.service";
import {ClientEntity} from "../entity/client.entity";
import {ClientIndividualEntity} from "../entity/client-individual.entity";
import {BaseResponse, BaseSearchResponse} from "../../../../../../core/common/base-response.common";
import {ClientLegalEntity} from "../entity/client-legal.entity";
import {useCallback} from "react";

export function useClientRepository() {
  const crud = useJbHttpCrudService<ClientEntity>('client')
  
  const search = useCallback(
    (
      page: number,
      limit: number,
      filter: any,
      sortParams?: { field: string, order: string },
    ): Promise<BaseSearchResponse<ClientEntity>> => {
      return crud.get(
        `${crud.resourceName}/search`,
        null,
        {
          queryParams: {
            page,
            limit,
            sortField: sortParams?.field,
            sortOrder: sortParams?.order,
            ...filter,
          }
        }
    )
  }, [])
  
  function saveIndividualData(clientId: string, individualData: ClientIndividualEntity) {
    return crud.put(
      `${crud.resourceName}/${clientId}/save-individual`,
      JSON.stringify(individualData)
    )
  }
  
  function findIndividualData(clientId: string): Promise<BaseResponse<ClientIndividualEntity>> {
    return crud.get(
      `${crud.resourceName}/${clientId}/find-individual`,
    )
  }
  
  function findLegalData(clientId: string): Promise<BaseResponse<ClientLegalEntity>> {
    return crud.get(
      `${crud.resourceName}/${clientId}/find-legal`,
    )
  }
  
  
  function saveLegalData(clientId: string, entity: ClientLegalEntity): Promise<BaseResponse<ClientLegalEntity>> {
    return crud.put(
      `${crud.resourceName}/${clientId}/save-legal`,
      JSON.stringify(entity),
    )
  }
  
  
  return {
    ...crud,
    saveIndividualData,
    findIndividualData,
    findLegalData,
    saveLegalData,
    search
  }
}
