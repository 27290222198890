import {Form, Input} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {useUserRepository} from "../../../../../domain/repository/user.repository";

const HELP_MESSAGE = 'A senha deverá conter no mínimo 6 caracteres com letras e números.'
export const PASSWORD_PATTERN = "^(?=.*\\d)(?=.*[a-zA-z])(?=.*[a-zA-Z]).{6,}$";
export function ChangeUserForm(props: {
  userId: string,
  onPasswordChanged: () => any,
}) {
  const [form] = Form.useForm();
  const userRepo = useUserRepository();
  
  function onFinish(values: any) {
    const { newPassword, confirmNewPassword } = values;
    if (newPassword !== confirmNewPassword) {
      form.setFields([
        { name: 'confirmNewPassword', errors: ['As senhas informadas não são iguais.'] }
      ])
      return;
    }
    
    userRepo.changePassword(
      props.userId,
      newPassword
    ).then(() => props.onPasswordChanged())
  }
  
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
    >
      <Form.Item
        label={'Nova Senha'}
        name={'newPassword'}
        rules={[{ required: true }]}
        help={HELP_MESSAGE}
      >
        <Input.Password  pattern={PASSWORD_PATTERN} />
      </Form.Item>
      <Form.Item
        label={'Confirmar Nova Senha'}
        name={'confirmNewPassword'}
        rules={[{ required: true }]}
      >
        <Input.Password pattern={PASSWORD_PATTERN}  />
      </Form.Item>
      <JbFormActionButtonComponent />
      
    </Form>
  )
}