import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useEffect, useMemo, useState} from "react";
import {ProcessTypeEntity} from "../domain/entity/process-type.entity";
import {useProcessTypeRepository} from "../domain/repository/process-type.repository";
import {ColumnsType} from "antd/es/table";
import {Table} from "antd";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";

export function ListProcessTypePage(props: ModulePageProps) {
  const [processTypes, setProcessTypes] = useState<ProcessTypeEntity[]>([]);
  const processTypeRepo = useProcessTypeRepository();

  useEffect(() => {
    loadData()
  }, [])
  
  function loadData() {
    return processTypeRepo.list().then((res) => setProcessTypes(res.data))
  }
  function remove(id: string) {
    processTypeRepo.remove(id).then(() => {
      loadData()
    })
  }

  const columns = useMemo<ColumnsType<any>>(() => {
    return [
      {
        key: 1,
        title: 'Nome',
        dataIndex: 'name'
      },
      {
        title: '',
        key: 99,
        align: 'right',
        dataIndex: 'id',
        render: (id) => (
          <JbTableRowActionsComponent
            onEdit={() => props.navigator(`edit/${id}`)}
            onRemove={() => remove(id)}
          />
        )
      }
    ]
  }, [])
  return(
    <>
      <JbPageHeaderComponent
        title={'Parametrizações / Tipo de Process'}
        description={'Gestão dos Tipos de Porcess'}
        onCreateLink={`${props.prefix}/create`}
      />
      <Table
        loading={processTypeRepo.loading}
        dataSource={processTypes}
        columns={columns}
      />
    </>
  )
}
