import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ClientEntity} from "../domain/entity/client.entity";
import {useClientRepository} from "../domain/repository/client.repository";
import {Button, Col, Form, Input, Row, Select, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {DrawerClientAttendancesComponent} from "../component/drawer-client-attendances.component";
import {useHistory} from "react-router-dom";
import {DatabaseOutlined, SearchOutlined} from "@ant-design/icons";
import {JbButtonWithPopover} from "../../../core/button-with-popover";

export function ListClient(props: ModulePageProps) {
  const [clients, setClients] = useState<ClientEntity[]>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState<any>();
  const [sortParams, setSortParams] = useState<{ field: string, order: string }>();
  const [listClientAttendances, setListClientAttendances] = useState<string>();
  const clientRepo = useClientRepository()
  const history = useHistory();

  const loadData = useCallback(() => {
    clientRepo.search(page, limit, filter, sortParams).then(res => {
      setClients(res.data.data);
      setTotal(res.data.total)
    })
  }, [limit, page, filter, sortParams])
  
  useEffect(() => {
    loadData()
  }, [loadData])

  function removeClient(id: string) {
    return clientRepo.remove(id).then(res => {
      clientRepo.list().then(res => setClients(res.data))
    })
  }

  const columns = useMemo<ColumnsType<any>>(() => {
    return [
      {
        key: 1,
        title: 'Nome',
        dataIndex: 'name',
        sorter: true,
      },
      {
        key: 1,
        title: 'Documento',
        dataIndex: 'document',
        sort: true,
      },
      {
        key: 2,
        title: 'Email',
        dataIndex: 'email',
        sort: true,
      },
      {
        key: 3,
        title: 'Telefone',
        dataIndex: 'phoneNumber'
      },
      {
        key: 99,
        title: '',
        dataIndex: 'id',
        align: 'right',
        render: id => (
          <JbTableRowActionsComponent
            onEdit={() => props.navigator(`edit/${id}`)}
            onRemove={() => removeClient(id)}
            extra={[
              <JbButtonWithPopover
                title={'Atendimentos'}
                size={'small'}
                type={'link'}
                icon={<DatabaseOutlined />}
                onClick={() => setListClientAttendances(id)}
              />
            ]}
          />
        )
      }
    ]
  }, [])

  function goToEditAttendance(id: string) {
    const paths = props.prefix.split('/');
    const newPath = paths.slice(0, paths.length - 1);
    newPath.push('attendance')
    newPath.push('wizard')
    newPath.push(id)
    history.push(newPath.join('/'));
  }
  return (
    <>
      <JbPageHeaderComponent
        title={`Atendimentos / Cliente`}
        description={'Gestão dos clientes'}
        onCreateLink={`${props.prefix}/create`}
      />
      <Form onFinish={values => {
        setFilter({ [values.field]: values.value })
      }}>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item name={'field'}>
              <Select placeholder={'Campo'} options={[
                  { label: 'Documento', value: 'document' },
                  { label: 'Nome', value: 'name' },
                  { label: 'Email', value: 'email' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'value'}>
              <Input placeholder={'Pesquisa'} />
            </Form.Item>
          </Col>
          <Col>
            <Button htmlType={'submit'} icon={<SearchOutlined />}>Pesquisar</Button>
          </Col>
        </Row>
      </Form>
      <Table
        loading={clientRepo.loading}
        columns={columns}
        dataSource={clients}
        onChange={(pagination, record, sorter: any) => {
          if (!sorter || !sorter.order) {
            setSortParams(undefined)
          } else {
            setSortParams({
              field: sorter?.field,
              order: sorter?.order
            })
          }
        }}
        pagination={{
          total,
          pageSize: limit,
          onChange: (page,size) => {
            setPage(page);
            setLimit(size);
          }
        }}
      />
      {
        !!listClientAttendances && (
          <DrawerClientAttendancesComponent
            open
            clientId={listClientAttendances as string}
            onEdit={goToEditAttendance}
            onClose={() => setListClientAttendances(undefined)}
          />
        )
      }
    </>
  )
}
