import {ModulePageProps} from "../../../core/module-pages";
import React from "react";
import {AttendanceWizard} from "../component/attendance-wizard";
import {useParams} from "react-router-dom";


export function EditAttendance(props: ModulePageProps) {
  const { attendanceId } = useParams<any>();
  return (
    <AttendanceWizard
      navigator={props.navigator}
      attendanceId={attendanceId}
    />
  )
}
