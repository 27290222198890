import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useEffect, useMemo, useState} from "react";
import {ColumnsType} from "antd/es/table";
import {Table} from "antd";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {CategoryEntity} from "../../../../../domain/entity/category.entity";
import {useCategoryRepository} from "../../../../../domain/repository/category.repository";

export function ListCategoryPage(props: ModulePageProps) {
  const [categories, setCategories] = useState<CategoryEntity[]>([]);
  const categoryRepo = useCategoryRepository();
  
  function loadCategory() {
    categoryRepo.list().then((res) => setCategories(res.data));
  }
  
  useEffect(() => {
    loadCategory()
  }, []);
  
  function onRemove(id: string) {
    categoryRepo.remove(id).then(() => loadCategory());
  }

  const columns = useMemo<ColumnsType<any>>(() => {
    return [
      {
        key: 1,
        title: 'Nome',
        dataIndex: 'name'
      },
      {
        key: 1,
        title: 'Descrição',
        dataIndex: 'description'
      },
      {
        title: '',
        key: 99,
        align: 'right',
        dataIndex: 'id',
        render: (id) => (
          <JbTableRowActionsComponent
            onEdit={() => props.navigator(`edit/${id}`)}
            onRemove={() => onRemove(id)}
          />
        )
      }
    ]
  }, [])
  return(
    <>
      <JbPageHeaderComponent
        title={'Financeiro / Categorias'}
        description={'Gestão das categories financeiras'}
        onCreateLink={`${props.prefix}/create`}
      />
      <Table
        loading={categoryRepo.loading}
        dataSource={categories}
        columns={columns}
      />
    </>
  )
}
