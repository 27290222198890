import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";

export type ModulePageProps = {
  navigator: (uri: string) => any,
  prefix: string
}

export type ModulePagesProps = {
  routes: Array<{ render: (props: ModulePageProps) => any, path: string }>
}

export function ModulePages(props: ModulePagesProps) {
  const { url } = useRouteMatch()
  const history = useHistory()
  function navigator(uri: string) {
    history.push(`${url}/${uri}`)
  }
  return (
    <Switch>
      {
        props.routes.map((route, idx) =>
          <Route
            key={idx}
            path={`${url}/${route.path}`}
            render={() => route.render({ navigator, prefix: url })}
          />
        )
      }
    </Switch>
  )
}
