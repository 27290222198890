import {Button, Drawer, Table} from "antd";
import moment, {Moment} from "moment";
import {useMemo} from "react";
import {AttendanceStepEntity} from "../../../attendance/domain/entity/attendance-step.entity";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";
import {ColumnsType} from "antd/es/table";
import {JbTableRowActionsComponent} from "../../../../core/component/jb-table-row-actions.component";

export function DrawerStepActionComponent(props: {
  currentDate: Moment,
  steps: AttendanceStepEntity[],
  onCancel: () => any,
  onViewStep: (step: AttendanceStepEntity) => any,
}) {
  
  const currentDate = useMemo(
    () => moment(props.currentDate.toISOString().substring(0, 10)),
    [props.currentDate]
  )
  
  const title = useMemo(() => {
    return props.currentDate.toDate().toLocaleDateString()
  }, [props.currentDate])
  
  const filteredStepsExpiring = useMemo(() => {
    return props.steps.filter(w => currentDate.diff(moment(w.endDate.substring(0,10)), 'd') === 0)
  }, [props.steps, currentDate])
  
  const filteredStepsBeginning = useMemo(() => {
    return props.steps.filter(w => currentDate.diff(moment(w.beginDate.substring(0, 10)), 'd') === 0)
  }, [props.steps, currentDate])
  
  
  return (
    <Drawer
      title={title}
      open
      onClose={props.onCancel}
      placement={'bottom'}
      bodyStyle={{padding: 0}}
    >
      <ListSteps steps={[...filteredStepsBeginning, ...filteredStepsExpiring]} onViewItem={props.onViewStep} />
    </Drawer>
  )
}

function ListSteps(props: {
  steps: AttendanceStepEntity[],
  onViewItem: (item: AttendanceStepEntity) => any,
}) {
  const {formDateInPtBr} = useDatetimeUtil()
  const columns = useMemo<ColumnsType<AttendanceStepEntity>>(() => {
    return [
      {
        key: 'clientName',
        dataIndex: 'clientName',
        title: 'Cliente',
      },
      {
        key: 'stepType',
        dataIndex: 'stepType',
        title: 'Movimentação',
        render: item => item.description,
      },
      {
        key: 'startDate',
        dataIndex: 'beginDate',
        title: 'Data de Início',
        render: formDateInPtBr
      },
      {
        key: 'endDate',
        dataIndex: 'endDate',
        title: 'Data de Encerramento',
        render: formDateInPtBr
      },
      {
        key: 'actions',
        dataIndex: 'id',
        render: (_,row) => (
          <JbTableRowActionsComponent
            onShow={() => props.onViewItem(row)}
          />
        )
      }
    ]
  }, [])
  
  return (
    <>
      <Table
        columns={columns}
        dataSource={props.steps}
      />
    </>
  )
  
}