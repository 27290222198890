import {Form, Input, Select} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {useEffect} from "react";

export function TenantFormComponent(props: {
  onSubmit: (values: any) => any,
  values: any,
}) {
  const [form] = Form.useForm();
  
  useEffect(() => {
    if (props.values) {
      form.setFieldsValue(props.values);
    }
  }, [props.values])
  
  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={props.onSubmit}
    >
      <Form.Item name={'name'} label={'Nome'} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={'type'} label={'Tipo Pessoa'} rules={[{ required: true }]}>
        <Select
          options={[
            { label: 'Pessoa Física', value: 'F'},
            { label: 'Pessoa Jurísidca', value: 'J'},
          ]}
        />
      </Form.Item>
      <Form.Item name={'document'} label={'Documento'} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <JbFormActionButtonComponent />
    </Form>
  )
}