import {Button, Divider, Form, Input, List, Modal, Space, Tag} from "antd";
import {EyeOutlined, SearchOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {useAttendanceRepository} from "../../module/attendance/domain/repository/attendance.repository";
import {SearchableAttendanceDto} from "../../../../domain/entity/searchable-attendance.dto";
import {useDatetimeUtil} from "../../../../core/util/datetime.util";
import {AttendanceStatusComponent} from "../../module/attendance/component/attendance-status.component";
import {AttendancePriorityTagComponent} from "../../module/attendance/component/attendance-priority-tag.component";
import {useHistory} from "react-router-dom";

export function ModalSearch(props: {
  open: boolean,
  onClose: () => any,
}) {
  const [form] = Form.useForm();
  const [tags, setTags] = useState<string[]>([]);
  const [results, setResults] = useState<SearchableAttendanceDto[]>([]);
  const attendanceRepo = useAttendanceRepository();
  const { formDateInPtBr } = useDatetimeUtil();
  const history = useHistory();
  
  function onCreateTag(values: any) {
    setTags([values.term])
    form.resetFields();
  }
  
  useEffect(() => {
    attendanceRepo.searchAttendances(tags[0])
      .then(res => setResults(res.data))
  }, [tags]);
  
  function getTitleItem(item: SearchableAttendanceDto) {
    return (
      <>
        <AttendanceStatusComponent status={item.status} />
        <AttendancePriorityTagComponent priority={item.priority} />
        {item.attendanceType} - {item.clientName}
      </>
    )
  }
  
  function goToAttendance(item: SearchableAttendanceDto) {
    history.push(
      { pathname: `/admin/attendance/details/${item.attendanceId}` }
    )
    props.onClose()
  }
  
  return (
    <Modal
      open={props.open}
      footer={null}
      onCancel={props.onClose}
      width={576}
      title={'Pesquisar Atendimentos'}
    >
      <Form onFinish={onCreateTag} form={form}>
        {
          !tags.length && (
            <Form.Item name={'term'}>
              <Input
                size={'large'}
                placeholder={'Pesquise pelo código do atendimento, nome do cliente, tipo de atendimento ou '}
                prefix={<SearchOutlined />}
                bordered={false}
                onSubmit={console.log}
              />
            </Form.Item>
          )
        }
      </Form>
      <Space>
        {tags.map((w,idx) =>
          <Tag closable onClose={(e) => {
            e.preventDefault()
            setTags(tags.filter(sw => w !== sw))
          }} key={idx}>{w}</Tag>
        )}
      </Space>
      
      {
        tags.length ? (
          <List
            loading={attendanceRepo.loading}
            dataSource={results}
            renderItem={item => (
              <List.Item
                actions={[
                  <Button
                    onClick={() => goToAttendance(item)}
                    icon={<EyeOutlined />}
                    type={'primary'}
                  />
                ]}
              >
                <List.Item.Meta
                  title={getTitleItem(item)}
                  description={
                    `Criado em ${formDateInPtBr(item.createdAt)}`
                  }
                />
              </List.Item>
            )}
          />
        ) : ''
      }
    </Modal>
  )
}