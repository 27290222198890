import {useEffect} from "react";

const BEARER_TOKEN_KEY = '7182631'
const REFRESH_TOKEN_KEY = '2118263'
export function useJbTokenStorageService() {
  useEffect(() => {

  }, [])

  function setToken(token: string) {
    token && localStorage.setItem(BEARER_TOKEN_KEY, token)
  }

  function setRefreshToken(token?: string) {
    token && localStorage.setItem(REFRESH_TOKEN_KEY, token)
  }

  function removeToken() {
    localStorage.removeItem(BEARER_TOKEN_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  }

  function getToken() {
    return localStorage.getItem(BEARER_TOKEN_KEY);
  }

  function getRefreshToken() {
    const raw = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (!raw) {
      return null;
    }
    return raw;
  }

  return {
    getToken,
    setToken,
    setRefreshToken,
    getRefreshToken,
    removeToken,
  }
}
