import {ProcessTypeEntity} from "../domain/entity/process-type.entity";
import {Form, Input, Switch} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {useEffect} from "react";

export type FormProcessTypeComponent = {
  values?: Partial<ProcessTypeEntity>,
  onSubmit: (values: any) => any
}
export function FormProcessTypeComponent(props: FormProcessTypeComponent) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.values) {
      form.setFieldsValue(props.values)
    }
  }, [props, props.values])
  return (
    <Form form={form} layout={'vertical'} onFinish={props.onSubmit}>
      <Form.Item name={'name'} label={'Nome'} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <JbFormActionButtonComponent />
    </Form>
  )
}
