export class AttendanceOriginEnum {
  static PHYSICAL = 'physical';
  static EMAIL = 'email';
  static PHONE = 'phone';
  static MESSAGE = 'message';
  static SITE = 'site';
  static OTHER = 'other';
  
  
  static getLabel(value: string): string {
    switch (value){
      case this.EMAIL: return 'E-mail';
      case this.PHYSICAL: return 'Presencial';
      case this.PHONE: return 'Telefone';
      case this.SITE: return 'Site';
      case this.MESSAGE: return 'Mensagem';
      case this.OTHER: return 'Outro';
      default: return value;
    }
  }
  
  static list() {
    return [
      this.PHYSICAL,
      this.EMAIL,
      this.PHONE,
      this.MESSAGE,
      this.SITE,
      this.OTHER
    ]
  }
  
  static getOptions() {
    return this.list().map(value => ({
      label: this.getLabel(value),
      value,
    }))
  }
}
