import {useJbHttpService} from "./jb-http.service";

export function useJbHttpPublicService() {
  const  {
    axiosInstance
  } = useJbHttpService();
  
  function publicAttendanceSearch(attendanceCode: string, captchaToken: string) {
    return axiosInstance.post(
      `public/attendance/${attendanceCode}`,
      JSON.stringify({captchaToken})
    ).then(res => res.data)
  }
  
  function getAttachment(
    attachmentId: string,
    tenantId: string,
    captchaToken: string,
    mimeType: string,
  ) {
    return axiosInstance.post(
      `public/attendance/${tenantId}/download/${attachmentId}`,
      JSON.stringify({ captchaToken }), {
        responseType: 'blob'
      }
    ).then(response => {
      if (response.status >= 200 && response.status <= 300) {
        return response.data;
      }
      Promise.reject(response.statusText);
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response], { type: mimeType }));
        return window.open(url, '__blank')
      })
  }
  
  function createInteraction(data: any) {
    return axiosInstance.post(
      `public/attendance/create-interaction`,
      JSON.stringify(data)
    ).then(res => res.data)
  }
  
  function uploadFileInteraction(data: any) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.set(key, data[key])
    })
    return axiosInstance.put(
      `public/attendance/upload-file-interaction`,
      formData
    ).then(res => res.data)
  }
  
  function addExternalAttendance(captchaToken: string, data: any) {
    return axiosInstance.post(
      `public/attendance/external-attendance`,
      JSON.stringify({
        ...data,
        captchaToken
      })
    ).then(res => res.data)
  }
  
  function getClientName(id: string) {
    return axiosInstance.get(
      `public/attendance/tenant/${id}`,
    ).then(res => res.data)
  }
  
  
  return {
    publicAttendanceSearch,
    createInteraction,
    getAttachment,
    uploadFileInteraction,
    addExternalAttendance,
    getClientName
  }
}