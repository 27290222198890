import {Col, Form, Row, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import {useEffect, useMemo, useState} from "react";
import {ProcessObjectEntity} from "../../../../../../domain/entity/process-object.entity";
import {useProcessObjectRepository} from "../../../../../../domain/repository/process-object.repository";
import {JbInputNumberComponent} from "../../../../core/form/jb-input-number.component";
import {JbFormActionButtonComponent} from "../../../../core/component/jb-form-action-button.component";

export function FormProcessObjectAttachedComponent(props: {
  onSubmit: (values: any) => any,
  onCancel: () => any,
  values: any,
  disableFields?: string[],
  processId: string,
}) {
  const [objects, setObjects] = useState<ProcessObjectEntity[]>([]);
  const [form] = useForm();
  
  const processObjectRepo = useProcessObjectRepository();
  
  useEffect(() => {
    processObjectRepo.list().then(res => setObjects(res.data))
  }, [])
  
  function onSubmit(values: any) {
    props.onSubmit(values)
  }
  
  useEffect(() => {
    form.setFieldsValue({
      ...props.values,
      objectId: props.values?.object?.id
    })
  }, [props.values, form]);
  
  const objectOpts = useMemo(() => {
    return objects.map(w => ({
      label: w.description,
      value: w.id,
    }))
  }, [objects])
  
  
  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={onSubmit}
    >
      <Form.Item label={'Objeto do Processo'} name={'objectId'} rules={[ { required: true }]}>
        <Select
          disabled={props.disableFields?.includes('objectId')}
          options={objectOpts}
        />
      </Form.Item>
      
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item label={'Espécie Expectativa'} name={'type'}  rules={[ { required: true }]}>
            <Select options={[
              {label: 'Receita', value: 'revenue'},
              {label: 'Despesa', value: 'cost'},
            ]}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'probability'} label={'Probilidade'}>
            <JbInputNumberComponent max={100} min={0} addonAfter={'%'} />
          </Form.Item>
        </Col>
      </Row>
      
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={'amount'} label={'Valor Estimado'}>
            <JbInputNumberComponent  addonBefore={'R$'}  />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'result'} label={'Resultado Final'}>
            <JbInputNumberComponent  addonBefore={'R$'}  />
          </Form.Item>
        </Col>
      </Row>
      
      <JbFormActionButtonComponent
        onCancel={props.onCancel}
      />
    </Form>
  )
}