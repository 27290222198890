import {ListFinanceEntry} from "../../../finance-entry/component/list-finance.entry";
import {useState} from "react";
import {ShowFinanceEntryModal} from "../../../finance-entry/component/show-finance-entry/modal";
import {Button} from "antd";
import {DollarOutlined} from "@ant-design/icons";
import {FinanceEntryFormModal} from "../../../finance-entry/component/finance-entry-form/modal";
import {FinanceEntryEntity} from "../../../../../../domain/entity/finance-entry.entity";
import {useFinanceEntryRepository} from "../../../../../../domain/repository/finance-entry.repository";
import {AttendanceEntity} from "../../domain/entity/attendance.entity";

export function AttendanceFinanceTabComponent(props: {
  attendance: AttendanceEntity,
}) {
  const [showItem, setShowItem] = useState<FinanceEntryEntity>();
  const [showForm, setShowForm] = useState(false);
  const financeRepo = useFinanceEntryRepository();
  
  function onCreateEntry(values: any) {
    return financeRepo.create({ ...values, attendanceId: props.attendance.id }).then(
      () => setShowForm(false)
    )
  }
  return (
    <>
      <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: 10}}>
        <Button
          type={'ghost'}
          shape={'round'}
          icon={<DollarOutlined />}
          onClick={() => setShowForm(true)}
        >
          Lançar Receita / Despesa
      </Button>
      </div>
      <ListFinanceEntry
        onShow={id => setShowItem(undefined)} // TODO implement thiss
        onEdit={console.log} // TODO Implement this
        filter={{ attendanceId: props.attendance.id }}
      />
      {
        showItem && (
          <ShowFinanceEntryModal
            financeEntryCode={showItem?.id}
            open
            entry={showItem}
            onClose={() => setShowItem(undefined)}
          />
        )
      }
      
      {
        showForm && (
          <FinanceEntryFormModal
            open
            onClose={() => setShowForm(false)}
            initialFields={{
              client: props.attendance.client,
            }}
            disabledFields={['clientId']}
            onSubmit={onCreateEntry}
          />
        )
      }
    </>
    
  )
}