import {Button, Collapse, Divider, Form, List, Select} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {useEffect, useMemo, useState} from "react";
import {UserEntity} from "../../domain/entity/user.entity";
import {useUserRepository} from "../../domain/repository/user.repository";
import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {AttendanceStepEntity} from "../../domain/entity/attendance-step.entity";
import {useAttendanceStepRepository} from "../../domain/repository/attendance-step.repository";
const { Panel } = Collapse;
export function AttendanceStepOwner(props: { stepId: string, attendanceId: string }) {
  const [attendance, setAttendance] = useState<AttendanceEntity>()
  const [attendanceStep, setAttendanceStep] = useState<AttendanceStepEntity>()
  const attendanceRepo = useAttendanceRepository();
  const attendanceStepRepo = useAttendanceStepRepository();
  
  function loadAttendanceStep(stepId: string) {
    attendanceStepRepo.find(stepId).then(res => setAttendanceStep(res.data));
  }
  function loadAttendance(attendanceId: string) {
    attendanceRepo.find(attendanceId).then(res => setAttendance(res.data))
  }
  
  useEffect(() => {
    if (props.attendanceId) {
      loadAttendance(props.attendanceId);
    }
  }, [props.attendanceId])
  useEffect(() => {
    if (props.stepId) {
      loadAttendanceStep(props.stepId);
    }
  }, [props.stepId])
  
  function removeAttendanceOwner(user: UserEntity) {
    return attendanceRepo.toggleOwner({
      attendanceId: props.attendanceId,
      userId: user.id
    }).then(() => loadAttendance(props.attendanceId))
  }
  function removeAttendanceStepOwner(user: UserEntity) {
    if (!user) return;
    return attendanceStepRepo.removeOwner(user.id, props.stepId).then(() => {
      loadAttendanceStep(props.stepId);
    });
  
  }
  
  function addAttendanceOwner(user: UserEntity | undefined) {
    if (!user) return;
    return attendanceRepo.toggleOwner({
      attendanceId: props.attendanceId,
      userId: user.id
    }).then(() => loadAttendance(props.attendanceId))
  }
  
  function addAttendanceStepOwner(user: UserEntity | undefined) {
    if (!user) return;
    return attendanceStepRepo.addOwner(user.id, props.stepId).then(() => {
      loadAttendanceStep(props.stepId);
    });
  }
  
  return (
    <Collapse defaultActiveKey={['1', '2']} ghost>
      <Panel header="Responsáveis Pelo Atendimento" key="1">
        <ListUsers
          loading={attendanceRepo.loading}
          users={attendance?.attendanceOwners ?? []}
          onRemove={removeAttendanceOwner}
        />
        <SelectUser
          onSelect={addAttendanceOwner}
          removedUsers={attendance?.attendanceOwners ?? []}
        />
      </Panel>
      <Panel header="Responsáveis Pela Movimentação" key="2">
        <ListUsers
          loading={false}
          onRemove={removeAttendanceStepOwner}
          users={attendanceStep?.stepOwners ?? []}
        />
        <SelectUser
          onSelect={addAttendanceStepOwner}
          removedUsers={attendanceStep?.stepOwners ??[]}
        />
      </Panel>
    </Collapse>
  )
}

function ListUsers(props: {
  users: UserEntity[],
  onRemove: (user: UserEntity) => any
  loading: boolean
}) {
  return (
    <List
      loading={props.loading}
      dataSource={props.users}
      renderItem={(item: UserEntity, idx) => (
        <List.Item actions={[
          <Button
            type={'link'}
            icon={<DeleteOutlined />}
            size={'middle'}
            onClick={() => props.onRemove(item)}
          />
        ]}>
          <List.Item.Meta
            key={idx}
            title={item.personName}
          />
        </List.Item>
      )}
    />
  )
}

function SelectUser(props: {
  removedUsers: UserEntity[],
  onSelect: (user: UserEntity | undefined) => any,
}) {
  const [users, setUsers] = useState<UserEntity[]>([])
  const [form] = Form.useForm()
  const userRepo = useUserRepository();
  
  useEffect(() => {
    userRepo.list().then(res => setUsers(res.data))
  }, [])
  
  const opts = useMemo(() => {
    return users.filter(w => !props.removedUsers.map(w => w.id).includes(w.id))
      .map(w => ({
        label: w.person?.name,
        value: w.id
      }))
  }, [users, props.removedUsers])
  
  if (!opts.length) {
    return null;
  }
  
  return (
    <Form form={form}>
      <Form.Item label={''} name={'userId'}>
        <Select
          options={opts}
          placeholder={'Adicionar Usuário'}
          onSelect={(userId: string) => {
            props.onSelect(users.find(w => w.id === userId))
            form.resetFields()
          }}
        />
      </Form.Item>
    </Form>
  )
}