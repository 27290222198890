import {Divider, Modal} from "antd";
import {AttendanceStepAttachments} from "./index";
import {UploadForm} from "./upload-form";
import {useEffect, useState} from "react";
import {AttendanceStepEntity} from "../../domain/entity/attendance-step.entity";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";

export function AttendanceStepAttachmentsModal(props: {
  stepId: string,
  attendanceId: string,
  onClose: () => any,
  visible: boolean
}) {
  const [step, setStep] = useState<AttendanceStepEntity>();
  const attendanceRepo = useAttendanceRepository();
  
  function loadStepData() {
    attendanceRepo.listSteps(
      props.attendanceId
    ).then(res => {
      if (res.data?.length) {
        
        const step = res.data.find(w => w.id === props.stepId)
        console.log(res.data, props.stepId)
        if (step) {
          setStep(step)
        }
      }
    })
  }
  
  useEffect(() => {
    loadStepData()
  }, [])
  
  console.log('step Id prop', props.stepId)
  
  return (
    <Modal
      onCancel={props.onClose}
      open={props.visible}
      footer={null}
      width={600}
    >
      {
        step && (
          <AttendanceStepAttachments
            attendanceStep={step}
            onAttachmentRemoved={() => loadStepData()}
          />
        )
      }
      <Divider />
      <UploadForm
        attendanceId={props.attendanceId}
        stepId={props.stepId}
        onAttached={loadStepData}
      />
    </Modal>
  )
}