import {Button, Col, Form, Input, List, Row} from "antd";
import {useEffect, useState} from "react";
import {ProcessTypeEntity} from "../domain/entity/process-type.entity";
import {useProcessTypeRepository} from "../domain/repository/process-type.repository";

export function FormProcessSubtypeComponent(props: { processTypeId: string }) {
  const [form] = Form.useForm();
  const [processType, setProcessType] = useState<ProcessTypeEntity>()
  
  const processTypeRepo = useProcessTypeRepository();
  
  function loadData() {
    processTypeRepo.find(props.processTypeId).then(res => {
      setProcessType(res.data)
    })
  }
  
  useEffect(() => {
    if (props.processTypeId) {
      loadData();
    }
  }, [props.processTypeId])
  
  function onSave(values: any) {
    const exists = processType?.subtypes?.find(w => w.description === values.description);
    if (exists) {
      form.setFields([
        { name: 'description', errors: ['Sub tipo eixstente']}
      ])
      return;
    }
    processTypeRepo.toggleSubtype(
      props.processTypeId,
      values.description
    ).then(() => {
      form.resetFields()
      loadData()
    })
  }
  
  function onRemove(description: string) {
    processTypeRepo.toggleSubtype(
      props.processTypeId,
      description
    ).then(() => {
      loadData()
    })
  }
  
  return (
    <>
      <Form form={form} onFinish={onSave}>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name={'description'} label={'Novo Sub Tipo'} rules={[{  required: true }]} required>
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Button type={'primary'} htmlType={'submit'}>
              Adicionar
            </Button>
          </Col>
        </Row>
      </Form>
      <List
        dataSource={processType?.subtypes ?? []}
        renderItem={item => (
          <List.Item actions={[
            <Button onClick={() => onRemove(item.description)} shape={'round'}>Remover</Button>
          ]}>
            <List.Item.Meta title={item.description} />
          </List.Item>
        )}
      />
    </>
  )
}