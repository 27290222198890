import {Divider, message, Steps} from "antd";
import {useCallback, useEffect, useMemo, useState} from "react";
import {FinanceEntryItemEntity} from "../../../../../../domain/entity/finance-entry-item.entity";
import {FinanceEntryEntity} from "../../../../../../domain/entity/finance-entry.entity";
import {ListFinanceItemsComponent} from "../list-finance-item.component";
import {FormItemStep} from "./form-item.step";
import {FormEntryStep} from "./form-entry.step";
import {JbFormActionButtonComponent} from "../../../../core/component/jb-form-action-button.component";
import {ConfirmEntryStep} from "./confirm-entry.step";

export function FinanceEntryForm(props: {
  onSubmit: (values: any) => any,
  initialValues?: Partial<FinanceEntryEntity>,
  disabledFields?: string[],
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [partialEntry, setPartialEntry] = useState<Partial<FinanceEntryEntity>>();
  const [currentItems, setCurrentItems] = useState<FinanceEntryItemEntity[]>([])
  
  const onAddItem = useCallback((item: any) => {
    setCurrentItems(prev => [...prev, item])
  }, [])
  
  const onRemoveItem = useCallback((item: Partial<FinanceEntryItemEntity>) => {
    setCurrentItems(prev => prev.filter(w => w.itemId !== item.itemId))
  }, [])
  
  const onFinish = useCallback((entry: Partial<FinanceEntryEntity>, items: Partial<FinanceEntryItemEntity>[]) => {
    props.onSubmit({
      ...entry,
      items
    });
  }, [props])
  
  useEffect(() => {
    if (props.initialValues?.items?.length) {
      setCurrentItems(props.initialValues.items);
    }
  }, [props.initialValues]);
  
  
  const currentStepComponent = useMemo(() => {
    if (currentStep === 0) {
      return (
        <FormEntryStep
          {...props}
          onSubmit={args => {
            setPartialEntry(args)
            setCurrentStep(prev => prev + 1);
          }}
        />
      )
    }
    if (currentStep === 1) {
      return (
        <>
          <FormItemStep
            onAddItem={onAddItem}
            currentItems={currentItems}
            typeEntry={partialEntry?.type}
          />
          <br />
          <ListFinanceItemsComponent
            items={currentItems}
            onRemove={onRemoveItem}
          />
          <JbFormActionButtonComponent
            labelCancel={'Voltar'}
            labelSave={'Continuar'}
            onCancel={() => {
              setCurrentStep(prev => prev - 1)
            }}
            onSaveClick={() => {
              if (!currentItems.length) {
                message.error('É necessário incluir pelo menos 1 item.')
                return;
              }
              setCurrentStep(prev => prev + 1)
            }}
          />
        </>
      )
    }
    return (
      <ConfirmEntryStep
        entry={partialEntry as FinanceEntryEntity}
        items={currentItems}
        onConfirm={() => onFinish(partialEntry as FinanceEntryEntity, currentItems)}
        onCancel={() => setCurrentStep(prev => prev - 1)}
      />
    )
  }, [
    currentStep,
    partialEntry,
    currentItems,
    props,
    onAddItem,
    onRemoveItem,
    onFinish
  ])
  
  return (
    <>
      <Steps current={currentStep} onChange={(step: number) => setCurrentStep(step)}>
        <Steps.Step key={0} title={'Dados Básicos'}/>
        <Steps.Step key={1} title={'Informe os Items'}/>
        <Steps.Step key={3} title={'Confirmar'}/>
      </Steps>
      <br/><br/>
      {currentStepComponent}
    </>
  )
}

