import {useCallback, useMemo} from "react";
import moment from "moment/moment";
import {ColumnsType} from "antd/es/table/Table";
import {Table} from "antd";
import {CashFlowItemEntity} from "../../../../../domain/entity/cash-flow-item.entity";
import {Moment} from "moment";

export function CashFlowListComponent(props: {
  items: CashFlowItemEntity[],
  startDate: Moment,
  endDate: Moment,
  groupStrategy: 'daily' | 'monthly' | 'yearly'
}) {
  const { format } = new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' })
  
  const renderDateColumsn = useCallback((dt: string) => {
    const [year, month, day] = dt.split('-');
    if (year && month && day) {
      return `${day}/${month}/${year}`
    }
    if (year && month) {
      return `${month}/${year}`
    }
    return year;
  },[])
  
  const getDateKey = useCallback((date: Moment | Date | string) => {
    let parsedDate = moment(date);
    switch (props.groupStrategy) {
      case "yearly":
        return parsedDate.format('YYYY');
      case 'monthly':
        return parsedDate.format('YYYY-MM');
      default:
        return parsedDate.format('YYYY-MM-DD');
    }
  }, [props.groupStrategy])
  
  const getKey = useCallback((date: string, category: string, itemType: string) => {
    return `${date}-${category}-${itemType}`;
  }, [])
  
  const getKeyFromItem = useCallback((item: CashFlowItemEntity) => {
    return getKey(getDateKey(item.paymentDate), item.category, item.itemType)
  }, [getDateKey, getKey])
  
  const dataSource = useMemo(() => {
    const dtSource = new Map<string, number>();
    for (const item of props.items) {
      dtSource.set(
        getKeyFromItem(item),
        (dtSource.get(
          getKeyFromItem(item)
        ) ?? 0) + item.paidAmount
      )
    }
    return dtSource;
  }, [getKeyFromItem, props.items]);
  
  const getGridItem = useCallback((date: string, category: string, itemType: string) => {
    return dataSource.get(getKey(date, category, itemType))
  }, [dataSource, getKey])
  
  
  const dateRange: Moment[] = useMemo(() => {
    const range = new Set<string>();
    let startAux = moment(props.startDate);
    while(true) {
      if (startAux > props.endDate) break;
      range.add(getDateKey(startAux));
      startAux.add(1, 'd');
    }
    return Array.from(range.values()).map(w => moment(w));
  }, [props.startDate, props.endDate, getDateKey])
  
  const generateRowsByType = useCallback((itemType: string, categoryName: string) => {
    const row: any = {
      category: categoryName,
      key: itemType,
      children: []
    };
    for (const date of dateRange) {
      row[getDateKey(date)] = 0;
    }
    const categories = new Set<string>();
    props
      .items
      .filter(w => w.itemType === itemType)
      .forEach(w =>  categories.add(w.category))
    for (const category of Array.from(categories.values())) {
      const childRow: any = { category }
      for (const date of dateRange) {
        const parsedDate = getDateKey(date);
        const value = getGridItem(parsedDate, category, itemType) ?? 0;
        childRow[parsedDate] = value;
        row[parsedDate] += value;
      }
      row.children.push(childRow)
    }
    return row;
  }, [dateRange, getDateKey, getGridItem, props.items])
  
  
  const columns: ColumnsType<any> = useMemo(() => {
    const columns: ColumnsType<any> = [];
    columns.push({
      title: '',
      dataIndex: 'category'
    })
    for (const date of dateRange) {
      columns.push({
        title: renderDateColumsn(getDateKey(date)),
        dataIndex: getDateKey(date),
        key: getDateKey(date),
        render: format
      })
    }
    return columns;
  }, [dateRange, format, getDateKey]);
  
  
  const totalRevenue = useMemo(
    () => generateRowsByType('R', 'Total Receitas'),
    [generateRowsByType]);
  const totalDeduction = useMemo(
    () => generateRowsByType('D',
      'Total Despesas'
    ), [generateRowsByType]);
  
  const resultRow = useMemo(() => {
    const row: any = {
      category: 'Resultado',
    }
    for (const date of dateRange) {
      const dateKey = getDateKey(date);
      row[dateKey] = totalRevenue[dateKey] - totalDeduction[dateKey];
    }
    return row;
  }, [dateRange, getDateKey, totalRevenue, totalDeduction])
  
  return (
    <Table
      columns={columns}
      expandable={{
        expandedRowKeys: ['R', 'D']
      }}
      dataSource={[
        totalRevenue,
        totalDeduction,
        resultRow
      ]}
    />
  )
}