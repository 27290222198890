import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {Descriptions} from "antd";
import {AttendanceStatusComponent} from "../attendance-status.component";
import React, {useMemo} from "react";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";
import {ProcessEntity} from "../../domain/entity/process.entity";
import {AttendanceStepEntity} from "../../domain/entity/attendance-step.entity";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {AttendanceStatusVo} from "../../domain/vo/attendance-status.vo";
import {AttendanceStatusActions} from "../attendance-status-actions";

export function AttendanceDescriptionComponent(props: {
  attendance?: AttendanceEntity,
  process?: ProcessEntity,
  steps?: AttendanceStepEntity[],
  reload: () => any,
}) {
  const { attendance, process, steps } = props
  const { formDateInPtBr } = useDatetimeUtil();

  const attendanceRepo = useAttendanceRepository();
  
  function suspendAttendance(suspensionDeadline: Date) {
    attendanceRepo.changeStatus(
      props.attendance?.id as string,
      'STOPPED',
      suspensionDeadline
    ).then(() => props.reload())
  }
  
  function reopenAttendance() {
    attendanceRepo.changeStatus(
      props.attendance?.id as string,
      'OPENED'
    ).then(() => props.reload())
  }
  
  function concludeAttendance() {
    attendanceRepo.changeStatus(
      props.attendance?.id as string,
      "CONCLUDED"
    ).then(() => props.reload())
  }
  
  const lastStepDateTime = useMemo<string | null>(() => {
    if (!steps?.length) {
      return null;
    }
    return formDateInPtBr(steps[steps.length - 1]?.beginDate) ?? null
  }, [steps])
  
  return (
    <Descriptions
      bordered
      size={'small'}
      layout={'vertical'}
      column={4} >
      <Descriptions.Item label={'Cliente'}>
        {attendance?.client.name}
      </Descriptions.Item>
      <Descriptions.Item label={'Tipo'}>
        {attendance?.attendanceType?.name}
      </Descriptions.Item>
      <Descriptions.Item label={'Abertura'}>
        {formDateInPtBr(attendance?.createdAt ?? new Date())}
      </Descriptions.Item>
      <Descriptions.Item label={'Status Atual'}>
        <AttendanceStatusComponent
          status={attendance?.status ?? ''}
          suspensionDeadline={attendance?.suspensionDeadline}
        />
        <div style={{float: 'right'}}>
          <AttendanceStatusActions
            status={attendance?.status as AttendanceStatusVo}
            onConclude={concludeAttendance}
            onStop={suspendAttendance}
            onOpen={reopenAttendance}
          />
        </div>
      </Descriptions.Item>
      <Descriptions.Item label={'Código Atendimento'}>
        <AttendanceStatusComponent status={attendance?.code ?? ''} />
      </Descriptions.Item>
      <Descriptions.Item label={'Tipo Processo'}>
        {process?.processType.name ?? <NotInformed />}
      </Descriptions.Item>
      <Descriptions.Item label={'Sub Tipo'}>
        {process?.processSubtype?.description ?? <NotInformed />}
      </Descriptions.Item>
      <Descriptions.Item label={'Código do Processo'}>
        {process?.code ?? <NotInformed />}
      </Descriptions.Item>
    </Descriptions>
  )
}

const NotInformed = () => <label style={{fontSize: 'small', color: 'gray'}}>Não Informado</label>