import {Popconfirm, Space} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import React, {ReactNode} from "react";
import {JbButtonWithPopover} from "../button-with-popover";

export function JbTableRowActionsComponent(props: {
  onEdit?: () => any,
  onShow?: () => any,
  onRemove?: () => any,
  disablePopConfirm?: true,
  extra?: ReactNode[] | ReactNode,
}) {
  return (
    <Space>
      {
        props.onShow && (
          <JbButtonWithPopover
            size={'small'}
            type={'primary'}
            title={'Visualizar'}
            onClick={props.onShow}
            icon={<EyeOutlined />}
          />
        )
      }
      {
        props.onEdit && (
          <JbButtonWithPopover
            size={'small'}
            type={'link'}
            title={'Editar'}
            onClick={props.onEdit}
            icon={<EditOutlined />}
          />
        )
      }
      {
        props.onRemove && (
          <Popconfirm
            title={'Deseja prosseguir com essa operação ?'}
            onConfirm={props.onRemove}
            disabled={props.disablePopConfirm}
          >
            <JbButtonWithPopover
              size={'small'}
              type={'link'}
              danger
              title={'Remover'}
              icon={<DeleteOutlined />}
              onClick={props.disablePopConfirm ? props.onRemove : undefined}
            />
          </Popconfirm>
        )
      }
      { props.extra }
    </Space>
  )
}
