import {ProcessObjectEntity} from "../../../../../domain/entity/process-object.entity";
import {Button, Col, Form, Input, List, Modal, Row, Table} from "antd";
import React, {useMemo, useState} from "react";
import {FormProcessObjectComponent} from "./form.process-object.component";
import {SearchOutlined} from "@ant-design/icons";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";

export function ListProcessObjectComponent(props: {
  dataSource: ProcessObjectEntity[],
  onRemove: (entity: ProcessObjectEntity) => any,
  onEdit: (entity: ProcessObjectEntity) => any,
}) {
  const [form] = Form.useForm()
  const [searchTerm, setSearchTerm] = useState<string>();
  const [editObject, setEditObject] = useState<ProcessObjectEntity>();
  
  const filteredData = useMemo(() => {
    return props.dataSource.filter(w => w.description.toLowerCase().includes(searchTerm?.toLowerCase() ?? ''))
  }, [searchTerm, props.dataSource])
  
  return (
    <>
      <Form form={form} layout={'horizontal'}>
        <Row>
          <Col span={8}>
            <Form.Item name={'search'}>
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Button
              onClick={() => setSearchTerm(form.getFieldValue('search'))}
              icon={<SearchOutlined />}
            >
              Pesquisar
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        dataSource={filteredData}
        showHeader={false}
        columns={[
          {
            dataIndex: 'description'
          },
          {
            title: '',
            align: 'right',
            dataIndex: 'id',
            render: (id, row) => (
              <JbTableRowActionsComponent
                onEdit={() => setEditObject(row)}
                onRemove={() => props.onRemove(row)}
              />
            )
          }
        ]}
      />
      {
        editObject && (
          <Modal
            title={`Editar Objeto ${editObject.description}`}
            onCancel={() => setEditObject(undefined)}
            footer={null}
            open
          >
            <FormProcessObjectComponent
              onSubmit={(values) => {
                props.onEdit({...editObject, ...values})
                setEditObject(undefined)
              }}
              initialValues={editObject}
            />
          </Modal>
        )
      }
    </>
  )
}