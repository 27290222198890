import {AttendanceOriginVo} from "../vo/attendance-origin.vo";
import {AttendanceStatusVo} from "../vo/attendance-status.vo";
import {ClientEntity} from "../../../client/domain/entity/client.entity";
import {AttendancePartEntity} from "./attendance-part.entity";
import {UserEntity} from "./user.entity";
import {AttendanceAuditEntity} from "./attendance-audit.entity";
import {AttendanceTypeEntity} from "../../../../../../domain/entity/attendance-type.entity";
import {WorkflowEntity} from "../../../../../../domain/entity/workflow.entity";

export type AttendanceEntity = {
  id: string,
  code: string,
  origin: AttendanceOriginVo,
  attendanceType?: AttendanceTypeEntity
  status: AttendanceStatusVo,
  report: string,
  client: ClientEntity,
  processId?: string;
  createdAt?: string;
  attendanceParts?: AttendancePartEntity[];
  attendanceOwners?: UserEntity[];
  attachments?: AttendanceAttachment[];
  suspensionDeadline?: string;
  auditEvents?: AttendanceAuditEntity[];
  priority: number;
  workflow?: WorkflowEntity
  workflowDisabled?: boolean;
}

export type AttendanceAttachment = {
  id: string;
  path: string;
  size: number;
  mimeType: string;
  
  isPublic?: boolean;
  originalName: string
}

export class AttendancePriority {
  static getOptions() {
    return [
      { label: 'Prioridade Máxima', value: 1, },
      { label: 'Prioridade Médio', value: 2 },
      { label: 'Prioridade Mínima', value: 3 },
      { label: 'Prioridade Normal', value: 4 },
    ]
  }
}

export function isValidAttendanceStatus(status: string): boolean {
  return status === 'OPENED' || status === 'SOPPED' || status === 'ARCHIVED';
}


export class AttendanceStatusEnum {
  static CONCLUDED: AttendanceStatusVo = 'CONCLUDED';
  static STOPPED: AttendanceStatusVo = 'STOPPED';
  static OPENED: AttendanceStatusVo = 'OPENED';
}