import {useCallback} from "react";

export function usePhoneUtil() {
  const formatPhone = useCallback((phone: string) => {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/g, "(\$1) \$2-\$3")
  }, [])
  
  return {
    formatPhone
  }
}