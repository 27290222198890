import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {FormWorkflowComponent} from "../component/form.workflow.component";
import {useWorkflowRepository} from "../../../../../domain/repository/workflow.repository";

export function CreateWorkflowPage(props: ModulePageProps) {
  const workflowRepo = useWorkflowRepository();
  
  function createWorkflow(values: any) {
    workflowRepo.create(values).then((res) => {
      props.navigator(`/edit/${res.data.id}`)
    })
  }
  return (
    <>
      <JbPageHeaderComponent title={'Novo Workflow'} />
      <FormWorkflowComponent onSubmit={createWorkflow} />
      {/*<FormTaskWorkflowComponent />*/}
    </>
    
  )
}