import {Badge, Button, Card, Descriptions, Modal} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  InfoOutlined,
  LinkOutlined,
  UserAddOutlined, UsergroupAddOutlined,
  WechatOutlined
} from "@ant-design/icons";
import React, {useState} from "react";
import {AttendanceStepEntity} from "../../domain/entity/attendance-step.entity";
import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";
import {FormAttendanceStep} from "../form.attendance-step";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {AttendanceStepMediaModal} from "./attendance-step-media.modal";
import {FinishAttendanceStepButtonComponent} from "./finish-attendance-step-button.component";
import {AttendanceStepCommentComponentModal} from "../attendance-step-comment.component/modal";
import {AttendanceStepAttachmentsModal} from "../attendance-step-attachments/modal";
import {AttendanceStepOwnerModal} from "../attendance-step-owner/modal";
import {useAttendanceStepRepository} from "../../domain/repository/attendance-step.repository";
import {JbButtonWithPopover} from "../../../../core/button-with-popover";

export function AttendanceStepCardComponent(props: {
  step: AttendanceStepEntity,
  attendance?: AttendanceEntity,
  reloadAttendance: () => any,
}) {
  const [editStep, setEditStep] = useState<AttendanceStepEntity>();
  const [showAttachments, setShowAttachments] = useState(false)
  const [displayComments, setDisplayComments] = useState(false);
  const [showStepOwners, setShowStepOwners] = useState(false);
  const [showStepTypeMedias, setShowStepTypeMedias] = useState<string>();
  const { step, attendance } = props
  const { formDateInPtBr, getDiffInDays } = useDatetimeUtil()
  const attendanceRepo = useAttendanceRepository();
  const attendanceStepRepo = useAttendanceStepRepository();
  
  function removeStep(id: string) {
    return attendanceStepRepo.remove(id).then(() => {
      props.reloadAttendance()
    })
  }
  
  function onSave(values: any) {
    return attendanceRepo.editStep(
      attendance?.id ?? '',
      editStep?.id ?? '',
      values
    ).then(res => {
      setEditStep(undefined)
      props.reloadAttendance()
    })
  }
  
  
  
  return (
    <>
      <Card
        title={step?.stepType?.description}
        extra={[
          <JbButtonWithPopover
            title={'Responsáveis pela Tarefa'}
            type={'text'}
            onClick={() => setShowStepOwners(true)}
            icon={<UsergroupAddOutlined />}
          />,
          <Badge count={step?.attachments?.length ?? 0} size={'small'}>
            <JbButtonWithPopover
              onClick={() => setShowAttachments(true)}
              type={'text'}
              icon={<LinkOutlined />}
              title={'Anexos da Movimentação'}
            />
          </Badge>,
          <JbButtonWithPopover
            type={'text'}
            title={'Comentários'}
            onClick={() => setDisplayComments(true)}
            icon={<WechatOutlined />}
          />,
          <JbButtonWithPopover
            onClick={() => setShowStepTypeMedias(step.stepType.id)}
            type={'text'}
            title={'Orientações'}
            icon={<InfoOutlined />}
          />
        ]}
        actions={[
          <FinishAttendanceStepButtonComponent
            step={step}
            onFinish={() => props.reloadAttendance()}
          />,
          <JbButtonWithPopover
            type={'text'}
            block
            title={'Editar Etapa'}
            onClick={() => setEditStep(step)} icon={<EditOutlined />}
          />,
          <JbButtonWithPopover
            type={'text'}
            title={'Excluir Etapa'}
            disabled={props.step.finished}
            block
            onClick={() => removeStep(step.id)}
            icon={<DeleteOutlined />}
          />,
        ]}
        bodyStyle={{paddingTop: 15}}
      >
        <Descriptions style={{margin: 0}} >
          <Descriptions.Item label={'Duração'}>
            {step.endDate ? getDiffInDays(step.beginDate, step.endDate) : 'Não definido'} dias
          </Descriptions.Item>
          <Descriptions.Item label={'Data de Início'}>
            {formDateInPtBr(step.beginDate)}
          </Descriptions.Item>
          <Descriptions.Item label={'Date de Término'}>
            {formDateInPtBr(step.endDate)}
          </Descriptions.Item>
        </Descriptions>
        <Card.Meta
          style={{whiteSpace: 'pre-wrap'}}
        />
      </Card>
      {
        editStep && (
          <Modal
            title={editStep.stepType.description}
            visible={true}
            width={1024}
            onCancel={() => setEditStep(undefined)}
            footer={null}
          >
            <FormAttendanceStep
              onSubmit={values => onSave(values)}
              values={editStep}
              startDate={attendance?.createdAt}
              onCancel={() => setEditStep(undefined)}
            />
          </Modal>
        )
      }
      {
        showStepTypeMedias && (
          <AttendanceStepMediaModal
            stepTypeId={showStepTypeMedias}
            closeModal={() => setShowStepTypeMedias(undefined)}
          />
        )
      }
      {
        <AttendanceStepCommentComponentModal
          stepId={props.step.id}
          attendanceId={props.step.attendanceId}
          visible={displayComments}
          onClose={() => setDisplayComments(false)}
        />
      }
      {
        <AttendanceStepAttachmentsModal
          stepId={props.step.id}
          visible={showAttachments}
          attendanceId={props.step.attendanceId}
          onClose={() => {
            setShowAttachments(false)
            props.reloadAttendance()
          }}
        />
      }
      {
        <AttendanceStepOwnerModal
          stepId={step.id}
          attendanceId={step.attendanceId}
          onClose={() => setShowStepOwners(false)}
          open={showStepOwners}
        />
      }
    </>
  )
}