import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {AttendanceTypeEntity} from "../entity/attendance-type.entity";
import {CategoryEntity} from "../entity/category.entity";

export function useCategoryRepository() {
  const crudHttpService = useJbHttpCrudService<CategoryEntity>('category')

  return {
    ...crudHttpService,
  }
}
