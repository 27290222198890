import {Button, List} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {WorkflowTaskEntity} from "../../../../../domain/entity/workflow-task.entity";
import {JbButtonWithPopover} from "../../../core/button-with-popover";

export function ListWorkflowTaskComponent(props: {
  tasks: WorkflowTaskEntity[],
  onRemoveTask: (task: WorkflowTaskEntity) => any,
  onMoveUp: (taskId: string) => any,
  onMoveDown: (taskId: string) => any,
  onCreate: () => any
}) {
  
  return (
    <>
      <List
        header={<Button onClick={props.onCreate} icon={<PlusOutlined />} type={'link'}>Adicionar Tarefa ao Workflow</Button>}
        dataSource={props.tasks}
        extra={[
        
        ]}
        renderItem={(item, idx) => (
          <List.Item
            key={idx}
            actions={[
              <JbButtonWithPopover
                title={'Mover Tarefa Para Cima'}
                disabled={idx === 0}
                icon={<ArrowUpOutlined />}
                onClick={() => props.onMoveUp(item.id)}
              />,
              <JbButtonWithPopover
                icon={<ArrowDownOutlined />}
                title={'Mover Tarefa Para Baixo'}
                onClick={() => props.onMoveDown(item.id)}
                disabled={idx === (props.tasks.length - 1)}
              />,
              <JbButtonWithPopover
                title={'Remover Tarefa'}
                icon={<DeleteOutlined />}
                onClick={() => props.onRemoveTask(item)}
              />,
            ]}
          >
            <List.Item.Meta
              title={`${idx + 1} - ${item.stepType?.description} (${item.daysNeeded} Dias)`}
              description={item.observation}
            />
          </List.Item>
        )}
      />
    </>
  )
}