import {PublicHeaderComponent} from "../../core/component/public-header.component";
import {Button, Card, Col, Form, Input, message, PageHeader, Result, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import {MaskedInput} from "antd-mask-input";
import {JbFormActionButtonComponent} from "../admin/core/component/jb-form-action-button.component";
import {useJbHttpPublicService} from "../../core/service/jb-http-public.service";
import {useEffect, useState} from "react";
import {JbCaptchaComponent} from "../../core/component/jb-captcha.component";
import {useParams} from "react-router-dom";

export function PublicPreAttendance() {
  const [form] = Form.useForm();
  const [clientName, setClientName] = useState('');
  const [resultMessage, setResultMessage] = useState<string>()
  const { addExternalAttendance, getClientName } = useJbHttpPublicService();
  const { tenantId } = useParams<any>()
  
  useEffect(() => {
    if (tenantId)
      getClientName(tenantId).then(res => setClientName(res))
  }, [tenantId]);
  
  async function onSubmit(values: any) {
    return addExternalAttendance(
      values.captcha,
      {...values, tenantId}
    ).then(() => {
      form.resetFields();
      setResultMessage('Sua solicitação foi registrada com sucesso. Em breve entraremos em contato !')
    }).catch(err => {
      message.error(
        err?.response?.data?.message ?? 'Erro ao realizar operação. Por favor tente mais tarde !'
      )
    })
  }
  
  
  return (
    <>
      <PublicHeaderComponent clientName={clientName} />
      <Content style={{padding: '60px 120px 0 120px'}}>
        <Card
          type={'inner'}
          title={
            <PageHeader
              title={'Fale Conosco'}
              subTitle={'Informe seus dados básicos e uma breve descrição da sua necessiade que em breve entraremos em contato com você'}
            />
          }
        >
          {
            resultMessage ? (
              <ResultComponent message={resultMessage} />
            ) : (
              <ExternalAttendanceForm onFinish={onSubmit} />
            )
          }
        </Card>
      </Content>
    </>
  )
}

function ResultComponent(props: { message: string }) {
  return (
    <Result
      title={props.message}
      status={'success'}
    />
  )
}

function ExternalAttendanceForm(props: { onFinish: (values: any) => any }) {
  const [form] = Form.useForm();
  const [captcha, setCaptcha] = useState<string>();
  
  function onFinish(values: any) {
    return props.onFinish({ ...values, captcha })
  }
  
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
    >
      <Row gutter={12}>
        <Col span={10}>
          <Form.Item name={'personName'} label={'Informe seu Nome'} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={'phoneNumber'} label={'Telefone de Contato'}  rules={[{ required: true }]}>
            <MaskedInput mask={'(00)00000-0000'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'personEmail'} label={'Email'} >
            <Input type={'email'} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name={'description'}
            label={'Breve Relato'}
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
        </Col>
      </Row>
      <JbCaptchaComponent
        onChange={setCaptcha}
      />
      <JbFormActionButtonComponent
        saveDisabled={!captcha}
      />
    </Form>
  )
}