import {Col, Divider, Form, Input, Row, Select} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {useEffect} from "react";

export function FormWorkflowComponent(props: {
  onSubmit: (values: any) => any,
  initialValues?: any,
  disableFields?: string[],
}) {
  const [form] = Form.useForm()
  
  useEffect(() => {
    if (props.initialValues) {
      form.setFieldsValue(props.initialValues)
    }
  }, [form,props.initialValues]);
  
  return (
    <Form
      layout={'vertical'}
      form={form}
      onFinish={props.onSubmit}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={'name'} label={'Nome do Workflow'} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'type'} label={'Selecione o Tipo'} rules={[{ required: true }]}>
            <Select
              disabled={props.disableFields?.includes('type')}
              options={[
                { label: 'Template', value: 'template' },
                { label: 'Automatização', value: 'automation'}
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <JbFormActionButtonComponent />
    </Form>
  )
}