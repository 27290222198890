import {AttendanceEntity} from "../domain/entity/attendance.entity";
import {Tag} from "antd";

export function AttendancePriorityTagComponent(props: {
  attendance?: AttendanceEntity,
  priority?: number
}) {
  switch (props.attendance?.priority || props.priority || 0) {
    case 1:
      return <Tag color={'red'}>Prioridade Máxima</Tag>;
    case 2:
      return <Tag color={'warning'}>Prioridade Média</Tag>;
    case 3:
      return <Tag color={'cyan'}>Prioridade Mínima</Tag>;
    default:
      return <Tag>Não Priorizado</Tag>;
  }
}