import {useEffect, useState} from "react";
import {StepTypeEntity} from "../../../../../domain/entity/step-type.entity";
import {useStepTypeRepository} from "../../../../../domain/repository/step-type.repository";
import {ListStepTypeMediaComponent} from "./list-step-type-media.component";
import {JbDropzoneComponent} from "../../../core/dropzone";

export function StepTypeMediaFormComponent(props: { 
  stepTypeId: string,
  currentFileList?: Array<{
    uid: string,
    name: string,
    status: 'done' | 'error' | 'uploading' | 'removed',
    path: string,
    url?: string
  }>
}) { 
    const [stepType, setStepType] = useState<StepTypeEntity>()
    const stepTypeRepository = useStepTypeRepository()
  
    function loadStepType() {
      stepTypeRepository.find(props.stepTypeId)
        .then(res => setStepType(res.data))
    }
    
    useEffect(() => {
      loadStepType();
    }, [props.stepTypeId])

    function uploadMedia(file: File) {
      return stepTypeRepository.uploadMedia(
        props.stepTypeId,
        file
      ).then(res => setStepType(res.data))
    }

    return (
      <>
        <JbDropzoneComponent
          onDrop={uploadMedia}
          description={'Arquivos Permitidos: DOC, PDF, PNG e JPEG'}
        />
        {
          stepType && (
            <ListStepTypeMediaComponent
              stepType={stepType}
              loading={stepTypeRepository.loading}
              onMediaRemoved={() => loadStepType()}
            />
          )
        }
      </>
    )
}