const messages = require('./messages.json')

export function useMessageService() {
  function getMessage(key: string): string {
    if (messages[key]) {
      return messages[key]
    }
    return key;
  }

  return {
    getMessage
  }
}
