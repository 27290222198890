import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import {Form, FormItemProps} from "antd";
import {useRef} from "react";

export function JbRichTextComponent(props: {
  value?: string,
  placeholder?: string,
  onChange?: (value: string) => any,
}) {
  const editor = useRef<any>();
  
  return (
    <div
      style={{cursor: 'pointer'}}
      onClick={() => editor?.current?.focus()}
    >
      <ReactQuill
        theme="snow"
        value={props.value ?? ''}
        onChange={props.onChange}
        ref={editor}
      />
    </div>
  );
}


export function JbRichTextComponentField(props: FormItemProps) {
  return (
    <Form.Item {...props}>
      <JbRichTextComponent />
    </Form.Item>
  )
}