import {Button, Empty, Modal, Timeline} from "antd";
import {ApartmentOutlined, PlusOutlined} from "@ant-design/icons";
import React, {useMemo, useState} from "react";
import {AttendanceStepEntity} from "../../domain/entity/attendance-step.entity";
import {FormAttendanceStep} from "../form.attendance-step";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {AttendanceStepCardComponent} from "./attendance-step-card.component";
import {AttendanceWorkflowModal} from "../attendance-workflow/modal";
import {WorkflowEntity} from "../../../../../../domain/entity/workflow.entity";
import {ListAttendanceStepComponent} from "../list-attendance-step.component";

export function AttendanceStepTabComponent(props: {
  steps: AttendanceStepEntity[],
  attendance?: AttendanceEntity,
  reloadAttendance: () => any,
}) {
  const [showCreateModal, setShowCreateModel] = useState(false);
  const [showWorkflowModal, setShowWorkflowModal] = useState<WorkflowEntity>();
  const { steps, attendance } = props
  const attendanceRepo = useAttendanceRepository();

  function onCreate(values: any) {
    values.attendanceId = attendance?.id ?? ''
    return attendanceRepo.addStep(values).then(res => {
      setShowCreateModel(false)
      props.reloadAttendance()
    })
  }
  
  const orderedSteps = useMemo(() => {
    return steps.sort((w) => {
      if (w.beginDate < w.beginDate) return 0;
      if (w.beginDate < w.beginDate) return 1;
      return 0;
    })
  }, [steps])
  
  const actions = useMemo(() => {
    if (attendance) {
      if (attendance.workflow && !attendance.workflowDisabled) {
        return (
          <Button
            onClick={() => setShowWorkflowModal(props.attendance?.workflow)}
            icon={<ApartmentOutlined />}
          >
            {attendance.workflow.name}
          </Button>
        )
      }
      return (
        <Button
          shape={"round"}
          icon={<PlusOutlined />}
          onClick={() => setShowCreateModel(true)}
        >
          Adicionar Movimentação
        </Button>
      )
    }
  }, [props.attendance])

  return (
    <div>
      <div style={{textAlign: 'right', marginBottom: 15}}>
        <Button.Group>
          {actions}
        </Button.Group>
      </div>
      {
        props.attendance && (
          <ListAttendanceStepComponent
            steps={orderedSteps}
            reload={props.reloadAttendance}
            attendance={props.attendance as AttendanceEntity}
          />
        )
      }
      {
        showCreateModal && (
          <Modal
            title={'Adicionar Movimentação'}
            visible={true}
            width={1024}
            onCancel={() => setShowCreateModel(false)}
            footer={null}
          >
            <FormAttendanceStep
              onSubmit={values => onCreate(values)}
              startDate={attendance?.createdAt}
              onCancel={() => setShowCreateModel(false)}
            />
          </Modal>
        )
      }
      
      {
        showWorkflowModal && attendance && (
          <AttendanceWorkflowModal
            open
            workflow={showWorkflowModal}
            attendance={attendance}
            onClose={() => {
              props.reloadAttendance()
              setShowWorkflowModal(undefined)
            }}
          />
        )
      }
    </div>
  )
}