import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useEffect, useMemo, useState} from "react";
import {ColumnsType} from "antd/es/table";
import {Table} from "antd";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {FinanceItemEntity} from "../../../../../domain/entity/finance-item.entity";
import {useSupplierRepository} from "../../../../../domain/repository/supplier.repository";

export function ListSupplierPage(props: ModulePageProps) {
  const [items, setItems] = useState<FinanceItemEntity[]>([]);
  const supplierRepo = useSupplierRepository();
  
  function loadData() {
    supplierRepo.list().then((res) => setItems(res.data));
  }
  
  useEffect(() => {
    loadData()
  }, []);
  
  function onRemove(id: string) {
    supplierRepo.remove(id).then(() => loadData());
  }

  const columns = useMemo<ColumnsType<any>>(() => {
    return [
      {
        key: 1,
        title: 'Nome',
        dataIndex: 'name'
      },
      {
        key: 1,
        title: 'Documento',
        dataIndex: 'document'
      },
      {
        key: 1,
        title: 'Email',
        dataIndex: 'email',
      },
      {
        key: 1,
        title: 'Telefone',
        dataIndex: 'phoneNumber',
      },
      {
        title: '',
        key: 99,
        align: 'right',
        dataIndex: 'id',
        render: (id) => (
          <JbTableRowActionsComponent
            onEdit={() => props.navigator(`edit/${id}`)}
            onRemove={() => onRemove(id)}
          />
        )
      }
    ]
  }, [])
  
  return(
    <>
      <JbPageHeaderComponent
        title={'Financeiro / Fornecedores'}
        description={'Gestão dos fornecedores'}
        onCreateLink={`${props.prefix}/create`}
      />
      <Table
        loading={supplierRepo.loading}
        dataSource={items}
        columns={columns}
      />
    </>
  )
}
