import {Checkbox, Col, DatePicker, Form, Input, Row, Select, Switch} from "antd";
import {ReactNode, useEffect, useMemo, useState} from "react";
import { AttendanceStepTypeEntity } from "../domain/entity/attendance-step-type.entity";
import { useAttendanceStepTypeRepository } from "../domain/repository/attendance-step-type.repository";
import { JbFormActionButtonComponent } from "../../../core/component/jb-form-action-button.component";
import moment, {Moment} from "moment";
import {useDatetimeUtil} from "../../../../../core/util/datetime.util";
import {JbRichTextComponentField} from "../../../core/form/jb-rich-text.component";
import {JbDatePickerComponent} from "../../../core/form/jb-date-picker.component";

export function FormAttendanceStep(props: {
  onSubmit: (values: any) => any,
  onCancel: () => any,
  startDate?: Date | string,
  extraActions?: ReactNode[],
  values?: any
}) {
  const [stepTypes, setStepTypes] = useState<AttendanceStepTypeEntity[]>([])
  const [beginDateSelected, setBeginDateSelected] = useState<Moment>();
  const [guidance, setGuidance] = useState<string>()
  const [form] = Form.useForm()
  const attendanceStepTypeRepo = useAttendanceStepTypeRepository();
  const { formDateInPtBr } =useDatetimeUtil();

  useEffect(() => {
    attendanceStepTypeRepo.list().then(res => setStepTypes(res.data))
  }, [])

  useEffect(() => {
    if (props.values) {
      const parsedValues = {
        ...props.values,
        stepTypeId: props.values.stepType ? props.values.stepType.id : undefined,
        beginDate: props.values.beginDate ? moment(props.values.beginDate) : null,
        endDate: props.values.endDate ? moment(props.values.endDate) : null,
      }
      form.setFieldsValue(parsedValues)
      if (props.values.stepTypeId) {
        onStepTypeChanged(props.values.stepTypeId)
      }
    }
  }, [props.values])

  const stepTypesOpts = useMemo<{ label: string, value: string }[]>(() => {
    return stepTypes.map(w => ({
      value: w.id,
      label: w.description
    }))
  }, [stepTypes])

  function onSubmit(values: any) {
    const {
      beginDate,
      endDate
    } = values
    values = {
      ...values,
      beginDate: beginDate,
      endDate: endDate,
    }
    props.onSubmit(values)
  }
  
  function onStepTypeChanged(selected: string) {
    const stepType = stepTypes.find(w => w.id === selected)
    if (stepType) {
      form.setFieldsValue({
        description: stepType.templateToFill ?? '',
      })
      if (stepType.guidance) {
        setGuidance(`Orientações: ${stepType.guidance}`);
      } else {
        setGuidance(undefined)
      }
    }
  }
  
  const startDate = useMemo(() => {
    if (props.startDate) {
      return moment(props.startDate)
    }
    return null;
  }, [props.startDate])
  
  const helpText = useMemo(() => {
    if (props.startDate) {
      return `Data de abertura ${formDateInPtBr(props.startDate)}`
    }
    return null;
  }, [props.startDate])
  

  return (
    <>
      <Form form={form} layout={'vertical'} onFinish={onSubmit}>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name={'stepTypeId'} label={'Tipo Movimentação'} rules={[{ required: true }]}>
              <Select options={stepTypesOpts} onSelect={(vl: string) => onStepTypeChanged(vl)} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'beginDate'}
              label={'Data de Inicio'}
              rules={[{ required: true }]}
              help={helpText}
            >
              <JbDatePickerComponent
                style={{width: '100%'}}
                onSelect={setBeginDateSelected}
                disabledDate={(dt: any) => !startDate ? false : dt.isBefore(startDate)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'endDate'} label={'Data de Término'}  rules={[{ required: true }]}>
              <JbDatePickerComponent
                format={'DD/MM/YYYY'}
                style={{width: '100%'}}
                picker={'date'}
                disabledDate={(dt: any) => !beginDateSelected ? false : beginDateSelected.isAfter(dt)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item valuePropName={'checked'} name={'isPublic'} label={'Exibir dados em consulta pública'}>
          <Switch />
        </Form.Item>
        <JbRichTextComponentField
          name={'description'}
          label={'Descrição'}
          // rules={[{ required: true }]}
          help={<div dangerouslySetInnerHTML={{__html: guidance ?? ''}}/>}
        />
        <JbFormActionButtonComponent
          onCancel={props.onCancel}
          extraActions={props.extraActions}
        />
      </Form>
    </>
  )
}
