import {Button, Col, Form, Row, Select} from "antd";
import {useEffect, useMemo, useState} from "react";
import {CategoryEntity} from "../../../../../domain/entity/category.entity";
import {useCategoryRepository} from "../../../../../domain/repository/category.repository";
import {SearchOutlined} from "@ant-design/icons";

export function FinanceListFilterComponent(props: {
  onTypeFilter: (category: string) => any,
  onStatusFilter: (status: string) => any,
  onFilter: (values: any) => any,
}) {
  const [form] = Form.useForm();
  
  const optTypes = useMemo(() => {
    return [
      { label: 'Receita', value: 'R'},
      { label: 'Despesa', value: 'D'},
    ]
  }, [])
  
  const statusesOpts = useMemo(() => {
    return [
      { label: 'Em Aberto', value: 'opened'},
      { label: 'Baixado', value: 'finished'},
      { label: 'Cancelado', value: 'canceled'},
    ]
  }, [])
  
  return (
    <Form
      form={form}
    >
      <Row gutter={12} justify={'end'}>
        <Col span={8}>
          <Form.Item name={'type'}>
            <Select
              options={optTypes}
              placeholder={'Receita / Despesa'}
              onChange={value => props.onTypeFilter(value)}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'status'} >
            <Select
              options={statusesOpts}
              onChange={(item: string) => props.onStatusFilter(item)}
              placeholder={'Status'}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col>
          <Button htmlType={"submit"} icon={<SearchOutlined />}>
            Filtrar
          </Button>
        </Col>
      </Row>
      
    </Form>
  )
}