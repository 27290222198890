import {FinanceEntryItemEntity} from "../../../../../domain/entity/finance-entry-item.entity";
import {useMemo} from "react";
import {Button, Table} from "antd";
import {DeleteOutlined} from "@ant-design/icons";

export function ListFinanceItemsComponent(props: {
  items: FinanceEntryItemEntity[],
  onRemove?: (item: FinanceEntryItemEntity) => any,
}) {
  const { format } = new Intl.NumberFormat('pt-BR', { style: 'currency',  currency: 'BRL',})
  
  const total = useMemo(() => {
    return props.items.map( w => w.amount * w.unitPrice ).reduce(
      (prev, next) => prev + next, 0
    )
  }, [props.items])
  
  return (
    <Table
      dataSource={props.items}
      size={'small'}
      pagination={false}
      footer={() => (
        <div style={{width: '100%', textAlign: 'right'}}>Total: <strong>{format(total)}</strong></div>
      )}
      columns={[
        {
          title: 'Item',
          dataIndex: 'item',
          render: (value: any) => value?.name,
        },
        {
          title: 'Quantidade',
          dataIndex: 'amount',
          align: 'right'
        },
        {
          title: 'Preço',
          dataIndex: 'unitPrice',
          render: format,
          align: 'right'
        },
        {
          title: 'Valor Total',
          dataIndex: 'total',
          render: (_, row) => format(row.amount * row.unitPrice),
          align: 'right'
        },
        {
          dataIndex: 'id',
          align: 'right',
          render: (id, row) => (
            <Button.Group>
              {
                props.onRemove && (
                  <Button
                    size={'small'}
                    icon={<DeleteOutlined />}
                    onClick={() => props.onRemove && props.onRemove(row)}
                  />
                )
              }
            </Button.Group>
          )
        }
      ]}
    />
  )
}