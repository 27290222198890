import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {message} from "antd";
import React, {useMemo} from "react";
import {AttendanceFormComponent} from "../attendance.form.component";
import {AttendanceEntity} from "../../domain/entity/attendance.entity";

export function AttendanceStep(props: {
  nextStep: () => any,
  attendance?: AttendanceEntity,
  onCreate: (attendance: AttendanceEntity) => any
}) {
  const attendanceRepo = useAttendanceRepository()
  function onSubmit(values: any) {
    if (props.attendance) {
      return attendanceRepo.edit(props.attendance.id, values)
        .then((res) => onFinish(res))
        .catch(err => onError(err))
    } else {
      return attendanceRepo.create(values)
        .then(res => onFinish(res))
        .catch(err => onError(err))
    }
  }

  function onError(err: any) {
  
  }

  function onFinish(res: any) {
    props.onCreate(res.data)
    props.nextStep()
  }

  const disabledFields = useMemo(() => {
    if (props.attendance) {
      return ['clientId', `workflowId`];
    }
    return undefined
  }, [props.attendance])

  return (
    <>
      <AttendanceFormComponent
        onSubmit={onSubmit}
        values={props.attendance}
        disabledFields={disabledFields}
      />
    </>
  )
}
