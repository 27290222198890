import {AttendanceEntity} from "../domain/entity/attendance.entity";
import {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useAttendanceRepository} from "../domain/repository/attendance.repository";
import {LoadingOutlined} from "@ant-design/icons";
import {useDocumentUtil} from "../../../../../core/util/document.util";
import {usePhoneUtil} from "../../../../../core/util/phone.util";
import {ProcessEntity} from "../domain/entity/process.entity";
import {useProcessRepository} from "../domain/repository/process.repository";
import {useDatetimeUtil} from "../../../../../core/util/datetime.util";
import {AttendancePdfReport} from "../component/attendance-pdf-report";
import {AttendanceStepEntity} from "../domain/entity/attendance-step.entity";

export function PrintAttendance(props: any) {
  const [attendance, setAttendance] = useState<AttendanceEntity>();
  const [process, setProcess] = useState<ProcessEntity>();
  const [steps, setSteps] = useState<AttendanceStepEntity[]>([]);
  const attendanceRepo = useAttendanceRepository();
  const processRepo = useProcessRepository();
  
  const { attendanceId }= useParams<any>()
  const { formatDocument } = useDocumentUtil();
  const { formatPhone } = usePhoneUtil();
  const { formDateInPtBr } = useDatetimeUtil();
  
  useEffect(() => {
    attendanceRepo.find(attendanceId).then(res => setAttendance(res.data));
    attendanceRepo.listSteps(attendanceId).then(res => setSteps(res.data));
  }, [attendanceId]);
  
  useEffect(() => {
    if (!attendance || !attendance?.processId) return;
    processRepo.find(attendance?.processId as string).then(w => setProcess(w.data));
  }, [attendance]);

  const attendanceType = useMemo(() => attendance?.attendanceType?.description, [attendance]);
  const clientName = useMemo(() => attendance?.client?.name, [attendance]);
  const clientDocument = useMemo(() =>
    attendance?.client?.document ? formatDocument(attendance?.client?.document) : '',
    [formatDocument, attendance]);
  const clientPhoneNumber = useMemo(() =>
    attendance?.client?.phoneNumber ? formatPhone(attendance?.client?.phoneNumber) : '',
    [formatPhone, attendance]);
  const report = useMemo(() => {
    if (!attendance || !attendance.report) return '';
    const element = document.createElement('div')
    element.innerHTML = attendance.report;
    return element.innerText ?? '';
  }, [attendance])
  const clientMail = useMemo(() => attendance?.client?.email, [attendance]);
  const attendanceCode  = useMemo(() => attendance?.code, [attendance]);
  const attendanceCreatedAt = useMemo(() =>
    attendance?.createdAt ? formDateInPtBr(attendance.createdAt) : '',
    [attendance])
  const processCode   = useMemo(() => process?.code, [process]);
  const processType = useMemo(() => process?.processType?.name,[process])
  const processSubType = useMemo(() => process?.processSubtype?.description,[process])
  const parts = useMemo(() => attendance?.attendanceParts ?? [], [attendance]);
  const processObjects = useMemo(() => process?.objects ?? [], [process])
  // const steps = useMemo(() => attendance.)
  
  
  if (!attendance || attendanceRepo.loading) {
    return  <LoadingOutlined />
  }
  return (
    <AttendancePdfReport
      title={attendanceType as string}
      subtitle={clientName as string}
      sections={[
        {
          key: 1,
          title: 'Cliente',
          rows: [
            `${clientName} - ${clientDocument}`,
            clientPhoneNumber,
            clientMail ?? ''
          ]
        },
        {
          key: 2,
          title: 'Atendimento',
          rows: [
            `${attendanceCode} - ${attendanceType}`,
            `Criado em ${attendanceCreatedAt}`
          ]
        },
        {
          key: 2.1,
          title: 'Relato do Atendimento',
          rows: [
            report
          ]
        },
        {
          key: 3,
          title: 'Processo',
          rows: [
            processCode ? `${processCode} ${processType} ${processSubType}` : 'Não Informado'
          ]
        },
        {
          key: 3.1,
          title: 'Partes do Processo',
          rows:  parts.map(w => `${w.partType?.name} - ${w.name} ${w.document}`)
        },
        {
          key: 3.2,
          title: 'Objectos do Processo',
          rows:  processObjects.map(w => `${w.description}`)
        },
        {
          key: 4,
          title: 'Movimentações',
          rows: steps.map((w, idx) => (
            `${idx + 1} - Início: ${formDateInPtBr(w.beginDate)}\t Vencimento: ${w.endDate ? formDateInPtBr(w.endDate) : 'Não Informado'}\t - ${w.stepType?.description}\n`
          ))
        }
      ]}
    />
  )
}
