import React, {useMemo} from "react";
import {ColumnsType} from "antd/es/table/Table";
import {AttendanceStatusComponent} from "./attendance-status.component";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {Button, Table} from "antd";
import {useDatetimeUtil} from "../../../../../core/util/datetime.util";
import {AttendanceEntity} from "../domain/entity/attendance.entity";
import {AttendancePriorityTagComponent} from "./attendance-priority-tag.component";
import {PrinterOutlined} from "@ant-design/icons";

export function ListAttendanceComponent(props: {
  dataSource: AttendanceEntity[],
  onEdit: (id: string) => any,
  onShow: (id: string) => any,
  onPrint?: (id: string) => any,
  loading?: boolean,
  pagination?: {
    pageSize: number,
    current: number,
    total: number,
    onChange: (page: number, pageSize: number) => any,
  }
}) {
  const { formDateInPtBr } = useDatetimeUtil()
  const columns = useMemo<ColumnsType<any>>(() => ([
    {
      title: 'Código Atend.',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
      render: client => client.name
    },
    {
      title: 'Tipo',
      dataIndex: 'attendanceType',
      key: 'attendanceType',
      render: type => type?.name
    },
    {
      title: 'Prioridade',
      dataIndex: 'priority',
      key: 'priority',
      render: (a,row) => <AttendancePriorityTagComponent attendance={row} />
    },
    {
      title: 'Stattus',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <AttendanceStatusComponent status={status} />
    },
    {
      title: 'Criado Em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: formDateInPtBr
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'actions',
      align: 'right',
      render: id => (
        <JbTableRowActionsComponent
          onEdit={() => props.onEdit(id)}
          onShow={() => props.onShow(id)}
          extra={[
            <Button
              icon={<PrinterOutlined />}
              onClick={() => props.onPrint ? props.onPrint(id) : null}
              size={'small'}
            />
          ]}
        />
      )
    }
  ]), [])
  
  return (
    <>
      <Table
        columns={columns}
        dataSource={props.dataSource}
        loading={props.loading}
        pagination={{...props.pagination}}
      />
    </>
  )
  
}