import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {AttendancePublicInteractionEntity} from "../entity/attendance-public-interaction.entity";
import {BaseResponse} from "../../core/common/base-response.common";

export function useAttendanceTypeRepository() {
  const crudHttpService = useJbHttpCrudService<AttendancePublicInteractionEntity>('attendance-public-interaction')

  function listInteractions(
    attendanceId: string
  ): Promise<BaseResponse<AttendancePublicInteractionEntity[]>> {
    return crudHttpService.get(
      `${crudHttpService.resourceName}/attendance/${attendanceId}`
    )
  }
  
  function createInteraction(
    attendanceId: string,
    data: any
  ): Promise<BaseResponse<AttendancePublicInteractionEntity>> {
    return crudHttpService.post(
      `${crudHttpService.resourceName}/attendance/${attendanceId}`,
      JSON.stringify(data)
    )
  }
  
  
  function uploadAttachment(attendanceId: string, file: File, createdBy: string) {
    const formData = new FormData()
    formData.set('file', file)
    formData.set('createdBy', createdBy);
    formData.set('createdAt', new Date().toISOString());
    return crudHttpService.put(
      `${crudHttpService.resourceName}/attendance/${attendanceId}/upload`,
      formData
    )
  }
  
  return {
    listInteractions,
    createInteraction,
    uploadAttachment,
    removeInteraction: crudHttpService.remove
  }
}
