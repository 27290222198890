import {Form, Modal, Select} from "antd";
import {JbInputNumberComponent} from "../../../core/form/jb-input-number.component";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {JbDatePickerComponent} from "../../../core/form/jb-date-picker.component";
import moment from "moment";


type RegisterPaymentFormComponent = {
  defaultValue: number,
  onSubmit: (values: any) => any,
}
export function RegisterPaymentFormComponent(props: RegisterPaymentFormComponent) {
  const [form] = Form.useForm();
  
  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={props.onSubmit}
      initialValues={{ amount: props.defaultValue, paymentDate: moment() }}
    >
      <Form.Item name={'amount'} label={'Valor'} rules={[{ required: true}]} >
        <JbInputNumberComponent />
      </Form.Item>
      <Form.Item name={'method'} label={'Forma de Pagamento'} rules={[{ required: true}]}>
        <Select
          options={[
            { label: 'Dinheiro', value: 'dinheiro' },
            { label: 'Pix', value: 'pix' },
            { label: 'Cartão Crédito / Débito', value: 'cartao' },
            { label: 'Boleto', value: 'boleto' },
            { label: 'Outro', value: 'outro' },
          ]}
        />
      </Form.Item>
      <Form.Item name={'paymentDate'} label={'Data de Baixa'} rules={[{ required: true }]}>
        <JbDatePickerComponent  />
      </Form.Item>
      <JbFormActionButtonComponent />
    </Form>
  )
}


export function ModalRegisterPaymentForm(props: {
  onClose: () => any,
} & RegisterPaymentFormComponent) {
  return (
    <Modal
      title={'Baixar Lançamento'}
      footer={null}
      onCancel={props.onClose}
      open
    >
      <RegisterPaymentFormComponent {...props}  />
    </Modal>
  )
}