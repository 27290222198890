import React, {useEffect, useState} from "react";
import {AttendanceEntity} from "../../attendance/domain/entity/attendance.entity";
import {useAttendanceRepository} from "../../attendance/domain/repository/attendance.repository";
import {ListAttendanceComponent} from "../../attendance/component/list-attendance.component";

export function ListClientAttendancesComponent(props: {
  clientId: string,
  onShow: (id: string) => any,
  onEdit: (id: string) => any,
}) {
  const [attendances, setAttendances] = useState<AttendanceEntity[]>([])
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [totalRegisters, setTotalRegisters] = useState(0);
  const attendanceRepo = useAttendanceRepository()
  
  useEffect(() => {
    attendanceRepo.list({
      page,
      limit,
      filter: {
        clientId: props.clientId
      }
    }).then(res => {
      setAttendances(res.data.result)
      setTotalRegisters(res.data.total)
    })
  }, [page, props.clientId])
  
  return (
    <ListAttendanceComponent
      onEdit={props.onEdit}
      onShow={props.onShow}
      dataSource={attendances}
      loading={attendanceRepo.loading}
      pagination={{
        pageSize: limit,
        current: page,
        total: totalRegisters,
        onChange: (page, pageSize) => {
          setPage(page)
          setLimit(pageSize)
        }
      }}
    />
  )
}