import {Col, Form, Input, Row, Switch} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {StepTypeEntity} from "../../../../../domain/entity/step-type.entity";
import {useEffect} from "react";
import {JbRichTextComponent, JbRichTextComponentField} from "../../../core/form/jb-rich-text.component";

export type StepTypeFormComponentProps = {
  onSubmit: (values: any) => any,
  values?: Partial<StepTypeEntity>
}
export function StepTypeFormComponent(props: StepTypeFormComponentProps) {
  const [form] = Form.useForm()

  useEffect(() => {
    if (props.values) {
      form.setFieldsValue(props.values)
    }
  }, [props])

  return (
    <Form form={form} layout={'vertical'} onFinish={props.onSubmit}>
      <Row gutter={16}>
        <Col span={18}>
          <Form.Item label={'Descrição'} name={'description'} required rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={'Audiência'} valuePropName={'checked'} name={'isJudicialHearing'}>
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      
      <JbRichTextComponentField
        name={'templateToFill'}
        label={'Template de Preenchimento'}
      />
      <JbRichTextComponentField
        name={'guidance'}
        label={'Orientações'}
      />
      <JbFormActionButtonComponent />
    </Form>
  )
}
