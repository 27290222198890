import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {AttendanceTypeEntity} from "../entity/attendance-type.entity";

export function useAttendanceTypeRepository() {
  const crudHttpService = useJbHttpCrudService<AttendanceTypeEntity>('attendance-type')

  return {
    ...crudHttpService,
  }
}
