import {Button, Form, Input, Select} from "antd";
import {useEffect, useMemo, useState} from "react";
import {WorkflowTaskEntity} from "../../../../../domain/entity/workflow-task.entity";
import {useStepTypeRepository} from "../../../../../domain/repository/step-type.repository";
import {StepTypeEntity} from "../../../../../domain/entity/step-type.entity";

export function FormTaskWorkflowComponent(props: {
  tasks: WorkflowTaskEntity[],
  onSubmit: (values: any) => any,
}) {
  const [stepTypes, setStepTypes] = useState<StepTypeEntity[]>([])
  const stepTypeRepo = useStepTypeRepository();
  const [taskForm] = Form.useForm();
  
  useEffect(() => {
    stepTypeRepo.list().then(res => setStepTypes(res.data))
  }, []);
  
  const stepTypeOpts = useMemo(() => {
    return stepTypes.map(w => ({
      label: w.description,
      value: w.id
    }))
  }, [stepTypes])
  
  function onSubmit(values: any) {
    props.onSubmit(values)
    taskForm.resetFields();
  }
  
  return (
    <Form onFinish={onSubmit} form={taskForm} layout={'vertical'}>
      <Form.Item rules={[{ required: true }]} name={'stepTypeId'} label={'Tipo de Movimentação'} >
        <Select options={stepTypeOpts} />
      </Form.Item>
      <Form.Item name={'daysNeeded'} label={'Quantidade de Dias'}  rules={[{ required: true }]}>
        <Input type={'number'} />
      </Form.Item>
      <Form.Item name={'observation'} label={'Informações Adicionais'}>
        <Input.TextArea />
      </Form.Item>
      <Button htmlType={'submit'} type={'primary'}>
        Adicionar Tarefa
      </Button>
    </Form>
  )
}