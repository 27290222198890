import {Col, Form, Input, Row} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {ClientEntity} from "../domain/entity/client.entity";
import {useEffect} from "react";

export function ClientCompanyPersonForm(props: {
  client: ClientEntity,
  onSubmit: (value: any) => any,
  currentData: any
}) {
  const [form] = Form.useForm();
  
  useEffect(() => {
    form.setFieldsValue(props.currentData);
  }, [props.currentData])
  
  function onFinish(values: any) {
    props.onSubmit(values)
  }
  
  return (
    <Form layout={'vertical'} form={form} onFinish={onFinish}>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={'fantasyName'} label={'Nome Fantasia'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'corporateName'} label={'Razão Social'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={16}>
          <Form.Item name={'legalNature'} label={'Natureza Jurídica'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'stateRegistration'} label={'Inscrição Estadual'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <JbFormActionButtonComponent />
    </Form>
  )
}