import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {AttendanceReportEntity} from "../entity/attendance-report.entity";
import {BaseResponse} from "../../core/common/base-response.common";
import {AttendanceStepReportEntity} from "../entity/attendance-step-report.entity";
import {ProcessReportEntity} from "../entity/process-report.entity";

export function useAttendanceReportRepository() {
  const crud = useJbHttpCrudService<AttendanceReportEntity>('attendance-report');
  
  function getReport(start: string ,end: string): Promise<BaseResponse<AttendanceReportEntity[]>> {
    let params: any = { start, end }
    const queryString = Object.keys(params).map((key: string) => `${key}=${params[key]}`).join('&')
    return crud.get(
      `${crud.resourceName}?${queryString}`
    )
  }
  
  function getStepsReport(start: string ,end: string): Promise<BaseResponse<AttendanceStepReportEntity[]>> {
    let params: any = { start, end }
    const queryString = Object.keys(params).map((key: string) => `${key}=${params[key]}`).join('&')
    return crud.get(
      `${crud.resourceName}/steps?${queryString}`
    )
  }
  
  function getProcessReport(
    start: string ,
    end: string
  ): Promise<BaseResponse<ProcessReportEntity[]>> {
    let params: any = { start, end }
    const queryString = Object.keys(params).map((key: string) => `${key}=${params[key]}`).join('&')
    return crud.get(
      `${crud.resourceName}/process?${queryString}`
    )
  }
  
  
  return {
    getReport,
    getStepsReport,
    getProcessReport
  }
}