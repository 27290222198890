import {ModulePageProps} from "../../../core/module-pages";
import React from "react";
import {AttendanceWizard} from "../component/attendance-wizard";

export function CreateAttendancePage(props: ModulePageProps) {
  return (
    <AttendanceWizard
      navigator={props.navigator}
    />
  )
}
