import {Button, Switch, Table} from "antd";
import {UserEntity} from "../../../../../domain/entity/user.entity";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {KeyOutlined} from "@ant-design/icons";

export function ListTenantUsersComponent(props: {
  users: UserEntity[],
  onEditRow: (row: UserEntity) => any,
  onToggleRow: (row: UserEntity) => any,
  onChangePassword: (row: UserEntity) => any
}) {
  return (
    <Table
      dataSource={props.users}
      columns={[
        {
          key: 'name',
          dataIndex: 'person',
          title: 'Nome',
          render: person => person.name,
        },
        {
          key: 'email',
          dataIndex: 'person',
          title: 'Email',
          render: person => person.email,
        },
        {
          key: 'username',
          dataIndex: 'username',
          title: 'Usuário de Acesso',
        },
        {
          key: 'active',
          dataIndex: 'active',
          title: 'Acesso Ativo',
          render: active => <Switch checked={active} />
        },
        {
          key: 'actions',
          dataIndex: 'id',
          title: '',
          render: (id, row) => (
            <JbTableRowActionsComponent
              onEdit={() => props.onEditRow(row) }
              extra={
                <Button
                  title={'Alterar Password'}
                  type={'link'}
                  icon={<KeyOutlined />}
                  size={'small'}
                  onClick={() => props.onChangePassword(row)}
                />
              }
            />
          )
        }
      ]}
    />
  )
}