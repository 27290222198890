import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React from "react";
import {UserFormComponent} from "../component/user.form.component";
import {useUserRepository} from "../../../../../domain/repository/user.repository";
import {ModulePageProps} from "../../../core/module-pages";

export function CreateUserPage(props: ModulePageProps) {
  const userRepository = useUserRepository()

  function onSubmit(values: any) {
    return userRepository.create(values).then(() => {
      props.navigator('/list')
    })
  }

  return (
    <>
      <JbPageHeaderComponent
        title={'Parametrizações / Novo Usuário'}
        description={'Criar Usuário'}
      />
      <UserFormComponent
        onSubmit={onSubmit}
        onCancel={() => props.navigator('list')}
      />
    </>
  )
}
