import {BaseResponse, BaseSearchResponse} from "../common/base-response.common";
import {useJbHttpAuthService} from "./jb-http-auth.service";

export function useJbHttpCrudService<T>(resourceName: string) {
  const {
    post,
    get,
    put,
    delete: httpDelete,
    loading,
    baseUrl,
    setLoading
  } = useJbHttpAuthService()

  function create(args: Partial<T>): Promise<BaseResponse<T>> {
    return post(`/${resourceName}`, args)
  }

  function edit(id: string, data: Partial<T>): Promise<BaseResponse<T>> {
    return put(`${resourceName}/${id}`, data)
  }

  function list(): Promise<BaseResponse<T[]>> {
    return get(`/${resourceName}`)
  }
  
  function search(limit: number, offset: number, filter: any,): Promise<BaseSearchResponse<T>> {
    const params = {
      limit,
      offset,
      ...filter
    }
    return get(
      `/${resourceName}`,
      null,
      {
        queryParams: params
      })
  }

  function find(id: string): Promise<BaseResponse<T>> {
    return get(`/${resourceName}/${id}`)
  }

  function remove(id: string): Promise<BaseResponse<T>> {
    return httpDelete(`/${resourceName}/${id}`)
  }

  return {
    create,
    edit,
    list,
    find,
    remove,
    post,
    get,
    search,
    put,
    delete: httpDelete,
    resourceName,
    loading,
    setLoading,
    baseUrl
  }
}
