import React, {useEffect, useState} from "react";
import {StepTypeEntity} from "../../../../../../domain/entity/step-type.entity";
import {useStepTypeRepository} from "../../../../../../domain/repository/step-type.repository";
import {Modal, Tabs} from "antd";
import {ListStepTypeMediaComponent} from "../../../step-type/component/list-step-type-media.component";
import {ListStepTypeLinkComponent} from "../../../step-type/component/list-step-type-link.component";

export function AttendanceStepMediaModal(props: { stepTypeId: string, closeModal: () => any }) {
  const [stepType, setStepType] = useState<StepTypeEntity>();
  const [selectedTab, setSelectedTab] = useState<string>('o');
  const stepTypeRepo = useStepTypeRepository()
  
  useEffect(() => {
    stepTypeRepo.find(props.stepTypeId).then(res => setStepType(res.data))
  }, [props.stepTypeId])
  
  return (
    <Modal
      open={true}
      title={stepType?.description}
      width={800}
      footer={null}
      onCancel={props.closeModal}
    >
      <Tabs onChange={setSelectedTab} defaultActiveKey={selectedTab} >
        <Tabs.TabPane tab={'Orientações'} key={'o'} />
        <Tabs.TabPane tab={'Links'} key={'l'} />
        <Tabs.TabPane tab={'Anexos'} key={'t'} />
      </Tabs>
      {
        stepType && selectedTab === 't' && (
          <ListStepTypeMediaComponent
            stepType={stepType}
            loading={stepTypeRepo.loading}
          />
        )
      }
      {
        stepType && selectedTab === 'l' && (
          <ListStepTypeLinkComponent
            stepType={stepType}
            loading={stepTypeRepo.loading}
          />
        )
      }
      {
        stepType && selectedTab === 'o' && (
          <div dangerouslySetInnerHTML={{__html: stepType?.guidance ?? '' }}/>
        )
      }
    </Modal>
  )
}