import axios from 'axios'
import env from '../../env.json'
import {useJbTokenStorageService} from "./jb-token-storage.service";

export function useJbHttpService() {
  const {getToken} = useJbTokenStorageService()

  const _env = process.env.NODE_ENV === 'development' ? 'dev' : 'prod';
  const axiosInstance = axios.create({
    baseURL: env[_env]['jb-base-url'],
    headers: {
      "Content-Type": "application/json"
    },
  })

  axiosInstance.interceptors.request.use((args) => {
    const token = getToken();
    if (token) {
      if (args) {
        args.headers = {
          ...args.headers,
          'Authorization': `Bearer ${token}`
        }
      }
    }
    return args
  })

  return {
    baseUrl: env[_env]['jb-base-url'],
    axiosInstance,
  };
}
