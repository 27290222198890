import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {FinanceEntryEntity} from "../entity/finance-entry.entity";
import {BaseResponse} from "../../core/common/base-response.common";
import {FinanceInsightEntity} from "../entity/finance-insight.entity";
import {FinanceHistoryEntity} from "../entity/finance-history.entity";
import {CashFlowItemEntity} from "../entity/cash-flow-item.entity";

export function useFinanceEntryRepository() {
  const crud = useJbHttpCrudService<FinanceEntryEntity>('finance-entry');
  
  function cancelEntry(id: string) {
    return crud.put(
      `${crud.resourceName}/${id}/cancel`,
      {}
    )
  }
  
  function registerPayment(entryId: string, amount: number, method: string, paymentDate: any) {
    return crud.post(
      `${crud.resourceName}/${entryId}/payment`,
      {
        entryId,
        amount,
        method,
        paymentDate
      }
    )
  }
  
  
  function loadPendingEntries(): Promise<BaseResponse<FinanceEntryEntity[]>> {
    return crud.get(`${crud.resourceName}/pending`);
  }
  
  function reportFinanceInsights(): Promise<BaseResponse<FinanceInsightEntity[]>> {
    return crud.get(`${crud.resourceName}/report/insights`);
  }
  
  function reportFinanceHistory(): Promise<BaseResponse<FinanceHistoryEntity>> {
    return crud.get(`${crud.resourceName}/report/history`);
  }
  
  function listCashFlowItems(start: string, end: string): Promise<BaseResponse<CashFlowItemEntity[]>> {
    return crud.get(
      `${crud.resourceName}/cash-flow`,
      undefined,
      {
        queryParams: {
          start, end
        }
      }
    )
  }
  
  return {
    ...crud,
    registerPayment,
    cancelEntry,
    loadPendingEntries,
    reportFinanceInsights,
    reportFinanceHistory,
    listCashFlowItems
  }
}