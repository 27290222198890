import React, {useEffect, useMemo, useState} from "react";
import {UserEntity} from "../../domain/entity/user.entity";
import {Avatar, Button, Col, Form, List, message, Row, Select} from "antd";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {useUserRepository} from "../../domain/repository/user.repository";
import {UserOutlined} from "@ant-design/icons";

export function AttendanceOwnersTabComponent(props: {
  attendanceId: string
}) {
  const [owners, setOwners] = useState<UserEntity[]>([])
  const [users, setUsers] = useState<UserEntity[]>([])
  const [form] = Form.useForm()

  const attendanceRepo = useAttendanceRepository();
  const userRepo = useUserRepository()

  function loadOwners(attendanceId: string) {
    return attendanceRepo
      .listOwners(attendanceId)
      .then(res => setOwners(res.data))
  }

  function loadUsers() {
    userRepo.list().then(res => setUsers(res.data))
  }

  function toggleOwner(userId: string) {
    const { attendanceId } = props;
    return attendanceRepo.toggleOwner({
      attendanceId,
      userId
    }).then(() => {
      form.resetFields()
      loadOwners(attendanceId)
    })
  }

  useEffect(() => {
    if (props.attendanceId) {
      loadOwners(props.attendanceId)
    }
    loadUsers()
  }, [props.attendanceId])

  const userOpts = useMemo<any>(() => {
    const ownersIds = owners.map(w => w.id)
    return users.filter(w => !ownersIds.includes(w.id))
      .map(w => ({
        label: w.person?.name ?? w.username,
        value: w.id
      }))
  }, [owners, users])

  return (
    <>
      <Form form={form} onFinish={values => toggleOwner(values.userId)}>
        <Row gutter={12}>
          <Col span={10}>
            <Form.Item name={'userId'} label={'Selecione um novo Responsável'}>
              <Select options={userOpts} />
            </Form.Item>
          </Col>
          <Col>
            <Button htmlType={'submit'} type={'primary'}>
              Adicionar
            </Button>
          </Col>
        </Row>
      </Form>
      <List
        dataSource={owners}
        loading={attendanceRepo.loading}
        renderItem={(item: UserEntity) => (
          <List.Item
            extra={[
              <Button onClick={() => toggleOwner(item.id)}>Remover</Button>
            ]}
          >
            <List.Item.Meta
              title={item.personName ?? item.username}
              avatar={
                <Avatar
                  icon={<UserOutlined />}
                />
              }
            />
          </List.Item>
        )}
      />
    </>
  )
}
