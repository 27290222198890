import {SupplierFormComponent} from "../component/supplier.form.component";
import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {useSupplierRepository} from "../../../../../domain/repository/supplier.repository";

export function CreateSupplierPage(props: ModulePageProps) {
  const supplierRepo = useSupplierRepository();
  
  function onCreate(values: any) {
    supplierRepo.create(values).then(() => {
      props.navigator('list')
    })
  }
  
  return (
    <>
      <JbPageHeaderComponent title={'Novo Item'} />
      <SupplierFormComponent
        onSubmit={onCreate}
        onCancel={() => props.navigator('list')}
      />
    </>
  )
}