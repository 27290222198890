import {AttendanceStepEntity} from "../../attendance/domain/entity/attendance-step.entity";
import {Col, Row, Statistic} from "antd";
import React, {useMemo} from "react";

export function InsightsDashboardComponent(props: {
  steps: AttendanceStepEntity[]
}) {
  const totalAttendances = useMemo(() => {
    const attendances = new Set<string>();
    props.steps.forEach(w => attendances.add(w.attendanceId))
    return attendances.size;
  }, [props.steps])
  
  const totalSteps = useMemo(() => props.steps.length, [props.steps]);
  const finished = useMemo(() => props.steps.filter(w => w.finished).length, [props.steps])
  const pending = useMemo(() => props.steps.filter(w => !w.finished).length, [props.steps])
  return (
    <div style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 20
    }}>
        <Statistic
          style={{textAlign: 'center'}}
          title={'Atendimentos'}
          value={totalAttendances}
        />
        <Statistic  style={{textAlign: 'center'}} title={' Movimentações'} value={totalSteps} />
        <Statistic  style={{textAlign: 'center'}} title={' Finalizadas'} value={finished} />
        <Statistic  style={{textAlign: 'center'}} title={' Pendentes'} value={pending} />
    </div>
  )
}