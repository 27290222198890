import {InputNumber, InputNumberProps} from "antd";

export function JbInputNumberComponent(props: InputNumberProps) {
  
  const formatter = (value: any) => {
    const { format } = new Intl.NumberFormat('pt')
    if (!value) return '';
    if (typeof value === 'number') return format(value);
    if (typeof value === 'string') {
      try {
        return format(parseFloat(value))
      } catch (e) {
        console.error(e);
        return '';
      }
    }
    return '';
  }
  
  
  const parser = (value: any) => {
    if (!value) return 0;
    if (typeof value === 'string') return parseFloat(
      value
        .split('.')
        .join('')
        .split(',')
        .join('.')
    );
    return '';
  }
  
  return (
    <InputNumber
      style={{width: '100%'}}
      lang={'pt'}
      formatter={formatter}
      parser={parser}
      {...props}
     />
  )
}