import {useJbHttpAuthService} from "./jb-http-auth.service";

export function useJbHttpTjService() {
  const { get, loading } = useJbHttpAuthService()
  
  function search(sg: string, processNumber: string): Promise<any> {
    processNumber = processNumber.split('.').join('').split('-').join('');
    return get(
      `data-jud?sg=${sg}&processNumber=${processNumber}`
    )
  }
  
  return {
    search,
    loading,
  }
}