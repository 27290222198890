import {ModulePages} from "../../core/module-pages";
import {ListAttendanceTypePage} from "./page/list.attendance-type";
import {CreateAttendanceTypePage} from "./page/create.attendance-type";
import {EditAttendanceTypePage} from "./page/edit.attendance-type";

export function AttendanceTypeModule(props: any) {
  return (
    <ModulePages routes={[
        { path: 'create', render: (props) => <CreateAttendanceTypePage {...props} /> },
        { path: 'edit/:id', render: (props) => <EditAttendanceTypePage {...props} /> },
        { path: 'list', render: (props) => <ListAttendanceTypePage {...props} /> },
        { path: '', render: (props) => <ListAttendanceTypePage {...props} /> },
      ]}
   />
  )
}
